import { format, isToday } from "date-fns";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { ThreadEdgeSimple } from "@utility-types";
import { Button } from "components/design-system/Button";
import { Form, SubmitButton } from "components/design-system/Forms";
import DatePicker from "components/design-system/Forms/DatePicker";
import TimePicker from "components/design-system/Forms/TimePicker";
import { Footer, Header, Main } from "components/ModalKit";
import { useModalState } from "components/ModalKit/ModalProvider";
import { StandardModal } from "components/Modals";
import useInboxThreadActions from "hooks/thread/useInboxThreadActions";
import useModalStore from "store/useModalStore";

type FormValues = {
  date: Date;
  time: string;
};

const FormContent = () => {
  const { watch, resetField } = useFormContext<FormValues>();
  const { date } = watch();

  useEffect(() => {
    if (!isToday(date)) return;
    resetField("time");
  }, [date, resetField]);

  return (
    <div className="flex grow gap-8 py-20">
      <div className="w-3/5">
        <DatePicker
          name="date"
          placeholder="Select a date"
          calendarProps={{ disabled: { before: new Date() } }}
        />
      </div>
      <div className="w-2/5">
        <TimePicker name="time" fromNow={isToday(date)} />
      </div>
    </div>
  );
};

const ThreadReminderCustomModal = ({ threadEdge }: { threadEdge: ThreadEdgeSimple }) => {
  const { modalId } = useModalState(({ modalId }) => ({
    modalId,
  }));
  const { closeModal, topModalState } = useModalStore(({ closeModal, topModalState }) => ({
    closeModal,
    topModalState,
  }));
  const { markThreadEdges } = useInboxThreadActions();
  const addReminder = ({ date, time }: FormValues) => {
    const reminderDate = new Date(`${date.toDateString()} ${time}`).toISOString();
    markThreadEdges({
      selection: { threadEdges: [threadEdge] },
      remindAt: reminderDate,
    });
    closeModal(modalId);
  };
  return (
    <StandardModal contentClassName="md:max-w-[564px]">
      <Header variant="bordered">
        Remind me
        <div className="flex grow" />
        <span className="bg-background-secondary rounded-half text-text-subtle text-footnote-bold px-12 py-3 mr-12">
          {format(new Date(), "z").replace("GMT", "UTC")}
        </span>
      </Header>
      <Form<FormValues>
        className="flex flex-col"
        useFormProps={{ defaultValues: { date: new Date() } }}
        onSubmit={addReminder}
      >
        <Main className="py-24 pl-32 pr-20">
          <FormContent />
        </Main>
        <Footer>
          <Button buttonStyle="subtle" onClick={() => closeModal(modalId)} type="button">
            Cancel
          </Button>
          <SubmitButton disabled={topModalState === "closing"}>Set reminder</SubmitButton>
        </Footer>
      </Form>
    </StandardModal>
  );
};

export default ThreadReminderCustomModal;
