import { GroupPreview, User } from "@utility-types";
import { routeToGroup } from "components/routing/utils";
import { FeedType } from "generated/graphql";
import { formatGroupName } from "utils/group/formatGroupName";

import ActivityItemLayout from "./ActivityItemLayout";

type Props = {
  actor: User;
  isRead: boolean;
  onClick: (e: React.MouseEvent<HTMLLIElement>) => void;
  selected: boolean;
  target: GroupPreview;
  timestamp: string;
};

const ActivityItemAddedTo = ({ actor, target, ...props }: Props): JSX.Element | null => {
  const { name: groupName, emoji } = formatGroupName(target);

  return (
    <ActivityItemLayout
      type={FeedType.Groups}
      groupType="Default"
      avatarProps={{
        emojiProps: { emoji },
        name: groupName,
        background: "transparent",
      }}
      title={`${actor.name} added you to ${target.name}`}
      linkTo={toSecondary =>
        routeToGroup({
          groupID: target.id,
          to: toSecondary ? "secondary" : undefined,
        })
      }
      {...props}
    >
      <div>
        <div className="text-text-primary text-subhead-bold">{groupName}</div>
        <div className="flex text-text-primary text-body">
          {target.members.totalCount} member
          {target.members.totalCount !== 1 ? "s" : ""}
          {target.description ? " · " : ""}
          {target.description}
        </div>
      </div>
    </ActivityItemLayout>
  );
};

export default ActivityItemAddedTo;
