import { useApolloClient } from "@apollo/client";
import { uniqBy } from "lodash-es";
import { useCallback, useEffect, useRef, useState } from "react";
import { FormatMessageResponse } from "stream-chat";
import { Channel } from "stream-chat/dist/types/channel";
import { ChannelStateReducerAction } from "stream-chat-react/dist/components/Channel/channelState";

import { GlueDefaultStreamChatGenerics, nodeAs } from "@utility-types";
import {
  ThreadMessagesDocument,
  ThreadMessagesQuery,
  useFetchThreadEdgeQuery,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import { ksuid } from "utils/ksuid";
import { glueMessageToStreamMessageResponse } from "utils/stream/message";

export default function useLoadInitialMessages<T extends GlueDefaultStreamChatGenerics>({
  channel,
  dispatch,
  messageLimit,
  scrollToBottom,
}: {
  channel: Channel<T>;
  dispatch: (value: ChannelStateReducerAction<T>) => void;
  messageLimit: number;
  scrollToBottom: () => void;
}) {
  const { authData, authReady } = useAuthData();
  const apolloClient = useApolloClient();

  const { data: threadEdgeData } = useFetchThreadEdgeQuery({
    fetchPolicy: authReady ? "cache-first" : "cache-only",
    variables: { id: `${channel.id}-${authData?.me.id}` },
  });

  const replyCount = nodeAs(threadEdgeData?.node, ["ThreadEdge"])?.node.recentMessages.replyCount;

  const messageCount = channel.state.messages.length;

  const hasMoreMessages = !!replyCount && replyCount > messageCount && messageLimit > messageCount;

  const [init, setInit] = useState<"loading" | "loaded">(
    hasMoreMessages || replyCount === undefined ? "loading" : "loaded"
  );

  const pageInfoRef = useRef({
    startCursor: null as string | null,
    hasNextPage: hasMoreMessages,
  });

  pageInfoRef.current.startCursor ??= ksuid(
    new Date(channel.state.messages[0]?.created_at || Date.now()).valueOf()
  );

  const loadMoreGlue = useCallback(async () => {
    const thread = nodeAs(
      (
        await apolloClient.query<ThreadMessagesQuery>({
          query: ThreadMessagesDocument,
          fetchPolicy: "no-cache",
          variables: {
            id: channel.id || "",
            last: messageLimit,
            before: pageInfoRef.current.startCursor,
          },
        })
      )?.data.node,
      ["Thread"]
    );

    if (!thread) {
      return { hasMore: false, newMessageCount: 0 };
    }

    const messages: FormatMessageResponse<GlueDefaultStreamChatGenerics>[] =
      thread.messages.edges.map(e =>
        channel.state.formatMessage(glueMessageToStreamMessageResponse(e.node))
      );

    const newMessages = uniqBy([...messages, ...channel.state.messages], m => m.id);

    if (newMessages[0]?.id === channel.state.messages[0]?.id) {
      pageInfoRef.current = {
        startCursor: null,
        hasNextPage: false,
      };
      return {
        hasMore: false,
        newMessageCount: 0,
      };
    }

    const { startCursor, hasNextPage } = thread.messages.pageInfo;
    pageInfoRef.current = { startCursor, hasNextPage };

    channel.state.messages = newMessages;

    dispatch({ channel, type: "copyStateFromChannelOnEvent" });

    return {
      hasMore: hasNextPage,
      newMessageCount: messages.length,
    };
  }, [apolloClient, channel, dispatch, messageLimit]);

  const loadMoreMessages = useCallback(
    async (loadMoreStream?: (limit: number) => Promise<number | void>) => {
      if (pageInfoRef.current.hasNextPage) {
        loadMoreGlue();
        return;
      }

      await loadMoreStream?.(messageLimit);

      if (replyCount && replyCount > channel.state.messages.length) {
        loadMoreGlue();
      }
    },
    [channel.state.messages.length, loadMoreGlue, messageLimit, replyCount]
  );

  const loadMoreGlueRef = useRef(loadMoreGlue);
  loadMoreGlueRef.current = loadMoreGlue;

  useEffect(() => {
    if (init === "loaded" || !authReady) return;

    if (hasMoreMessages) {
      loadMoreGlueRef.current().finally(() => setInit("loaded"));
      return;
    }

    setInit("loaded");
  }, [authReady, init, hasMoreMessages]);

  useEffect(() => {
    if (init !== "loaded") return;
    scrollToBottom();
  }, [init, scrollToBottom]);

  return {
    hasNextGluePage: pageInfoRef.current.hasNextPage,
    loadingMessages: hasMoreMessages && init === "loading",
    loadMoreMessages,
  };
}
