import { User } from "@utility-types";
import IdentityBadge from "components/design-system/ui/IdentityBadge/IdentityBadge";
import { AdminUser } from "components/Icons";

import { parameters } from "./profileParameters";
import { ProfileItemProps } from "./types";

type Props = Pick<ProfileItemProps, "description" | "domains" | "size" | "recipient"> & {
  admin?: User;
};

const ProfileDescription = ({
  admin,
  description,
  domains,
  size,
  recipient,
}: Props): JSX.Element | null => {
  if (!description && !domains?.length) return null;

  return (
    <div
      className={`flex flex-row w-full min-w-0 mt-2 items-center
        ${parameters[size].descriptionClassNames} font-normal text-text-subtle`}
      data-testid="profile-description-container"
    >
      {!!description && (!domains || domains.length > 1 || description !== domains[0]) && (
        <span className="inline-block mr-4 h-18 truncate">{description}</span>
      )}
      <IdentityBadge recipient={recipient} />
      {!!admin && (
        <div className="flex flex-row ml-12">
          <AdminUser className="mr-4" />
          <span className="font-semibold">{admin.name}</span>
        </div>
      )}
    </div>
  );
};
export default ProfileDescription;
