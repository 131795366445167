import { scan } from "react-scan"; // must be imported before react
// eslint-disable-next-line import/order
import { useLayoutEffect } from "react";
import { FeatureFlag, useFeatureFlagEnabled } from "featureFlags";

const useDeveloperTools = () => {
  const isReactScanEnabled = useFeatureFlagEnabled(FeatureFlag.REACT_SCAN);

  useLayoutEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      return;
    }

    scan({
      enabled: !!isReactScanEnabled,
      showToolbar: !!isReactScanEnabled,
      animationSpeed: "fast",
      log: false,
    });
  }, [isReactScanEnabled]);
};

export default useDeveloperTools;
