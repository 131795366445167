import { Button } from "components/design-system/Button";
import Hr from "components/design-system/Hr";
import { Icon } from "components/design-system/icons";
import { InformationBubble } from "components/design-system/InformationBubble";
import { planDisplayName } from "components/Payment/utils/planDisplayName";
import { SubscriptionStatus, WorkspaceSubscriptionFieldsFragment } from "generated/graphql";

import useManageBilling from "../hooks/useManageBilling";

import { planDate } from "./utils";

const CurrentPlan = ({
  daysRemaining = 0,
  subscription,
  workspaceID,
}: {
  daysRemaining?: number;
  subscription: WorkspaceSubscriptionFieldsFragment;
  workspaceID: string;
}) => {
  const { fetchingBillingURL, openManageBilling } = useManageBilling(workspaceID);

  // trialing:
  if (!subscription.plan) {
    return (
      <>
        <div className="flex flex-col mb-32 px-32">
          <div className="text-body-bold text-text-primary">Free trial</div>
          {daysRemaining > 0 ? (
            <div className="text-body text-text-secondary">
              You are currently on a free trial with{" "}
              <span className="text-body-bold">{daysRemaining} days</span> remaining.
            </div>
          ) : (
            <>
              <div className="text-body text-text-secondary">
                Your free trial has <span className="text-body-bold">expired</span>.
              </div>
              <InformationBubble className="mt-8" iconProps={{ className: "text-icon-alert" }}>
                Choose a plan to maintain access to your workspace.
              </InformationBubble>
            </>
          )}
        </div>
        <Hr className="my-20" />
      </>
    );
  }

  // has plan:
  const statusMessages = {
    [SubscriptionStatus.Active]:
      subscription.cancelAtPeriodEnd &&
      `Your subscription ends on ${planDate(subscription.currentPeriodEnd)}`,
    [SubscriptionStatus.Trialing]:
      subscription.cancelAtPeriodEnd &&
      `Your subscription ends on ${planDate(subscription.currentPeriodEnd)}`,
    [SubscriptionStatus.Canceled]: "Your subscription was canceled.",
    [SubscriptionStatus.PastDue]: "Payment failed. Please update your billing details.",
    [SubscriptionStatus.Unpaid]: "Your subscription has expired.",
  };
  const statusMessage = statusMessages[subscription.status];

  return (
    <>
      <div className="flex flex-col mb-32 px-32 text-body">
        <div className="flex items-center">
          <div className="grow min-w-0">
            <div className="text-body-bold text-text-primary">Current plan</div>
            <div className="grow min-w-0 text-text-secondary">
              {planDisplayName(subscription.plan)}
              {` - ${subscription.quantity} users`}
              <br />
              {subscription.status !== SubscriptionStatus.Canceled &&
                subscription.cancelAtPeriodEnd &&
                `Renews on ${planDate(subscription.currentPeriodEnd)}`}
            </div>
          </div>
          {subscription.status !== SubscriptionStatus.Canceled && (
            <Button
              buttonFont="subheadBold"
              buttonStyle="action"
              buttonType="none"
              disabled={fetchingBillingURL}
              onClick={openManageBilling}
              type="button"
            >
              <span className="inline-flex gap-4 items-center">
                Manage billing
                <Icon icon="ExternalLink" size={14} />
              </span>
            </Button>
          )}
        </div>

        {statusMessage && (
          <InformationBubble className="mt-8" iconProps={{ className: "text-icon-alert" }}>
            {statusMessage}
          </InformationBubble>
        )}
      </div>
      <Hr className="my-20" />
    </>
  );
};

export default CurrentPlan;
