import { produce } from "immer";
import { devtools, persist } from "zustand/middleware";

import { ColorSchemeTypes } from "@utility-types";
import { ThreadsOrder } from "generated/graphql";

import { glueCreateZustand } from "./helper/glueCreate";

const STORE_NAME = "LocalSettingsStore";

type LocalSettingsStore = {
  codeblockLanguageSetting: string;
  collapsedSidebarSections: string[];
  colorScheme: ColorSchemeTypes;
  colorSchemeActive: ColorSchemeTypes;
  colorSchemePreview: ColorSchemeTypes;
  currentThreadSort: ThreadsOrder;
  dismissedDownloadBanner?: boolean;
  enterKeyBehavior: "new-line" | "send-message";
  hasSentMessageViaCMDEnter?: boolean;
  notificationAskedAt?: number;
  updateSidebarSections: (section: string, collapsed: boolean) => void;
};

const useLocalSettingsStore = glueCreateZustand<LocalSettingsStore>({
  name: STORE_NAME,
})(
  devtools(
    persist(
      set => ({
        codeblockLanguageSetting: "text",
        collapsedSidebarSections: [],
        colorScheme: ColorSchemeTypes.Default,
        colorSchemeActive: ColorSchemeTypes.Default,
        colorSchemePreview: ColorSchemeTypes.Default,
        currentThreadSort: ThreadsOrder.LastMessage,
        enterKeyBehavior: "new-line",
        updateSidebarSections: (section, collapsed) =>
          set(
            produce(state => {
              const index = state.collapsedSidebarSections.indexOf(section);
              if (index > -1) {
                !collapsed && state.collapsedSidebarSections.splice(index, 1);
              } else {
                collapsed &&
                  (state.collapsedSidebarSections = [...state.collapsedSidebarSections, section]);
              }
            })
          ),
      }),
      {
        migrate: (s, v) => {
          if (v === 0) {
            delete (s as Record<string, unknown>).grpState;
            delete (s as Record<string, unknown>).chatState;
          }
          if (v <= 6) {
            delete (s as Record<string, unknown>).restoreRoute;
            delete (s as Record<string, unknown>).routes;
            delete (s as Record<string, unknown>).routesStack;
          }
          return s as LocalSettingsStore;
        },
        name: STORE_NAME,
        partialize: state => {
          const stateToPersist = Object.fromEntries(
            Object.entries(state).filter(([key]) => !["updateSidebarSections"].includes(key))
          );
          return {
            ...stateToPersist,
          };
        },
        version: 6,
      }
    ),
    {
      name: STORE_NAME,
    }
  )
);

export default useLocalSettingsStore;
