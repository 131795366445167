import {
  FloatingArrow,
  Placement,
  arrow,
  autoUpdate,
  flip,
  offset,
  safePolygon,
  shift,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import { animated, useTransition } from "@react-spring/web";
import { Dispatch, SetStateAction, cloneElement, useEffect, useMemo, useRef } from "react";

import { variantProps } from "components/helper/classNameVariants";
import { Portal } from "components/Portal";
import useUpdatingState from "hooks/useUpdatingState";
import useSafeAreaInsetsStore from "store/useSafeAreaInsetsStore";
import { deviceHasMouse } from "utils/deviceHasMouse";
import tw from "utils/tw";

export type TooltipProps = {
  children: JSX.Element;
  className?: string;
  closeOnEscape?: boolean;
  content: React.ReactNode;
  disabled?: boolean;
  isOpenListener?: Dispatch<SetStateAction<boolean>>;
  keepOpenOnHover?: boolean;
  open?: boolean;
  placement?: Placement;
  showArrow?: boolean;
  tooltipStyle?: "default" | "inverted";
};

const isTestEnv = process.env.NODE_ENV === "test";

const TooltipInner = ({
  children,
  className: classNameProp,
  content,
  closeOnEscape = true,
  disabled,
  isOpenListener,
  keepOpenOnHover,
  open = false,
  placement: defaultPlacement = "top",
  showArrow = true,
  tooltipStyle = "default",
}: TooltipProps) => {
  const className = {
    base: tw("border-border-container border-thin rounded-md shadow-strong-ui", "w-max max-w-sm", {
      "max-w-[256px] py-5 px-10 text-sm leading-5 text-center": typeof content === "string",
    }),
    variants: {
      tooltipStyle: {
        default: "bg-background-body",
        inverted: "bg-background-strong text-text-inverse",
      },
    },
  };

  const tooltipProps = variantProps(className);
  // const [arrowRef, setArrowRef] = useState<SVGSVGElement | null>(null);
  const arrowRef = useRef(null);
  const [isOpen, setIsOpen] = useUpdatingState(open);
  const padding = useSafeAreaInsetsStore();

  useEffect(() => {
    isOpenListener?.(isOpen);
  }, [isOpen, isOpenListener]);

  const { context, floatingStyles, refs } = useFloating({
    middleware: [
      offset(4),
      flip({ crossAxis: false, padding }),
      shift({ padding }),
      arrow({
        element: arrowRef,
      }),
    ],
    onOpenChange: setIsOpen,
    open: isOpen,
    placement: defaultPlacement,
    strategy: "fixed",
    whileElementsMounted: autoUpdate,
  });
  const dismiss = useDismiss(context, { escapeKey: closeOnEscape });
  const hover = useHover(context, {
    delay: { close: 0, open: isTestEnv ? 0 : 500 },
    handleClose: keepOpenOnHover ? safePolygon({ blockPointerEvents: false }) : undefined,
    mouseOnly: !isTestEnv,
    move: false,
  });
  const role = useRole(context, { role: "tooltip" });

  const { getFloatingProps, getReferenceProps } = useInteractions([dismiss, hover, role]);

  const referenceProps = useMemo(() => {
    const nextReferenceProps = getReferenceProps({
      ref: refs.setReference,
    });
    delete nextReferenceProps.onPointerDown; // prevents clobbering existing onPointerDown events on `children`, below
    return nextReferenceProps;
  }, [getReferenceProps, refs.setReference]);

  const transitions = useTransition(isOpen, {
    config: { friction: 100, mass: 2, tension: 2000 },
    enter: { opacity: 1, scale: 1 },
    from: { opacity: 0, scale: 0.75 },
    leave: { opacity: 0, scale: 0.75 },
  });

  if (disabled) return children;

  return (
    <>
      {cloneElement(children, {
        ...(referenceProps ?? {}),
      })}
      <Portal id="overlays" zIndex="100">
        {transitions(
          (styles, open) =>
            open && (
              <animated.div
                ref={refs.setFloating}
                data-testid="tooltip"
                style={{
                  ...floatingStyles,
                  ...styles,
                }}
                {...tooltipProps({ tooltipStyle, className: classNameProp })}
                {...getFloatingProps()}
              >
                {content}
                {
                  // `!isTestEnv` prevents error when running tests,
                  // "NaN is an invalid value for the left css style property"
                  !isTestEnv && showArrow && (
                    <FloatingArrow
                      ref={arrowRef}
                      className={tw(
                        "[&>path:first-of-type]:stroke-interactive-strong/15",
                        {
                          "fill-background-strong": tooltipStyle === "inverted",
                        },
                        { "fill-background-modal": tooltipStyle === "default" }
                      )}
                      context={context}
                      strokeWidth={0.5}
                    />
                  )
                }
              </animated.div>
            )
        )}
      </Portal>
    </>
  );
};

const hasMouse = deviceHasMouse();

// this wrapper prevents the tooltip from rendering on mobile, so we're not processing floating-ui setup for no reason
const Tooltip = (props: TooltipProps) => {
  if (hasMouse && props.content && !props.disabled) {
    return <TooltipInner {...props} />;
  }

  return props.children;
};

export default Tooltip;
