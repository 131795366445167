import VideoElement from "components/Media/Video";
import { ExternalObjectMedia } from "generated/graphql";
import { parseProxiedUrl } from "utils/fixProxiedUrl";
type Props = {
  image?: string;
  title: string;
  video: Pick<ExternalObjectMedia, "contentType" | "url">;
};

export const VideoPreview = ({ image, title, video }: Props): JSX.Element => (
  <div data-testid="video-preview" onClick={e => e.preventDefault()}>
    {video.contentType === "text/html" ? (
      <iframe
        allow="fullscreen"
        className="w-full aspect-video"
        sandbox="allow-scripts allow-same-origin allow-presentation"
        src={parseProxiedUrl(video.url).replace("autoplay=1", "autoplay=0")} // disable autoplay for both Youtube and Vimeo
        title={title}
      />
    ) : (
      <VideoElement
        className="w-full aspect-video"
        containerClassName="border-b border-border-container"
        poster={image || undefined}
        src={video.url}
        title={title}
        type={video.contentType || "video/mp4"}
        controls
      />
    )}
  </div>
);

export default VideoPreview;
