import { ComponentProps } from "react";

import Avatar from "components/design-system/Avatar/Avatar";
import { Icon } from "components/design-system/icons";
import PrimaryPill from "components/design-system/PrimaryPill";
import tw from "utils/tw";

export const Sidebar = ({ children }: WithChildren) => {
  return (
    <div className="h-full w-full md:w-[280px] shrink-0">
      <div className="bg-background-body md:bg-background-app h-full w-full">{children}</div>
    </div>
  );
};

export const SidebarItem = ({
  avatarURL,
  children,
  icon,
  isActive,
  name,
  onClick,
  showPrimaryPill,
}: WithChildren<{
  avatarURL?: string;
  isActive?: boolean;
  icon?: ComponentProps<typeof Icon>["icon"];
  name?: string;
  onClick?: () => void;
  showPrimaryPill?: boolean;
}>) => {
  return (
    <button
      className={tw(
        "flex items-center justify-between",
        "h-44 md:h-32 px-8 rounded-md text-body text-left text-text-primary w-full",
        "focus-visible-shadow hover:bg-background-list-hover",
        {
          "bg-background-list-selected hover:bg-background-list-selected text-body-bold": isActive,
        }
      )}
      onClick={onClick}
      type="button"
    >
      <span className="flex gap-8 items-center grow min-w-0">
        <Avatar
          avatarURL={avatarURL}
          background={icon ? "transparent" : undefined}
          className={icon ? (isActive ? "text-icon-primary" : "text-icon-secondary") : undefined}
          iconProps={{ icon: icon, size: 20 }}
          name={name}
          priority={["icon", "avatar", "name"]}
          size="x-small"
        />
        <span className="grow min-w-0 truncate">{children}</span>
      </span>
      {showPrimaryPill ? <PrimaryPill /> : null}
      <Icon className="md:hidden" icon="ChevronRight" size={20} />
    </button>
  );
};
