import { BaseFlagConfig } from "./types";

export enum ExternalFeatureFlag {
  ReadReceipts = "ReadReceipts",
}

export type ExternalFeatureFlagConfig = BaseFlagConfig & {
  /** The fallback value if the flag is not found or an error is encountered during evaluation. */
  defaultValue: boolean;
  source: "posthog";
};

export const externalFeatureFlagConfigs: Record<ExternalFeatureFlag, ExternalFeatureFlagConfig> = {
  [ExternalFeatureFlag.ReadReceipts]: {
    defaultValue: false,
    source: "posthog",
    key: "is-read-receipts-enabled",
  },
};
