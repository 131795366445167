import { ComponentProps } from "react";

import { ModalProps } from "components/ModalKit/Modal";
import { Main } from "components/ModalKit/Parts";
import { StandardModal } from "components/Modals";
import useAppStateStore from "store/useAppStateStore";

import ThreadCompose from "./ThreadCompose/ThreadCompose";

type Props = ModalProps & Omit<ComponentProps<typeof ThreadCompose>, "isModal">;

const ThreadComposeModal = ({
  draftID,
  initialDraft,
  recipientsRemoved,
  replyToMessage,
  ...props
}: Props) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  return (
    <StandardModal
      closeButtonPosition="right"
      closeButtonTitle="Save & close"
      contentClassName="md:!max-w-[800px]"
      {...props}
    >
      <Main>
        <ThreadCompose
          draftID={draftID}
          initialDraft={initialDraft}
          recipientsRemoved={recipientsRemoved}
          replyToMessage={replyToMessage}
          isModal
          variant={breakpointMD ? undefined : "thread-compose-border-none"}
        />
      </Main>
    </StandardModal>
  );
};

export default ThreadComposeModal;
