import { createZustandStoreContext } from "create-zustand-store-context";
import { useEffect } from "react";

import { ModalElement } from "./types";
import { ModalState } from "./types";

import { useModalDragging } from ".";

type ModalStore = {
  dragging: {
    bind: ReturnType<typeof useModalDragging>["bind"];
    dragStyles: ReturnType<typeof useModalDragging>["dragStyles"];
  };
  enqueuedToClose?: boolean;
  modalId: ModalElement["id"];
  state?: ModalState;
};

const defaultState: ModalStore = {
  dragging: { bind: () => ({}), dragStyles: {} },
  enqueuedToClose: false,
  modalId: "",
  state: "closed",
};

const {
  storeContext,
  useState: useModalState,
  useStore: useModalStore,
} = createZustandStoreContext<ModalStore>({
  defaultState,
  name: "ModalProvider",
});

const ModalStoreContext = storeContext;

const ModalProvider = ({
  children,
  enqueuedToClose,
  modalId,
  state,
}: WithChildren<Pick<ModalStore, "enqueuedToClose" | "modalId" | "state">>) => {
  const dragging = useModalDragging(modalId);

  const currentStore = useModalStore(undefined, {
    dragging,
    enqueuedToClose,
    modalId,
    state,
  });

  useEffect(
    () =>
      currentStore.setState({
        enqueuedToClose,
        modalId,
        state,
      }),
    [currentStore, enqueuedToClose, modalId, state]
  );

  return <ModalStoreContext.Provider value={currentStore}>{children}</ModalStoreContext.Provider>;
};

const MockModalProvider = ({ children }: WithChildren) => {
  const currentStore = useModalStore(undefined, {
    modalId: "mock-modal-id",
    state: "open",
  });

  return <ModalStoreContext.Provider value={currentStore}>{children}</ModalStoreContext.Provider>;
};

export { MockModalProvider, ModalProvider, useModalState };
