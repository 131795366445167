import { isEmptyArray } from "@remirror/core";
import {
  EmojiSuggestHandler,
  EmojiSuggestHandlerCommand,
  EmojiSuggestHandlerProps,
  FlatEmoji,
} from "@remirror/extension-emoji";
import { MenuNavigationOptions, UseMenuNavigationReturn, useMenuNavigation } from "@remirror/react";
import { useExtensionEvent, useHelpers } from "@remirror/react-core";
import { useCallback, useMemo, useState } from "react";

import { getEmojisByName } from "components/design-system/Emoji/data";

import GlueEmojiExtension from "../remirror-extensions/GlueEmojiExtension";

export interface EmojiState extends Pick<EmojiSuggestHandlerProps, "range" | "query"> {
  /**
   * The command to run to replace the query with the request emoji.
   *
   * @default undefined
   */
  apply: EmojiSuggestHandlerCommand;
  /**
   * The list of emoji generated by the query.
   *
   * @default []
   */
  list: FlatEmoji[];
}

export type UseEmojiProps = MenuNavigationOptions;

export interface UseEmojiReturn extends UseMenuNavigationReturn<FlatEmoji> {
  /**
   * The state of the current query, only available when active.
   */
  state: EmojiState | null;
}

const emptyEmoji: never[] = [];

/**
 * This hook provides the state for setting up an emoji state change handler. It
 * applies the keybindings and the required change handlers.
 */
export function useEmoji(props: UseEmojiProps = {}): UseEmojiReturn {
  const { direction, dismissKeys, focusOnClick, submitKeys } = props;
  const [state, setState] = useState<EmojiState | null>(null);
  const helpers = useHelpers();
  const items = state?.list ?? emptyEmoji;
  const isOpen = !!state;

  const onDismiss = useCallback(() => {
    if (!state) {
      return false;
    }

    // Ignore the current mention so that it doesn't show again for this
    // matching area
    helpers
      .getSuggestMethods()
      .addIgnored({ from: state.range.from, name: "emoji", specific: true });

    setState(null);
    return true;
  }, [helpers, state]);

  const onSubmit = useCallback(
    (emoji: FlatEmoji) => {
      if (!state || isEmptyArray(state.list)) {
        return false;
      }

      state.apply(emoji.emoji);

      return true;
    },
    [state]
  );

  const menu = useMenuNavigation({
    direction,
    dismissKeys,
    focusOnClick,
    isOpen,
    items,
    onDismiss,
    onSubmit,
    submitKeys,
  });
  const { setIndex } = menu;

  const onChange: EmojiSuggestHandler = useCallback(
    props => {
      const { apply, change, exit, query, range } = props;

      getEmojisByName(query, 20).then(emojis => {
        if (change) {
          setIndex(0);
          setState({
            apply: code => {
              setState(null);
              return apply(code);
            },
            list: emojis,
            query,
            range,
          });
        }

        if (exit) {
          setState(null);
        }
      });
    },
    [setIndex]
  );

  // Add the change handler to the emoji state.
  useExtensionEvent(GlueEmojiExtension, "suggestEmoji", onChange);

  return useMemo(() => ({ ...menu, state }), [menu, state]);
}
