import { sanitizeUrl } from "@braintree/sanitize-url";
import { Children, type MouseEvent, useRef } from "react";
import { Link, useHistory } from "react-router-dom";

import { Icon, IconName } from "components/design-system/icons";
import FilePreview, { FilePreviewRef } from "components/FilePreview/FilePreview";
import { MentionSearchName } from "components/MessageEditor/types";
import { RecipientTooltip } from "components/RecipientTooltip";
import {
  AppOrigin,
  routePath,
  routeToGroup,
  routeToThread,
  routeToUser,
} from "components/routing/utils";
import useDownloadFileOnMobile from "components/views/groups/Shared/hooks/useDownloadFileOnMobile";
import AudioModal from "components/views/groups/Shared/Media/AudioModal";
import ImageModal from "components/views/groups/Shared/Media/ImageModal";
import VideoModal from "components/views/groups/Shared/Media/VideoModal";
import useAuthData from "hooks/useAuthData";
import useModalStore from "store/useModalStore";
import clickTemporaryLink from "utils/clickTemporaryLink";
import { matchURL } from "utils/matchURL";
import { isNativeMobile } from "utils/platform";
import env from "utils/processEnv";
import tw from "utils/tw";

import MentionIcons from "./MentionIcons";
import { parseFileMention } from "./utils";

import { Styles } from ".";

const mentionRegex = /^glue:(fil|obj|grp|thr|usr|wks)_/g;
const mentionTooltipRegex = /^glue:(grp|thr|usr|wks)_/g;

type HyperlinkProps = {
  className?: string;
  reactTestId?: string;
  url: string;
  handleMasonryLink?: (url: string) => void;
  isSourceMention?: boolean;
};

const masonryLink = /^masonry:[a-zA-Z0-9/_-]*$/i;

type LinkIconProps = {
  icon: IconName;
};

const LinkIcon = ({ icon }: LinkIconProps) => {
  return <Icon className="text-icon-link inline mb-3 mr-1" icon={icon} size={16} strokeWidth={1} />;
};

const Hyperlink = ({
  children,
  className: classNameProp,
  reactTestId,
  url: urlProp,
  handleMasonryLink,
  isSourceMention = false,
}: WithChildren<HyperlinkProps>): JSX.Element | null => {
  const { authData } = useAuthData();
  const history = useHistory();
  const downloadFileOnMobile = useDownloadFileOnMobile();

  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const filePreviewRef = useRef<FilePreviewRef>(null);

  const isMention = urlProp.match(mentionRegex) !== null;
  const isMentionWithTooltip = urlProp.match(mentionTooltipRegex) !== null;
  const isOwnMention = urlProp === `glue:${authData?.me.id}`;
  const isGlueAIMention = urlProp === `glue:${env.glueAIBotID}`;

  const className = {
    link: tw(
      Styles.hyperLink,
      { [Styles.mention]: isMention || isSourceMention },
      { [Styles.mentionOwn]: isOwnMention },
      { [Styles.mentionGlueAI]: isGlueAIMention },
      {
        [Styles.mentionUser]: urlProp.startsWith("glue:usr_"),
      },
      classNameProp
    ),
  };

  if (urlProp.match(masonryLink) && handleMasonryLink) {
    const onClick = (e: MouseEvent) => {
      e.preventDefault();
      handleMasonryLink(urlProp);
    };
    return (
      <a className={className.link} data-testid={reactTestId} onClick={onClick} href={urlProp}>
        {children}
      </a>
    );
  }

  const canonicalUrl = urlProp.replace(mentionRegex, `${window.location.origin}/$1_`);

  const url = isMention ? canonicalUrl : urlProp;
  const matchedURL = matchURL(url);

  if (!matchedURL) return <>{Children.map(children, child => child)}</>;

  const { origin, pathname, search } = new URL(matchedURL);

  const id = urlProp.replace("glue:", "");
  const prefix = id.split("_")[0];
  const file = parseFileMention(urlProp, children);

  // default / most are internal links
  let href: string = pathname + search;
  switch (prefix) {
    case "fil":
      href = file?.url ?? href;
      break;
    case "obj":
      href = `${env.glueApiUrl}/external/${id}/redirect`;
      break;
  }

  const handleMentionClick = (e: MouseEvent) => {
    e.preventDefault();

    const handleFileClick = () => {
      if (!file) return null;

      if (file.fileType === "audio") {
        return openModal(<AudioModal url={file.url} />);
      }
      if (file.fileType === "video") {
        return openModal(<VideoModal title={file.name} url={file.url} />);
      }
      if (file.previewable) {
        return filePreviewRef.current?.openPreview(file);
      }
      if (isNativeMobile()) {
        return downloadFileOnMobile(href, file.name);
      }
      return clickTemporaryLink(href, file.name);
    };

    switch (prefix) {
      case "fil":
        return handleFileClick();
      case "obj":
        return window.open(href, "_blank");
      case "grp":
        return history.push(
          routeToGroup({
            to: "secondary",
            groupID: id,
          })
        );
      case "thr":
        return history.push(
          routeToThread({
            to: "secondary",
            threadID: id,
          })
        );
      case "usr":
        return history.push(
          routeToUser({
            to: "secondary",
            userID: id,
          })
        );
      case "wks":
        return history.push(
          routePath({
            recipientID: id,
          })
        );
      default:
        return null;
    }
  };

  const linkProps = {
    className: className.link,
    "data-testid": reactTestId,
    onClick: isMention ? handleMentionClick : undefined,
  };

  const InternalLink = () => (
    <>
      {isMention && file?.previewable && <FilePreview ref={filePreviewRef} files={[file]} />}
      {isMention && file?.fileType === "image" ? (
        <a className={className.link}>
          <ImageModal children={children} file={file} mentionUrl={urlProp} />
        </a>
      ) : !href.startsWith("/") ? (
        <a {...linkProps} href={href}>
          <MentionIcons url={urlProp}>{children}</MentionIcons>
        </a>
      ) : (
        <Link {...linkProps} to={href}>
          <MentionIcons url={urlProp}>{children}</MentionIcons>
        </Link>
      )}
    </>
  );

  if (isMentionWithTooltip) {
    const recipientID = urlProp.replace("glue:", "");
    return (
      <RecipientTooltip
        elementDOMAttributes={{
          "data-mention-atom-id": recipientID,
          "data-mention-atom-name": MentionSearchName.All,
        }}
        id={recipientID}
        clickEnabled
      >
        <InternalLink />
      </RecipientTooltip>
    );
  }

  const isInternal = isMention || `${origin}` === AppOrigin;
  if (isInternal) return <InternalLink />;

  // open external links in a new tab
  return (
    <a
      className={className.link}
      data-testid={reactTestId}
      href={sanitizeUrl(url)}
      rel="noreferrer"
      target="_blank"
      title={urlProp}
    >
      {isSourceMention ? (
        <>
          <LinkIcon icon="Link" />
          {children}
          <LinkIcon icon="ExternalLinkMinimal" />
        </>
      ) : (
        children
      )}
    </a>
  );
};

export default Hyperlink;
