import { nodeAs } from "@utility-types";
import { useFileQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";

const useFileById = (id: string) => {
  const { authReady } = useAuthData();

  const { data } = useFileQuery({
    fetchPolicy: authReady ? "cache-first" : "cache-only",
    skip: !id || !authReady,
    variables: { id },
  });

  return nodeAs(data?.node, ["File"]);
};

export default useFileById;
