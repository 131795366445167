import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import useOnce from "hooks/useOnce";
import useShowActionModal, { isGlueAction } from "hooks/useShowActionModal";

const GLUE_ACTION = "action";
const GLUE_ACTION_RECIPIENT = "action_recipient";

export default function useExecuteURLAction() {
  const history = useHistory();

  const showActionModal = useShowActionModal();

  const actionRef = useRef<Parameters<ReturnType<typeof useShowActionModal>>[0]["action"]>(null);

  const recipientRef = useRef<string | null>(null);

  useOnce(() => {
    const searchParams = new URLSearchParams(history.location.search);

    if (searchParams.has(GLUE_ACTION)) {
      const action = searchParams.get(GLUE_ACTION) || "";

      actionRef.current = isGlueAction(action) ? action : null;
      recipientRef.current = searchParams.get(GLUE_ACTION_RECIPIENT);

      searchParams.delete(GLUE_ACTION);
      searchParams.delete(GLUE_ACTION_RECIPIENT);

      // Generate the updated search string
      const updatedSearchString = searchParams.toString();
      // Replace the current URL with the updated search parameters
      const newUrl = `${location.pathname}${updatedSearchString ? `?${updatedSearchString}` : ""}`;
      history.replace(newUrl);
    }
  });

  useEffect(() => {
    if (actionRef.current) {
      const action = actionRef.current;
      const recipient = recipientRef.current;

      showActionModal({ action, recipient });

      actionRef.current = null;
      recipientRef.current = null;
    }
  }, [showActionModal]);
}
