import { useCallback, useState } from "react";

import { Button } from "components/design-system/Button";
import { Icon } from "components/design-system/icons";

export const ThreadComposeBanner = ({
  recipientsRemoved,
}: {
  recipientsRemoved: number;
}) => {
  const [isBannerRemoved, setIsBannerRemoved] = useState(false);

  const handleCloseBanner = useCallback(() => {
    setIsBannerRemoved(true);
  }, []);

  if (recipientsRemoved === 0 || isBannerRemoved) return null;

  return (
    <div className="px-16 py-8 flex items-center justify-between border-b border-border-container">
      <span className="text-text-secondary text-subhead flex items-center gap-8">
        <Icon icon="Info" size={20} className="text-icon-action" />
        {recipientsRemoved} {recipientsRemoved !== 1 ? "groups" : "group"} removed. Only admins can
        post to restricted groups.
      </span>
      <Button
        type="button"
        buttonStyle="icon-secondary"
        icon="Close"
        iconSize={20}
        onClick={handleCloseBanner}
      />
    </div>
  );
};
