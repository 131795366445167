import { InputHTMLAttributes } from "react";

import tw from "utils/tw";

type Props = {
  children?: React.ReactNode;
  className?: string;
  htmlFor?: string;
} & InputHTMLAttributes<HTMLLabelElement>;

export const Label = ({ children, className, htmlFor, ...props }: Props) => (
  <label
    className={tw("flex items-center mb-5 select-none text-body-bold text-text-primary", className)}
    htmlFor={htmlFor}
    {...props}
  >
    {children}
  </label>
);
