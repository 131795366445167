import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import {
  ChannelActionContextValue,
  ChannelStateContextValue,
  MessageNotification as DefaultMessageNotification,
  MESSAGE_ACTIONS,
  MessageProps,
  StreamMessage,
  useChannelActionContext,
  useChannelStateContext,
  useChatContext,
  useComponentContext,
  useEnrichedMessages,
} from "stream-chat-react";
import { useDebouncedCallback } from "use-debounce";

import { GlueDefaultStreamChatGenerics } from "@utility-types";
import useComponentMounted from "hooks/useComponentMounted";
import usePrevious from "hooks/usePrevious";
import { StreamClientContext } from "providers/StreamClientProvider";
import { animateScrollTo } from "utils/animate";

import { useThreadViewState } from "../../provider/ThreadViewProvider";
import { TypingIndicator as DefaultTypingIndicator } from "../TypingIndicator";

import {
  useLoadMessages,
  useMessageListElements,
  useReplyToMessage,
  useScrollLocationLogic,
  useUnreadMarkLogic,
} from "./hooks";
import { MessageListNotifications as DefaultMessageListNotifications } from "./MessageListNotifications";
import ThreadGoToBottom from "./ThreadGoToBottom";
import ThreadGoToUnread from "./ThreadGoToUnread";
import { addUnreadMark, getGroupStyles } from "./utils";

const isTestEnv = process.env.NODE_ENV === "test";

type MessageListWithContextProps<T extends GlueDefaultStreamChatGenerics> = Omit<
  ChannelStateContextValue<T>,
  "members" | "mutes" | "watchers" | "messages"
> &
  MessageListProps<T>;

const MessageListWithContext = <T extends GlueDefaultStreamChatGenerics>(
  props: MessageListWithContextProps<T> & {
    historyMode: boolean;
    messageID?: string;
  }
) => {
  const {
    channel,
    disableDateSeparator = false,
    headerPosition,
    hideDeletedMessages = false,
    hideNewMessageSeparator = false,
    historyMode,
    messageActions = Object.keys(MESSAGE_ACTIONS),
    messageID,
    messageLimit = 25,
    messages: loadedMessages = [],
    noGroupByUser = false,
    notifications,
    read,
    returnAllReadData = false,
    threadList = false,
    unsafeHTML = false,
  } = props;

  const { streamChannelRecent } = useContext(StreamClientContext);

  const { dispatch } = useChannelActionContext<T>("MessageList");

  const { client, customClasses } = useChatContext<T>("MessageList");
  const unreadCount = (channel?.id && streamChannelRecent(channel.id).countUnread()) || 0;

  const {
    pauseLoadMore,
    replyTo,
    scrolledToEnd,
    scrolledToLinkedMessage,
    setState: threadViewUpdater,
    threadStartedAt,
  } = useThreadViewState(
    ({ pauseLoadMore, replyTo, scrolledToEnd, scrolledToLinkedMessage, threadStartedAt }) => ({
      pauseLoadMore,
      replyTo,
      scrolledToEnd,
      scrolledToLinkedMessage,
      threadStartedAt,
    })
  );

  const messages = useReplyToMessage({
    messages: loadedMessages,
    replyToMessage: replyTo?.message,
    threadStartedAt,
  });

  const prevLastRead = useUnreadMarkLogic({
    messages,
    read,
    scrolledToEnd,
  });

  const {
    MessageListNotifications = DefaultMessageListNotifications,
    MessageNotification = DefaultMessageNotification,
    TypingIndicator = DefaultTypingIndicator,
  } = useComponentContext<T>("MessageList");

  const { hasNewMessages, listRef, onScroll, scrollToBottom } = useScrollLocationLogic({
    historyMode,
    messages,
    scrolledUpThreshold: props.scrolledUpThreshold,
  });

  const isMounted = useComponentMounted();

  const { hasNextGluePage, loadingMessages, loadMoreMessages } = useLoadMessages({
    channel,
    dispatch,
    messageLimit,
    scrollToBottom,
  });
  const hasNextGluePageRef = useRef(hasNextGluePage);
  hasNextGluePageRef.current = hasNextGluePage;

  const [showGoToBottom, setShowGoToBottom] = useState(false);
  const [showGoToUnread, setShowGoToUnread] = useState(false);
  const [linkedMessage, setLinkedMessage] = useState<HTMLLIElement | null>();
  const unreadMarkRef = useRef<HTMLLIElement>(null);
  const prevMessageID = usePrevious(messageID);

  const { messageGroupStyles, messages: enrichedMessages } = useEnrichedMessages({
    channel,
    disableDateSeparator,
    groupStyles: getGroupStyles,
    headerPosition,
    hideDeletedMessages,
    hideNewMessageSeparator,
    messages,
    noGroupByUser,
  });

  const firstLoadedMessageDate = messages[1]?.created_at;

  const showUnreadMarker =
    prevLastRead &&
    firstLoadedMessageDate &&
    firstLoadedMessageDate < prevLastRead &&
    // Don't show unread marker before a single message
    messages.filter(m => m.status === "received").length > 1;

  const elements = useMessageListElements({
    enrichedMessages: showUnreadMarker
      ? addUnreadMark<T>({
          lastRead: prevLastRead,
          messages: enrichedMessages,
          userId: client?.userID ?? "",
        })
      : enrichedMessages,
    internalMessageProps: {
      additionalMessageInputProps: props.additionalMessageInputProps,
      closeReactionSelectorOnClick: props.closeReactionSelectorOnClick,
      customMessageActions: props.customMessageActions,
      disableQuotedMessages: props.disableQuotedMessages,
      formatDate: props.formatDate,
      getDeleteMessageErrorNotification: props.getDeleteMessageErrorNotification,
      getFlagMessageErrorNotification: props.getFlagMessageErrorNotification,
      getFlagMessageSuccessNotification: props.getFlagMessageSuccessNotification,
      getMuteUserErrorNotification: props.getMuteUserErrorNotification,
      getMuteUserSuccessNotification: props.getMuteUserSuccessNotification,
      getPinMessageErrorNotification: props.getPinMessageErrorNotification,
      Message: props.Message,
      messageActions,
      onlySenderCanEdit: props.onlySenderCanEdit,
      onMentionsClick: props.onMentionsClick,
      onMentionsHover: props.onMentionsHover,
      onUserClick: props.onUserClick,
      onUserHover: props.onUserHover,
      openThread: props.openThread,
      renderText: props.renderText,
      retrySendMessage: props.retrySendMessage,
      unsafeHTML,
    },
    linkedMessageId: messageID,
    linkedMessageRef: setLinkedMessage,
    messageGroupStyles,
    read,
    returnAllReadData,
    threadList,
    unreadMarkRef,
  });

  const hasNextPage = props.hasMore || hasNextGluePage;

  const firstMessageRead = useMemo(() => {
    const firstMessageAt = messages[0]?.created_at;
    return !prevLastRead || !firstMessageAt || firstMessageAt < prevLastRead;
  }, [messages, prevLastRead]);

  const getScrollPosition = (el: HTMLElement) =>
    Math.floor(el.scrollHeight - (el.scrollTop + el.clientHeight));

  const getShowGoToBottom = useCallback(
    (el: HTMLElement) => getScrollPosition(el) > 100 || !!historyMode,
    [historyMode]
  );

  const debouncedOnScroll = useDebouncedCallback(
    event => {
      const element = event.target;
      const { top: messageListTop } = element.getBoundingClientRect();
      const { offsetHeight, scrollHeight, scrollTop } = element;

      const unreadMarkTop = unreadMarkRef?.current?.getBoundingClientRect().top;
      const isUnreadMarkVisible = (unreadMarkTop || 0) - messageListTop > -15;
      setShowGoToUnread(
        unreadMarkRef.current
          ? !isUnreadMarkVisible
          : !firstMessageRead && !!unreadCount && messages.length < unreadCount
      );
      setShowGoToBottom(getShowGoToBottom(element));
      onScroll({ element, offsetHeight, scrollHeight, scrollTop });
    },
    58,
    { leading: true }
  );

  const loadMore = useCallback(async () => {
    if ((historyMode && !scrolledToLinkedMessage) || pauseLoadMore) {
      return;
    }

    loadMoreMessages(props.loadMore);
  }, [historyMode, loadMoreMessages, pauseLoadMore, props.loadMore, scrolledToLinkedMessage]);

  const [scrollSentryRef, { rootRef: scrollListRef }] = useInfiniteScroll({
    delayInMs: 500, // slow rendering causes scroll anchoring to fail without delay
    hasNextPage,
    loading: props.loadingMore ?? false,
    onLoadMore: loadMore,
    rootMargin: "400px 0px 0px 0px",
  });

  const messageListClass = customClasses?.messageList || "str-chat-list";
  const threadListClass = threadList ? customClasses?.threadList || "str-chat-list--thread" : "";

  const currListRef = listRef.current;
  useEffect(() => scrollListRef(currListRef), [currListRef, scrollListRef]);

  useEffect(() => {
    if (!prevMessageID) return;
    if (!messageID || prevMessageID !== messageID) {
      threadViewUpdater({ scrolledToLinkedMessage: false });
    }
  }, [messageID, prevMessageID, threadViewUpdater]);

  useEffect(() => {
    if (!messageID || !linkedMessage || !listRef.current) return;

    const getOffsetTop = () => linkedMessage.offsetTop - 48;

    listRef.current.scrollTop = getOffsetTop();

    const scrollTimeout = setTimeout(() => {
      if (!isMounted.current || !listRef.current) return;

      const onScrollDone = () =>
        threadViewUpdater({ prevScrollTop: 0, scrolledToLinkedMessage: true });

      const offsetTop = getOffsetTop();
      if (listRef.current.scrollTop === offsetTop) {
        onScrollDone();
        return;
      }

      animateScrollTo(listRef.current, offsetTop, onScrollDone);
    }, 158);

    return () => clearTimeout(scrollTimeout);
  }, [isMounted, scrolledToLinkedMessage, linkedMessage, listRef, messageID, threadViewUpdater]);

  useEffect(() => {
    const handler = () => listRef.current && setShowGoToBottom(getShowGoToBottom(listRef.current));

    window.addEventListener("resize", handler);

    return () => window.removeEventListener("resize", handler);
  }, [getShowGoToBottom, listRef]);

  return (
    <>
      <ThreadGoToUnread
        lastRead={prevLastRead}
        messageList={listRef.current}
        show={showGoToUnread}
        unreadMark={unreadMarkRef.current}
      />
      <div
        ref={listRef}
        className={`${messageListClass} ${threadListClass}`}
        onScroll={e => {
          if (e.currentTarget.scrollTop < 2) {
            e.currentTarget.scrollTop = 2; // ensure scroll anchoring during infinite scroll
          }
          debouncedOnScroll(e);
        }}
      >
        {loadingMessages && !isTestEnv ? (
          <ul className="str-chat-ul" data-testid="message-list" />
        ) : (
          <>
            {!pauseLoadMore && hasNextPage && <div key="top" ref={scrollSentryRef} />}
            <ul className="str-chat-ul" data-testid="message-list">
              {elements}
            </ul>
            <TypingIndicator threadList={threadList} />
          </>
        )}
      </div>
      <ThreadGoToBottom
        historyMode={historyMode}
        isUnread={!!showUnreadMarker}
        listRef={listRef}
        show={showGoToBottom}
      />
      <MessageListNotifications
        MessageNotification={MessageNotification}
        hasNewMessages={hasNewMessages}
        isNotAtLatestMessageSet={props.hasMoreNewer ?? false}
        notifications={notifications}
        scrollToBottom={scrollToBottom}
      />
    </>
  );
};

type PropsDrilledToMessage =
  | "additionalMessageInputProps"
  | "closeReactionSelectorOnClick"
  | "customMessageActions"
  | "disableQuotedMessages"
  | "formatDate"
  | "getDeleteMessageErrorNotification"
  | "getFlagMessageErrorNotification"
  | "getFlagMessageSuccessNotification"
  | "getMuteUserErrorNotification"
  | "getMuteUserSuccessNotification"
  | "getPinMessageErrorNotification"
  | "Message"
  | "messageActions"
  | "onlySenderCanEdit"
  | "onMentionsClick"
  | "onMentionsHover"
  | "onUserClick"
  | "onUserHover"
  | "openThread"
  | "renderText"
  | "retrySendMessage"
  | "unsafeHTML";

export type MessageListProps<T extends GlueDefaultStreamChatGenerics> = Partial<
  Pick<MessageProps<T>, PropsDrilledToMessage>
> & {
  /** Disables the injection of date separator components, defaults to `false` */
  disableDateSeparator?: boolean;
  /** Whether or not the list has more items to load */
  hasMore?: boolean;
  /** Position to render HeaderComponent */
  headerPosition?: number;
  /** Hides the MessageDeleted components from the list, defaults to `false` */
  hideDeletedMessages?: boolean;
  /** Hides the DateSeparator component when new messages are received in a channel that's watched but not active, defaults to false */
  hideNewMessageSeparator?: boolean;
  /** Function called when more messages are to be loaded, defaults to function stored in [ChannelActionContext](https://getstream.io/chat/docs/sdk/react/contexts/channel_action_context/) */
  loadMore?: ChannelActionContextValue["loadMore"] | (() => Promise<void>);
  /** Whether or not the list is currently loading more items */
  loadingMore?: boolean;
  /** Message ID to link to */
  messageID?: string;
  /** The limit to use when paginating messages */
  messageLimit?: number;
  /** The messages to render in the list, defaults to messages stored in [ChannelStateContext](https://getstream.io/chat/docs/sdk/react/contexts/channel_state_context/) */
  messages?: StreamMessage<T>[];
  /** If true, turns off message UI grouping by user */
  noGroupByUser?: boolean;
  /** If true, `readBy` data supplied to the `Message` components will include all user read states per sent message */
  returnAllReadData?: boolean;
  /** The pixel threshold to determine whether or not the user is scrolled up in the list, defaults to 200px */
  scrolledUpThreshold?: number;
  /** If true, indicates the message list is a thread  */
  threadList?: boolean;
};

/**
 * The MessageList component renders a list of Messages.
 * It is a consumer of the following contexts:
 * - [ChannelStateContext](https://getstream.io/chat/docs/sdk/react/contexts/channel_state_context/)
 * - [ChannelActionContext](https://getstream.io/chat/docs/sdk/react/contexts/channel_action_context/)
 * - [ComponentContext](https://getstream.io/chat/docs/sdk/react/contexts/component_context/)
 * - [TypingContext](https://getstream.io/chat/docs/sdk/react/contexts/typing_context/)
 */
export const MessageList = <T extends GlueDefaultStreamChatGenerics>(
  props: MessageListProps<T> & {
    historyMode: boolean;
    messageID?: string;
  }
): JSX.Element => {
  const { loadMore } = useChannelActionContext<T>("MessageList");

  const {
    members: _membersPropToNotPass,
    messages,
    mutes: _mutesPropToNotPass,
    watchers: _watchersPropToNotPass,
    ...channelContext
  } = useChannelStateContext<T>("MessageList");

  return (
    <MessageListWithContext<T>
      loadMore={loadMore}
      messages={messages}
      {...channelContext}
      {...props}
    />
  );
};
