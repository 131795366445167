type EmptyLineHelper = "<br>";

export const EMPTY_LINE_REGEX = new RegExp(/^\s*$/g);

export const INLINE_NODES = ["A", "CODE", "EM", "S", "STRONG"];

export const HARD_BREAK = "REMIRROR_GLUE_MD_PRESERVE_HARD_BREAKS_HELPER_STRING";

/**
 * Glue Markdown for <subject> element that we use when we create a thread.
 */
export const SUBJECT_MARKDOWN = "%%%%%%%";

export const SUBJECT_TEXT_REGEX = new RegExp(`^ {0}(${SUBJECT_MARKDOWN})(?=\\s|$)(.*)(?:\n+|$)`);

export const removeSubjectMarkdown = (input: string) => input.replace(SUBJECT_TEXT_REGEX, "$2\n\n");

export const unescapeCodeFence = (input: string) => input.replaceAll("\\`\\`\\`", "```");

export const unescapeCodeFenceInCodeElements = (htmlString: string) => {
  const html = new DOMParser().parseFromString(htmlString, "text/html");

  Array.from(html.body.querySelectorAll("code")).forEach(
    code => (code.textContent = unescapeCodeFence(code.textContent || ""))
  );

  return html.body.innerHTML;
};

/**
 * Converts Hard breaks to empty lines.
 *
 * @param input
 * @returns string
 */
export const replaceHardbreaksPlaceHolder = (
  input: string,
  withWhiteSpace = false,
  replacement = ""
): string =>
  input
    // Multiple hard breaks in the same element have only a single new line in between.
    // Adding an additional new line `\n`.
    .replaceAll(`${HARD_BREAK}\n${HARD_BREAK}`, `${HARD_BREAK}\n\n${HARD_BREAK}`)
    // Remove Hard break placeholder we set in TurndownService option
    .replaceAll(`${HARD_BREAK}${withWhiteSpace ? "\n" : ""}`, replacement);

/**
 * Remove links without link text and preserve empty lines.
 *
 * @param  input markdown
 * @param  br helper to make empty line visible default is `" "`
 * */
export const parseMarkdown = (input: string, br?: EmptyLineHelper): string =>
  removeEmptyLinks(preserveEmptyLines(input, br));

/**
 * Remove links without link text
 *
 * It used to be possible to insert links without link text in the previous Slate editor.
 *
 * @param input
 */
export const removeEmptyLinks = (input: string): string => input.replace(/^\[\]\(.*\)/g, "");

/**
 * Preserve empty lines
 *
 * To preserve multiple empty lines that can be created in the editor,
 * we convert specific empty lines from the markdown into paragraphs.
 *
 * To visualize an empty paragraph as an empty line in the timeline we insert a hardbreak `<br>`.
 *
 * When restoring markdown in the editor no argument needs to be provided since
 * ProseMirror will insert a "ProseMirror-trailingBreak" into an empty paragraph for us.
 *
 * @param input
 * @param br
 */
export const preserveEmptyLines = (input: string, br?: EmptyLineHelper): string => {
  let emptyLine = 0;
  let inCodeBlock = false;

  return input
    .split("\n")
    .map(line => {
      // Check if we have an empty editor paragraph
      const isEmptyLine = EMPTY_LINE_REGEX.test(line);

      const addEmpty = isEmptyLine && emptyLine % 2 !== 0;

      // check for opening or closing a code block
      // we don't want to add additional lines inside a code block.
      const codeFence = /```/gi.test(line);

      emptyLine = isEmptyLine ? ++emptyLine : 0;

      inCodeBlock = codeFence ? !inCodeBlock : inCodeBlock;

      // If inside a code block we just return the line
      if (inCodeBlock) return line;

      if (addEmpty && isEmptyLine) return `<p>${br || " "}</p>\n`;

      return line;
    })
    .join("\n");
};

export const escapeHtml = (input: string) =>
  input
    .replaceAll("&", "&amp;")
    .replaceAll("<", "&lt;")
    .replaceAll(">", "&gt;")
    .replaceAll('"', "&quot;")
    .replaceAll("'", "&#039;");
