import { GroupPreviewSimple } from "@utility-types";
import { Icon } from "components/design-system/icons";
import { useSnackbar } from "providers/SnackbarProvider";

const useJoinedGroupSnackBar = (group?: Pick<GroupPreviewSimple, "id" | "name">) => {
  const { openSnackbar } = useSnackbar();

  const joinGroupContent = () => (
    <p className="flex items-center ml-4 font-semibold">
      <span>
        <Icon className="icon" icon="Users" size={20} />
      </span>
      Joined {group?.name} group.
    </p>
  );

  return () => openSnackbar("info", joinGroupContent, 5000);
};

export default useJoinedGroupSnackBar;
