// Types defined in src/@types/process-env.d.ts
export const env = {
  circleBuildNum: process.env.VITE_CIRCLE_BUILD_NUM,
  debugAnalytics: process.env.VITE_DEBUG_ANALYTICS === "true",
  firebaseApiKey: process.env.VITE_FIREBASE_API_KEY,
  firebaseAuthDomain: process.env.VITE_FIREBASE_AUTH_DOMAIN,

  glueAIAppID: process.env.VITE_GLUE_AI_APP_ID,
  glueAIBotID: process.env.VITE_GLUE_AI_BOT_ID,
  glueApiUrl: process.env.VITE_GLUE_API_URL,
  glueApiKnownAliases: (process.env.VITE_GLUE_API_KNOWN_ALIASES || "")
    .split(",")
    .filter(x => x.trim() !== ""), // currently only applicable to staging
  glueAppId: process.env.VITE_GLUE_APP_ID,
  glueAppUrl: process.env.VITE_GLUE_APP_URL,
  glueDownloadAppUrl: process.env.VITE_DOWNLOAD_APP_URL,
  glueEnv: process.env.VITE_GLUE_ENV,
  glueFavicon: process.env.VITE_FAVICON,
  glueTapUrl: process.env.VITE_GLUE_TAP_URL,
  helpGroupId: process.env.VITE_HELP_GROUP_ID,
  nativeVersionNumber: process.env.VITE_NATIVE_VERSION_NUMBER,
  salesGroupId: process.env.VITE_SALES_GROUP_ID,
  rudderApiUrl: process.env.VITE_RUDDER_API_URL,
  rudderDataPlaneUrl: process.env.VITE_RUDDER_DATAPLANE_URL,
  rudderWriteKey: process.env.VITE_RUDDER_WRITE_KEY,

  postHogKey: process.env.VITE_PUBLIC_POSTHOG_KEY,
  postHogHost: process.env.VITE_PUBLIC_POSTHOG_HOST,

  sentryDsn: process.env.VITE_SENTRY_DSN,
  sentryReplaysSampleRate: Number.parseFloat(process.env.VITE_SENTRY_REPLAYS_SAMPLE_RATE || "0.05"),
  sentryTracesSampleRate: Number.parseFloat(process.env.VITE_SENTRY_TRACES_SAMPLE_RATE || "0.1"),

  streamApiKey: process.env.VITE_STREAM_API_KEY,
  streamChannelType: process.env.VITE_STREAM_CHANNEL_TYPE,

  stripePublicKey: process.env.VITE_STRIPE_PUBLIC_KEY,
  vercelGitCommitSHA: process.env.VITE_VERCEL_GIT_COMMIT_SHA,
  version: process.env.VITE_VERSION,
};

export default env;
