import { Workspace, WorkspacePreview } from "@utility-types/graphql";
import Avatar from "components/design-system/Avatar/Avatar";

type Props = {
  workspace?: Workspace | WorkspacePreview;
} & WithChildren;

const WorkspaceListItem = ({ children, workspace, ...props }: Props) => {
  return (
    <div className="flex items-center justify-between h-40 mb-16" {...props}>
      <div className="flex items-center">
        <div className="grow-0 shrink-0 mr-10 rounded-md overflow-hidden">
          <Avatar
            avatarURL={workspace?.avatarURL}
            name={workspace?.name ?? ""}
            rounded={false}
            size="medium"
          />
        </div>
        <div>
          <div className="font-semibold text-base leading-5">{workspace?.name ?? ""}</div>
          <div className="text-sm leading-4 text-text-subtle">
            {workspace?.admin && <>Admin: {workspace?.admin?.name} &middot; </>}
            {workspace?.members?.edges.length ?? 0} members
          </div>
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default WorkspaceListItem;
