import { Remirror, useRemirror } from "@remirror/react";
import { ComponentProps, memo, useEffect, useRef } from "react";

import { useEmoji } from "components/design-system/Emoji";
import useEnterKeyBehavior from "components/MessageEditor/hooks/useEnterKeyBehavior";
import { useEditorContentState } from "components/MessageEditor/providers/EditorContentProvider";
import GlueWysiwyg from "components/MessageEditor/remirror-extensions/GlueWysiwyg";
import { EditorProps } from "components/MessageEditor/types";

import editorStyles from "../../editorStyles";
import { useExtraAttributes, useOnError } from "../../hooks";
import { hasEditorTextContent } from "../../utils";
import { EditorComponents } from "../EditorComponents";

type Props = Omit<EditorProps, "readOnly"> &
  Pick<ComponentProps<typeof EditorComponents>, "enterKeyBehavior" | "forwardedRef">;

/**
 * All components rendered within Editor have access to the remirror context
 * via useRemirrorContext.
 */
const Editor = memo(
  ({ enterKeyBehavior: enterKeyBehaviorProp, onChange, placeholder, ...props }: Props) => {
    const enterKeyBehaviorSetting = useEnterKeyBehavior();
    const enterKeyBehavior = enterKeyBehaviorProp || enterKeyBehaviorSetting;

    const { flat: emojiData } = useEmoji();
    const { onContentError } = useOnError();

    const { readOnly: editorContentReadOnly, setState: editorContentUpdater } =
      useEditorContentState(({ readOnly }) => ({
        readOnly,
      }));

    const { getContext, manager, state } = useRemirror({
      extensions: () => [
        ...GlueWysiwyg({
          className: editorStyles,
          emojiData,
          enterKeyBehavior,
          placeholder,
        }),
      ],
      extraAttributes: useExtraAttributes(),
      onError: onContentError,
      stringHandler: "markdown",
    });

    const isMounted = useRef(false);

    useEffect(() => {
      isMounted.current = true;
      return () => {
        isMounted.current = false;
      };
    }, []);

    useEffect(() => {
      const removeHandler = getContext()?.addHandler("updated", ({ state }) => {
        editorContentUpdater({
          hasTextContent: hasEditorTextContent(state),
        });

        // MessageEditor onChange callback
        isMounted.current && onChange();
      });

      return () => removeHandler?.();
    }, [getContext, editorContentUpdater, onChange]);

    return (
      <Remirror editable={!editorContentReadOnly} initialContent={state} manager={manager}>
        <EditorComponents
          enterKeyBehavior={enterKeyBehavior}
          isMounted={isMounted}
          onChange={onChange}
          readOnly={editorContentReadOnly}
          placeholder={placeholder}
          {...props}
        />
      </Remirror>
    );
  }
);

Editor.displayName = "Editor";

export default Editor;
