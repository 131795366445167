import { useCallback, useEffect, useRef } from "react";

import { Form, SubmitButton } from "components/design-system/Forms";
import Hr from "components/design-system/Hr";
import AppearancePref from "components/SettingsModal/views/AccountSettings/Preferences/components/AppearancePref";
import AppVersion from "components/SettingsModal/views/AccountSettings/Preferences/components/AppVersion";
import EditorPref from "components/SettingsModal/views/AccountSettings/Preferences/components/EditorPref";
import GlobalAIModelPref from "components/SettingsModal/views/AccountSettings/Preferences/components/GlobalAIModelPref";
import InboxNotificationsPref from "components/SettingsModal/views/AccountSettings/Preferences/components/InboxNotificationsPref";
import PushNotificationsPref from "components/SettingsModal/views/AccountSettings/Preferences/components/PushNotificationsPref";
import type { FormData } from "components/SettingsModal/views/AccountSettings/Preferences/types";
import { useUpdateSettingsMutation, useUserSettingsQuery } from "generated/graphql-operations";
import { NotifyMessagesSoundSetting } from "generated/graphql-types";
import useAuthData from "hooks/useAuthData";
import useLocalSettingsStore from "store/useLocalSettingsStore";

import NotificationSoundsPref from "./components/NotificationSoundsPref";

const PreferencesView = ({ parentModalId }: { parentModalId?: string }) => {
  const { authData, authReady } = useAuthData();
  const workspaceID = authData?.me.workspaceIDs[0];

  const { colorScheme, colorSchemePreview, enterKeyBehavior } = useLocalSettingsStore(
    ({ colorScheme, colorSchemePreview, enterKeyBehavior }) => ({
      colorScheme,
      colorSchemePreview,
      enterKeyBehavior,
    })
  );

  /**
   * This prevents the colorSchemePreview from being set to the wrong value when the component is unmounted;
   *
   * TODO: After `AppearancePref` is no longer being shared by the prior version of `PreferencesModal`, we can probably
   * move this into `AppearancePref` and remove the `useEffect` hook.
   */
  const colorSchemePreviewRef = useRef(colorSchemePreview);
  colorSchemePreviewRef.current = colorSchemePreview;
  const colorSchemeRef = useRef(colorScheme);
  colorSchemeRef.current = colorScheme;
  useEffect(() => {
    return () => {
      if (colorSchemePreviewRef.current !== colorSchemeRef.current) {
        useLocalSettingsStore.setState({ colorSchemePreview: colorSchemeRef.current });
      }
    };
  }, []);

  const { data, loading } = useUserSettingsQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
  });

  const [updateSettings] = useUpdateSettingsMutation({
    errorPolicy: "all",
  });

  const handleFormSubmit = useCallback(
    ({
      colorScheme,
      enterKeyBehavior,
      notifyMessages,
      notifyMessagesSound,
      chatModel,
    }: FormData) => {
      if (!data || !notifyMessages) return;

      useLocalSettingsStore.setState({ colorScheme, enterKeyBehavior });

      const payload = chatModel
        ? {
            llmSettings: {
              ...data.settings.llmSettings,
              chatModel,
            },
            notifyMessages,
            notifyMessagesSound,
          }
        : { notifyMessages, notifyMessagesSound };

      return updateSettings({
        optimisticResponse: {
          updateSettings: {
            ...data.settings,
            ...payload,
          },
        },
        variables: {
          settings: payload,
        },
      }).catch(err => {
        console.warn("Error: [UpdateSettings] - ", err.message);
      });
    },
    [data, updateSettings]
  );

  return (
    <Form<FormData>
      className="contents"
      loading={loading}
      onSubmit={handleFormSubmit}
      useFormProps={{
        defaultValues: {
          colorScheme,
          chatModel: data?.settings.llmSettings.chatModel,
          enterKeyBehavior,
          notifyMessages: data?.settings.notifyMessages,
          notifyMessagesSound:
            data?.settings.notifyMessagesSound ?? NotifyMessagesSoundSetting.Direct,
        },
      }}
    >
      <div className="flex flex-col grow h-full">
        <div className="flex flex-col gap-20 grow min-h-0 h-full overflow-y-auto pt-24">
          <InboxNotificationsPref />
          <NotificationSoundsPref />
          <PushNotificationsPref preferencesModalId={parentModalId} />
          <Hr />
          <EditorPref />
          <Hr />
          <AppearancePref />
          {workspaceID && (
            <>
              <Hr />
              <GlobalAIModelPref workspaceID={workspaceID} />
            </>
          )}
          <AppVersion />
        </div>

        <div className="border-t border-border-container flex items-center justify-end h-[calc(64px_+_env(safe-area-inset-bottom))] shrink-0 px-20 pb-[env(safe-area-inset-bottom)]">
          <SubmitButton className="justify-center w-full md:w-auto">Save</SubmitButton>
        </div>
      </div>
    </Form>
  );
};

export default PreferencesView;
