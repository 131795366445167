import { WorkspaceEdge } from "@utility-types";
import WorkspaceItem from "components/design-system/ui/ProfilePane/WorkspaceItem";
import { useModalState } from "components/ModalKit/ModalProvider";
import { ModalElement } from "components/ModalKit/types";
import ConfirmationRequiredModal from "components/Modals/ConfirmationRequiredModal";
import { useDeleteAccountMutation } from "generated/graphql-operations";
import { MemberRole } from "generated/graphql-types";
import useAuthData from "hooks/useAuthData";
import { useSnackbar } from "providers/SnackbarProvider";
import useModalStore from "store/useModalStore";

const DeleteAccountModal = ({
  parentModalId,
  workspaceEdges,
}: {
  parentModalId: ModalElement["id"];
  workspaceEdges: WorkspaceEdge[];
}): JSX.Element | null => {
  const { signOut } = useAuthData();
  const { openSnackbar } = useSnackbar();
  const { modalId } = useModalState(({ modalId }) => ({
    modalId,
  }));
  const { closeModals } = useModalStore(({ closeModals }) => ({ closeModals }));
  const [deleteAccount] = useDeleteAccountMutation({ errorPolicy: "all" });

  const handleDeleteAccount = () =>
    deleteAccount({ variables: { input: { deactivate: false } } }).then(() => {
      signOut();

      const modalsToClose = parentModalId ? [parentModalId] : [];
      if (modalId) modalsToClose.push(modalId);
      closeModals(modalsToClose);

      openSnackbar("info", "👋 Your account will be deleted.", 10000);
    });

  const workspaces = workspaceEdges
    .filter(edge => edge.memberRole === MemberRole.Admin)
    .map(edge => edge.node);

  const isOnlyAdminOnWorkspace = workspaces.some(w => w.members.edges.length === 1);

  return (
    <ConfirmationRequiredModal
      headerTitle="Delete your account?"
      confirmButtonText="Delete account"
      informationBubbleText={
        isOnlyAdminOnWorkspace
          ? "This can't be undone. Once confirmed, both your account and any workspaces will be permanently deleted."
          : "This action can't be undone. Once confirmed, your account will be permanently deleted."
      }
      onConfirm={handleDeleteAccount}
    >
      {isOnlyAdminOnWorkspace ? (
        <div className="text-body">
          <p className="m-0 mb-16">Are you sure you want to delete your account?</p>
          <p className="text-body-bold m-0 mb-6">
            Warning: You are the only administrator of the following workspaces:
          </p>
          <div className="flex flex-col gap-6 mt-6 mb-16">
            {workspaces?.map(wks => (
              <WorkspaceItem key={wks.id} workspaceID={wks.id} singleLine />
            ))}
          </div>
          <span>Deleting your account will:</span>
          <ul className="list-indented">
            <li>Cancel any active subscriptions</li>
            <li>Permanently delete these workspaces including groups, threads, and content</li>
            <li>
              Remove all your profile information and any other workspace, group, or thread
              memberships
            </li>
          </ul>
        </div>
      ) : (
        <div className="flex flex-col gap-[1lh] text-body">
          <p className="m-0">Are you sure you want to delete your account?</p>
          <p className="m-0">
            Deleting your account is permanent and will remove all your profile information and any
            workspace, group and thread memberships.
          </p>
        </div>
      )}
    </ConfirmationRequiredModal>
  );
};

export default DeleteAccountModal;
