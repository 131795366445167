/**
 * Check if a timezone is supported by our API.
 * @param timezone - The timezone to check.
 * @returns True if the timezone is supported, false otherwise.
 */
export const isSupportedTimezone = (timezone: string, supportedTimezones: string[]) => {
  return supportedTimezones.includes(timezone);
};

/**
 * The list of timezones that are supported by the browser.
 */
export const browserSupportedTimezones = Intl.supportedValuesOf("timeZone");

/**
 * Filter the list of timezones to only include those that are supported by our API.
 * @param timezones - The list of timezones to filter.
 * @returns The filtered list of timezones.
 */
export const filterTimezonesByAPISupported = (
  timezones: string[] = browserSupportedTimezones,
  supportedTimezones: string[]
) => {
  return timezones.filter(timezone => supportedTimezones.includes(timezone));
};

/**
 * Get the UTC offset for a timezone.
 * @param timezone - The timezone to get the UTC offset for.
 * @returns The UTC offset for the timezone.
 */
export const getTimezoneUTCOffset = (timezone: string) => {
  const now = new Date();

  // Get UTC offset in minutes for this timezone
  const offset =
    new Date(now.toLocaleString("en-US", { timeZone: timezone })).getTime() -
    new Date(now.toLocaleString("en-US", { timeZone: "UTC" })).getTime();

  // Convert to hours and format as UTC±XX:XX
  const hours = Math.floor(Math.abs(offset) / (1000 * 60 * 60));
  const minutes = Math.floor((Math.abs(offset) % (1000 * 60 * 60)) / (1000 * 60));
  const sign = offset >= 0 ? "+" : "-";
  const utcOffset = `UTC${sign}${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;

  return utcOffset;
};

export const formatTimezoneList = (timezones: string[]) => {
  return timezones
    .map(timezone => {
      const utcOffset = getTimezoneUTCOffset(timezone) ?? "";

      return {
        label: `(${utcOffset}) ${timezone.replace(/_/g, " ")}`,
        utcOffset,
        value: timezone,
      };
    })
    .sort((a, b) => {
      // Compare offsets first
      if (a.utcOffset !== b.utcOffset) {
        // Convert offset strings to numbers for comparison
        const aValue =
          Number.parseFloat(a.utcOffset.replace(/[^0-9+-]/g, "").replace(":", ".")) || 0;
        const bValue =
          Number.parseFloat(b.utcOffset.replace(/[^0-9+-]/g, "").replace(":", ".")) || 0;
        return aValue - bValue;
      }

      // If offsets are equal, sort by timezone name
      return a.value.localeCompare(b.value);
    });
};

/**
 * Group and sort the list of timezones by their UTC offset.
 * @param timezones - The list of timezones to group.
 * @returns A map of UTC offsets to lists of timezones.
 */
export const groupTimezonesByOffset = (timezones: string[] = browserSupportedTimezones) => {
  const offsets = new Map();

  for (const timezone of timezones) {
    const utcOffset = getTimezoneUTCOffset(timezone);

    if (offsets.has(utcOffset)) {
      offsets.set(utcOffset, [...offsets.get(utcOffset), timezone]);
    } else {
      offsets.set(utcOffset, [timezone]);
    }
  }

  // Sort offsets by UTC offset
  const sortedOffsets = new Map(
    [...offsets.entries()].sort((a, b) => {
      // Extract numeric values from UTC strings for comparison
      const aValue = Number.parseFloat(a[0].replace("UTC", "").replace(":", ".")) || 0;
      const bValue = Number.parseFloat(b[0].replace("UTC", "").replace(":", ".")) || 0;
      return aValue - bValue;
    })
  );

  return sortedOffsets;
};

/**
 * Get the timezone automatically detected by the browser.
 * If the timezone is not supported by our API,
 * it will return the first timezone in the list of supported timezones
 * that has the same UTC offset.
 * @returns The timezone automatically detected by the browser.
 */
export const getTimezoneAutomatically = (supportedTimezones: string[]) => {
  const timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (isSupportedTimezone(timezone, supportedTimezones)) return timezone;

  const tzOffset = getTimezoneUTCOffset(timezone);
  const tzGroup = groupTimezonesByOffset(supportedTimezones).get(tzOffset);
  return tzGroup?.[0];
};
