import { PostHogProvider as PostHogProviderInternal } from "posthog-js/react";

import env from "utils/processEnv";

type PostHogProviderProps = WithChildren;

export const PostHogProvider = ({ children }: PostHogProviderProps) => {
  return (
    <PostHogProviderInternal
      apiKey={env.postHogKey}
      options={{
        api_host: env.postHogHost,
      }}
    >
      {children}
    </PostHogProviderInternal>
  );
};
