import { SubscriptionPlan } from "@utility-types";
import { Button } from "components/design-system/Button";
import ChoosePlanDropdown from "components/Payment/ChoosePlanDropdown";
import { WorkspaceSubscriptionFieldsFragment } from "generated/graphql";

const ChoosePlan = ({
  subscription,
  plans,
  // workspaceID,
}: {
  plans?: SubscriptionPlan[];
  subscription?: WorkspaceSubscriptionFieldsFragment;
  workspaceID: string;
}) => {
  /** TODO:
   * Allow upgrade from an existing monthly subscription to annual subscription;
   * the mutation and UI below do that, but we are waiting for the design of some intermediate UI (confirmation, etc.)
   */

  // const [updateWorkspaceSubscription] =
  //   useUpdateWorkspaceSubscriptionMutation();

  // if (plan?.interval === SubscriptionInterval.Month) {
  //   const handleUpgrade = async () => {
  //     if (!options[1]?.value || !workspaceID) return;

  //     updateWorkspaceSubscription({
  //       refetchQueries: [WorkspaceSubscriptionQueryDocument],
  //       variables: {
  //         input: {
  //           subscriptionPlanID: options[1]?.value,
  //         },
  //         workspaceID,
  //       },
  //     });
  //   };

  //   return (
  //     <>
  //       <div className="flex items-center">
  //         <div className="grow min-w-0">
  //           <span className="text-body-bold">Upgrade to annual plan</span>
  //           <br />
  //           Save $144 per year
  //         </div>
  //         <Button
  //           buttonFont="subheadBold"
  //           onClick={handleUpgrade}
  //           type="button"
  //         >
  //           Upgrade
  //         </Button>
  //       </div>
  //       <Hr className="my-20" />
  //     </>
  //   );
  // }

  // trialing; allow the user to select a subscription plan:
  if (!subscription?.plan || subscription.status === "canceled") {
    return (
      <>
        <div className="mb-4 px-32 text-body-bold">Choose a plan</div>
        <div className="flex gap-8 pb-20 px-32">
          <ChoosePlanDropdown plans={plans} />
          <Button buttonFont="subheadBold" className="justify-center w-200">
            Sign up
          </Button>
        </div>
      </>
    );
  }

  return null;
};

export default ChoosePlan;
