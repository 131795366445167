import { Link } from "react-router-dom";

import { Recipient, nodeIs } from "@utility-types";
import { MemberRoleBadge } from "components/design-system/Badge";
import { Button } from "components/design-system/Button";
import IdentityBadge from "components/design-system/ui/IdentityBadge/IdentityBadge";
import { MembersListItemDetails } from "components/MembersList/MembersListItemDetails";
import { routePath } from "components/routing/utils";
import useRecipientDomains from "hooks/workspace/useRecipientDomains";
import { formatGroupName } from "utils/group/formatGroupName";

const getThreadRecipientName = (recipient: Recipient): { name: string; emoji?: string } => {
  if (recipient.__typename === "GroupPreview" || recipient.__typename === "Group") {
    const { emoji, name } = formatGroupName({
      id: recipient.id,
      name: recipient.name,
    });

    return { name, emoji };
  }

  return { name: recipient.name, emoji: undefined };
};

const getThreadRecipientSubtitle = (recipient: Recipient, workspaceName?: string): string => {
  if (nodeIs(recipient, ["Group", "GroupPreview", "Workspace", "WorkspacePreview"])) {
    return workspaceName ?? "";
  }

  return recipient.bio ?? "";
};

type ThreadRecipientItemProps = {
  recipient: Recipient;
  isThreadStarter?: boolean;
  isAdmin?: boolean;
  handleRemoveUser?: (recipient: Recipient) => void;
};

const ThreadRecipientItem = ({
  recipient,
  isThreadStarter = false,
  isAdmin = false,
  handleRemoveUser,
}: ThreadRecipientItemProps) => {
  const { workspace } = useRecipientDomains(recipient);
  const { avatarURL } = recipient;
  const { name, emoji } = getThreadRecipientName(recipient);
  const subtitle = getThreadRecipientSubtitle(recipient, workspace?.name);

  return (
    <div className="py-8">
      <div className="flex items-center justify-between self-stretch h-40">
        <Link className="focus-visible-shadow" to={routePath({ recipientID: recipient.id })}>
          <MembersListItemDetails
            name={name}
            avatarURL={avatarURL ?? null}
            subtitle={subtitle}
            emojiProps={{ emoji, size: 27 }}
            badge={<IdentityBadge recipient={recipient} />}
            roleBadge={isThreadStarter && <MemberRoleBadge variant="subtle" memberRole="starter" />}
          />
        </Link>

        {isAdmin && !isThreadStarter && handleRemoveUser && (
          <Button
            buttonStyle="subtle"
            icon="CloseCircle"
            onClick={() => handleRemoveUser(recipient)}
          />
        )}
      </div>
    </div>
  );
};

export default ThreadRecipientItem;
