import { Capacitor } from "@capacitor/core";
import { captureException } from "@sentry/react";

export type Platform = "ios" | "android" | "mac" | "web";

const isPlatform = (str: string): str is Platform =>
  str === "ios" || str === "android" || str === "mac" || str === "web";

export const getPlatform = (): Platform => {
  let platform = Capacitor.getPlatform();

  // Capacitor.getPlatform() returns "ios" on Mac platform (e.g. the native Mac app).
  if (platform === "ios" && !navigator.userAgent.includes("Mobile")) {
    platform = "mac";
  }

  if (isPlatform(platform)) {
    return platform;
  }

  captureException(`Unexpected platform value: ${platform}`);
  return "web";
};

export const isWeb = () => getPlatform() === "web";

/**
 * @summary True if native app for macOS, iOS, or Android
 */
export const isNative = () => getPlatform() !== "web";

export const isNativeMac = () => getPlatform() === "mac";

export const isNativeIOS = () => getPlatform() === "ios";

export const isNativeAndroid = () => getPlatform() === "android";

/**
 * @summary True if native app for iOS or Android
 */
export const isNativeMobile = () => isNativeIOS() || isNativeAndroid();

// Currently this only returns true for native iOS and Android
// In the future this should be updated to check for mobile web browsers also
export const isMobile = () => isNativeMobile();

export const isSafari = () => isWeb() && /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

// This returns true if displayed in standalone mode
// This occurs when the app has been installed via Chrome/Edge or via the MS Store
// such that it appears as a standalone, native app.
export const isStandalone = () => window.matchMedia("(display-mode: standalone)").matches;
