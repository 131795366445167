import { forwardRef, useCallback, useEffect, useRef, useState } from "react";

import { Recipient } from "@utility-types";
import { ErrorBoundary } from "components/helper";
import generateRandomId from "utils/generateRandomId";

import Editor from "./components/Editor/Editor";
import useRestoreEditorOnError from "./hooks/useRestoreEditorOnError";
import { EditorContentProvider } from "./providers/EditorContentProvider";
import { EditorProvider } from "./providers/EditorProvider";
import { EditorProps, MessageEditor as MessageEditorRef, Mode } from "./types";

type Props = Omit<EditorProps, "onChange" | "readOnly" | "mode"> & {
  editorId?: string;
  enterKeyBehavior?: "new-line" | "send-message";
  mode?: Mode;
  onChange?: () => void;
};

const MessageEditor = forwardRef<MessageEditorRef, Props>(
  (
    {
      editorId: editorIdProps,
      mode = "compose",
      enterKeyBehavior,
      onChange: onChangeProps = () => void undefined,
      onMention: onMentionProps,
      placeholder = "",
      submitForm: submitFormProps,
      ...props
    },
    ref
  ) => {
    const [editorId] = useState(editorIdProps || generateRandomId());

    // ensuring referential equality boilerplate code
    const onChangeRef = useRef(onChangeProps);
    const onMentionRef = useRef(onMentionProps);
    const submitFormRef = useRef(submitFormProps);

    const onChange = useCallback(() => onChangeRef.current(), []);
    const onMention = useCallback((m: Recipient) => onMentionRef.current?.(m), []);
    const submitForm = useCallback(() => submitFormRef.current(), []);

    const { error, restoreEditor } = useRestoreEditorOnError();

    useEffect(() => {
      onChangeRef.current = onChangeProps;
      onMentionRef.current = onMentionProps;
      submitFormRef.current = submitFormProps;
    }, [onChangeProps, onMentionProps, submitFormProps]);

    if (error) return null;

    return (
      <section
        className="message-editor flex relative flex-col grow min-h-0 w-full outline-none"
        data-testid="messageEditor"
      >
        <ErrorBoundary onError={restoreEditor}>
          <EditorProvider editorId={editorId}>
            <EditorContentProvider editorId={editorId}>
              <Editor
                forwardedRef={ref}
                enterKeyBehavior={enterKeyBehavior}
                mode={mode}
                onChange={onChange}
                onMention={onMention}
                placeholder={placeholder}
                submitForm={submitForm}
                {...props}
              />
            </EditorContentProvider>
          </EditorProvider>
        </ErrorBoundary>
      </section>
    );
  }
);

MessageEditor.displayName = "MessageEditor";

export default MessageEditor;
