import { useState } from "react";
import { Link } from "react-router-dom";

import { User } from "@utility-types";
import { Badge } from "components/design-system/Badge";
import { Button } from "components/design-system/Button";
import { routePath } from "components/routing/utils";
import { formatRelativeTime } from "utils/formatDate";

import { MembersListItemDetails } from "./MembersListItemDetails";

type Props = {
  approvalID: string;
  isAdmin: boolean;
  isByMe: boolean;
  isForMe: boolean;
  isInvite: boolean;
  joining: Pick<User, "id" | "name" | "avatarURL">;
  onApprove: (id: string) => Promise<void>;
  onCancel: (id: string) => Promise<void>;
  onResend?: (id: string) => Promise<void>;
  requestedAt: Date;
};

export function PendingMembersListItem({
  approvalID,
  isAdmin,
  isByMe,
  isForMe,
  isInvite,
  joining,
  requestedAt,
  onApprove,
  onCancel,
  onResend,
}: Props) {
  const [isPendingUpdate, setIsPendingUpdate] = useState(false);

  const handleApprove = () => {
    setIsPendingUpdate(true);
    return onApprove(approvalID).finally(() => {
      setIsPendingUpdate(false);
    });
  };

  const handleCancel = () => {
    setIsPendingUpdate(true);
    return onCancel(approvalID).finally(() => {
      setIsPendingUpdate(false);
    });
  };

  const handleResend = onResend
    ? () => {
        setIsPendingUpdate(true);
        return onResend(approvalID).finally(() => {
          setIsPendingUpdate(false);
        });
      }
    : undefined;

  return (
    <li className="flex items-center gap-20 min-h-[46px]">
      <Link className="focus-visible-shadow mr-auto" to={routePath({ recipientID: joining.id })}>
        <MembersListItemDetails
          name={joining.name}
          subtitle={formatRelativeTime(requestedAt)}
          avatarURL={joining.avatarURL}
          badge={<Badge variant="warning">{isInvite ? "Invited" : "Requested"}</Badge>}
        />
      </Link>

      {isAdmin && isInvite && handleResend && (
        <Button
          disabled={isPendingUpdate}
          type="button"
          icon="Refresh"
          className="text-subhead-bold"
          buttonStyle="subtle"
          buttonType="text"
          iconSize={20}
          onClick={handleResend}
        >
          Resend
        </Button>
      )}
      {(isForMe || (isAdmin && !isInvite)) && (
        <Button
          disabled={isPendingUpdate}
          type="button"
          icon="Check"
          className="text-subhead-bold"
          buttonStyle="simplePrimary"
          buttonType="text"
          iconSize={20}
          iconMargin="mr-6"
          onClick={handleApprove}
        >
          Approve
        </Button>
      )}
      {(isForMe || isAdmin) && (
        <Button
          disabled={isPendingUpdate}
          type="button"
          icon="Close"
          className="text-subhead-bold"
          buttonStyle="secondaryDestructive"
          buttonType="text"
          iconSize={20}
          iconMargin="mr-6"
          onClick={handleCancel}
        >
          {isByMe || (isAdmin && isInvite) ? "Cancel" : "Ignore"}
        </Button>
      )}
    </li>
  );
}
