import { Button } from "components/design-system/Button";
import useModalStore from "store/useModalStore";

import { BlockComponentProps } from "./BlockComponent";
import BlockConfirm from "./BlockConfirm";
import { ButtonControl } from "./blockTypes";

type BlockButtonProps = BlockComponentProps & {
  block: ButtonControl;
};

const BlockButton = ({ block, handlers: { onAction } }: BlockButtonProps) => {
  const { openModal } = useModalStore(({ openModal }) => ({ openModal }));

  const handleConfirm = () => {
    onAction(block, block.value);
  };

  const handleButtonClick = () => {
    if (block.confirm) {
      openModal(<BlockConfirm confirm={block.confirm} onConfirm={handleConfirm} />);
    } else {
      handleConfirm();
    }
  };

  return (
    <div>
      <Button
        type="button"
        aria-label={block.accessibilityLabel || block.text.text}
        buttonStyle={block.style === "danger" ? "simpleDestructive" : "secondary"}
        onClick={handleButtonClick}
      >
        {block.text.text}
      </Button>
    </div>
  );
};

export default BlockButton;
