/* spellchecker: disable */

import { Icon } from "components/design-system/icons";
import tw from "utils/tw";

enum Icons {
  Archive = "Archive",
  Audio = "Audio",
  Code = "Code",
  ExcelFilled = "ExcelFilled",
  Image = "Image",
  PDFFilled = "PDFFilled",
  PowerpointFilled = "PowerpointFilled",
  Text = "Text",
  Video = "Video",
  WordFilled = "WordFilled",
}

// Partially based on:
// https://stackoverflow.com/a/4212908/2570866

const archiveFileTypes = [
  // .zip
  "application/zip",
  // .z7
  "application/x-7z-compressed",
  // .ar
  "application/x-archive",
  // .tar
  "application/x-tar",
  // .tar.gz
  "application/gzip",
  // .tar.Z
  "application/x-compress",
  // .tar.bz2
  "application/x-bzip",
  // .tar.lz
  "application/x-lzip",
  // .tar.lz4
  "application/x-lz4",
  // .tar.lzma
  "application/x-lzma",
  // .tar.lzo (no test)
  "application/x-lzop",
  // .tar.xz
  "application/x-xz",
  // .war
  "application/x-webarchive",
  // .rar
  "application/vnd.rar",
];

const codeFileTypes = [
  // .html .htm
  "text/html",
  // .css
  "text/css",
  // .js
  "application/x-javascript",
  // .json
  "application/json",
  // .py
  "text/x-python",
  // .go
  "text/x-go",
  // .c
  "text/x-csrc",
  // .cpp
  "text/x-c++src",
  // .rb
  "application/x-ruby",
  // .rust
  "text/rust",
  // .java
  "text/x-java",
  // .php
  "application/x-php",
  // .cs
  "text/x-csharp",
  // .scala
  "text/x-scala",
  // .erl
  "text/x-erlang",
  // .sh
  "application/x-shellscript",
];

const excelMimeTypes = [
  // .csv
  "text/csv",
  // TODO: maybe more data files

  // Microsoft Excel
  // .xls .xlt .xla (no test for .xla)
  "application/vnd.ms-excel",
  // .xlsx
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  // .xltx (no test)
  "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  // .xlsm
  "application/vnd.ms-excel.sheet.macroEnabled.12",
  // .xltm (no test)
  "application/vnd.ms-excel.template.macroEnabled.12",
  // .xlam (no test)
  "application/vnd.ms-excel.addin.macroEnabled.12",
  // .xlsb (no test)
  "application/vnd.ms-excel.addin.macroEnabled.12",

  // LibreOffice/OpenOffice Calc
  // .ods
  "application/vnd.oasis.opendocument.spreadsheet",
  // .ots
  "application/vnd.oasis.opendocument.spreadsheet-template",
  // .fods
  "application/vnd.oasis.opendocument.spreadsheet-flat-xml",
  // .uos
  // NOTE: firefox doesn't know mimetype so maybe ignore
];

const powerpointMimeTypes = [
  // Microsoft Word
  // .ppt .pot .pps .ppa (no test for .ppa)
  "application/vnd.ms-powerpoint",
  // .pptx
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  // .potx (no test)
  "application/vnd.openxmlformats-officedocument.presentationml.template",
  // .ppsx
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  // .ppam
  "application/vnd.ms-powerpoint.addin.macroEnabled.12",
  // .pptm
  "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
  // .potm
  "application/vnd.ms-powerpoint.template.macroEnabled.12",
  // .ppsm
  "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",

  // LibreOffice/OpenOffice Writer
  // .odp
  "application/vnd.oasis.opendocument.presentation",
  // .otp
  "application/vnd.oasis.opendocument.presentation-template",
  // .fodp
  "application/vnd.oasis.opendocument.presentation-flat-xml",
  // .uop
  // NOTE: firefox doesn't know mimetype so maybe ignore
];

const wordMimeTypes = [
  // Microsoft Word
  // .doc .dot
  "application/msword",
  // .doc .dot
  "application/msword-template",
  // .docx
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  // .dotx (no test)
  "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  // .docm
  "application/vnd.ms-word.document.macroEnabled.12",
  // .dotm (no test)
  "application/vnd.ms-word.template.macroEnabled.12",

  // LibreOffice/OpenOffice Writer
  // .odt
  "application/vnd.oasis.opendocument.text",
  // .ott
  "application/vnd.oasis.opendocument.text-template",
  // .fodt
  "application/vnd.oasis.opendocument.text-flat-xml",
  // .uot
  // NOTE: firefox doesn't know mimetype so maybe ignore
];

type IconKey = keyof typeof Icons;
type IconDefinition = Record<string, `File${IconKey}` | "File">;

const parseIconName = (icon: IconKey): `File${IconKey}` => `File${icon}`;

const mimeTypeToIconMap: IconDefinition = {
  "application/pdf": parseIconName(Icons.PDFFilled),
};

for (const type of archiveFileTypes) {
  mimeTypeToIconMap[type] = parseIconName(Icons.Archive);
}

for (const type of codeFileTypes) {
  mimeTypeToIconMap[type] = parseIconName(Icons.Code);
}

for (const type of excelMimeTypes) {
  mimeTypeToIconMap[type] = parseIconName(Icons.ExcelFilled);
}

for (const type of powerpointMimeTypes) {
  mimeTypeToIconMap[type] = parseIconName(Icons.PowerpointFilled);
}

for (const type of wordMimeTypes) {
  mimeTypeToIconMap[type] = parseIconName(Icons.WordFilled);
}

const mimeTypeToIcon = (mimeType?: string): IconDefinition[string] => {
  if (mimeType == null) return "File";

  const icon = mimeTypeToIconMap[mimeType];
  if (icon) return icon;

  if (mimeType.startsWith("audio/")) return parseIconName(Icons.Audio);
  if (mimeType.startsWith("text/")) return parseIconName(Icons.Text);
  if (mimeType.startsWith("video/")) return parseIconName(Icons.Video);

  return "File";
};

export type FileType =
  | "archive"
  | "audio"
  | "code"
  | "doc"
  | "image"
  | "pdf"
  | "sheets"
  | "slides"
  | "text"
  | "video";

// NOTE: audio, image and video use different (smaller) icons
// than those from mimeTypeToIcon as per Figma specs
const fileTypeToIcon = (fileType: FileType) => {
  switch (fileType) {
    case "archive":
      return parseIconName(Icons.Archive);
    case "audio":
      return "Volume2";
    case "code":
      return parseIconName(Icons.Code);
    case "doc":
      return parseIconName(Icons.WordFilled);
    case "image":
      return "Image";
    case "pdf":
      return parseIconName(Icons.PDFFilled);
    case "sheets":
      return parseIconName(Icons.ExcelFilled);
    case "slides":
      return parseIconName(Icons.PowerpointFilled);
    case "text":
      return parseIconName(Icons.Text);
    case "video":
      return "PlayCircle";
    default:
      return "File";
  }
};

type FileIconProps = {
  filename?: string;
  fileType?: FileType;
  iconSize?: number;
  mimeType?: string;
  strokeWidth?: number;
  isLink?: boolean;
};

type EndsWithFilled<T extends string> = T extends `${infer _S}Filled` ? T : never;
type FilledIcon = EndsWithFilled<`File${IconKey}`>;

const isFilledIconType = (icon: string): icon is FilledIcon => icon.endsWith("Filled");

const iconClassName: Record<FilledIcon, string> = {
  FilePDFFilled: "text-file-types-pdf",
  FileWordFilled: "text-file-types-doc",
  FileExcelFilled: "text-file-types-xls",
  FilePowerpointFilled: "text-file-types-ppt",
};

const FileIcon = ({
  fileType,
  mimeType,
  iconSize = 24,
  strokeWidth = 1.5,
  isLink = false,
}: FileIconProps): JSX.Element => {
  const icon = fileType !== undefined ? fileTypeToIcon(fileType) : mimeTypeToIcon(mimeType);
  const isFilledIcon = isFilledIconType(icon);

  return (
    <Icon
      className={tw(isFilledIcon && iconClassName[icon], {
        "text-icon-link": isLink && !isFilledIcon,
        "text-icon-secondary": !isLink && !isFilledIcon,
      })}
      icon={icon}
      size={iconSize}
      strokeWidth={strokeWidth}
    />
  );
};

export default FileIcon;
