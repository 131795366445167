import { ComponentProps } from "react";
import { useHistory } from "react-router";

import { Button } from "components/design-system/Button";
import { currentPathWithSearch, useRouteParams } from "components/routing/utils";
import useWorkspaceID from "hooks/useWorkspaceID";

const DirectoryButton = ({
  buttonStyle = "icon-primary",
  className,
  label,
}: {
  buttonStyle?: ComponentProps<typeof Button>["buttonStyle"];
  className?: string;
  label?: string;
}) => {
  const history = useHistory();
  const { recipientID } = useRouteParams();
  const workspaceID = useWorkspaceID(recipientID);

  return (
    <Button
      buttonFont="xs"
      buttonStyle={buttonStyle}
      buttonType="icon"
      className={className}
      icon="Globe"
      iconSize={20}
      onClick={() => history.push(currentPathWithSearch({ v: "directory" }), { workspaceID })}
      iconLast
    >
      {label}
    </Button>
  );
};

export default DirectoryButton;
