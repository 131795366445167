import { Icon, IconName } from "components/design-system/icons";

import { AttachmentType, getAttachmentIcon } from "./utils";

type Props = {
  attachmentTypes: AttachmentType[];
};

const ThreadItemContentAttachment = ({ attachmentTypes: types }: Props): JSX.Element => {
  const attachmentIcon: IconName = getAttachmentIcon(types);
  return <Icon className="text-interactive-subtle" icon={attachmentIcon} size="15" />;
};

export default ThreadItemContentAttachment;
