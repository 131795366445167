import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";

import { Button } from "components/design-system/Button";
import { Form, TextInput } from "components/design-system/Forms";

type Props = {
  onMemberSearch: (search?: string) => void;
  searchQuery?: string;
};

const SearchInput = ({ onMemberSearch }: Pick<Props, "onMemberSearch">) => {
  const { setValue } = useFormContext<{ search?: string }>();
  const search: string = useWatch({ name: "search" });

  const debouncedMemberSearch = useDebouncedCallback(onMemberSearch, 350);

  useEffect(() => {
    if (search === "") {
      debouncedMemberSearch.cancel();
      onMemberSearch("");
      return;
    }

    debouncedMemberSearch(search);
  }, [debouncedMemberSearch, onMemberSearch, search]);

  return (
    <div className="flex items-center relative w-full pt-12">
      <TextInput
        name="search"
        placeholder="Search..."
        icon="Search"
        iconPosition="start"
        wrapperClassName="!my-0 w-full"
      />
      {search && (
        <Button
          className="absolute right-8"
          buttonStyle="icon-secondary"
          buttonType="icon"
          icon="Close"
          iconSize={20}
          onClick={() => setValue("search", "")}
        />
      )}
    </div>
  );
};

const MembersSearch = ({ onMemberSearch, searchQuery }: Props) => (
  <Form useFormProps={{ defaultValues: { search: searchQuery } }}>
    <SearchInput onMemberSearch={onMemberSearch} />
  </Form>
);

export default MembersSearch;
