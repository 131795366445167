import { FileFieldsFragment } from "generated/graphql";

type GetThumbnailDimensions = {
  imageWidth: number;
  ratio: number;
  targetHeight: number;
  targetWidth: number;
};

export const getThumbnailDimensions = ({
  imageWidth,
  ratio,
  targetHeight,
  targetWidth,
}: GetThumbnailDimensions) => {
  let height = Math.ceil(targetWidth / ratio);
  let width = targetWidth;

  /**
   ** - the calculated height is insufficient to fill the grid cell
   **   - and we have enough width to work with
   ** ... then we can lock the height, and calculate a larger width.
   */
  if (height < targetHeight && imageWidth > targetWidth) {
    if (targetHeight * ratio >= targetWidth) {
      height = targetHeight;
      width = Math.ceil(targetHeight * ratio);
    }
  }

  return {
    height,
    width,
  };
};

export const parseImage = (file: FileFieldsFragment, defaultImageSize = 192) => {
  const imageAlt = file.name;
  const imageSrc = file.url;
  const { blurHash, height, width } = file.metadata ?? {};
  const imageHeight = height ?? defaultImageSize;
  const imageWidth = width ?? defaultImageSize;

  const ratio = imageWidth / imageHeight;
  const targetHeight = Math.min(defaultImageSize, imageHeight);
  const targetWidth = Math.min(defaultImageSize, imageWidth);

  const { height: thumbnailHeight, width: thumbnailWidth } = getThumbnailDimensions({
    imageWidth,
    ratio,
    targetHeight,
    targetWidth,
  });

  const maxHeight = Math.min(imageHeight, window.innerHeight);
  const maxWidth = Math.min(imageWidth, window.innerWidth);
  const isLandScape = ratio >= 1;

  const imageResize = {
    fit: "max" as const,
    h: isLandScape ? Math.round(maxWidth / ratio) : maxHeight,
  };

  const thumbnailResize = {
    fit: "max" as const,
    h: thumbnailHeight,
    w: thumbnailWidth,
  };

  return {
    blurHash,
    defaultImageSize,
    height: height ?? defaultImageSize,
    imageAlt,
    imageSrc,
    imageResize,
    thumbnailHeight,
    thumbnailResize,
    thumbnailWidth,
    width: width ?? defaultImageSize,
  };
};
