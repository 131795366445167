import {
  ChatType,
  ThreadsOrder,
  useMailboxCountsQuery,
  useNotificationCountsQuery,
  usePersistentChatsQuery,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";

type UnreadCounts = {
  unreadDirectMessages: number;
  unreadMentionCount: number;
  unreadNotificationCount: number;
  unreadInboxCount: number;
  unseenFeedCount: number;
};

export const useSidebarCounts = ({ refresh }: { refresh?: boolean } = {}): UnreadCounts => {
  const { authReady } = useAuthData();

  const fetchOptions = {
    fetchPolicy: authReady
      ? refresh
        ? ("cache-and-network" as const)
        : ("cache-first" as const)
      : ("cache-only" as const),
    nextFetchPolicy: "cache-first" as const,
  };

  const { data: notificationCountsData } = useNotificationCountsQuery(fetchOptions);

  const { data: mailboxCountsData } = useMailboxCountsQuery(fetchOptions);

  const { data: userChatsData } = usePersistentChatsQuery({
    ...fetchOptions,
    variables: {
      chatType: ChatType.User,
      last: 9,
      order: ThreadsOrder.Unread,
    },
  });

  return {
    unreadMentionCount: mailboxCountsData?.mentionedThreads.totalCount ?? 0,
    unreadDirectMessages: userChatsData?.persistentChats.unreadCounts.total ?? 0,
    unreadNotificationCount: notificationCountsData?.notifications?.unseenCount ?? 0,
    unreadInboxCount:
      (notificationCountsData?.joinApprovals?.totalCount ?? 0) +
      (mailboxCountsData?.unreadInbox.totalCount ?? 0),
    unseenFeedCount: mailboxCountsData?.unseenFeed.totalCount ?? 0,
  };
};

export default useSidebarCounts;
