import { nodeAs } from "@utility-types";
import { useAppQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";

const useApp = (appID?: string) => {
  const { authReady } = useAuthData();

  const { data } = useAppQuery({
    fetchPolicy: authReady ? "cache-first" : "cache-only",
    skip: !appID || !authReady,
    variables: { id: appID ?? "" },
  });

  return nodeAs(data?.node, ["App"]);
};

export default useApp;
