import { nodeAs } from "@utility-types";
import Avatar from "components/design-system/Avatar/Avatar";
import { Skeleton } from "components/Skeleton";
import { useFetchWorkspaceOrPreviewEdgeQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import tw from "utils/tw";

import IdentityBadge from "../IdentityBadge/IdentityBadge";

/**
 * Displays workspace information.
 *
 * @param singleLine - Whether to display the information in a single line.
 * @param workspaceID - The ID of the workspace to display.
 */

export default function WorkspaceItem({
  singleLine = false,
  workspaceID,
}: {
  singleLine?: boolean;
  workspaceID: string;
}) {
  const { authData, authReady } = useAuthData();
  const { data } = useFetchWorkspaceOrPreviewEdgeQuery({
    fetchPolicy: authReady ? "cache-first" : "cache-only",
    skip: !(workspaceID && authData),
    variables: {
      id: `${workspaceID}-${authData?.me.id}`,
    },
  });

  const workspaceEdge = nodeAs(data?.node, ["WorkspaceEdge", "WorkspacePreviewEdge"]);

  const memberCount = workspaceEdge?.node.members.totalCount;
  const memberLabel = memberCount === 1 ? "member" : "members";

  return (
    <div className={tw("flex gap-8 items-center overflow-hidden", !singleLine && "h-40")}>
      <Avatar
        avatarURL={workspaceEdge?.node.avatarURL}
        name={workspaceEdge?.node.name}
        rounded="rounded-md"
        size={singleLine ? "x-small" : "medium"}
      />
      <div className={tw("overflow-hidden", singleLine && "flex gap-6")}>
        <div className="text-subhead-bold truncate select-text">
          {workspaceEdge ? workspaceEdge.node.name : <Skeleton width="80px" height="32px" />}
        </div>
        <div className="flex">
          <div className="text-footnote text-text-subtle flex overflow-hidden">
            <IdentityBadge recipient={workspaceEdge?.node} />
            <span className="mx-4">·</span>
            {memberCount && (
              <>
                <span className="mr-4">{memberCount}</span>
                <span className="truncate mr-4">{memberLabel}</span>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
