import { WorkspaceAppEdge } from "@utility-types";
import { Button } from "components/design-system/Button";
import { InformationBubble } from "components/design-system/InformationBubble";
import { SettingsModal } from "components/SettingsModal";
import { AddButton } from "components/SettingsModal/views/WorkspaceSettings/apps/StaticAppItems";
import { AppConfigureMethod } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useModalStore from "store/useModalStore";
import tw from "utils/tw";

import AppsSection from "./AppsSection";
import RemoveAppModal from "./ConfirmRemoveAppModal";

const ThreadsApps = ({
  addApp,
  appsAdded,
  appsAvailable,
  canChangeApps = true,
  configureApp,
  name,
  removeApp,
  workspaceID,
}: {
  addApp: (workspaceApp: WorkspaceAppEdge) => void;
  canChangeApps?: boolean;
  configureApp: (workspaceApp: WorkspaceAppEdge) => void;
  appsAdded?: WorkspaceAppEdge[];
  appsAvailable?: WorkspaceAppEdge[];
  name: string;
  removeApp: (workspaceApp: WorkspaceAppEdge) => Promise<unknown>;
  workspaceID?: string;
}) => {
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));
  const { authData } = useAuthData();

  const formatAddedSection = (workspaceApps: WorkspaceAppEdge[]) =>
    workspaceApps.map(workspaceApp => {
      const app = workspaceApp.node;
      const inAppWorkspace = authData?.me.workspaceIDs.includes(workspaceApp.workspaceID);

      return {
        actions:
          canChangeApps && inAppWorkspace ? (
            <>
              {workspaceApp.node.configureThread === AppConfigureMethod.Configure ? (
                <Button
                  buttonStyle="simplePrimary"
                  className="mr-10"
                  icon="EditSimple"
                  iconClassName="text-interactive-subtle"
                  onClick={() => configureApp(workspaceApp)}
                />
              ) : null}
              <Button
                buttonStyle="simplePrimary"
                icon="Trash"
                iconClassName="text-interactive-subtle"
                onClick={() =>
                  openModal(
                    <RemoveAppModal
                      app={workspaceApp.node}
                      container={name}
                      removeApp={() => removeApp(workspaceApp)}
                    />
                  )
                }
              />
            </>
          ) : undefined,
        description: app.description,
        icon: { alt: app.name, src: app.avatarURL },
        id: app.id,
        title: app.name,
      };
    });

  const formatAvailableSection = (workspaceApps: WorkspaceAppEdge[]) =>
    workspaceApps.map(workspaceApp => {
      const app = workspaceApp.node;
      return {
        actions: <AddButton />,
        description: app.description,
        icon: { alt: app.name, src: app.avatarURL },
        id: app.id,
        onClick: () => addApp(workspaceApp),
        title: app.name,
      };
    });

  const handleOpenWorkspaceProfileModal = () => {
    openModal(<SettingsModal subView="apps" view={workspaceID} />);
  };

  return (
    <>
      {appsAdded && <AppsSection items={formatAddedSection(appsAdded)} title="Added" />}
      {appsAvailable && canChangeApps && (
        <AppsSection items={formatAvailableSection(appsAvailable)} title="Available" />
      )}
      {(appsAdded?.length || appsAvailable?.length) && canChangeApps && workspaceID ? (
        <div
          className={tw({
            "mt-10": !appsAdded?.length && (!appsAvailable?.length || !canChangeApps),
          })}
        >
          <InformationBubble>
            <div className="flex items-center">
              You can configure new apps in
              <Button
                buttonStyle="simplePrimary"
                className="pl-4 pr-0 !text-footnote-bold"
                onClick={handleOpenWorkspaceProfileModal}
              >
                workspace settings
              </Button>
              .
            </div>
          </InformationBubble>
        </div>
      ) : null}
    </>
  );
};

export default ThreadsApps;
