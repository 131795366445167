import { Placement } from "@floating-ui/react";
import { ComponentProps, forwardRef, useEffect } from "react";
import { FieldValues, Path, PathValue, useFormContext } from "react-hook-form";

import Avatar from "components/design-system/Avatar/Avatar";
import useFloatingList from "components/design-system/FloatingUi/useFloatingList";
import { Icon } from "components/design-system/icons";
import { Header, Main } from "components/ModalKit";
import { ModalProps } from "components/ModalKit/Modal";
import { StandardModal } from "components/Modals";
import { Skeleton } from "components/Skeleton";
import useElementSize from "hooks/useElementSize";
import useModalStore from "store/useModalStore";
import tw from "utils/tw";

import styles from "./Dropdown.module.css";
import { TextInput } from "./TextInput";
import { Option, SelectProps } from "./types";

type Mode = "default" | "icon-only" | "simplified";

type FakeSelectProps = {
  className?: string;
  disabled?: boolean;
  hasError: boolean;
  isOpen: boolean;
  loading?: boolean;
  mode?: Mode;
  placeholder?: string;
  selectedOption?: Option;
};

const OptionIcon = ({
  icon,
  iconSrc,
  name,
}: {
  icon?: ComponentProps<typeof Icon>["icon"];
  iconSrc?: string;
  name?: string;
}) => {
  return iconSrc !== undefined ? (
    <Avatar avatarURL={iconSrc} name={name} rounded="rounded-sm" size="x-small" />
  ) : icon ? (
    <Icon className="rounded-sm text-icon-secondary" icon={icon} size={20} />
  ) : null;
};

const FakeSelect = forwardRef<HTMLDivElement, FakeSelectProps>(
  (
    {
      className,
      disabled,
      hasError,
      loading,
      mode = "default",
      isOpen,
      placeholder,
      selectedOption,
    },
    ref
  ) => (
    <div
      tabIndex={0}
      ref={ref}
      aria-haspopup="listbox"
      aria-expanded={isOpen}
      aria-disabled={disabled}
      className={tw(
        "flex items-center select-none cursor-pointer h-36 min-w-0",
        {
          "text-text-subtle hover:text-text-subtle-hover": !disabled,
          "border rounded": mode !== "simplified",
          "gap-8 p-10": mode === "default",
          "gap-4 p-8": mode === "icon-only",
        },
        className,
        disabled
          ? "border-border-disabled text-text-disabled"
          : hasError
            ? "border-border-alert"
            : isOpen
              ? "border-border-active"
              : "border-border-container"
      )}
    >
      {loading ? (
        <Skeleton className="h-20 min-w-20 w-full" flex />
      ) : (
        <>
          {mode !== "simplified" && <OptionIcon {...selectedOption} />}

          <span className="truncate">
            {mode !== "icon-only" && (selectedOption ? selectedOption.label : placeholder)}
          </span>
        </>
      )}
      <Icon
        icon={isOpen ? "DropUp" : "DropDown"}
        size="20"
        className={tw("ml-auto", disabled ? "text-text-disabled" : "text-icon-secondary")}
      />
    </div>
  )
);

type SelectDropdownProps<TFieldValues extends FieldValues> = SelectProps<TFieldValues> & {
  className?: string;
  dropdownWidth?: number;
  error?: string;
  toModal?: boolean;
  onModalClose?: () => void;
  loading?: boolean;
  mode?: Mode;
  placement?: Placement;
  selectValueByDefault?: boolean;
  wrapperClassName?: string;
  keepInViewPort?: boolean;
};

const SELECT_DROPDOWN_MODAL_ID = "select-dropdown-modal";
// The modal version is only used in mobile devices and doesn't support keyboard navigation
// If we ever want to support it, we should consider creating a new component instead of reusing this one
const SelectDropdownModal = <TFieldValues extends FieldValues>({
  children,
  options,
  placeholder,
  selectedValue,
  handleSelectOption,
  onModalClose,
  name,
  ...props
}: WithChildren<
  ModalProps &
    Pick<SelectDropdownProps<TFieldValues>, "placeholder" | "options" | "onModalClose"> &
    Pick<SelectProps<TFieldValues>, "name"> & {
      selectedValue: string;
      handleSelectOption: (value: string) => void;
    }
>) => (
  <StandardModal height="half" {...props} closeButtonPosition="right" afterClose={onModalClose}>
    <Header>{placeholder}</Header>
    <Main className={`${styles["dropdown-list"]} pt-8 pb-32 border-none mt-0 rounded-none`}>
      {options.map(({ value, icon, iconSrc, label, description }) => (
        <div
          key={value}
          className={tw(styles["dropdown-list-item"], "gap-8")}
          onClick={() => handleSelectOption(value)}
        >
          <OptionIcon icon={icon} iconSrc={iconSrc} name={label} />
          <div className="flex flex-col gap-2">
            <span className="text-subhead">{label}</span>
            {description && (
              <span className="text-text-secondary text-footnote">{description}</span>
            )}
          </div>
          {selectedValue === value && (
            <Icon className="text-icon-primary-selected ml-auto" icon="Check" size={24} />
          )}
        </div>
      ))}
    </Main>
  </StandardModal>
);

const SelectDropdown = <TFieldValues extends FieldValues>({
  className,
  disabled,
  dropdownWidth = 0,
  error,
  hideErrorMessage,
  toModal = false,
  loading,
  mode,
  name,
  options,
  placeholder,
  placement = "bottom",
  selectValueByDefault = true,
  wrapperClassName,
  onModalClose,
  keepInViewPort,
}: SelectDropdownProps<TFieldValues>) => {
  const {
    formState: { errors, isSubmitting },
    setValue,
    watch,
  } = useFormContext<TFieldValues>();
  const selectedValue = watch(name);
  const selectedOption = options.find(o => o.value === selectedValue);

  const { openModal, closeModal } = useModalStore(({ openModal, closeModal }) => ({
    openModal,
    closeModal,
  }));

  // Sets the initial value for the hidden input which contains the valuable data for the API
  useEffect(() => {
    if (selectedValue || !selectValueByDefault) return;
    const firstOption = options[0];
    if (!firstOption) return;
    setValue(name, firstOption.value as PathValue<TFieldValues, Path<TFieldValues>>, {
      shouldDirty: true,
    });
  }, [name, options, setValue, selectedValue, selectValueByDefault]);

  const [inputRef, { inlineSize: inputWidth }] = useElementSize<HTMLDivElement>();

  const { activeIndex, anchorProps, floatingProps, isOpen, getItemProps, listRef, setIsOpen } =
    useFloatingList({
      placement,
      strategy: "fixed",
      keepInViewPort,
    });

  const floatingStyles =
    "style" in floatingProps && typeof floatingProps.style === "object" ? floatingProps.style : {};

  const handleSelectOption = (value: string) => {
    setValue(name, value as PathValue<TFieldValues, Path<TFieldValues>>, {
      shouldDirty: true,
      shouldTouch: true,
    });
    if (toModal) {
      closeModal(SELECT_DROPDOWN_MODAL_ID);
      return;
    }
    setIsOpen(false);
  };

  return (
    <>
      <div
        className={tw("min-w-0", wrapperClassName, {
          "pointer-events-none": disabled || isSubmitting,
        })}
        {...(!toModal
          ? anchorProps
          : {
              onClick: e => {
                e.stopPropagation();
                openModal(
                  <SelectDropdownModal
                    placeholder={placeholder}
                    options={options}
                    selectedValue={selectedValue}
                    name={name}
                    handleSelectOption={handleSelectOption}
                    onModalClose={onModalClose}
                  />,
                  {
                    id: SELECT_DROPDOWN_MODAL_ID,
                  }
                );
              },
            })}
        aria-label={`Select ${placeholder ?? "an item"}`}
      >
        <FakeSelect
          ref={inputRef}
          hasError={!!errors[name] || !!error}
          className={className}
          disabled={disabled}
          loading={loading}
          mode={mode}
          selectedOption={selectedOption}
          isOpen={isOpen}
          placeholder={placeholder ?? "Select an item..."}
        />
        <TextInput
          disabled={isSubmitting}
          hideErrorMessage={hideErrorMessage}
          name={name}
          type="hidden"
          wrapperClassName="!my-0"
        />
      </div>

      {isOpen && !toModal && (
        <div
          {...floatingProps}
          className={styles["dropdown-list"]}
          style={{
            width: mode === "icon-only" ? "auto" : `${Math.max(dropdownWidth, inputWidth)}px`,
            ...floatingStyles,
          }}
          // biome-ignore lint/a11y/useSemanticElements: this DIV is a listbox!
          role="listbox"
          tabIndex={0}
          aria-orientation="vertical"
        >
          {options.map(({ value, icon, iconSrc, label, description }, i) => (
            <div
              aria-selected={selectedValue === value}
              key={value}
              ref={node => {
                listRef.current[i] = node;
              }}
              {...getItemProps({
                role: "option",
                onClick: () => handleSelectOption(value),
                onKeyDown: e => {
                  if (e.key === "Enter") {
                    handleSelectOption(value);
                  }
                },
              })}
              className={tw(styles["dropdown-list-item"], "gap-8")}
              tabIndex={activeIndex === i ? 0 : -1}
            >
              <OptionIcon icon={icon} iconSrc={iconSrc} name={label} />
              <div className="flex flex-col gap-2">
                <span className="text-subhead text-text-primary">{label}</span>
                {description && (
                  <span className="text-text-subtle text-caption">{description}</span>
                )}
              </div>
              {selectedValue === value && (
                <Icon className="text-icon-primary-selected ml-auto" icon="Check" size={20} />
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default SelectDropdown;
