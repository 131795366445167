import { RefObject, useEffect, useRef, useState } from "react";
import ResizeObserver from "resize-observer-polyfill";

const useElementSize = <T extends HTMLElement>(): [RefObject<T>, ResizeObserverSize] => {
  const elementRef = useRef<T>(null);
  const [elementSize, setElementSize] = useState<ResizeObserverSize>({
    blockSize: 0,
    inlineSize: 0,
  });

  useEffect(() => {
    if (elementRef.current) {
      const observer = new ResizeObserver(entries => {
        if (!entries[0]?.borderBoxSize[0]) return;
        setElementSize(entries[0].borderBoxSize[0]);
      });

      observer.observe(elementRef.current);

      return () => observer.disconnect();
    }
  }, []);

  return [elementRef, elementSize];
};

export default useElementSize;
