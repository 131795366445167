import { RefObject } from "react";

import { nodeIs } from "@utility-types";

import { ResultEdgeType, Results } from "../types";

import { ResultItem, RecipientProfileLine, GroupResultItem } from "./ResultItem";
import ResultList from "./ResultList";

export type Props<T extends "users" | "groups"> = {
  disableResults?: boolean;
  isReversed?: boolean;
  label: string;
  onClickResult?: (
    item: ResultEdgeType<T>["node"],
    index: number,
    event: React.MouseEvent<HTMLDivElement | HTMLLIElement, MouseEvent>
  ) => void;
  results: Results<T>;
  searching?: boolean;
  selectedResultID?: string;
  selectedResultRef?: RefObject<HTMLLIElement>;
  setSelectedResultID?: (id: string) => void;
};

const RecipientsResults = <T extends "users" | "groups">({
  disableResults = false,
  isReversed,
  label,
  onClickResult,
  results,
  searching = false,
  selectedResultID,
  selectedResultRef,
  setSelectedResultID,
}: Props<T>): JSX.Element | null => {
  if (!results.edges.length) return null;
  return (
    <ResultList isReversed={isReversed} label={label} searching={searching}>
      {results.edges.map((edge, index) => {
        const isGroup = nodeIs(edge, ["GroupEdge", "GroupOrPreviewEdge", "WorkspaceEdge"]);

        if (isGroup) {
          return (
            <GroupResultItem
              key={`search-results-${edge.node.id}`}
              disableResults={disableResults}
              edge={edge}
              index={index}
              onClickResult={onClickResult}
              selectedResultID={selectedResultID}
              selectedResultRef={selectedResultRef}
              setSelectedResultID={setSelectedResultID}
            />
          );
        }

        return (
          <ResultItem
            key={`search-results-${edge.node.id}`}
            edge={edge}
            index={index}
            onClickResult={onClickResult}
            selectedResultID={selectedResultID}
            selectedResultRef={selectedResultRef}
            setSelectedResultID={setSelectedResultID}
          >
            <RecipientProfileLine recipient={edge.node} />
          </ResultItem>
        );
      })}
    </ResultList>
  );
};
export default RecipientsResults;
