import { useCallback } from "react";

import { SettingsModal } from "components/SettingsModal";
import { environmentFlagNames, LocalFeatureFlag } from "featureFlags";
import useShortcut from "hooks/useShortcut";
import useFeatureFlagStore from "store/useFeatureFlagStore";
import useModalStore from "store/useModalStore";

import useMobileTabSequence from "./hooks/useMobileTabSequence";

const MODAL_ID = "feature-flag-modal";

export default function FeatureFlags() {
  const { modalIsOpen, openModal } = useModalStore(({ modalIsOpen, openModal }) => ({
    modalIsOpen,
    openModal,
  }));

  const { flags } = useFeatureFlagStore(({ flags }) => ({
    flags,
  }));

  const isKey = (x: typeof flags, k: PropertyKey): k is LocalFeatureFlag => k in x;

  Object.keys(flags).forEach(k => {
    if (!isKey(flags, k)) return;
    if (environmentFlagNames.includes(k)) return;

    useFeatureFlagStore.setState(state => {
      delete state.flags[k];
      return state;
    });
  });

  const showModal = useCallback(() => {
    if (environmentFlagNames.length === 0 || modalIsOpen(MODAL_ID)) return;

    openModal(<SettingsModal view="feature-flags" />, {
      id: MODAL_ID,
    });
  }, [modalIsOpen, openModal]);

  useMobileTabSequence({
    isModalOpen: modalIsOpen(),
    showModal,
  });

  // onToggleShortcut Press control + CMD + option + ;
  useShortcut(["Control", "Meta", "Alt", ";"], () => {
    showModal();
  });

  return null;
}
