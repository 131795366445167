import { useApolloClient } from "@apollo/client";
import { useCallback } from "react";
import { useHistory } from "react-router";
import { useChatContext } from "stream-chat-react";

import { StreamGlueMessage, User } from "@utility-types";
import { readThreadEdge } from "apollo/cache/threadHelpers";
import useAppDrawerStore from "components/AppDrawer/useAppDrawerStore";
import { routeToThread } from "components/routing/utils";
import { useThreadViewState } from "components/thread/ThreadView/provider/ThreadViewProvider";
import ThreadComposeModal from "components/threads/ThreadComposeModal";
import { UserFieldsFragmentDoc } from "generated/graphql-operations";
import { useIsGroupMember } from "hooks/group/useIsGroupMember";
import { useIsGroupMessageable } from "hooks/group/useIsGroupMessageable";
import useAuthData from "hooks/useAuthData";
import useAppStateStore from "store/useAppStateStore";
import useModalStore from "store/useModalStore";
import env from "utils/processEnv";
import { getReplyThreadID, streamMessageToGlueMessage } from "utils/stream/message";

const useReplyInThreadModal = (message: StreamGlueMessage) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  const { authData } = useAuthData();
  const cache = useApolloClient().cache;
  const { channel } = useChatContext();
  const history = useHistory();
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));
  const isGroupMember = useIsGroupMember();
  const isGroupMessageable = useIsGroupMessageable();

  const { openDrawer } = useAppDrawerStore(({ openDrawer }) => ({
    openDrawer,
  }));

  const { recipientID } = useThreadViewState(({ recipientID }) => ({
    recipientID,
  }));

  const handleReplyInThread: () => void = useCallback(async () => {
    const messageID = message.id;
    const replyThreadID = getReplyThreadID(message);

    const threadID = channel?.id;
    const threadEdge = readThreadEdge(`${threadID}-${authData?.me.id}`, cache);

    const thread = threadEdge?.node;
    let recipients = (thread?.recipients.edges || [])
      .map(e => e.node)
      .filter(r => r.id !== authData?.me.id && r.id !== env.glueAIBotID);

    if (!thread?.isPersistentChat && recipients[0]?.__typename === "User") {
      // Remove the thread starter from recipients if they are a member of a group recipient
      const starter = recipients[0];
      for (const group of recipients.filter(r => r.__typename !== "User")) {
        try {
          if (await isGroupMember(group, starter)) {
            recipients = recipients.slice(1);
            break;
          }
        } catch (e) {
          console.error("[useReplyInThreadModal] isGroupMember:", e);
          break;
        }
      }
    }

    let recipientsRemoved = 0;

    // Remove non-messageable groups from recipients
    try {
      const messageableGroups = await Promise.all(
        recipients.map(async r => {
          const isMessageable = r.__typename !== "User" ? await isGroupMessageable(r.id) : true;
          return isMessageable ? r : null;
        })
      );
      recipients = messageableGroups.filter(g => g !== null);
      recipientsRemoved = messageableGroups.filter(g => g === null).length;
    } catch (e) {
      console.error("[useReplyInThreadModal] isGroupMessageable:", e);
    }

    if (recipients.length === 0) {
      const glueMessage = streamMessageToGlueMessage(message);
      const user = cache.readFragment<User>({
        fragment: UserFieldsFragmentDoc,
        fragmentName: "UserFields",
        id: `User:${glueMessage.user.id}`,
        optimistic: true,
      });
      recipients = [user ?? glueMessage.user];
    }

    if (!replyThreadID && messageID && threadID) {
      if (breakpointMD) {
        openDrawer({
          action: "reply",
          initialRecipients: recipients,
          message: { id: messageID, threadID },
          recipientsRemoved,
        });
      } else {
        openModal(
          <ThreadComposeModal
            initialDraft={{ recipients }}
            recipientsRemoved={recipientsRemoved}
            replyToMessage={{ id: messageID, threadID }}
          />
        );
      }

      return;
    }

    history.push(
      routeToThread({
        recipientID,
        threadID: replyThreadID || "",
        to: "secondary",
      })
    );
  }, [
    authData?.me.id,
    cache,
    channel?.id,
    history,
    isGroupMember,
    isGroupMessageable,
    message,
    openDrawer,
    openModal,
    recipientID,
    breakpointMD,
  ]);

  return { handleReplyInThread };
};

export default useReplyInThreadModal;
