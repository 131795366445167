import { MouseEvent } from "react";

import { HeaderButton } from "components/design-system/Button";
import { Icon } from "components/design-system/icons";

type Props = {
  count: number;
  disabled?: boolean;
  tooltip?: string;
  hasExternal?: boolean;
  onClick: (e: MouseEvent) => void;
};

const FacePileCount = ({ count, disabled = false, tooltip, hasExternal, onClick }: Props) => {
  return (
    <HeaderButton
      icon="User"
      tooltip={tooltip || "See all recipients"}
      tooltipPlacement="bottom"
      tooltipStyle="inverted"
      disabled={disabled}
      onClick={onClick}
    >
      {count === 0 ? "-" : count}
      {hasExternal && (
        <Icon
          className="p-2 text-icon-warning bg-background-warning rounded-sm select-none"
          icon="ExternalAccount"
          size={14}
          strokeWidth={1}
        />
      )}
    </HeaderButton>
  );
};

export default FacePileCount;
