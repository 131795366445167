import MyFeedImg from "assets/icons-jpg/my-feed.jpg";
import useSidebarCounts from "components/SideBar/hooks/useSidebarCounts";
import { useUnseenGroupsCountQuery } from "generated/graphql-operations";
import useAuthData from "hooks/useAuthData";
import useHistoryItem from "hooks/useHistoryItem";

const MyFeedHeader = () => {
  const { authReady } = useAuthData();

  useHistoryItem();

  const { data: unseenGroupsData } = useUnseenGroupsCountQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-first",
  });

  const unseenGroupsCount = unseenGroupsData?.groups.totalCount || 0;

  const { unseenFeedCount } = useSidebarCounts();

  return (
    <div className="pb-16">
      <div className="flex items-center select-none" data-testid="recipient-heading">
        <div className="flex items-center grow-0 h-24 mr-8">
          <img alt="My feed icon" className="rounded-sm w-24 h-24 shrink-0" src={MyFeedImg} />
        </div>
        <div className="grow font-semibold text-text-primary leading-7 text-xl">My feed</div>
        <div className="text-text-secondary">
          <UpdateContent groupsCount={unseenGroupsCount} updatesCount={unseenFeedCount} />
        </div>
      </div>
    </div>
  );
};

function UpdateContent({
  groupsCount,
  updatesCount,
}: {
  groupsCount: number;
  updatesCount: number;
}) {
  if (groupsCount > 0 && updatesCount > 0) {
    return `${updatesCount} update${
      updatesCount !== 1 ? "s" : ""
    } in ${groupsCount} group${groupsCount !== 1 ? "s" : ""}`;
  }

  return "";
}

export default MyFeedHeader;
