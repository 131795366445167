import { App } from "@utility-types/graphql";
import { ModalProps } from "components/ModalKit/Modal";
import { ConfirmationAlert } from "components/Modals";

type Props = {
  app: App;
  container?: string;
  removeApp: () => Promise<unknown>;
} & ModalProps;

const ConfirmRemoveAppModal = ({ app, container, removeApp, ...props }: Props) => (
  <ConfirmationAlert
    confirmLabel="Remove"
    header={container ? `Remove ${app.name} from "${container}"?` : `Remove ${app.name}`}
    onConfirm={removeApp}
    isDestructive
    {...props}
  />
);

export default ConfirmRemoveAppModal;
