import { useEffect } from "react";

import { Mailbox, nodeIs } from "@utility-types/graphql";
import { Button } from "components/design-system/Button";
import { InformationBubble } from "components/design-system/InformationBubble";
import {
  UserMilestone,
  useCompleteMilestoneMutation,
  useFetchJoinApprovalsQuery,
  useThreadListQuery,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useOnboardingStore from "store/useOnboardingStore";

import ContentWrapper from "./ContentWrapper";
import Footer from "./Footer";

const NoWorkspaceWall = () => {
  const { currentStep, noWorkspaceEmailSent, setState } = useOnboardingStore(
    ({ currentStep, noWorkspaceEmailSent, setState }) => ({
      currentStep,
      noWorkspaceEmailSent,
      setState,
    })
  );
  const { authReady } = useAuthData();
  const { data: joinApprovalsData } = useFetchJoinApprovalsQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
  });
  const { data: threadListData } = useThreadListQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    variables: { mailbox: Mailbox.Inbox },
  });

  const groupInvites = joinApprovalsData?.joinApprovals.edges.filter(j =>
    nodeIs(j.node.joinable, ["GroupPreview"])
  );
  const threadList = threadListData?.threads.edges;
  const groupAndThreadInvitesCount = (groupInvites?.length ?? 0) + (threadList?.length ?? 0);

  const [completeMilestone] = useCompleteMilestoneMutation();
  useEffect(() => {
    if (groupAndThreadInvitesCount > 0 || noWorkspaceEmailSent) {
      return;
    }
    completeMilestone({
      variables: { input: { milestone: UserMilestone.NoWorkspaceWall } },
    }).then(() => setState({ noWorkspaceEmailSent: true }));
  }, [completeMilestone, groupAndThreadInvitesCount, noWorkspaceEmailSent, setState]);

  return (
    <ContentWrapper
      title="You don't have a workspace, yet."
      headline="A workspace is a shared space to work together as a team. You can join a workspace with your work email or with an invitation from a coworker."
    >
      <InformationBubble className="mt-32">
        Looking for a different workspace? Sign in with another work email address, request an
        invitation, or visit Glue on the web.
      </InformationBubble>
      <Footer
        onClickBack={() =>
          setState({
            view: "CompleteYourProfile",
            currentStep: currentStep - 1,
          })
        }
        hideSkip
      >
        {groupAndThreadInvitesCount > 0 ? (
          <Button
            onClick={() =>
              setState({
                view: "Review",
                goBackTo: "NoWorkspaceWall",
                currentStep: currentStep + 1,
              })
            }
          >
            Continue without workspace
          </Button>
        ) : null}
      </Footer>
    </ContentWrapper>
  );
};

export default NoWorkspaceWall;
