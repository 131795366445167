import Picker from "@emoji-mart/react";
import { ComponentProps, useEffect } from "react";

type Props = {
  skinTonePosition: ComponentProps<typeof Picker>["skinTonePosition"];
};

const skinToneKey = "emoji-mart.skin";
let savedSkinTone: string | null = null;

export default function usePreserveSkinTone({ skinTonePosition }: Props) {
  useEffect(() => {
    const currentTone = localStorage.getItem(skinToneKey);

    if (savedSkinTone && savedSkinTone !== currentTone) {
      localStorage.setItem(skinToneKey, savedSkinTone);
    }

    if (skinTonePosition === "none" && currentTone !== "0") {
      savedSkinTone = currentTone;
      localStorage.setItem(skinToneKey, "0");
    }

    return () => {
      savedSkinTone && localStorage.setItem(skinToneKey, savedSkinTone);
      savedSkinTone = null;
    };
  }, [skinTonePosition]);

  useEffect(() => {
    const handler = () => savedSkinTone && localStorage.setItem(skinToneKey, savedSkinTone);

    window.addEventListener("beforeunload", handler);

    return () => {
      window.removeEventListener("beforeunload", handler);
    };
  }, []);
}
