import { useRef } from "react";
import { useHistory } from "react-router";

import { UserEdge } from "@utility-types";
import BackStackButton from "components/App/AppLayoutMobile/BackStackButton";
import Avatar from "components/design-system/Avatar/Avatar";
import PaneHeader from "components/design-system/ui/PaneHeader";
import OptionalLink from "components/OptionalLink";
import { routeToUser } from "components/routing/utils";
import ChatThreadInfo from "components/threads/ThreadHeader/ChatThreadInfo";
import useElementBreakpoint from "hooks/useElementBreakpoint";

import UserProfileActionMenu from "./UserProfileActionMenu";

const desktopWidth = 480;

const NoPersistentChatHeader = ({
  userEdge,
}: {
  userEdge?: UserEdge;
}): JSX.Element | null => {
  const history = useHistory();

  const headerRef = useRef<HTMLDivElement>(null);

  const desktopBreakpoint = useElementBreakpoint(headerRef, desktopWidth);

  const user = userEdge?.node;

  const userPath = user && routeToUser({ to: "secondary", userID: user.id });

  return (
    <PaneHeader ref={headerRef}>
      <div className="flex grow items-center min-h-[52px]">
        <BackStackButton />

        <div className="flex items-center select-none">
          <OptionalLink enabled={!!user} to={userPath}>
            <Avatar
              avatarURL={user?.avatarURL}
              background="transparent"
              margin="mr-12"
              name={user?.name}
              rounded="rounded-md"
              size="large"
            />
          </OptionalLink>
          <ChatThreadInfo
            chatType="user"
            onClick={() => userPath && history.push(userPath)}
            recipient={user}
            showExternalLabel={!!desktopBreakpoint}
          />
        </div>
      </div>

      <div className="flex shrink">{userEdge && <UserProfileActionMenu userEdge={userEdge} />}</div>
    </PaneHeader>
  );
};

export default NoPersistentChatHeader;
