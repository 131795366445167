import type { Placement } from "@floating-ui/react";
import React, { useRef } from "react";

import { Dropdown } from "components/design-system/FloatingUi";
import {
  ActionSheetItemGroups,
  DropdownActions,
} from "components/design-system/FloatingUi/DropdownActions";
import { IconName } from "components/design-system/icons";

type Props = {
  multiple?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void> | void;
  onlyImages?: boolean;
  placement?: Placement;
  readOnly?: boolean;
  target: JSX.Element;
};

const FileUploadMenu = ({
  multiple = false,
  onChange,
  onlyImages = false,
  placement = "bottom-start",
  readOnly = false,
  target,
}: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const menuElements: { icon: IconName; onClick: () => void; text: string }[] = [
    {
      icon: "Image" as const,
      onClick: () => {
        fileInputRef.current?.setAttribute("accept", "image/*");
        fileInputRef.current?.removeAttribute("capture");
        fileInputRef.current?.click();
      },
      text: "Photo Library",
    },
    {
      icon: "Camera" as const,
      onClick: () => {
        fileInputRef.current?.setAttribute("accept", "image/*");
        fileInputRef.current?.setAttribute("capture", "");
        fileInputRef.current?.click();
      },
      text: "Take Photo",
    },
  ];

  if (!onlyImages) {
    menuElements.push({
      icon: "Folder" as const,
      onClick: () => {
        fileInputRef.current?.removeAttribute("capture");
        fileInputRef.current?.removeAttribute("accept");
        fileInputRef.current?.click();
      },
      text: "Choose Files",
    });
  }

  const itemGroups: ActionSheetItemGroups[] = [
    {
      items: !readOnly ? [...menuElements] : [],
      name: "File upload menu",
    },
  ];

  return (
    <>
      <Dropdown content={<DropdownActions actions={itemGroups} />} placement={placement}>
        {target}
      </Dropdown>

      <input
        ref={fileInputRef}
        className="hidden"
        disabled={readOnly}
        id="picture-input"
        multiple={multiple}
        onChange={onChange}
        type="file"
      />
    </>
  );
};

export default FileUploadMenu;
