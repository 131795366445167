import { ApolloCache } from "@apollo/client";
import { uniqBy } from "lodash-es";

import { Message } from "@utility-types";
import {
  MessagePinsDocument,
  MessagePinsQuery,
  usePinMessageMutation,
  useUnpinMessageMutation,
} from "generated/graphql";

const usePinMessage = (threadID?: string) => {
  const updatePinCache = (cache: ApolloCache<unknown>, messageProp: Message) => {
    if (!cache) return;

    const pinnedMessages = cache.readQuery<MessagePinsQuery>({
      query: MessagePinsDocument,
      variables: { threadID },
    });

    const messageID = messageProp.id;
    const edges = pinnedMessages?.messagePins.edges;

    const message = !!edges?.find(
      m => m.node.message.id === messageID || m.node.message.streamID === messageID
    );

    const newEdges = message
      ? edges?.filter(m => m.node.message.id !== messageID && m.node.message.streamID !== messageID)
      : (edges?.length ?? 0) < 4
        ? [
            uniqBy(
              [
                ...(edges ?? []),
                {
                  __typename: "MessagePinEdge",
                  node: {
                    __typename: "MessagePin",
                    id: "",
                    message: messageProp,
                    user: messageProp.user,
                    createdAt: new Date().toISOString(),
                  },
                },
              ],
              "node.message.id"
            ),
          ]
        : edges;

    cache.writeQuery({
      query: MessagePinsDocument,
      variables: { threadID },
      data: {
        messagePins: {
          ...pinnedMessages?.messagePins,
          edges: newEdges,
          totalCount: (pinnedMessages?.messagePins.totalCount ?? 0) + (message ? -1 : 1),
        },
      },
    });
  };

  const [pinMessageMutation] = usePinMessageMutation({
    errorPolicy: "all",
  });

  const [unpinMessageMutation] = useUnpinMessageMutation({
    errorPolicy: "all",
  });

  const pinMessage = async (message: Message) => {
    await pinMessageMutation({
      variables: { messageID: message.id },
      update: cache => updatePinCache(cache, message),
    });
  };

  const unpinMessage = async (message: Message) => {
    await unpinMessageMutation({
      variables: { messageID: message.id },
      update: cache => updatePinCache(cache, message),
    });
  };

  return { pinMessage, unpinMessage };
};

export default usePinMessage;
