import { useMemo } from "react";
import type { GroupStyle } from "stream-chat-react/dist/components/MessageList/utils";

import type { Message } from "@utility-types";
import { Icon } from "components/design-system/icons";
import { MessageAttachments } from "components/Message/MessageAttachments";
import { Timestamp } from "components/Message/Timestamp";
import { isOnlyEmojis as isOnlyEmojisFunction, renderText } from "components/Message/utils";
import { Skeleton } from "components/Skeleton";
import { getAttachmentIcon, mapAttachmentTypes } from "components/threads-list/ThreadItem/utils";
import { formatDateWithTime } from "utils/formatDate";
import { glueMessageToStreamMessage } from "utils/stream/message";
import { truncateSubject } from "utils/thread/truncateSubject";
import tw from "utils/tw";

type Props = {
  collapsed?: boolean;
  firstGroupStyle?: GroupStyle;
  message: (Message & { type?: string }) | undefined;
  messageActionMenu?: JSX.Element | null;
  messageGlueStatus?: JSX.Element | null;
  messagePreviewRendered?: boolean;
  truncateFunc?: (input: string, length: number) => string;
  truncateLength?: number;
};

export const TruncateLength = 620;

export const ReplyToMessageContent = ({
  collapsed,
  firstGroupStyle,
  message,
  messageActionMenu,
  messageGlueStatus,
  messagePreviewRendered = false,
  truncateFunc,
  truncateLength: truncateLengthProps,
}: Props) => {
  const messageText = useMemo(() => renderText(message?.text ?? ""), [message]);

  const collapsedMessageText = useMemo(() => {
    const trunc = truncateFunc || truncateSubject;
    const truncatedMessage = messagePreviewRendered
      ? renderText(`${trunc(message?.text || "", truncateLengthProps || TruncateLength)}\n`)
      : message?.textPreview;

    const types = mapAttachmentTypes(message?.attachments ?? []);

    const attachmentIcon = getAttachmentIcon(types);

    return truncatedMessage ? (
      messagePreviewRendered ? (
        truncatedMessage
      ) : (
        <p className="m-0 truncate">{truncatedMessage}</p>
      )
    ) : types.length ? (
      <p className="m-0">
        <Icon className="text-interactive-subtle" icon={attachmentIcon} size="15" />
      </p>
    ) : (
      ""
    );
  }, [
    message?.attachments,
    message?.text,
    message?.textPreview,
    messagePreviewRendered,
    truncateFunc,
    truncateLengthProps,
  ]);

  const deleted = message?.type === "deleted";

  const asStreamMessage =
    message &&
    glueMessageToStreamMessage({
      ...message,
    });

  const messageGlueAttachment: JSX.Element | null =
    message && !deleted ? (
      <MessageAttachments attachments={asStreamMessage?.attachments || []} messageId={message.id} />
    ) : null;

  const isOnlyEmojis = isOnlyEmojisFunction(message?.text);

  return (
    <div className="relative w-full min-w-0">
      <div className="pr-16">
        {firstGroupStyle !== "top" && firstGroupStyle !== "single" && messageActionMenu}

        <div className="str-chat-message-content" data-testid="message-glue-content">
          {message ? (
            <div className={tw({ "text-3xl": isOnlyEmojis })} data-testid="message-glue-message">
              {collapsed ? collapsedMessageText : messageText}
            </div>
          ) : (
            <Skeleton className="my-0" height="18px" />
          )}

          {!collapsed && message?.text === "" && messageGlueAttachment}
        </div>

        {!collapsed && message?.text !== "" && messageGlueAttachment}

        {!collapsed && message?.updatedAt && (
          <div className={"text-caption text-text-subtle mt-2 mb-4"}>
            (edited&nbsp;
            <Timestamp
              customClass="text-caption text-text-subtle"
              date={message.updatedAt}
              formatDate={formatDateWithTime}
            />
            )
          </div>
        )}
      </div>

      {messageGlueStatus}
    </div>
  );
};
