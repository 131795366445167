import { ComponentProps } from "react";

import { Button } from "components/design-system/Button";

import { useDisableSubmit } from "./hooks";

/**
 * We should use this component in all the Forms. It contains conditions to
 * disable it based on the following criteria
 *
 * - There are required fields empty.
 * - The form doesn't have any change to be saved.
 * - The form contains one or more invalid fields.
 * - There's a pending request (Is submitting)
 */
export const SubmitButton = (
  props: ComponentProps<typeof Button> & { requireChanges?: boolean }
) => {
  const { requireChanges, ...rest } = props;
  const disabled = useDisableSubmit(requireChanges);
  return (
    <Button buttonStyle="primary" {...rest} disabled={props.disabled || disabled} type="submit" />
  );
};
