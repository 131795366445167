import Badge, { BadgeProps } from "./Badge";

type MemberRole = "admin" | "app" | "starter";

type MemberRoleBadgeProps = Pick<BadgeProps, "variant" | "className"> & {
  memberRole: MemberRole;
};

const getMemberRoleDisplayText = (role: MemberRole) => {
  switch (role) {
    case "admin":
      return "Admin";
    case "app":
      return "App";
    case "starter":
      return "Starter";
  }
};

const MemberRoleBadge = ({ memberRole, variant, className }: MemberRoleBadgeProps) => (
  <Badge variant={variant} className={className}>
    {getMemberRoleDisplayText(memberRole)}
  </Badge>
);

export default MemberRoleBadge;
