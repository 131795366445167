import fixDevApiUrl from "utils/fixDevApiUrl";

import env from "./processEnv";
const { hostname: apiHostname } = new URL(env.glueApiUrl);

export const parseProxiedUrl = (url: string) => {
  url = fixDevApiUrl(url);

  // we are generating at runtime since we were unable to mock at startup for testing
  const hostnames = [apiHostname];
  if (env.glueApiKnownAliases.length > 0) {
    hostnames.push(
      ...env.glueApiKnownAliases.map(url => {
        const { hostname: apiLegacyHostname } = new URL(url);
        return apiLegacyHostname;
      })
    );
  }

  const proxiedUrlRegex = new RegExp(
    `^(?:.+?(?:${hostnames
      .map(s => s.replace(".", "\\."))
      .join("|")})/(?:api/)?proxy/)(.+?)(?:/(image|video))?$`
  );

  const [_, proxiedUrl] = url.match(proxiedUrlRegex) || [];
  if (proxiedUrl) {
    return decodeURIComponent(proxiedUrl);
  }
  return url;
};
