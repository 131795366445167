import Hr from "components/design-system/Hr";

type Props = {
  divider?: "top" | "bottom" | false;
  header?: JSX.Element;
};

const HrDivider = () => <Hr className="w-full my-8" colorClassName="border-t-border-strong" />;

const Section = ({ children, divider = "top", header }: WithChildren<Props>) => {
  return (
    <>
      {divider === "top" && <HrDivider />}
      {header}
      {children}
      {divider === "bottom" && <HrDivider />}
    </>
  );
};

export default Section;
