import { useEffect, useRef } from "react";

import { useUpdateSettingsMutation, useUserProfileDataQuery } from "generated/graphql";
import useAppStateStore from "store/useAppStateStore";
import { getTimezoneAutomatically } from "utils/timezone";

import useAuthData from "./useAuthData";
import useTimezoneList from "./useTimezoneList";

const useBrowserTimezone = () => {
  const { appStatus } = useAppStateStore(({ appStatus }) => ({ appStatus }));
  const { authReady, fetchAuthData } = useAuthData();

  const inFlight = useRef(false);

  const timezones = useTimezoneList();

  const { data: profileData } = useUserProfileDataQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
  });

  const [updateSettings] = useUpdateSettingsMutation({
    errorPolicy: "all",
  });

  useEffect(() => {
    if (appStatus === "inactive" || !authReady || !timezones) return;
    if (!profileData?.settings?.timezone?.auto) return;

    const timezoneName = getTimezoneAutomatically(timezones);

    if (profileData.settings.timezone.name === timezoneName || !timezoneName) return;

    if (inFlight.current) return;

    inFlight.current = true;

    updateSettings({
      optimisticResponse: {
        updateSettings: {
          ...profileData.settings,
          timezone: {
            __typename: "Timezone",
            auto: true,
            name: timezoneName,
          },
        },
      },
      variables: {
        settings: {
          timezone: {
            auto: true,
            name: timezoneName,
          },
        },
      },
    })
      .then(() => fetchAuthData({ refresh: true }))
      .finally(() => {
        inFlight.current = false;
      });
  }, [appStatus, authReady, fetchAuthData, profileData?.settings, timezones, updateSettings]);
};

export default useBrowserTimezone;
