import { useCallback, useEffect, useRef, useState } from "react";
import { ElementRef } from "react";
import { useFormContext } from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";

import { Form } from "components/design-system/Forms";
import { MessageEditor } from "components/MessageEditor";
import useValidateMessage from "components/thread/hooks/useValidateMessage";
import useSendMessage from "components/thread/ThreadView/hooks/useSendMessage";
import useAppUnfurlSetupMessage from "components/threads/hooks/useAppUnfurlSetupMessage";
import useRestoreReply from "components/threads/ThreadCompose/hooks/useRestoreReply";
import { DraftListDocument, useDeleteDraftMutation } from "generated/graphql-operations";
import useCacheEvict from "hooks/state/useCacheEvict";
import useNativeKeyboardStore from "store/useNativeKeyboardStore";

import useLlmModel from "../hooks/useLlmModel";

import AIMessageEditor from "./AIMessageEditor";

const SetFormDefaults = ({
  defaultModel,
  defaultModelLoading,
}: {
  defaultModel: string | undefined;
  defaultModelLoading: boolean | undefined;
}) => {
  const { reset } = useFormContext();
  const didReset = useRef(false);

  useEffect(() => {
    if (didReset.current || defaultModelLoading) return;

    reset({
      chatModel: defaultModel,
    });

    didReset.current = true;
  }, [defaultModel, defaultModelLoading, didReset, reset]);

  return null;
};

const AIThreadReply = () => {
  const editor = useRef<ElementRef<typeof MessageEditor> | null>(null);
  const { evictNode } = useCacheEvict();

  const { validateMessage } = useValidateMessage();
  const sendMessage = useSendMessage();
  const { sendSetupMessages } = useAppUnfurlSetupMessage();

  const [deleteDraftMutation] = useDeleteDraftMutation();

  const {
    channel,
    threadID,
    threadWorkspaceID,
    draft,
    draftID,
    draftIDRef,
    dispatch,
    addDraft,
    removeDraft,
  } = useRestoreReply({ editor });

  const updateDraft = useDebouncedCallback(
    useCallback(() => {
      if (!channel?.id || !editor.current) return;

      const message = editor.current.getMessage();
      const hasNoText = !message.text.length;
      const hasNoAttachments = !message.attachments.length;

      dispatch({ type: "change", draftForm: { message } });

      if (hasNoAttachments && hasNoText) {
        removeDraft({ threadID: channel.id });
        return;
      }
      addDraft({
        threadID: channel.id,
        message,
        draftID: draftIDRef.current,
      });
    }, [addDraft, channel?.id, dispatch, draftIDRef, removeDraft]),
    500
  );

  const onFormSubmit = () => {
    if (!editor.current || !channel) return;

    const message = editor.current.getStreamMessage();
    if (!validateMessage(message)) return;

    const appUnfurlSetups = editor.current.getAppUnfurlSetups();
    (async () => {
      const sentMessage = await sendMessage(message);
      if (!sentMessage) return;
      sendSetupMessages(sentMessage.id, threadID, appUnfurlSetups);
    })();

    threadID && removeDraft({ threadID });
    draftID &&
      draft &&
      deleteDraftMutation({
        refetchQueries: [DraftListDocument],
        update: c => evictNode(draft, c),
        variables: { id: draftID },
      });

    editor.current.reset();
    editor.current.focusEditor();
  };

  const onAttachFiles = useCallback((files: File[]) => {
    if (!editor.current) return;
    editor.current.addAttachments(files);
  }, []);

  const { defaultModel, defaultModelLoading } = useLlmModel(threadID);

  const [safeAreaPadding, setSafeAreaPadding] = useState(false);
  useEffect(
    () =>
      useNativeKeyboardStore.subscribe(
        ({ keyboardHeight }) => keyboardHeight,
        keyboardHeight => {
          setSafeAreaPadding(keyboardHeight > 100);
        }
      ),
    []
  );

  return (
    <div className="pb:0 md:pb-16 px-0 md:px-16 max-h-[80%] min-h-0 md:min-h-[122px]">
      <Form
        className="flex min-h-0 h-full"
        useFormProps={{ defaultValues: { chatModel: defaultModel } }}
      >
        <SetFormDefaults defaultModel={defaultModel} defaultModelLoading={defaultModelLoading} />
        <AIMessageEditor
          editor={editor}
          onChange={updateDraft}
          onAttachFiles={onAttachFiles}
          sendDraft={onFormSubmit}
          safeAreaPadding={safeAreaPadding}
          threadID={threadID}
          workspaceID={threadWorkspaceID}
          isReply
        />
      </Form>
    </div>
  );
};

export default AIThreadReply;
