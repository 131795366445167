import { useApolloClient } from "@apollo/client";
import { useEffect, useState } from "react";

import { Recipient, nodeIs } from "@utility-types";
import { FetchWorkspaceDocument, FetchWorkspaceQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import isDefined from "utils/isDefined";

export type CommonWorkspace = {
  id: string;
  name: string;
  domain?: string;
};

export const useGetCommonWorkspaces = (
  recipient?: Recipient,
  setWorkspaceLoaded?: (workspaceLoaded: boolean) => void
) => {
  const { authData, authReady } = useAuthData();
  const apolloClient = useApolloClient();

  const [commonWorkspaces, setCommonWorkspaces] = useState<CommonWorkspace[]>([]);

  useEffect(() => {
    if (!authReady) return;

    (async () => {
      if (!recipient) return;

      const commonWorkspacesIDs =
        "workspaceIDs" in recipient
          ? recipient.workspaceIDs?.filter(workspaceID =>
              authData?.me.workspaceIDs?.includes(workspaceID)
            )
          : authData?.me.workspaceIDs?.includes(recipient.workspaceID ?? "")
            ? [recipient.workspaceID]
            : undefined;

      if (!commonWorkspacesIDs) return [];

      const commonWorkspaces = await Promise.all(
        commonWorkspacesIDs.map(workspaceID =>
          apolloClient
            .query<FetchWorkspaceQuery>({
              fetchPolicy: "cache-first",
              query: FetchWorkspaceDocument,
              variables: { id: workspaceID },
            })
            .then(response => {
              if (nodeIs(response.data.node, ["Workspace"])) {
                return {
                  id: response.data.node.id,
                  name: response.data.node.name,
                  domain: response.data.node.domains[0],
                };
              }
            })
        )
      );

      setCommonWorkspaces(commonWorkspaces.filter(isDefined));
    })().finally(() => {
      setWorkspaceLoaded?.(true);
    });
  }, [apolloClient, authData?.me, authReady, recipient, setWorkspaceLoaded]);

  return commonWorkspaces;
};
