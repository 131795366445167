const getElementBounds = (
  el: HTMLElement | Element
): { XOffset: number; YOffset: number; height: number; width: number } => {
  const { scrollX, scrollY } = window;
  const {
    documentElement: { scrollLeft, scrollTop },
  } = document;
  const pageScrollX = scrollX || scrollLeft;
  const pageScrollY = scrollY || scrollTop;
  const rect = el.getBoundingClientRect();
  return {
    height: rect.height,
    width: rect.width,
    XOffset: rect.left + pageScrollX,
    YOffset: rect.top + pageScrollY,
  };
};

export default getElementBounds;
