import { StreamGlueMessage } from "@utility-types";
import { hasSources, keepResponseSources } from "md/extractSources";
import { InlineMarkdownComponent } from "md/RenderMarkdown";
import useModalStore from "store/useModalStore";
import { isMobile } from "utils/platform";

import {
  MessageMetadataInfoDropdown,
  MessageMetadataInfoModal,
} from "../../MessageActions/MessageMetadataInfo";

type MessageSourcesProps = {
  message: StreamGlueMessage;
};

type SourcesDropdownWrapperProps = {
  children: JSX.Element;
};

type OtherSourcesProps = {
  onClick?: () => void;
};

/**
 * @summary Matches markdown style links e.g. [text](url).
 */
const markdownLinkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;

/**
 * @summary Total number of markdown links in a given text.
 */
const countSources = (str: string): number => {
  const matches = str.match(markdownLinkRegex);
  return matches ? matches.length : 0;
};

/**
 * @summary The default number of sources to be displayed.
 */
const defaultSourcesLimit = 2;

/**
 * @summary The number of sources that exceed the default limit.
 */
const getAdditionalSources = (str: string): number => {
  const totalSources = countSources(str);
  return totalSources - defaultSourcesLimit;
};

/**
 * @summary Trims the number of mentions in a given string to a specified maximum.
 */
const trimSources = (str: string, limit = defaultSourcesLimit): string => {
  const matches = Array.from(str.matchAll(markdownLinkRegex)) as RegExpMatchArray[];
  const limitedMatches = matches.slice(0, limit);

  return limitedMatches.map(match => `[${match[1]}](${match[2]})`).join(" ");
};

/**
 * @summary Renders the sources of a Glue AI message.
 */
const MessageSources = ({ message }: MessageSourcesProps) => {
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  if (!hasSources(message.text)) return null;

  const allSources = keepResponseSources(message.text || "");
  const displayedSources = trimSources(allSources || "");
  const numOtherSources = getAdditionalSources(allSources || "");

  const handleOnClickMobile = () => {
    return openModal(<MessageMetadataInfoModal message={message} />);
  };

  const SourcesDropdownWrapper = ({ children }: SourcesDropdownWrapperProps) => {
    return (
      <MessageMetadataInfoDropdown message={message} placement="top-end">
        {children}
      </MessageMetadataInfoDropdown>
    );
  };

  const OtherSources = ({ onClick }: OtherSourcesProps) => (
    <span className="leading-none ml-4 text-footnote-bold text-text-subtle" onClick={onClick}>
      {`+${numOtherSources} more`}
    </span>
  );

  return (
    <div className="flex mt-12">
      <span className="leading-[27px]">
        <span className="mr-4 mt-1 text-subhead text-text-secondary">Sources:</span>
        <InlineMarkdownComponent text={displayedSources} />
        {numOtherSources > 0 && (
          // inline-block rule below ensures MessageMetadataInfoDropdown renders inline next to OtherSources
          <span className="[&>div]:inline-block cursor-pointer">
            {!isMobile() ? (
              <SourcesDropdownWrapper>
                <OtherSources />
              </SourcesDropdownWrapper>
            ) : (
              <OtherSources onClick={handleOnClickMobile} />
            )}
          </span>
        )}
      </span>
    </div>
  );
};

export default MessageSources;
