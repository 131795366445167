import { memo, useEffect, useState } from "react";
import { Event } from "stream-chat/dist/types/types";
import { useChatContext } from "stream-chat-react";

import { GlueDefaultStreamChatGenerics } from "@utility-types";
import useAppStateStore from "store/useAppStateStore";
import { isNativeMobile } from "utils/platform";

import { CustomNotification } from "./CustomNotification";

const offlineStatusDelay = 4000;

const UnMemoizedConnectionStatus = <T extends GlueDefaultStreamChatGenerics>() => {
  const { appStatus } = useAppStateStore.getState();
  const { client } = useChatContext<T>("ConnectionStatus");

  const [online, setOnline] = useState(true);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    const isHealthy = () =>
      (client.wsConnection?.isHealthy || client.wsFallback?.isHealthy() || false) &&
      client._hasConnectionID();

    const onConnectionChanged = ({ online = isHealthy() }: Pick<Event<T>, "online"> = {}) => {
      if (online) return setOnline(true);

      // don't show offline status when native mobile app is in background
      if (isNativeMobile() && appStatus === "inactive") return;

      clearTimeout(timeout);
      timeout = setTimeout(() => setOnline(isHealthy()), offlineStatusDelay);
    };

    onConnectionChanged();

    client.on("connection.changed", onConnectionChanged);
    return () => {
      client.off("connection.changed", onConnectionChanged);
      clearTimeout(timeout);
    };
  }, [appStatus, client]);

  return (
    <CustomNotification active={!online} type="error">
      Connection failure, reconnecting now...
    </CustomNotification>
  );
};

export const ConnectionStatus = memo(UnMemoizedConnectionStatus);
