import { FieldFunctionOptions, InMemoryCache, Reference } from "@apollo/client";
import { ReadFieldFunction } from "@apollo/client/cache/core/types/common";
import { differenceInWeeks } from "date-fns";
import { intersection, orderBy, uniqBy } from "lodash-es";

import {
  GroupEdgeSimple,
  GroupOrPreviewEdgeSimple,
  GroupPreview,
  GroupSimple,
  ThreadEdgeSimple,
  UserEdge,
  WorkspaceEdgeSimple,
} from "@utility-types";
import {
  Connection,
  MemberRole,
  QueryLocalGroupsArgs,
  QueryLocalThreadsArgs,
  QueryLocalUsersArgs,
  ThreadRecipientConnection,
  ThreadSubscription,
  ThreadsMailbox,
  UserEdgeStatus,
} from "generated/graphql";
import { formatGroupName } from "utils/group/formatGroupName";
import { parseEdgeID } from "utils/parseEdgeID";

import { sortedMatches } from "./utils/sortedMatches";

type Node = { __typename: string };

const nodes = <T extends Node, U = T["__typename"]>(
  type: U,
  cache: InMemoryCache
): (T & { scoreBoost?: number })[] => {
  const results: T[] = [];
  const cacheObject = cache.extract();
  for (const key in cacheObject) {
    if (key.startsWith(`${type}:`)) {
      results.push(cacheObject[key] as T);
    }
  }
  return results;
};

const readMembers = (readField: ReadFieldFunction, ref: Reference) =>
  readField<Connection>("members", ref)?.edges.map(m => readField<string>("id", m.node)) ?? [];

const readThreadMemberIDs = (
  readField: ReadFieldFunction,
  threadID: string
): Set<string | undefined> => {
  const recipients = readField<ThreadRecipientConnection>("recipients", {
    __ref: `Thread:${threadID}`,
  });

  if (!recipients) return new Set();

  const recipientIDs = recipients.edges
    .map(r => readField<string>("id", r.node))
    .filter((id): id is string => !!id);

  const membersIDs: Set<string | undefined> = new Set(recipientIDs);

  const groupIDs = recipientIDs.filter(id => id.startsWith("grp_"));
  for (const groupID of groupIDs) {
    const ids = readMembers(readField, { __ref: `Group:${groupID}` });
    for (const id of ids) {
      membersIDs.add(id);
    }
  }

  const workspaceIDs = recipientIDs.filter(id => id.startsWith("wks_"));
  for (const workspaceID of workspaceIDs) {
    const ids = readMembers(readField, { __ref: `Workspace:${workspaceID}` });
    for (const id of ids) {
      membersIDs.add(id);
    }
  }

  return membersIDs;
};

const scoreBoostRecipients = <T extends GroupOrPreviewEdgeSimple | ThreadEdgeSimple | UserEdge>(
  readField: ReadFieldFunction,
  results: T[],
  threadID?: string,
  workspaceIDs?: string[]
) => {
  const membersIDs = threadID ? readThreadMemberIDs(readField, threadID) : undefined;

  return results.map(r => {
    const resultID = readField<string>("id", r.node);
    if (!resultID) return r;

    let scoreBoost = membersIDs?.has(resultID) ? 2 : 0;
    if (r.__typename === "UserEdge") {
      const user = r.node;
      const userWorkspaces = readField<string[]>("workspaceIDs", user) ?? [];
      scoreBoost += readField("avatarURL", user) ? 0.5 : 0;
      scoreBoost += intersection(userWorkspaces, workspaceIDs).length ? 1 : 0;
      scoreBoost += r.status === UserEdgeStatus.Connected ? 1 : 0;
    }

    if (r.__typename === "UserEdge" || r.__typename === "GroupOrPreviewEdge") {
      const interactedAt = readField<string>("lastInteractionAt", r);
      if (interactedAt) {
        const weeksAgo = differenceInWeeks(new Date(), new Date(interactedAt));
        scoreBoost += Math.max(0, Math.min(2.0, 2.0 - weeksAgo)) / 2.0;
      }
    }

    return { ...r, scoreBoost };
  });
};

const removeExclusions = <T extends GroupOrPreviewEdgeSimple | ThreadEdgeSimple | UserEdge>({
  exclude,
  readField,
  results,
}: {
  exclude?: string[] | null;
  readField: ReadFieldFunction;
  results: T[];
}) => {
  if (!exclude) return results;
  const excludeSet = new Set(exclude);
  return results.filter(r => !excludeSet.has(readField<string>("id", r.node) ?? ""));
};

export const searchGroups = ({
  args,
  cache,
  canRead,
  readField,
}: FieldFunctionOptions<QueryLocalGroupsArgs>): GroupOrPreviewEdgeSimple[] => {
  const groupEdges = nodes<GroupEdgeSimple>("GroupEdge", cache);
  const groups = nodes<GroupSimple>("Group", cache);
  const groupPreviews = nodes<GroupPreview>("GroupPreview", cache);

  const userID = parseEdgeID(groupEdges[0]?.id)?.userID;

  let results: ReturnType<typeof nodes<GroupOrPreviewEdgeSimple>> = uniqBy(
    [...groups, ...groupPreviews],
    "id"
  ).map(r => ({
    __typename: "GroupOrPreviewEdge" as const,
    id: `${r.id}-${userID}`, // must use correct edge id to avoid duplicates
    node: r,
  }));

  // remove dangling references from evicted objects
  // https://www.apollographql.com/docs/react/caching/garbage-collection/#dangling-references
  results = results.filter(edge => canRead(edge.node));
  results = orderBy(results, "cursor", "desc");
  results = removeExclusions({
    exclude: args?.filter?.excludeIDs,
    readField,
    results,
  });

  results = results.filter(r => !!readField("archivedAt", r.node) === !!args?.filter?.archived);
  if (args?.filter?.mutualWorkspaceIDs) {
    const workspaceIDs = new Set(args?.filter?.mutualWorkspaceIDs ?? []);
    results = results.filter(
      r =>
        readField("__typename", r.node) === "Group" ||
        workspaceIDs.has(readField<string>("workspaceID", r.node) ?? "")
    );
  }
  results = scoreBoostRecipients(readField, results, args?.filter?.threadID ?? undefined);

  if (args?.filter?.match) {
    const { match } = args.filter;
    results = sortedMatches(results, match, e => readField<string>("name", e.node));
  } else {
    results = results.sort((x, y) => (y.scoreBoost || 0) - (x.scoreBoost || 0));
  }

  return results.filter(edge => canRead(edge.node)).slice(0, args?.first || results.length);
};

export const searchThreads = ({
  args,
  cache,
  canRead,
  readField,
}: FieldFunctionOptions<QueryLocalThreadsArgs>): ThreadEdgeSimple[] => {
  let results = nodes<ThreadEdgeSimple>("ThreadEdge", cache);
  results = results.filter(edge => canRead(edge.node));
  results = results.filter(r => readField("cursor", r)); // skip partial (since fixed in 95cf590e)
  results = results.filter(r => !readField("isPersistentChat", r.node)); // skip chats
  results = orderBy(results, "cursor", "desc");

  if (args?.filter?.match) {
    const { match } = args.filter;
    results = sortedMatches(results, match, e => readField<string>("subject", e.node));
  }

  switch (args?.filter?.mailbox) {
    case ThreadsMailbox.Inbox:
      results = results.filter(
        r =>
          readField<ThreadSubscription>("subscription", r) === ThreadSubscription.Inbox &&
          readField<boolean>("isArchived", r) === false
      );
      break;
    case ThreadsMailbox.Archived:
      results = results.filter(
        r =>
          readField<ThreadSubscription>("subscription", r) === ThreadSubscription.Inbox &&
          readField<boolean>("isArchived", r) === true
      );
      break;
    case ThreadsMailbox.Sent:
      results = results.filter(r => readField<MemberRole>("recipientRole", r) === "admin");
      break;
  }

  results = removeExclusions({
    exclude: args?.filter?.excludeIDs,
    readField,
    results,
  });

  return results.slice(0, args?.last || results.length);
};

export const searchWorkspaces = ({
  args,
  cache,
  canRead,
  readField,
}: FieldFunctionOptions<QueryLocalGroupsArgs>): WorkspaceEdgeSimple[] => {
  let results = nodes<WorkspaceEdgeSimple>("WorkspaceEdge", cache);
  results = results.filter(edge => canRead(edge.node));
  results = results.filter(r => readField("cursor", r)); // skip partial (since fixed)
  results = orderBy(results, "cursor", "desc");
  if (args?.filter?.match) {
    const { match } = args.filter;
    const isAll = "all ".startsWith(match.split(" ")[0]?.toLowerCase() ?? "");
    results = sortedMatches(results, match, e => {
      const id = readField<string>("id", e.node);
      const name = readField<string>("name", e.node);
      const formatted = formatGroupName({ id, name })?.name;
      return isAll ? `All ${formatted}` : formatted;
    });
  }

  return results.slice(0, args?.first || results.length);
};

export const searchUsers = ({
  args,
  cache,
  canRead,
  readField,
}: FieldFunctionOptions<QueryLocalUsersArgs>): UserEdge[] => {
  let results = nodes<UserEdge>("UserEdge", cache);
  results = results.filter(edge => canRead(edge.node));
  results = results.filter(edge => !readField<string>("name", edge.node)?.endsWith(" (App)"));
  const edgeStatus = new Set(
    args?.filter?.edgeStatus ?? [
      UserEdgeStatus.Connected,
      UserEdgeStatus.None,
      UserEdgeStatus.Starred,
    ]
  );
  results = results.filter(u => edgeStatus.has(u.status));
  results = removeExclusions({
    exclude: args?.filter?.excludeIDs,
    readField,
    results,
  });
  results = scoreBoostRecipients(
    readField,
    results,
    args?.filter?.threadID ?? undefined,
    args?.filter?.mutualWorkspaceIDs ?? undefined
  );
  if (args?.filter?.match) {
    const { match } = args.filter;
    results = sortedMatches(results, match, e => readField<string>("name", e.node));
  } else {
    results = results.sort((x, y) => (y.scoreBoost || 0) - (x.scoreBoost || 0));
  }

  return results.slice(0, args?.first || results.length);
};
