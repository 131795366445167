import { ApolloClient, NormalizedCacheObject, isApolloError } from "@apollo/client";
import { captureException } from "@sentry/react";
import { useCallback, useEffect, useRef, useState } from "react";

import {
  AuthConfigDocument,
  AuthConfigQuery,
  AuthConfigQueryVariables,
  useAuthConfigQuery,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useOnce from "hooks/useOnce";
import usePreviousRef from "hooks/usePreviousRef";
import parseJWT from "utils/parseJWT";
import env from "utils/processEnv";

import isServerError from "../utils/isServerError";

export type AuthData = AuthConfigQuery;

export type FetchAuthData = (options?: {
  cached?: boolean;
  refresh?: boolean;
}) => Promise<AuthData>;

export const RefreshIntervalSeconds = 3600;

export const useFetchAuthData = (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  signOut: () => void
): {
  authData?: AuthData;
  clearAuthData: () => Promise<void>;
  fetchAuthData: FetchAuthData;
} => {
  const { authReady } = useAuthData();
  const [authData, setAuthData] = useState<AuthData>();
  const authDataRef = usePreviousRef(authData);

  const { data: cachedAuthData } = useAuthConfigQuery({
    client: apolloClient,
    fetchPolicy: "cache-only",
  });

  const lastRefreshed = useRef<Date>(new Date(0));

  const needsRefresh = useCallback(
    () => (Date.now() - lastRefreshed.current.getTime()) / 1000 > RefreshIntervalSeconds,
    [lastRefreshed]
  );

  const clearAuthData = useCallback(
    () =>
      new Promise<void>(resolve => {
        if (authDataRef.current) {
          setAuthData(undefined);
          lastRefreshed.current = new Date(0);
        }
        resolve();
      }),
    [authDataRef]
  );

  const fetchAuthData = useCallback(
    (options?: { cached?: boolean; refresh?: boolean }) => {
      const networkOnly = options?.refresh || (!options?.cached && needsRefresh());

      return apolloClient
        .query<AuthConfigQuery, AuthConfigQueryVariables>({
          context: { batch: networkOnly === false },
          fetchPolicy: networkOnly ? "network-only" : "cache-first",
          query: AuthConfigDocument,
        })
        .then(({ data }) => {
          setAuthData(data);
          if (networkOnly) {
            lastRefreshed.current = new Date();
          } else {
            const jwt = parseJWT(data.config.streamToken);

            lastRefreshed.current = jwt ? new Date(jwt.iat * 1000) : new Date();
          }
          return data;
        })
        .catch(err => {
          // Consider a 401 API response as revoked authorization
          captureException(err, {
            level: "warning",
            extra: { customMessage: "[useFetchAutData] Error fetching auth data" },
          });
          if (
            isApolloError(err) &&
            isServerError(err.networkError) &&
            err.networkError.statusCode === 401 && // HTTP response
            err.networkError.result.reason === "invalid" // API response (ignore proxy responses)
          ) {
            captureException(err, {
              level: "warning",
              extra: { customMessage: "[useFetchAutData] Auth token revoked" },
            });
            signOut();
          }
          throw err;
        });
    },
    [apolloClient, needsRefresh, signOut]
  );

  useEffect(() => {
    if (cachedAuthData) {
      setAuthData(cachedAuthData);
    }
  }, [cachedAuthData]);

  useOnce(() => {
    if (!authReady) return;

    fetchAuthData({ cached: true }).catch((error: Error) => {
      if (env.glueEnv === "development") {
        console.warn("Auth data not cached:", error.message);
      }
    });
  });

  return { authData, clearAuthData, fetchAuthData };
};
