import { Main } from "components/ModalKit";
import { useModalState } from "components/ModalKit/ModalProvider";
import { StandardModal } from "components/Modals";
import Payment from "components/Payment/Payment";
import { SubscriptionPlan } from "generated/graphql";

export type Props = {
  onComplete?: () => void;
  planID?: SubscriptionPlan["id"];
  refetchSubscription?: () => void;
  workspaceID?: string;
};

const PaymentModal = ({ planID, refetchSubscription, workspaceID }: Props) => {
  const { state } = useModalState(({ state }) => ({
    state,
  }));

  return (
    // afterClose, fire `refetchSubscription` again for redundancy; stripe.initEmbeddedCheckout onComplete has been flaky
    <StandardModal afterClose={refetchSubscription}>
      <Main className="py-12">
        <Payment
          workspaceID={workspaceID}
          planID={planID}
          onComplete={refetchSubscription}
          destroying={state === "closing"}
        />
      </Main>
    </StandardModal>
  );
};

export default PaymentModal;
