import { first } from "lodash-es";
import { useContext } from "react";
import { useHistory } from "react-router-dom";

import { FadeInOut } from "components/Animated";
import { Button } from "components/design-system/Button";
import { HistoryState } from "components/Navigation/HistoryState";
import { routeToThread } from "components/routing/utils";
import { StreamClientContext } from "providers/StreamClientProvider";
import { animateScrollTo } from "utils/animate";

import { useThreadViewState } from "../../provider/ThreadViewProvider";

type Props = {
  lastRead?: Date;
  messageList: HTMLDivElement | null;
  show: boolean;
  unreadMark: HTMLLIElement | null;
};
const ThreadGoToUnread = ({ lastRead, messageList, show, unreadMark }: Props) => {
  const {
    setState: threadViewUpdater,
    threadID,
    threadPane,
  } = useThreadViewState(({ threadID, threadPane }) => ({
    threadID,
    threadPane,
  }));

  const { newStreamChannel } = useContext(StreamClientContext);

  const history = useHistory<HistoryState>();

  const goToUnreadMark = () => {
    if (!lastRead || !messageList || !threadID) return;
    if (unreadMark) {
      threadViewUpdater({ pauseLoadMore: false });
      animateScrollTo(messageList, unreadMark.offsetTop, () => {
        threadViewUpdater({ pauseLoadMore: true });
      });
      return;
    }

    newStreamChannel({
      messagesQueryParam: {
        created_at_before_or_equal: lastRead,
        limit: 1,
      },
      threadID,
    }).then(lastReadMessage => {
      const lastReadMessageID = first(lastReadMessage.state.messages)?.id;

      history.push(
        routeToThread({
          messageID: lastReadMessageID,
          threadID,
          forceTo: threadPane,
        }),
        { historyLastRead: lastRead }
      );
    });
  };

  return (
    <FadeInOut className="z-2" show={show}>
      <Button
        buttonStyle="none"
        buttonType="none"
        className="relative top-15 mx-auto -mb-40"
        icon="ArrowUpCircle"
        iconClassName="bg-background rounded-full text-interactive-primary"
        iconSize={40}
        iconStroke={2}
        onClick={goToUnreadMark}
      />
    </FadeInOut>
  );
};

export default ThreadGoToUnread;
