import Hr from "components/design-system/Hr";
import { ModalElement } from "components/ModalKit/types";

import NotificationPermission from "./NotificationPermission";
import SimulateAppUpdateAvailable from "./SimulateAppUpdateAvailable";
import SimulateError from "./SimulateError";
import SimulateOnboarding from "./SimulateOnboarding";

const Sandbox = ({ modalId }: { modalId?: ModalElement["id"] }) => {
  return (
    <div className="grow min-h-0 overflow-y-auto py-24">
      <div className="px-20 md:px-32">
        <div className="flex flex-col gap-24 mb-24 text-body-bold">
          <NotificationPermission />
        </div>
      </div>

      <Hr />

      <div className="mt-32 px-20 md:px-32">
        <div className="flex flex-col gap-24 text-body-bold">
          <SimulateAppUpdateAvailable />
          <SimulateError />
          <SimulateOnboarding modalID={modalId} />
        </div>
      </div>
    </div>
  );
};

export default Sandbox;
