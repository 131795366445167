import { uniqBy } from "lodash-es";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo } from "react";
import { SubmitHandler, useFormContext } from "react-hook-form";

import { Recipient, WorkspaceEdge } from "@utility-types";
import EditableAvatar from "components/Avatar/EditableAvatar";
import { Form, Label, TextInput, Toggle, useDisableSubmit } from "components/design-system/Forms";
import { RecipientsSelect } from "components/design-system/Forms/RecipientsSelect";
import Hr from "components/design-system/Hr";
import { ModalProps } from "components/ModalKit/Modal";
import { apiURL } from "components/routing/utils";
import { useLlmProviders } from "components/SettingsModal/views/WorkspaceSettings/hooks/useLlmProviders";
import { ProviderApiKeyInput } from "components/SettingsModal/views/WorkspaceSettings/ProviderApiKeyInput";
import GroupChatPermissions from "components/workspace-group/GroupChatPermissions";
import { MemberRole as MemberRoleValue, MessageableBy } from "generated/graphql";
import useAuthData from "hooks/useAuthData";

type MemberRole = { member: string; role: MemberRoleValue };

const memberRoles = (recipients: Recipient[]): MemberRole[] =>
  uniqBy(
    recipients.map(r => ({
      member: r.id,
      role: MemberRoleValue.Default,
    })),
    "member"
  );

export type WorkspaceInput = {
  avatarURL: string | null;
  domains: string[];
  members: MemberRole[];
  name: string;
  chatMessageableBy: MessageableBy;
  messageableBy: MessageableBy;
};

type FormData = {
  avatarURL?: string | null;
  joinable: boolean;
  members: Recipient[];
  name: string;
  chatMessageableBy: MessageableBy;
};

type Props = {
  editMode?: boolean;
  loading?: boolean;
  onSave: (id: string, input: WorkspaceInput) => Promise<unknown>;
  title?: string;
  workspaceEdge?: WorkspaceEdge;
};

export type FormRef = {
  submitDisabled: boolean;
  submitForm: () => void;
};

type FieldsProps = Partial<Props> & {
  domainAvatarURL: string | null;
  onSubmit: SubmitHandler<FormData>;
};

const FormContent = forwardRef<FormRef, FieldsProps>(
  ({ domainAvatarURL, editMode, onSubmit, workspaceEdge }: FieldsProps, ref) => {
    const { handleSubmit, setValue, watch } = useFormContext<FormData>();
    const { authData } = useAuthData();
    const authDomain = authData?.me.addressDomains[0];

    const workspace = workspaceEdge?.node;

    const availableAIProviders = useLlmProviders(workspace?.id);

    const { avatarURL, joinable, name } = watch();

    useEffect(() => {
      let nextAvatarURL = avatarURL === domainAvatarURL ? null : avatarURL;
      nextAvatarURL ??= joinable ? domainAvatarURL : null;
      setValue("avatarURL", nextAvatarURL);
    }, [avatarURL, domainAvatarURL, joinable, setValue]);

    const submitForm = useCallback(() => {
      handleSubmit(onSubmit)();
    }, [handleSubmit, onSubmit]);

    const disabled = useDisableSubmit();

    // We need to expose these in order to use them in external CTAs
    useImperativeHandle(
      ref,
      () => ({
        submitDisabled: disabled,
        submitForm,
      }),
      [submitForm, disabled]
    );

    return (
      <div className="flex flex-col h-full py-16">
        <span className="text-body-bold">Workspace settings</span>
        <span className="text-text-subtle">
          Workspaces are shared environments for teams, consisting of users and groups.
        </span>

        <div className="flex justify-start items-center gap-16 mt-20">
          <EditableAvatar
            avatarURL={avatarURL}
            name={name}
            onAvatarUpdate={avatarUrl => setValue("avatarURL", avatarUrl, { shouldDirty: true })}
            rounded="rounded-lg"
            size="x-large"
          />
          <div className="grow">
            <Label className="text-body-bold" htmlFor="name">
              Name
            </Label>
            <TextInput<FormData>
              autoComplete="off"
              config={{ required: true }}
              data-testid="workspace-name"
              name="name"
              placeholder="Company name"
              wrapperClassName="!my-0"
            />
          </div>
        </div>

        {!editMode && (
          <RecipientsSelect
            label="Members"
            name="members"
            placeholder="Users or emails..."
            selectGroups={false}
            wrapperClassName="!mb-0 mt-32"
          />
        )}

        {authDomain && (
          <div className="mt-32">
            <span className="text-body-bold">Domain Access</span>
            <Toggle
              label={
                <span>
                  Allow anyone with <strong>{authDomain}</strong> email address to join?
                </span>
              }
              labelClassName="justify-between w-full"
              name="joinable"
              wrapperClassName="!my-0"
            />
          </div>
        )}

        <div className="mt-32">
          <GroupChatPermissions isWorkspace />
        </div>

        {workspace?.id && (
          <div className="mt-32">
            <Hr />
            <div className="flex flex-col gap-12 mt-20">
              <div>
                <h2 className="m-0 text-base font-bold">Glue AI</h2>
                <div className="text-body text-text-subtle">Add API keys to lift usage limits:</div>
              </div>

              {availableAIProviders?.map(({ id, provider, name, iconSrc, keyPreview }) => (
                <ProviderApiKeyInput
                  key={provider}
                  id={id}
                  keyPreview={keyPreview}
                  provider={provider}
                  iconSrc={iconSrc}
                  name={name}
                  workspaceID={workspace?.id}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
);

export const WorkspaceModalForm = forwardRef<FormRef, Props & ModalProps>(
  ({ loading, onSave, workspaceEdge, ...props }: Props & ModalProps, ref): JSX.Element => {
    const { authData } = useAuthData();
    const authDomain = authData?.me.addressDomains[0];

    const workspace = workspaceEdge?.node;

    const defaultDomains = useMemo(
      () => workspace?.domains || (authDomain ? [authDomain] : []),
      [authDomain, workspace?.domains]
    );

    const domainAvatarURL = useMemo(() => {
      const domain = defaultDomains[0];
      if (domain) {
        return apiURL(`/proxy/${encodeURIComponent(`https://logo.clearbit.com/${domain}`)}/image`);
      }

      return null;
    }, [defaultDomains]);

    const onSubmit = useCallback(
      (data: FormData) =>
        onSave(workspace?.id || "", {
          ...data,
          avatarURL: data.avatarURL || null,
          domains: data.joinable ? (authDomain ? [authDomain] : []) : [],
          members: memberRoles(data.members),
          messageableBy:
            workspace?.messageableBy === MessageableBy.Anyone
              ? MessageableBy.Anyone
              : data.chatMessageableBy === MessageableBy.Recipient
                ? MessageableBy.Workspace
                : data.chatMessageableBy,
        }),
      [authDomain, onSave, workspace?.id, workspace?.messageableBy]
    );

    const isJoinable = useMemo(
      () => !!(authDomain && defaultDomains.includes(authDomain)),
      [authDomain, defaultDomains]
    );

    return (
      <Form
        className="h-full"
        loading={loading}
        onSubmit={onSubmit}
        useFormProps={{
          defaultValues: {
            avatarURL: workspace?.avatarURL,
            joinable: isJoinable,
            members: workspace?.members.edges.map(e => e.node) || (authData ? [authData.me] : []),
            chatMessageableBy: workspace?.chatMessageableBy ?? MessageableBy.Recipient,
            name: workspace?.name || "",
          },
        }}
      >
        <FormContent
          ref={ref}
          domainAvatarURL={domainAvatarURL}
          onSubmit={onSubmit}
          workspaceEdge={workspaceEdge}
          {...props}
        />
      </Form>
    );
  }
);

export default WorkspaceModalForm;
