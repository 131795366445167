import { MouseEvent, useCallback, useMemo, useRef } from "react";

import {
  Message,
  ThreadEdge,
  ThreadEdgeSimple,
  ThreadPreview,
  ThreadPreviewEdge,
  ThreadSimple,
  nodeIs,
} from "@utility-types";
import BackStackButton from "components/App/AppLayoutMobile/BackStackButton";
import PaneHeader from "components/design-system/ui/PaneHeader";
import useThreadAction from "components/MessageEditor/components/controls/ThreadActions/hooks/useThreadAction";
import { shouldSummarizeThread } from "components/Summaries/Util";
import ThreadInfoModal from "components/thread/ThreadView/components/ThreadInfoModal";
import useElementBreakpoint from "hooks/useElementBreakpoint";
import useMemberEdge from "hooks/useMemberEdge";
import useModalStore from "store/useModalStore";

import ThreadActionMenu from "../ThreadActionMenu";

import FollowButton from "./FollowButton";
import PinnedButton from "./PinnedButton";
import ThreadInfo from "./ThreadInfo";
import ThreadMembersCount from "./ThreadMembersCount";
import ThreadReminderBanner from "./ThreadReminderBanner";

const desktopWidth = 480;

type Props = {
  headerType?: "simple" | "full";
  onClose?: () => void;
  replyTo:
    | {
        message: Message;
        thread: ThreadSimple | ThreadPreview;
      }
    | undefined;
  threadEdge: ThreadEdgeSimple | ThreadPreviewEdge | undefined; // pre-cached
  threadEdgeFull: ThreadEdge | ThreadPreviewEdge | undefined; // refetched
};

const ThreadHeader = ({
  headerType = "full",
  onClose,
  replyTo,
  threadEdge,
  threadEdgeFull,
}: Props) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const desktopBreakpoint = useElementBreakpoint(headerRef, desktopWidth);

  const threadID = threadEdge?.node.id;
  const { memberEdge, previewEdge } = useMemberEdge(threadEdge);
  const { memberEdge: memberEdgeFull } = useMemberEdge(threadEdgeFull);
  const threadFull = memberEdgeFull?.node;

  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const recipients = useMemo(
    () => threadEdge?.node?.recipients.edges.map(e => e.node),
    [threadEdge?.node?.recipients.edges]
  );

  const onClickModal = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      if (!threadID) return;
      openModal(<ThreadInfoModal threadID={threadID} />);
    },
    [openModal, threadID]
  );

  // summarization

  const {
    available: canSummarize,
    loading: summarizing,
    performThreadAction: performSummarizeThreadAction,
  } = useThreadAction({ threadID, name: "summarize_thread" });

  const summarize = async () => {
    if (!canSummarize) {
      return;
    }

    await performSummarizeThreadAction?.();
  };

  const showSummarize = !!canSummarize && threadFull && shouldSummarizeThread(threadFull);

  return (
    <PaneHeader
      ref={headerRef}
      onClose={onClose}
      trailingContent={
        nodeIs(threadEdge, ["ThreadEdge"]) && <ThreadReminderBanner threadEdge={threadEdge} />
      }
    >
      <div className="flex flex-col grow min-w-0">
        <div className="flex items-start min-h-[52px]">
          <div className="inline-flex grow min-w-0">
            <BackStackButton />
            <ThreadInfo
              headerType={headerType}
              onClick={memberEdge ? onClickModal : undefined}
              recipients={recipients}
              replyTo={replyTo}
              subject={threadEdge?.node.subject}
              isPreviewOnly={!memberEdge}
            />
          </div>
          <div className="flex grow-0 shrink-0 justify-end py-2 gap-8">
            {!previewEdge && desktopBreakpoint && headerType === "full" ? (
              <ThreadMembersCount thread={threadFull} onClick={onClickModal} />
            ) : null}

            {!previewEdge && threadID && <PinnedButton threadID={threadID} />}

            {!previewEdge && desktopBreakpoint && <FollowButton threadEdge={memberEdge} />}

            <ThreadActionMenu
              additionalActions={
                showSummarize
                  ? [
                      {
                        icon: "SparkleFilled" as const,
                        text: "Summarize thread",
                        onClick: summarize,
                      },
                    ]
                  : []
              }
              threadEdge={threadEdge}
              threadContainerBreakpointMD={!!desktopBreakpoint}
              loading={!desktopBreakpoint && summarizing}
            />
          </div>
        </div>
      </div>
    </PaneHeader>
  );
};

export default ThreadHeader;
