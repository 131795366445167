import { default as LLMGlueLogo } from "assets/llm-logo-glue.svg?react";
import SelectDropdown from "components/design-system/Forms/SelectDropdown";
import { useFetchLlmModelsQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import glueImageURL from "utils/glueImageURL";

import { Field, FieldSet } from "./FieldSet";

const GlobalAIModelPref = ({ workspaceID }: { workspaceID: string }) => {
  const { authReady } = useAuthData();
  const { data } = useFetchLlmModelsQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    variables: {
      workspaceID,
    },
  });

  const options = data?.llmModels.edges.map(({ node }) => {
    return {
      iconSrc: glueImageURL(node.providerLogoURL, { fit: "max", w: 40, h: 40 }),
      label: node.name,
      description: node.description,
      value: node.id,
    };
  });

  return (
    <FieldSet className="px-20 md:px-32 pb-20" label="Glue AI model">
      <Field label="Preferred model:">
        <SelectDropdown
          name="chatModel"
          options={[
            {
              icon: LLMGlueLogo,
              label: "Auto",
              description: "Glue AI will select the best model",
              value: "auto",
            },
            ...(options ?? []),
          ]}
        />
      </Field>
    </FieldSet>
  );
};

export default GlobalAIModelPref;
