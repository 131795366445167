import { InputButton } from "components/design-system/Forms";
import { Icon } from "components/design-system/icons";
import { ConfirmationAlert } from "components/Modals";
import {
  LambdaFieldsFragment,
  LambdasDocument,
  useDeleteLambdaMutation,
  useEnableLambdaMutation,
} from "generated/graphql";
import useModalStore from "store/useModalStore";

import { LambdaFullScreenModal } from "./LambdaFullScreenModal";
import ManageSecretsModal from "./ManageSecretsModal";

type Props = {
  lambdas?: LambdaFieldsFragment[];
};

const LambdaList = ({ lambdas }: Props) => {
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const [deleteLambda] = useDeleteLambdaMutation();
  const [enableLambda] = useEnableLambdaMutation();

  if (!lambdas) {
    return null;
  }

  const handleDelete = (lambda: LambdaFieldsFragment) => {
    openModal(
      <ConfirmationAlert
        confirmLabel="Delete"
        header={`Delete the "${lambda.name}" lambda?`}
        onConfirm={() =>
          deleteLambda({
            refetchQueries: [LambdasDocument],
            variables: {
              id: lambda.id,
            },
          })
        }
        isDestructive
      />
    );
  };

  const handleEdit = (lambda: LambdaFieldsFragment) => {
    openModal(<LambdaFullScreenModal lambda={lambda} />);
  };

  const handleEditSecrets = (lambda: LambdaFieldsFragment) => {
    openModal(<ManageSecretsModal lambdaId={lambda.id} />);
  };

  const handleEnable = async (lambda: LambdaFieldsFragment) => {
    await enableLambda({
      refetchQueries: [LambdasDocument],
      variables: {
        enabled: !lambda.enabled,
        id: lambda.id,
      },
    });
  };

  return lambdas.map(lambda => {
    return (
      <div
        key={lambda.id}
        style={{
          alignItems: "center",
          border: "1px solid #555869",
          borderRadius: "4px",
          display: "flex",
          justifyContent: "space-between",
          margin: "8px",
          padding: "8px",
        }}
      >
        <div style={{ alignItems: "center", display: "flex" }}>
          <span onClick={() => handleEnable(lambda)} style={{ cursor: "pointer" }}>
            {lambda.enabled ? (
              <Icon className="mr-5 text-accent-primary" icon="ThreadSelected" size={18} />
            ) : (
              <Icon className="mr-5" icon="Checkbox" size={18} />
            )}
          </span>

          {lambda.name}
        </div>

        <div style={{ alignItems: "center", display: "flex" }}>
          <InputButton
            buttonStyle="none"
            buttonType="text"
            icon="BulletedList"
            onClick={() => {
              handleEditSecrets(lambda);
            }}
            tooltip="Manage Secrets"
          />
          &nbsp;
          <InputButton
            buttonStyle="none"
            buttonType="text"
            icon="Compose"
            onClick={() => {
              handleEdit(lambda);
            }}
            tooltip="Edit Lambda"
          />
          &nbsp;
          <InputButton
            buttonStyle="simpleDestructive"
            buttonType="text"
            icon="Trash"
            onClick={() => {
              handleDelete(lambda);
            }}
            tooltip="Delete Lambda"
          />
        </div>
      </div>
    );
  });
};

export default LambdaList;
