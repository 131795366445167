import { ComponentProps, FormEvent, KeyboardEvent, useCallback, useRef, useState } from "react";

import { isGlueFile, StreamGlueMessage, nodeAs } from "@utility-types";
import analytics from "analytics";
import { FeedbackProvided } from "analytics/events/feedback";
import { Button } from "components/design-system/Button";
import { Tooltip } from "components/design-system/FloatingUi";
import { Checkbox, Form, SubmitButton, TextArea } from "components/design-system/Forms";
import Icon from "components/design-system/icons/Icon";
import { FileUploader } from "components/FileUploader";
import { fileToFileUpload } from "components/MessageEditor/stream-helpers";
import { useModalState } from "components/ModalKit/ModalProvider";
import { Footer, Main } from "components/ModalKit/Parts";
import { StandardModal } from "components/Modals";
import { useFetchMessageQuery, useLeaveFeedbackMutation } from "generated/graphql-operations";
import { AiFeedbackReason, FeedbackType, Platform } from "generated/graphql-types";
import { useSnackbar } from "providers/SnackbarProvider";
import useModalStore from "store/useModalStore";
import { getPlatform } from "utils/platform";

import { MessageRow } from "./MessageRow";
import { FeedbackType as AnalyticsFeedbackType } from "./types";

const CheckboxItem = ({ name, label, ...props }: ComponentProps<typeof Checkbox>) => {
  return (
    <Checkbox
      {...props}
      name={name}
      label={label}
      labelClassName="text-subhead"
      wrapperClassName="!my-0"
    />
  );
};

const SnackbarContent = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  return (
    <div className="flex justify-between items-baseline min-w-[500px]">
      <span>Thank you for your feedback!</span>
      <button className="text-body-bold" onClick={onClose}>
        Okay
      </button>
    </div>
  );
};

const feedbackReasons: { name: AiFeedbackReason; label: string }[] = [
  {
    name: AiFeedbackReason.Relevance,
    label: "Inaccurate, off-topic, or irrelevant",
  },
  { name: AiFeedbackReason.Depth, label: "Incomplete or lacking depth" },
  { name: AiFeedbackReason.Clarity, label: "Unclear or hard to understand" },
  { name: AiFeedbackReason.Style, label: "Unfit tone, style, or formatting" },
  {
    name: AiFeedbackReason.Problematic,
    label: "Biased, offensive, or problematic",
  },
  { name: AiFeedbackReason.Other, label: "Other" },
];

type FormData = {
  includeThread: boolean;
  reason: { [key in AiFeedbackReason]: boolean };
  moreInfo: string;
};

type Props = {
  promptMessageId: string;
  response: StreamGlueMessage;
  threadID: string;
  threadWorkspaceID?: string;
};

export const GlueAIFeedbackModal = ({
  promptMessageId,
  response,
  threadID,
  threadWorkspaceID,
}: Props) => {
  const { data: promptMessageData } = useFetchMessageQuery({
    variables: { id: promptMessageId },
  });

  const [leaveFeedback] = useLeaveFeedbackMutation();

  const prompt = nodeAs(promptMessageData?.node, ["Message"]);
  const { openSnackbar, closeSnackbar } = useSnackbar();

  const { modalId } = useModalState(({ modalId }) => ({
    modalId,
  }));
  const { closeModal } = useModalStore(({ closeModal }) => ({ closeModal }));

  const handleCloseModal = useCallback(() => {
    closeModal(modalId);
  }, [closeModal, modalId]);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadFiles = useCallback((e: KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === "Enter" || e.key === " ") {
      fileInputRef.current?.click();
    }
  }, []);

  const [uploadsState, setUploadsState] = useState<
    ComponentProps<typeof FileUploader>["orderedUploads"]
  >(new Map());

  const handleAttachFiles = useCallback((event: FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.files === null) return;

    const newFiles = [...event.currentTarget.files];

    setUploadsState(prevState => {
      const temp = new Map();
      [...prevState.values(), ...newFiles.map(fileToFileUpload).filter(isGlueFile)].forEach(
        file => {
          temp.set(file.id, file);
        }
      );

      return temp;
    });
  }, []);

  const handleFileUploaderChange = useCallback(
    (state: ComponentProps<typeof FileUploader>["orderedUploads"]) => {
      if (uploadsState.size === 0) return;

      setUploadsState(state);
    },
    [uploadsState.size]
  );

  const handleSubmit = useCallback(
    async (values: FormData) => {
      const attachmentIDs = [...uploadsState.values()].map(v => v.id);

      const input = {
        feedbackType: FeedbackType.Ai,
        text: values.moreInfo,
        platform: getPlatform() as Platform,
        workspaceID: threadWorkspaceID ?? "",
        attachmentIDs,
        ai: {
          includeThread: values.includeThread,
          responseMessageID: response.id,
          reasons: Object.entries(values.reason)
            .filter(([, val]) => val === true)
            .map(([key]) => key as AiFeedbackReason),
        },
      };

      await leaveFeedback({
        variables: { input },
        onCompleted: () => {
          analytics.track(FeedbackProvided, {
            feedbackType: AnalyticsFeedbackType.GENERATED_RESPONSE,
            score: -1,
            threadId: threadID,
          });

          openSnackbar("info", <SnackbarContent onClose={closeSnackbar} />);
          closeModal(modalId);
        },
      });
    },
    [
      uploadsState,
      threadWorkspaceID,
      response.id,
      leaveFeedback,
      openSnackbar,
      closeSnackbar,
      closeModal,
      modalId,
      threadID,
    ]
  );

  return (
    <Form<FormData>
      onSubmit={handleSubmit}
      useFormProps={{
        defaultValues: {
          includeThread: true,
          reason: {
            [AiFeedbackReason.Relevance]: false,
            [AiFeedbackReason.Depth]: false,
            [AiFeedbackReason.Clarity]: false,
            [AiFeedbackReason.Style]: false,
            [AiFeedbackReason.Problematic]: false,
            [AiFeedbackReason.Other]: false,
          },
          moreInfo: "",
        },
      }}
    >
      <StandardModal
        closeButton={false}
        contentHandlesSafeArea={false}
        header={
          <div className="pl-32 pr-20 pt-4 pb-3 flex items-center justify-between border-border-container border-b-1">
            <h3 className="text-headline-bold">Send feedback</h3>
            <button type="button" onClick={handleCloseModal}>
              <Icon className="text-icon-secondary" icon="Close" size={24} />
            </button>
          </div>
        }
      >
        <Main>
          <div className="px-32 py-16 bg-background-app border-border-container border-b-1">
            <h4 className="my-0 text-body-bold">Prompt & response</h4>

            <div className="rounded-lg border border-border-container bg-background-body px-16 py-8 mt-4 mb-16">
              {prompt && <MessageRow message={prompt} type="prompt" />}
              <MessageRow message={response} type="response" />
            </div>

            <div className="flex items-center justify-between">
              <CheckboxItem name="includeThread" label="Share entire thread" defaultChecked />
              <Tooltip
                content="Including the thread helps us understand your feedback and improve AI responses"
                tooltipStyle="inverted"
                placement="bottom-end"
              >
                <button type="button" className="cursor-pointer text-caption-bold text-text-action">
                  Why do we need this?
                </button>
              </Tooltip>
            </div>
          </div>

          <div className="px-32 py-24">
            <h4 className="my-0 text-body-bold">Reason for feedback</h4>
            <ul className="md:columns-2 pt-8">
              {feedbackReasons.map(({ name, label }) => (
                <li key={name} className="py-6">
                  <CheckboxItem name={`reason.${name}`} label={label} />
                </li>
              ))}
            </ul>

            <TextArea
              wrapperClassName="mt-24"
              name="moreInfo"
              label="More information"
              placeholder="Please provide additional details"
            />

            <div className="flex justify-between items-center">
              <div>
                <h4 className="my-0 text-body-bold">Attachments</h4>
                <p className="my-0 text-subhead text-text-secondary">
                  Attach images, files, or videos
                </p>
              </div>

              <Button
                buttonStyle="action"
                type="button"
                className="!px-0"
                onKeyDown={handleUploadFiles}
              >
                <label
                  className="flex items-center gap-6 cursor-pointer"
                  htmlFor="feedback-file-upload"
                >
                  <Icon className="mr-0" icon="Paperclip" size={20} />
                  <span className="text-subhead-bold">Add attachment</span>
                </label>
                <input
                  className="overflow-hidden absolute -z-1 w-0 h-0 opacity-0"
                  id="feedback-file-upload"
                  multiple={true}
                  tabIndex={-1}
                  type="file"
                  onChange={handleAttachFiles}
                />
              </Button>
            </div>

            <FileUploader onChange={handleFileUploaderChange} orderedUploads={uploadsState} />
          </div>
        </Main>

        <Footer flexboxClassName="justify-end items-baseline" className="!px-20">
          <Button type="button" buttonStyle="simpleSecondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <SubmitButton requireChanges={false}>Send feedback</SubmitButton>
        </Footer>
      </StandardModal>
    </Form>
  );
};
