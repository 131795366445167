import { Workspace } from "@utility-types";
import { useFetchWorkspaceProfileQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";

const useGetWorkspace = (workspaceID?: string): { workspace: Workspace | undefined } => {
  const { authReady } = useAuthData();
  const { data } = useFetchWorkspaceProfileQuery({
    fetchPolicy: authReady ? "cache-first" : "cache-only",
    skip: !workspaceID,
    variables: { id: workspaceID || "" },
  });

  return { workspace: data?.node as Workspace | undefined };
};

export default useGetWorkspace;
