import { useHistory } from "react-router";

import { Illustration } from "components/design-system/illustrations";
import { useEducationWelcomeCard } from "components/Education";
import { routeToView } from "components/routing/utils";
import { DownloadAppBanner } from "components/SideBar/DownloadAppQr";
import { useShowDownloadApp } from "components/SideBar/DownloadAppQr/hooks/useShowDownloadApp";
import Sidebar from "components/SideBar/Sidebar";
import ComposeButton from "components/SideBar/SidebarHeader/ComposeButton";
import SidebarHeader from "components/SideBar/SidebarHeader/SidebarHeader";

import { InboxSidebarSectionsProvider } from "../inbox/providers/InboxSidebarSectionsProvider";

import { AI_SIDEBAR_ID } from "./AIMain";
import AIThreadList from "./AIThreadList";

const AISidebarDesktop = () => {
  const history = useHistory();
  const { showDownloadBanner } = useShowDownloadApp();
  const { showCampaign } = useEducationWelcomeCard("aiSuperTab");

  return (
    <Sidebar id={AI_SIDEBAR_ID}>
      <SidebarHeader
        buttons={<ComposeButton onClick={() => history.push(routeToView({ view: "compose" }))} />}
        title={
          <div className="flex gap-12 items-center">
            <Illustration className="rounded-md" illustration="GlueAIAvatar" />
            Glue AI
          </div>
        }
      />

      <InboxSidebarSectionsProvider>
        <AIThreadList />
      </InboxSidebarSectionsProvider>
      <DownloadAppBanner show={showDownloadBanner && !showCampaign} />
    </Sidebar>
  );
};

export default AISidebarDesktop;
