import { GroupPreviewSimple, ThreadPreview, User, WorkspacePreviewSimple } from "@utility-types";
import Hyperlink from "components/MessageElements/Hyperlink";
import { routeToThread } from "components/routing/utils";
import { FeedType } from "generated/graphql";
import { formatGroupName } from "utils/group/formatGroupName";

import ActivityItemLayout from "./ActivityItemLayout";

type Props = {
  actor: User;
  isRead: boolean;
  object: ThreadPreview;
  onClick: (e: React.MouseEvent<HTMLLIElement>) => void;
  selected: boolean;
  target: GroupPreviewSimple | WorkspacePreviewSimple;
  timestamp: string;
};

const ActivityItemAddedThread = ({ actor, object, target, ...props }: Props): JSX.Element => {
  return (
    <ActivityItemLayout
      avatarProps={{ ...actor }}
      groupType="Thread"
      linkTo={toSecondary =>
        routeToThread({
          threadID: object.id,
          to: toSecondary ? "secondary" : "primary",
          superTab: "activity",
        })
      }
      title={`New thread in ${formatGroupName(target).nameWithEmoji}`}
      type={FeedType.Groups}
      {...props}
    >
      <div>
        <div className="text-text-primary text-subhead-bold">{actor.name}</div>
        <div className="text-text-primary text-body pointer-events-none">
          <span className="mr-4">Started</span>
          <Hyperlink url={`glue:${object.id}`}>{object.name}</Hyperlink>
        </div>
      </div>
    </ActivityItemLayout>
  );
};

export default ActivityItemAddedThread;
