import { memo } from "react";

import { FileUploadState } from "@utility-types";
import { Button } from "components/design-system/Button";
import { Icon } from "components/design-system/icons";
import glueImageURL from "utils/glueImageURL";
import tw from "utils/tw";

import LoadingIndicator from "./LoadingIndicator";

type Props = {
  handleRetry: (id: string) => void;
  id: string;
  loadingState: FileUploadState;
  url: string;
};

const ImageAttachment = memo(
  ({ handleRetry, id, loadingState, url }: Props): JSX.Element => {
    const className = {
      img: tw(
        "border border-border-container hover:border-border-container-hover w-full h-full bg-background-ghost rounded-lg",
        {
          "object-cover": url,
        }
      ),
    };

    return (
      <div
        className="relative h-56 w-56"
        {...(loadingState !== "uploading" ? { "data-testid": "image-uploaded" } : {})}
      >
        {loadingState === "uploading" && <LoadingIndicator />}
        {loadingState === "failed" && (
          <Button
            buttonStyle="none"
            className="absolute justify-center w-full h-full bg-accent-error/50 !rounded-lg"
            data-testid="uploading-image-failed"
            onClick={() => handleRetry(id)}
          >
            <Icon className="text-background" icon="RefreshCCW" />
          </Button>
        )}
        {url ? (
          <img
            alt="Preview"
            className={className.img}
            src={glueImageURL(url, { fit: "max", h: 200, w: 200 })}
          />
        ) : (
          <div className={className.img} />
        )}
      </div>
    );
  },
  (prev, next) => prev.loadingState === next.loadingState && prev.url === next.url
);

export default ImageAttachment;
