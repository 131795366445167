import { ChangeEvent, KeyboardEvent, MouseEvent, useEffect, useRef, useState } from "react";
import { FieldValues, Path, PathValue, useFormContext } from "react-hook-form";

import { Button } from "components/design-system/Button";
import { Icon } from "components/design-system/icons";
import useElementTextWidth from "hooks/useElementTextWidth";
import useOnClickOutside from "hooks/useOnClickOutside";
import { emailSeparatorsRegex, isValidEmail } from "utils/emailValidations";
import tw from "utils/tw";

import { TextInputProps } from "./types";

const TRIGGER_KEYS = ["Enter", "Tab"];

const validText = /\S+/;

type Props<T extends FieldValues, TName extends Path<T> = Path<T>> = {
  name: TName;
  label?: string;
  placeholder: string;
} & Pick<TextInputProps<T>, "config">;

const EmailChipsArea = <T extends FieldValues>({ name, config, label, placeholder }: Props<T>) => {
  const [emails, setEmails] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [inputActive, setInputActive] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { measureText } = useElementTextWidth(inputRef);
  const {
    register,
    setValue,
    formState: { isSubmitting },
  } = useFormContext<T>();

  const prevIsSubmitting = useRef(isSubmitting);
  useEffect(() => {
    if (isSubmitting === prevIsSubmitting.current) return;
    prevIsSubmitting.current = isSubmitting;
    if (!isSubmitting) return;
    setEmails([]);
  }, [isSubmitting, name, setValue]);

  useEffect(() => {
    const validEmails = emails.filter(isValidEmail);
    setValue(name, validEmails as PathValue<T, Path<T>>);
  }, [emails, name, setValue]);

  const tokenizeEmails = (value?: string) => {
    if (!inputValue.length && !value?.length) return;
    const validEmails = (value ?? inputValue)
      .split(emailSeparatorsRegex)
      .filter(r => validText.test(r) && !emails.includes(r));
    setEmails(v => [...v, ...validEmails]);
    setInputValue("");
    if (inputRef.current) {
      inputRef.current.style.width = "10px";
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;
    if (TRIGGER_KEYS.includes(key)) {
      tokenizeEmails();
      e.preventDefault();
    }
    if (!inputValue.length && key === "Backspace") {
      setEmails(v => v.slice(0, -1));
    }
  };

  const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    if (emailSeparatorsRegex.test(value)) {
      tokenizeEmails(value);
      return;
    }
    setInputValue(value);
    if (inputRef.current) {
      inputRef.current.style.width = `${measureText(value, true)}px`;
    }
  };

  useOnClickOutside([containerRef], () => {
    if (!inputActive) return;
    tokenizeEmails();
    setInputActive(false);
  });

  const TokenizedEmail = ({ email }: { email: string }) => {
    const isValid = isValidEmail(email);
    const handleRemoveEmail = (e: MouseEvent) => {
      e.stopPropagation();
      const addedEmails = [...emails];
      const index = addedEmails.findIndex(e => e === email);
      if (index > -1) {
        addedEmails.splice(index, 1);
      }
      setEmails(addedEmails);
    };
    return (
      <div
        className={tw("flex items-center h-32 pr-4 rounded-lg border-1 overflow-hidden", {
          "bg-background-secondary border-background-secondary pl-8": isValid,
          "border-border-alert ": !isValid,
        })}
      >
        {!isValid && (
          <div className="flex justify-center items-center bg-background-alert-secondary w-32 h-32 mr-8">
            <Icon className="text-icon-alert" icon="Info" size={16} />
          </div>
        )}
        <span
          className={tw("text-subhead overflow-hidden text-ellipsis", {
            "text-text-alert": !isValid,
          })}
        >
          {email}
        </span>
        <Button
          className={tw("ml-4", { "text-text-alert": !isValid })}
          buttonStyle="icon-secondary"
          buttonType="icon"
          icon="Close"
          iconSize={16}
          type="button"
          onClick={handleRemoveEmail}
        />
      </div>
    );
  };

  return (
    <>
      {label && <span className="text-subhead-bold text-text-secondary">{label}</span>}
      <input className="hidden" id={name} {...register(name, config)} />
      <div
        className={tw(
          "flex flex-wrap gap-x-8 gap-y-6 group/invite-to-glue min-h-[84px] max-h-[196px]",
          "rounded-md px-12 py-6 overflow-x-hidden overflow-y-auto border-1",
          {
            "border-border-container focus-within:border-border-active active:border-border-active cursor-text":
              !isSubmitting,
          },
          {
            "border-border-strong focus-within:border-border-strong active:border-border-strong bg-background-secondary cursor-default":
              isSubmitting,
          },
          { "mt-4": !!label }
        )}
        onClick={() => {
          !isSubmitting && inputRef.current?.focus();
          setInputActive(true);
        }}
      >
        {((!emails.length && !inputValue.length) || isSubmitting) && (
          <div
            className={tw("flex items-center text-subhead text-text-subtle h-32", {
              "group-focus-within/invite-to-glue:hidden group-active/invite-to-glue:hidden":
                !isSubmitting,
            })}
          >
            {isSubmitting ? "Sent!" : placeholder}
          </div>
        )}
        {emails.map(e => (
          <TokenizedEmail key={e} email={e} />
        ))}
        <input
          className="!min-w-10 w-10 h-32 text-subhead bg-transparent outline-none shadow-none"
          ref={inputRef}
          type="text"
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          value={inputValue}
          disabled={isSubmitting}
        />
      </div>
    </>
  );
};

export default EmailChipsArea;
