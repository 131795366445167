import { ApolloCache, Reference } from "@apollo/client";
import { sortBy } from "lodash-es";

import { ThreadEdgeSimple } from "@utility-types";
import {
  PersistentChatsDocument,
  PersistentChatsQuery,
  ThreadEdgeSimpleAndNodeFieldsFragmentDoc,
  ThreadListDocument,
  ThreadListQuery,
  ThreadListQueryVariables,
  ThreadsOrder,
} from "generated/graphql";
import threadChatType from "utils/thread/threadChatType";

export type ThreadListCacheOp = "add" | "rm" | "mv";

export type ThreadListVariables = Pick<
  ThreadListQueryVariables,
  "excludeChats" | "excludeStarred" | "mailbox" | "recipientID"
>;

export const updatedUnreadCounts = (edge: ThreadEdgeSimple, edgeWas: ThreadEdgeSimple) => {
  // Calculate recipients unread count changes
  let mentioned = 0;
  let total = 0;
  let unseen = 0;

  if (edge.isRead !== edgeWas.isRead) {
    total = edge.isRead && !edgeWas.isRead ? -1 : 1;
  }

  if (edge.isSeen !== edgeWas.isSeen) {
    unseen = edge.isSeen && !edgeWas.isSeen ? -1 : 1;
  }

  if (edge.isMentioned !== edgeWas.isMentioned) {
    mentioned = !edge.isMentioned && edgeWas.isMentioned ? total : 1;
  } else {
    mentioned = edgeWas.isMentioned ? total : 0;
  }

  return { mentioned, total, unseen };
};

export const mutateThreadEdges = (
  edge: ThreadEdgeSimple,
  op: ThreadListCacheOp,
  sort: "all" | "one" | "none",
  list: {
    edges: ThreadEdgeSimple[];
    totalCount: number;
  }
) => {
  const { id } = edge;
  const inList = !!list.edges.find(e => e.id === id);

  // If only moving and not in list, we're done
  if (op === "mv" && !inList) return list;

  const threads = { ...list }; // allow mutations

  // Add or remove edge as needed
  if (op === "add" && !inList) {
    threads.edges = [...threads.edges, edge];
    threads.totalCount += 1;
  } else if (op === "rm") {
    // can't guard on inList because we don't always have
    // all edges loaded locally in all lists
    threads.edges = threads.edges.filter(e => e.id !== id);
    threads.totalCount = Math.max(0, threads.totalCount - 1);
  }

  // Re-sort list on add or move
  if (op === "add" || op === "mv") {
    if (sort === "all") {
      // Should immediately sort any mailboxes we're not viewing
      // so that they don't shift when navigating back
      threads.edges = sortBy(threads.edges, "cursor");
    } else if (sort === "one" || (op === "add" && !inList)) {
      // Move or insert thread edge into the correct spot in the list
      // without re-sorting the entire list——better UX for thread actions
      threads.edges = threads.edges.filter(e => e.id !== edge.id);
      let newIndex = threads.edges.findIndex(e => edge.cursor < e.cursor);
      if (newIndex === -1) newIndex = threads.edges.length;
      threads.edges.splice(newIndex, 0, edge);
    }
  }

  return threads;
};

export const mutateThreadList = (
  edge: ThreadEdgeSimple,
  op: ThreadListCacheOp,
  variables: ThreadListVariables & {
    sort?: boolean; // sort when visible
  },
  cache: ApolloCache<unknown>
) => {
  const list = readThreadList(variables, cache)?.threads;
  if (!list) return;

  const { sort } = variables;
  const sortEdges = sort ? "one" : "none";
  const threads = mutateThreadEdges(edge, op, sortEdges, list);

  cache.writeQuery({
    data: { threads },
    query: ThreadListDocument,
    variables,
  });
};

export const mutatePersistentChats = (
  edge: ThreadEdgeSimple,
  edgeWas: ThreadEdgeSimple,
  op: ThreadListCacheOp,
  cache: ApolloCache<unknown>,
  order: ThreadsOrder
) => {
  const variables = {
    chatType: threadChatType(edge.node),
    order,
  };

  const list = cache.readQuery<PersistentChatsQuery>({
    optimistic: true,
    query: PersistentChatsDocument,
    variables,
  })?.persistentChats;

  if (!list) return;

  const persistentChats = mutateThreadEdges(edge, op, "all", list);

  const { mentioned, total } = updatedUnreadCounts(edge, edgeWas);

  cache.writeQuery<PersistentChatsQuery>({
    data: {
      persistentChats: {
        ...list,
        ...persistentChats,
        unreadCounts: {
          ...list.unreadCounts,
          mentioned: Math.max(0, list.unreadCounts.mentioned + mentioned),
          total: Math.max(0, list.unreadCounts.total + total),
        },
      },
    },
    query: PersistentChatsDocument,
    variables,
  });
};

export const readThreadList = (
  variables: ThreadListVariables | undefined,
  cache: ApolloCache<unknown>
) =>
  cache.readQuery<ThreadListQuery>({
    optimistic: true,
    query: ThreadListDocument,
    variables,
  });

export const readThreadEdge = (
  threadEdgeID: string,
  cache: ApolloCache<unknown>
): ThreadEdgeSimple | null =>
  cache.readFragment<ThreadEdgeSimple>({
    fragment: ThreadEdgeSimpleAndNodeFieldsFragmentDoc,
    fragmentName: "ThreadEdgeSimpleAndNodeFields",
    id: `ThreadEdge:${threadEdgeID}`,
    optimistic: true,
  });

export const writeThreadEdge = (
  edge: ThreadEdgeSimple,
  cache: ApolloCache<unknown>
): Reference | undefined =>
  cache.writeFragment({
    data: edge,
    fragment: ThreadEdgeSimpleAndNodeFieldsFragmentDoc,
    fragmentName: "ThreadEdgeSimpleAndNodeFields",
    id: cache.identify(edge),
  });
