import { StreamImageAttachment } from "@utility-types";

import PhotoSwipeImageWrapper from "./PhotoSwipeImageWrapper";

type GetThumbnailDimensions = {
  ratio: number;
  targetHeight: number;
  targetWidth: number;
};

const getThumbnailDimensions = ({ ratio, targetHeight, targetWidth }: GetThumbnailDimensions) => {
  let height = Math.ceil(targetWidth / ratio);
  let width = targetWidth;

  if (height > targetHeight) {
    height = targetHeight;
    width = Math.ceil(targetHeight * ratio);
  }

  return { height, width };
};

const SingleImage = ({
  galleryWidth,
  image,
}: {
  galleryWidth: number;
  image: StreamImageAttachment;
}) => {
  const {
    fallback,
    image_blurhash: imageBlurHash,
    image_height: imageHeight = 768,
    image_url: imageURL,
    image_width: imageWidth = 768,
  } = image;

  const ratio = imageWidth / imageHeight;
  const maxThumbnailHeight = Math.min(galleryWidth / 2 / ratio, galleryWidth / 2);

  const targetHeight = Math.min(maxThumbnailHeight, imageHeight);
  const targetWidth = Math.min(galleryWidth, imageWidth);

  const { height: thumbnailHeight, width: thumbnailWidth } = getThumbnailDimensions({
    ratio,
    targetHeight,
    targetWidth,
  });

  const resize = {
    fit: "max" as const,
    h: Math.min(imageHeight, thumbnailHeight, maxThumbnailHeight),
    w: Math.min(imageWidth, thumbnailWidth),
  };

  return (
    <PhotoSwipeImageWrapper
      alt={fallback}
      blurHash={imageBlurHash}
      className="max-h-full"
      figureClassName="border border-border-container cursor-pointer hover:border-border-container-hover overflow-hidden rounded-lg"
      height={thumbnailHeight}
      image={image}
      resize={resize}
      src={imageURL}
      width={thumbnailWidth}
    />
  );
};

export default SingleImage;
