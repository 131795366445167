import { uniq } from "lodash-es";
import { Fragment, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import Button from "components/design-system/Button/Button";
import { Checkbox, Form, TextInput } from "components/design-system/Forms";
import Hr from "components/design-system/Hr";
import { Footer, Main } from "components/ModalKit/Parts";
import { useSlackImportQuery, useUpdateSlackImportMutation } from "generated/graphql";
import tw from "utils/tw";

import type { Selections } from "./Reducer";
import SelectionToggle from "./SelectionToggle";
import type { StepsProps } from "./types";

type UpdateSelections = {
  updateSelections: (includeChannels: string[], skipChannels: string[], groups: string[]) => void;
};

type Props = StepsProps & UpdateSelections & Selections & { workspaceID: string };

type FormContentProps = Props & { allChannels: string[] };

const FormContent = ({
  workspaceID,
  cancelButton,
  updateSelections,
  onClickNext,
  allChannels,
  groups: groupsProps = [],
  includeChannels: includeChannelsProps = [],
  skipChannels: skipChannelsProps = [],
}: FormContentProps) => {
  const [allIndeterminate, setAllIndeterminate] = useState(false);
  const [filteredChannels, setFilteredChannels] = useState<string[]>(allChannels);

  const [groups, setGroups] = useState<string[]>(groupsProps);
  const [includeChannels, setIncludeChannels] = useState<string[]>(includeChannelsProps);
  const [skipChannels, setSkipChannels] = useState<string[]>(skipChannelsProps ?? []);

  const [updateLoading, setUpdateLoading] = useState(false);
  const [updateSlackImport] = useUpdateSlackImportMutation();

  const { watch, setValue } = useFormContext();
  const values = watch();
  const { all, search } = values;

  useEffect(() => {
    setFilteredChannels(
      allChannels?.filter(channel =>
        search?.length > 0 ? channel.toLowerCase().includes(search.toLowerCase()) : true
      ) ?? []
    );
  }, [search, allChannels]);

  const handleCheckAll = (checked: boolean) => {
    // If the all checkbox is indeterminate it works as "select all"
    const isChecked = checked || allIndeterminate;
    if (allIndeterminate) {
      setValue("all", true);
    }
    filteredChannels?.map(channel => {
      setValue(channel, isChecked);
    });
    const channelsExcluded =
      allChannels?.filter(channel => !filteredChannels.includes(channel)) ?? [];
    if (isChecked) {
      setIncludeChannels(v => uniq([...v, ...filteredChannels]));
      setSkipChannels(channelsExcluded);
      return;
    }
    setSkipChannels(v => uniq([...v, ...filteredChannels]));
    setIncludeChannels([]);
    setGroups([]);
  };

  useEffect(() => {
    // If all channels are selected set the all checkbox as checked
    if (
      filteredChannels.length &&
      filteredChannels.every(channel => includeChannels.includes(channel))
    ) {
      setAllIndeterminate(false);
      setValue("all", true);
      return;
    }
    if (includeChannels.length === 0) {
      setValue("all", false);
    }
    setAllIndeterminate(includeChannels.length > 0);
  }, [filteredChannels, includeChannels, setValue]);

  const handleCheckChannel = (channel: string) => {
    if (skipChannels.includes(channel)) {
      setIncludeChannels(v => [...v, channel]);
      setSkipChannels(v => v.filter(c => c !== channel));
    } else {
      setIncludeChannels(v => v.filter(c => c !== channel));
      setSkipChannels(v => [...v, channel]);
    }
    if (groups.includes(channel)) {
      setGroups(v => v.filter(c => c !== channel));
    }
  };

  const handleChangeChannelType = (channel: string, type: "thread" | "group") => {
    if (type === "thread") {
      setGroups(v => v.filter(c => c !== channel));
    } else {
      setGroups(v => [...v, channel]);
    }
  };

  const handleReviewSelections = () => {
    setUpdateLoading(true);
    updateSlackImport({
      variables: {
        workspaceID,
        config: { includeChannels, skipChannels, groups },
      },
    })
      .then(() => {
        updateSelections(includeChannels, skipChannels, groups);
        onClickNext();
      })
      .finally(() => setUpdateLoading(false));
  };

  return (
    <>
      <Main className="flex flex-col px-32 py-16 min-h-[60vh]">
        <span className="text-body">
          Select channels to import to Glue. Channels can be imported as threads or groups.
        </span>
        <div className="flex flex-col mt-16 border-1 border-border-container rounded-lg overflow-hidden grow">
          <div className="flex items-center pl-16 pr-8 border-b-1 border-border-container">
            <Checkbox
              className={tw("text-icon-secondary", {
                "!text-icon-primary-selected": all,
              })}
              name="all"
              wrapperClassName="!my-0"
              indeterminate={allIndeterminate}
              config={{ onChange: e => handleCheckAll(e.target.checked) }}
            />
            <div className="flex items-center relative w-full ml-12">
              <TextInput
                wrapperClassName="!my-8 w-full"
                className="h-32 border-border-container hover:border-border-container-hover"
                name="search"
                placeholder="Search channels"
                icon="Search"
              />
              {search && (
                <Button
                  className="absolute right-8"
                  buttonStyle="icon-secondary"
                  buttonType="icon"
                  icon="Close"
                  iconSize={20}
                  onClick={() => setValue("search", "")}
                />
              )}
            </div>
          </div>
          <div className="overflow-x-hidden overflow-y-auto">
            {filteredChannels.map((channel, i) => {
              const key = `${channel}-${i}`;
              return (
                <Fragment key={key}>
                  <div className="flex justify-between items-center h-56 px-16 py-8 hover:bg-background-list-subtle-hover">
                    <Checkbox
                      className={tw("text-icon-secondary", {
                        "!text-icon-primary-selected": includeChannels.includes(channel),
                      })}
                      name={channel}
                      wrapperClassName="!my-0 grow"
                      label={<span className="text-subhead-bold ml-12">#{channel}</span>}
                      config={{
                        onChange: () => handleCheckChannel(channel),
                      }}
                    />
                    {includeChannels.includes(channel) && (
                      <div className="flex items-center">
                        <SelectionToggle
                          textOn="Thread"
                          textOff="Group"
                          isChecked={!groups.includes(channel)}
                          onChange={isChecked =>
                            handleChangeChannelType(channel, isChecked ? "thread" : "group")
                          }
                        />
                      </div>
                    )}
                  </div>
                  <Hr className="w-full ml-50" />
                </Fragment>
              );
            })}
          </div>
        </div>
      </Main>
      <Footer>
        {cancelButton}
        <Button
          disabled={includeChannels.length === 0 || updateLoading}
          type="button"
          onClick={handleReviewSelections}
        >
          Review {includeChannels.length === 0 ? "" : includeChannels.length} selections
        </Button>
      </Footer>
    </>
  );
};

const MakeSelections = ({
  workspaceID,
  includeChannels: includeChannelsProps,
  skipChannels: skipChannelsProps,
  ...props
}: Props) => {
  const { data } = useSlackImportQuery({ variables: { workspaceID } });
  const allChannels = uniq([
    ...(data?.slackImport?.config?.includeChannels ?? []),
    ...(data?.slackImport?.config?.skipChannels ?? []),
  ]).sort();

  let includeChannels = data?.slackImport?.config?.includeChannels;
  let skipChannels = data?.slackImport?.config?.skipChannels;
  if (!!includeChannelsProps?.length || !!skipChannelsProps?.length) {
    includeChannels = includeChannelsProps;
    skipChannels = skipChannelsProps;
  }

  return (
    <Form
      className="flex flex-col overflow-hidden"
      useFormProps={{
        defaultValues: {
          search: "",
          ...includeChannels?.reduce<Record<string, boolean>>((acc, channel) => {
            acc[channel] = true;
            return acc;
          }, {}),
        },
      }}
    >
      <FormContent
        allChannels={allChannels}
        includeChannels={includeChannels}
        skipChannels={skipChannels}
        workspaceID={workspaceID}
        {...props}
      />
    </Form>
  );
};

export default MakeSelections;
