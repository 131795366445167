import { UsersOrder, useFetchUsersQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";

import DirectMessagesList from "./DirectMessagesList";

const Directory = () => {
  const { authReady } = useAuthData();
  const { data, fetchMore, loading } = useFetchUsersQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    variables: {
      filter: {
        mutualGroups: true,
        mutualWorkspaces: true,
      },
      first: Math.ceil(window.innerHeight / 64),
      order: UsersOrder.Name,
    },
  });

  const persistentChats = data?.users.edges.map(e =>
    e.persistentChatEdge !== null ? e.persistentChatEdge : e.node
  );

  const pageInfo = data?.users.pageInfo;

  const loadMore = () => {
    if (!pageInfo?.endCursor) return;
    fetchMore({
      variables: {
        after: pageInfo.endCursor,
      },
    });
  };

  return (
    <DirectMessagesList
      data={persistentChats}
      hasNextPage={!!pageInfo?.hasNextPage}
      loadMore={loadMore}
      loading={loading}
      isDirectoryList
    />
  );
};

export default Directory;
