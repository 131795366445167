import { ThreadEdgeSimple } from "@utility-types";
import { HeaderButton } from "components/design-system/Button";
import { isSubscribed } from "components/threads-list/ThreadItem/utils";
import useInboxThreadActions from "hooks/thread/useInboxThreadActions";

const FollowButton = ({ threadEdge }: { threadEdge: ThreadEdgeSimple | undefined }) => {
  const following = threadEdge && isSubscribed(threadEdge);
  const threadActions = useInboxThreadActions();
  return (
    <HeaderButton
      icon={following ? "BellFilled" : "Bell"}
      tooltip={following ? "Unfollow thread" : "Follow thread"}
      tooltipPlacement="bottom"
      tooltipStyle="inverted"
      onClick={e => {
        if (!threadEdge) return;
        e.stopPropagation();
        threadActions.toggleThreadSubscribed(threadEdge);
      }}
    />
  );
};

export default FollowButton;
