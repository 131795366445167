import { nodeAs } from "@utility-types";
import {
  useFetchGroupOrPreviewEdgeQuery,
  useFetchGroupOrPreviewEdgeSimpleQuery,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import { IAuthContext } from "providers/AuthProvider";

type QueryOptions = {
  fetchPolicy?: "cache-and-network" | "cache-first" | "cache-only";
};

const buildQueryOptions = (
  { authData, authReady }: Pick<IAuthContext, "authData" | "authReady">,
  groupID: string | undefined,
  { fetchPolicy = "cache-first" }: QueryOptions
) => ({
  fetchPolicy: authReady ? fetchPolicy : ("cache-only" as const),
  nextFetchPolicy: "cache-first" as const,
  skip: !authData?.me.id || !groupID,
  variables: { id: `${groupID}-${authData?.me.id}` },
});

const nodeTypes = [
  "GroupEdge" as const,
  "GroupPreviewEdge" as const,
  "WorkspaceEdge" as const,
  "WorkspacePreviewEdge" as const,
];

const useFetchGroupEdge = (groupID: string | undefined, queryOptions: QueryOptions = {}) => {
  const options = buildQueryOptions(useAuthData(), groupID, queryOptions);
  const { data, loading } = useFetchGroupOrPreviewEdgeQuery(options);
  return { edge: nodeAs(data?.node, nodeTypes), loading };
};

export const useFetchGroupEdgeSimple = (
  groupID: string | undefined,
  queryOptions: QueryOptions = {}
) => {
  const options = buildQueryOptions(useAuthData(), groupID, queryOptions);
  const { data, loading } = useFetchGroupOrPreviewEdgeSimpleQuery(options);
  return { edge: nodeAs(data?.node, nodeTypes), loading };
};

export default useFetchGroupEdge;
