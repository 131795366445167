const TeamBasedContactCard = ({ isEnterprise = false }: { isEnterprise?: boolean }) => {
  const data = {
    title: isEnterprise ? "See Glue in action" : "Have questions?",
    description: isEnterprise
      ? "Schedule a quick demo and see how Glue makes teamwork easy."
      : "Discover how Glue can simplify your work.",
    linkText: isEnterprise ? "Book a demo" : "Get in touch",
    linkTo: isEnterprise
      ? "https://calendly.com/d/ckhb-vmg-wm4/glue-product-onboarding"
      : "mailto:support@glue.ai",
  };

  return (
    <div className="flex flex-col">
      <span className="text-body-bold">{data.title}</span>
      <span className="mt-4 text-text-secondary">{data.description}</span>
      <a
        className="mt-12 bg-background-action hover:bg-background-action-hover flex justify-center items-center px-16 py-6 rounded-md text-subhead-bold text-text-action-inverse"
        href={data.linkTo}
        target="_blank"
        rel="noreferrer"
      >
        {data.linkText}
      </a>
    </div>
  );
};

export default TeamBasedContactCard;
