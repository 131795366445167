import { captureMessage } from "@sentry/react";
import { memo, useCallback, useEffect, useMemo, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

import glueLogo from "assets/icons-png/glue-logo.png";
import MusicMarimbaChordSound from "assets/music_marimba_chord.wav";
import { routeToThread } from "components/routing/utils";
import useComponentMounted from "hooks/useComponentMounted";
import { markdownToPlainText } from "md";
import useAppStateStore from "store/useAppStateStore";
import { isNativeMac } from "utils/platform";

import {
  CreateBrowserNotification,
  CreateNativeNotification,
  GetNotificationInstance,
} from "./CreateNotification";
import ShowNotificationFavicon from "./ShowNotificationFavicon";

const notificationSound = new Audio(MusicMarimbaChordSound);

const getAppNotificationTitle = ({
  sender,
  isDirectMessage,
  isPersistentChat,
  subject,
}: { sender: string; isDirectMessage: boolean; isPersistentChat: boolean; subject: string }) => {
  if (isDirectMessage) {
    return sender;
  }
  if (isPersistentChat) {
    // remove " Chat" suffix from the subject for messages to group chats
    const [, trimmedSubject] = subject.match(/^(.*?)(?:\s+Chat)?$/) || [null, subject];
    return `${sender} in ${trimmedSubject}`;
  }
  return `${sender} in ${subject}`;
};

type Props = {
  getInstance?: (M: GetNotificationInstance) => void;
  onShow?: () => void;
};

type MemoizedProps = Props & {
  changePath: (threadID: string) => void;
  activeNotification: Parameters<Parameters<typeof useAppStateStore>[0]>[0]["activeNotification"];
  clearNotification: () => void;
  urlPath: string;
};

export const LocalNotificationHandler = ({
  getInstance: getInstanceProp,
  onShow,
}: Props): JSX.Element | null => {
  const { activeNotification, setState } = useAppStateStore(({ activeNotification, setState }) => ({
    activeNotification,
    setState,
  }));

  const clearNotification = useCallback(() => {
    setState({ activeNotification: undefined });
  }, [setState]);

  const history = useHistory();
  const location = useLocation();

  const urlPath = location.pathname.replace("/", "");
  const changePath = (threadID: string) => {
    const threadPath = routeToThread({
      threadID,
      superTab: "inbox",
      to: "primary",
    });
    history.push(threadPath);
  };

  return (
    <MemoizedLocalNotificationHandler
      changePath={changePath}
      getInstance={getInstanceProp}
      onShow={onShow}
      activeNotification={activeNotification}
      clearNotification={clearNotification}
      urlPath={urlPath}
    />
  );
};

const MemoizedLocalNotificationHandler = memo(
  ({
    changePath,
    getInstance,
    onShow,
    activeNotification,
    clearNotification,
    urlPath,
  }: MemoizedProps): JSX.Element => {
    const favicon = useMemo(() => new ShowNotificationFavicon(), []);
    const isMounted = useComponentMounted();
    const getInstanceRef = useRef<GetNotificationInstance | null>(null);
    const instancesRef = useRef(new Map<string, Notification | undefined>());

    const getInstances = [
      (method: GetNotificationInstance) => {
        getInstanceRef.current = method;
      },
    ];

    const clearInstance = useCallback((key: string) => {
      instancesRef.current.get(key)?.close();
      instancesRef.current.delete(key);
    }, []);

    const clearCurrentNotification = useCallback(() => {
      favicon.show(false);
      clearNotification();
      clearInstance(urlPath);
    }, [urlPath, clearNotification, clearInstance, favicon]);

    const setInstancesRef = (key: string, instance: Notification | undefined) => {
      clearInstance(key);
      instancesRef.current.set(key, instance);
    };

    if (getInstance) {
      getInstances.push(getInstance);
    }

    useEffect(
      () =>
        useAppStateStore.subscribe(
          ({ appStatus }) => appStatus,
          appStatus => {
            if (appStatus === "inactive") return;
            clearCurrentNotification();
          }
        ),
      [clearCurrentNotification]
    );

    if (!activeNotification) {
      return <div data-testid="no-browser-notification" />;
    }

    const isAppNotification = "threadID" in activeNotification;

    // Hardcoded to true because a sound is "manually" played in the onShow handler
    const silent = true;
    const notificationOptions = isAppNotification
      ? {
          body: markdownToPlainText(activeNotification.message.text),
          data: { target: activeNotification.threadID },
          icon: glueLogo,
          requireInteraction: true,
          tag: activeNotification.message.id,
          silent,
          title: getAppNotificationTitle({
            sender: activeNotification.message.user.name,
            isPersistentChat: activeNotification.threadEdge.node.isPersistentChat,
            isDirectMessage:
              activeNotification.threadEdge.node.isPersistentChat &&
              activeNotification.threadEdge.node.recipients.edges.length === 2,
            subject: activeNotification.threadEdge.node.subject,
          }),
        }
      : {
          title: activeNotification.message.title,
          body: activeNotification.message.body,
          tag: activeNotification.message.id,
          silent,
        };

    return isNativeMac() ? (
      <CreateNativeNotification
        getNotificationInstance={getInstances}
        options={notificationOptions}
        playSound={activeNotification.playSound}
      />
    ) : (
      <CreateBrowserNotification
        getNotificationInstance={getInstances}
        onCreate={activeNotification.onCreate}
        onClick={(e: Event) => {
          const notification = e.target as Notification | null | undefined;
          if (!notification) return;
          const threadID = notification.data?.target;
          if (typeof threadID !== "string") return;

          window.focus();
          changePath(threadID);
          clearInstance(threadID);
        }}
        onClose={() => {
          isMounted.current && clearCurrentNotification();
        }}
        onShow={() => {
          const instance = getInstanceRef.current?.(activeNotification.message.id);
          if (activeNotification.playSound) {
            notificationSound.play().catch(e => {
              if (e instanceof DOMException && e.name === "NotAllowedError") {
                // Expected if user has not interacted with the page yet
                // We check notification permissions before triggering, so it's likely not a permission issue at this point
                return;
              }
              const message = "[BrowserNotification] Failed to play notification sound";
              console.error(message, e);
              captureMessage(message, { level: "error", extra: { error: e } });
            });
          }

          if (isAppNotification) {
            setInstancesRef(activeNotification.threadID, instance);
          }

          onShow?.();
        }}
        options={notificationOptions}
        timeout={0}
      />
    );
  },
  /* istanbul ignore next */
  (prev, next) =>
    prev.urlPath === next.urlPath &&
    prev.activeNotification?.message?.id === next.activeNotification?.message?.id
);
