import { cloneElement, useEffect, useRef, useState } from "react";
import type { FlatEmoji } from "svgmoji";

import { getEmojisByName } from "components/design-system/Emoji/data";
import useMenuKeyboardShortcuts from "hooks/useMenuKeyboardShortcuts";
import mergeRefs from "utils/mergeRefs";

import useFloatingElement from "../FloatingUi/useFloatingElement";
import { useCaretPosition } from "../Forms";

import EmojiList from "./EmojiList";

/**
 * Sets the value of an input element and triggers a React change event. This works particularly well for
 * inputs that are controlled by react-hook-form. Instead of setting the `value` prop directly, it uses a
 * setter function so changes are properly tracked and not deduplicated by React.
 *
 * For a detailed explanation, see [here](https://hustle.bizongo.in/simulate-react-on-change-on-controlled-components-baa336920e04).
 */
const setReactInputValue = (input: HTMLInputElement, value: string) => {
  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
    HTMLInputElement.prototype,
    "value"
  )?.set;

  nativeInputValueSetter?.call(input, value);

  const inputEvent = new Event("input", { bubbles: true });
  input.dispatchEvent(inputEvent);
};

const EmojiListWrapper = ({
  children,
  className,
}: {
  children: JSX.Element;
  className?: string;
}) => {
  const [emojis, setEmojis] = useState<FlatEmoji[] | null>(null);
  const [selectedItemRef, setSelectedItemRef] = useState<HTMLDivElement | null>(null);
  const emojiListRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { anchorProps, floatingProps, isOpen, setIsOpen } = useFloatingElement({
    strategy: "fixed",
    placement: "bottom-start",
  });

  const { selectedItem } = useMenuKeyboardShortcuts({
    closeMenu: () => setEmojis(null),
    data: emojis ?? [],
    onSelectItem: item => onSelectEmoji(0, item),
    scrollProps: { block: "center" },
    selectedItemRef: selectedItemRef,
  });

  const { left, position, resetPosition, ...caretEvents } = useCaretPosition(inputRef);

  useEffect(() => {
    const selectedText = position?.selectedText.text ?? "";
    // Two letters after the ":" character
    if (selectedText.startsWith(":") && selectedText.length > 2) {
      getEmojisByName(selectedText.replace(":", ""), 20).then(emojis => setEmojis(emojis));
      return;
    }
    setEmojis(null);
  }, [position]);

  useEffect(() => {
    setIsOpen(!!emojis);
  }, [emojis, setIsOpen]);

  const onSelectEmoji = (_index: number, emoji: FlatEmoji) => {
    if (!position || !inputRef.current) return;
    const { selectedText, value } = position;

    const before = value.substring(0, selectedText.start);
    let after = value.substring(selectedText.end);
    if (after.at(0) !== " ") {
      after += ` ${after}`;
    }

    const input = inputRef.current;
    input.focus();
    setReactInputValue(input, `${before}${emoji.emoji}${after}`);

    const caretAt = selectedText.start + emoji.emoji.length + 1;
    input.setSelectionRange(caretAt, caretAt);

    resetPosition();
    setEmojis(null);
  };

  const selectedIndex = emojis?.findIndex(e => e.emoji === selectedItem?.emoji) ?? 0;

  const handleMouseDown = (e: React.MouseEvent) => {
    if (!isOpen) return;
    e.preventDefault();
  };

  return (
    <>
      <div className={className} {...anchorProps}>
        {cloneElement(children, {
          disableArrowShortcuts: isOpen,
          inputRef: mergeRefs(inputRef, children.props.inputRef),
          onKeyDown: (e: KeyboardEvent) => {
            if (e.key === "Escape") {
              resetPosition();
            }
            if (!isOpen) {
              children.props.onKeyDown?.(e);
            }
          },
          onFocus: (e: FocusEvent) => {
            caretEvents.onFocus?.();
            children.props.onFocus?.(e);
          },
          onClick: (e: MouseEvent) => {
            caretEvents.onClick?.();
            children.props.onClick?.(e);
          },
          onKeyUp: (e: KeyboardEvent) => {
            caretEvents.onKeyUp?.(e);
            children.props.onKeyUp?.(e);
          },
        })}
      </div>
      {isOpen && (
        <div className="z-1" {...floatingProps} onMouseDown={handleMouseDown}>
          <EmojiList
            containerProps={{
              className:
                "bg-background-body border-border-container border-thin relative rounded-lg shadow-strong-ui",
              ref: emojiListRef,
              style: { left },
            }}
            emojis={emojis ?? []}
            itemProps={(index, emoji) => ({
              onClick: () => onSelectEmoji(index, emoji),
              ref:
                selectedIndex === index
                  ? (e: HTMLDivElement | null) => e !== null && setSelectedItemRef(e)
                  : undefined,
            })}
            selectedIndex={selectedIndex}
          />
        </div>
      )}
    </>
  );
};

export default EmojiListWrapper;
