import { nodeAs } from "@utility-types/graphql";
import { EditorMessage } from "components/MessageEditor/types";
import { useFetchGroupOrPreviewEdgeQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useModalStore from "store/useModalStore";
import { isGroupEdgeMessageable } from "utils/group/isGroupEdgeMessageable";
import env from "utils/processEnv";

import ThreadComposeModal from "../ThreadComposeModal";

/**
 * @summary Glue Sales and Support static group IDs.
 * In DEV you'll need to update your .env.local vars VITE_HELP_GROUP_ID and VITE_SALES_GROUP_ID
 * with local group IDs for the recipients field to populate correctly.
 */

const channels = {
  sales: env.salesGroupId,
  support: env.helpGroupId,
};

/**
 * @summary Compose a thread to Glue Sales or Support groups.
 */

const useComposeToGlue = (channel: keyof typeof channels) => {
  const { authData, authReady } = useAuthData();
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const { data } = useFetchGroupOrPreviewEdgeQuery({
    fetchPolicy: authReady ? "cache-first" : "cache-only",
    skip: !channels[channel] || !authData?.me.id,
    variables: {
      id: `${channels[channel]}-${authData?.me.id}`,
    },
  });

  const groupEdge = nodeAs(data?.node, ["GroupEdge", "GroupPreviewEdge"]);
  const isMessageable = isGroupEdgeMessageable(groupEdge, authData?.workspaces.edges);
  const group = groupEdge?.node;

  const composeThreadToGlue = (options?: {
    subject?: string;
    message?: EditorMessage;
    parentModalId?: string;
  }) => {
    const { subject, message, parentModalId } = options ?? {};
    const initialDraft = {
      ...(group && isMessageable ? { recipients: [group] } : {}),
      message: message ?? { attachments: [], text: "" },
      subject,
    };
    return openModal(<ThreadComposeModal initialDraft={initialDraft} />, {
      closeModals: parentModalId ? [parentModalId] : undefined,
    });
  };

  return { group, composeThreadToGlue };
};

export default useComposeToGlue;
