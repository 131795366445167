import { useRef } from "react";

import { Modal, ModalProps } from "components/ModalKit/Modal";
import useAppStateStore from "store/useAppStateStore";
import tw from "utils/tw";

type Props = WithChildren<ModalProps>;

const SuperModal = ({ children, contentClassName, ...props }: Props) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <Modal
      alignment={
        breakpointMD
          ? undefined
          : {
              x: "text-center",
              y: "align-bottom",
            }
      }
      closeButtonPosition="right"
      containerScrolling={false}
      contentClassName={tw(
        "overflow-hidden rounded-t-xl shadow-level3 w-full", // mobile and up
        "md:border-border-container md:border-thin md:max-w-[1080px] md:my-50 md:rounded-lg", // desktop
        contentClassName
      )}
      contentTransitions={
        breakpointMD
          ? undefined
          : {
              enter: {
                y: "0%",
              },
              from: {
                y: "100%",
              },
              leave: {
                y: "100%",
              },
            }
      }
      disableOpacityTransition={!breakpointMD}
      {...props}
    >
      <div
        ref={contentRef}
        className={tw(
          "flex flex-col h-[100vh] max-h-safe-viewport-[10px]", // mobile and up
          "md:h-safe-viewport-[100px] md:max-h-[690px]" // desktop
        )}
      >
        {children}
      </div>
    </Modal>
  );
};

export default SuperModal;
