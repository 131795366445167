import { useApolloClient } from "@apollo/client";
import { useEffect, useRef, useState } from "react";

import { CustomEmoji, nodeAs } from "@utility-types";
import { hasEmoji } from "components/design-system/Emoji";
import { CustomEmojiDocument, CustomEmojiQuery } from "generated/graphql";

import { validCustomEmojiID } from "../utils";

const emojiPrefix = "emj_";

const isCustomEmojiReaction = (r: string) => !hasEmoji(r, true) && r.match(validCustomEmojiID);

type CustomReaction = Partial<Pick<CustomEmoji, "id" | "imageURL" | "name">>;
const useCustomReactionsData = (reactions: string[]) => {
  const [customReactions, setCustomReactions] = useState<Map<string, CustomReaction | undefined>>(
    new Map()
  );

  const customReactionsRef = useRef(customReactions);
  customReactionsRef.current = customReactions;

  const apolloClient = useApolloClient();
  useEffect(() => {
    const uniqReactions = reactions
      .filter(isCustomEmojiReaction)
      .filter(r => !customReactionsRef.current.has(r));
    if (!uniqReactions.length) return;

    const newReactions: Map<string, CustomReaction | undefined> = new Map(
      uniqReactions.map(r => [r, { id: r, imageURL: undefined, name: undefined }])
    );

    setCustomReactions(v => new Map([...v, ...newReactions]));

    (async () => {
      const emojis = await Promise.all(
        uniqReactions.map(reaction =>
          apolloClient
            .query<CustomEmojiQuery>({
              query: CustomEmojiDocument,
              variables: { id: `${emojiPrefix}${reaction}` },
            })
            .then(({ data }) => nodeAs(data.node, ["CustomEmoji"]))
            .catch(() => ({
              id: reaction,
              imageURL: undefined,
              name: undefined,
            }))
        )
      );
      const foundReactions = new Map(
        emojis
          .filter((e): e is CustomEmoji => e !== undefined)
          .map(e => [e.id.replace(emojiPrefix, ""), e])
      );

      setCustomReactions(v => new Map([...v, ...foundReactions]));
    })();
  }, [reactions, apolloClient]);

  return { customReactions };
};

export default useCustomReactionsData;
