import { Tags, load as loadExif } from "exifreader";

type ImageDimensions = {
  height: number;
  width: number;
};

const getResolutionUnit: (tags: Tags) => number = (tags: Tags) => {
  if (tags?.ResolutionUnit?.value && typeof tags.ResolutionUnit.value === "number") {
    return tags.ResolutionUnit.value;
  }

  // 72 pixels per inch is the standard for online use;
  // we can make the imperfect assumption that the image is a screenshot if the resolution is divisible by 72
  const xRes = Number.parseInt(tags?.XResolution?.description ?? "72");
  const isScreenRes = xRes % 72 === 0;

  let resolutionUnit =
    isScreenRes || tags?.UserComment === "Screenshot" // Apple includes a UserComment tag for screenshots on iOS and macOS
      ? xRes / 72
      : 1;

  if (resolutionUnit < 1) resolutionUnit = 1;

  return resolutionUnit;
};

const handleOrientation = (
  tags: Tags,
  { width = 0, height = 0 }: { width?: number; height?: number }
) => {
  // Orientation values 1-4 are for landscape orientation, 5-8 for portrait orientation;
  // if undefined, we default to landscape orientation (e.g. height and width values should NOT be swapped)
  const isRotated = Number.parseInt(tags?.Orientation?.value.toString() ?? "1") >= 5;

  return {
    height: isRotated ? width : height,
    width: isRotated ? height : width,
  };
};

const newImage = (src: File) => {
  const img = new Image();
  img.crossOrigin = "Anonymous";
  img.src = URL.createObjectURL(src);
  return img;
};

const getImageDimensions = (src: File) =>
  loadExif(src, { async: true }).then(tags =>
    new Promise<{ height: number; width: number }>(resolve => {
      const height = tags?.ImageHeight?.value ?? tags?.["Image Height"]?.value;
      const width = tags?.ImageWidth?.value ?? tags?.["Image Width"]?.value;

      if (height !== undefined && width !== undefined) {
        resolve({ height, width });
        return;
      }

      const img = newImage(src);
      img.onload = () => {
        resolve({ height: img.height, width: img.width });
      };
    })
      .then(({ height: h, width: w }) => {
        const { height, width } = handleOrientation(tags, {
          height: h,
          width: w,
        });

        const resolutionUnit = getResolutionUnit(tags);

        return {
          height: Math.floor(height / resolutionUnit),
          width: Math.floor(width / resolutionUnit),
        };
      })
      .catch(e => {
        console.error("Error loading image metadata", e);

        // in cases of unrecognized file type (e.g. webp), try to get dimensions from the file
        return new Promise<ImageDimensions>(resolve => {
          const img = newImage(src);
          img.onload = () => {
            resolve({
              height: img.height,
              width: img.width,
            });
          };
        });
      })
  );

export default getImageDimensions;
