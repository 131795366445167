import { ComponentProps, ReactNode } from "react";

import tw from "utils/tw";

import { Icon } from "../icons";

import styles from "./ButtonGroup.module.css";

export type ButtonGroupButtonProps = {
  icon: ComponentProps<typeof Icon>["icon"];
  iconSize?: ComponentProps<typeof Icon>["size"];
  children: ReactNode;
  variant?: "default" | "destructive";
  onClick?: ComponentProps<"button">["onClick"];
  disabled?: ComponentProps<"button">["disabled"];
  type?: ComponentProps<"button">["type"];
};

export const ButtonGroupButton = ({
  children,
  variant = "default",
  icon,
  iconSize = 20,
  onClick,
  disabled,
  type = "button",
}: ButtonGroupButtonProps) => {
  return (
    <button
      type={type}
      className={tw(styles.button, styles[variant])}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && <Icon icon={icon} size={iconSize} className={tw(styles.icon, styles[variant])} />}
      {children}
    </button>
  );
};
