import { useCallback } from "react";

import { EditorMessage, EditorStreamMessage } from "components/MessageEditor/types";
import { SaveDraftInput, SendMessageInput } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import { useSnackbar } from "providers/SnackbarProvider";

const limit = 12000;

type ActionType = "save" | "send";

type UseValidateMessage = {
  validateDraft: (message: SaveDraftInput, action: ActionType, showSnackbar?: boolean) => boolean;
  validateMessage: (
    message: EditorMessage | EditorStreamMessage,
    showSnackbar?: boolean
  ) => boolean;
  validateMessageToGlueAI: (
    message: SendMessageInput,
    action: ActionType,
    showSnackbar?: boolean
  ) => boolean;
};

const useValidateMessage = (): UseValidateMessage => {
  const { authData } = useAuthData();
  const { openSnackbar } = useSnackbar();

  const validateDraft = useCallback(
    (draft: SaveDraftInput, action: ActionType, showSnackbar = true) => {
      const text = draft.message.text.trim();

      if (text.length > limit) {
        openSnackbar(
          "error",
          `Message cannot have more than ${limit.toLocaleString()} characters.`,
          5000
        );
        return false;
      }

      const subject = draft.subject?.trim();
      const hasTextContent = !!subject || !!text;

      if (action === "save") {
        return hasTextContent;
      }

      if (!hasTextContent) {
        showSnackbar && openSnackbar("error", "Thread must have a subject or message.", 5000);
        return false;
      }

      const recipients = draft.recipients.filter(r => r !== authData?.me.id);
      if (recipients.length < 1) {
        showSnackbar && openSnackbar("error", "Thread must have at least one recipient", 5000);
        return false;
      }

      return true;
    },
    [authData?.me.id, openSnackbar]
  );

  const validateMessage = useCallback(
    (message: EditorMessage | EditorStreamMessage, showSnackbar = true) => {
      if (message.text.length > limit) {
        showSnackbar &&
          openSnackbar(
            "error",
            `Message cannot have more than ${limit.toLocaleString()} characters.`,
            5000
          );
        return false;
      }

      if (!message.text.trim() && !message.attachments.length) {
        showSnackbar && openSnackbar("error", "Message cannot be empty", 5000);
        return false;
      }

      return true;
    },
    [openSnackbar]
  );

  const validateMessageToGlueAI = useCallback(
    (input: SendMessageInput, action: ActionType, showSnackbar = true) => {
      const text = input.message.text.trim();

      if (text.length > limit) {
        openSnackbar(
          "error",
          `Message cannot have more than ${limit.toLocaleString()} characters.`,
          5000
        );
        return false;
      }

      const subject = input.thread?.subject?.trim();
      const hasTextContent = !!subject || !!text;

      if (action === "save") {
        return hasTextContent;
      }

      if (!hasTextContent) {
        showSnackbar && openSnackbar("error", "Thread must have a subject or message.", 5000);
        return false;
      }

      return true;
    },
    [openSnackbar]
  );

  return { validateDraft, validateMessage, validateMessageToGlueAI };
};

export default useValidateMessage;
