import { groupBy } from "lodash-es";
import { RefObject } from "react";

import { Recipient, ThreadEdgeSimple } from "@utility-types";
import Avatar from "components/design-system/Avatar/Avatar";
import { MessageFieldsFragment } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useAppStateStore from "store/useAppStateStore";
import isGlueAIRecipient from "utils/thread/isGlueAIRecipient";
import tw from "utils/tw";

import type { SearchResults } from "../types";
import type { ResultEdgeType } from "../types";

import { GroupResultItem, RecipientProfileLine, ResultItem } from "./ResultItem";
import ResultList from "./ResultList";
import { SelectedSearchResult } from "./SearchResults";
import { ThreadResultsItem } from "./ThreadResults";

type Props = {
  disableResults?: boolean;
  isReversed?: boolean;
  onClickResult?: (
    item: SelectedSearchResult,
    index: number,
    event: React.MouseEvent<HTMLDivElement | HTMLLIElement, MouseEvent>
  ) => void;
  results?: SearchResults["instantResults"];
  searching: boolean;
  selectedResultID?: string;
  selectedResultRef?: RefObject<HTMLLIElement>;
  setSelectedResultID?: (id: string) => void;
};

const MoreResultsThreadItem = ({
  index,
  lastMatchedMessage,
  onClickResult,
  result,
  selectedResultID,
  selectedResultRef,
  setSelectedResultID,
}: WithChildren<
  {
    index: number;
    lastMatchedMessage?: MessageFieldsFragment;
    result: ResultEdgeType<"threads">;
  } & Pick<
    Props,
    "onClickResult" | "selectedResultID" | "selectedResultRef" | "setSelectedResultID"
  >
>) => {
  const { authData } = useAuthData();

  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  const isSelected = selectedResultID === result.node.id;

  const otherRecipients =
    result.node?.recipients.edges.map(e => e.node).filter(r => r.id !== authData?.me.id) ?? [];

  const icon = result.node?.isPersistentChat
    ? "ChatRounded"
    : isGlueAIRecipient(otherRecipients)
      ? "SparkleFilled"
      : "Thread";

  return (
    <li ref={isSelected ? selectedResultRef : undefined}>
      <div
        className={tw("flex items-center justify-start px-16 h-56", "cursor-pointer select-none", {
          "!bg-accent-highlight/25": isSelected,
        })}
        onClick={e => onClickResult?.(lastMatchedMessage ?? result.node, index, e)}
        onFocus={() => setSelectedResultID?.(result.node.id)}
        onMouseEnter={() => setSelectedResultID?.(result.node.id)}
      >
        <Avatar
          background="transparent"
          className="text-icon-secondary"
          iconProps={{ icon }}
          margin="mr-8"
          size={breakpointMD ? "x-small" : "small"}
        />
        <ThreadResultsItem item={result} lastMatchedMessage={lastMatchedMessage} />
      </div>
    </li>
  );
};

const MoreResults = ({
  disableResults = false,
  isReversed,
  onClickResult,
  results,
  searching,
  selectedResultID,
  selectedResultRef,
  setSelectedResultID,
}: Props) => {
  const resultsTotal = results?.reduce((acc, results) => acc + results.edges.length, 0);

  if (!resultsTotal || searching) return null;

  return (
    <ResultList isReversed={isReversed} label="More results" searching={searching}>
      {results?.map(results => {
        switch (results.resultType) {
          case "threads":
            const messagesByThreadID = groupBy(
              results.matchedMessages?.edges?.map(e => e.node),
              m => m.threadID
            );

            return results.edges.map((edge, index) => {
              const matchedMessages = messagesByThreadID[edge.node.id] ?? [];
              const lastMatchedMessage = matchedMessages[matchedMessages.length - 1];

              return (
                <MoreResultsThreadItem
                  key={edge.node.id}
                  index={index}
                  lastMatchedMessage={lastMatchedMessage}
                  onClickResult={onClickResult}
                  result={edge as ThreadEdgeSimple}
                  selectedResultID={selectedResultID}
                  selectedResultRef={selectedResultRef}
                  setSelectedResultID={setSelectedResultID}
                />
              );
            });

          case "groups":
            return results.edges.map((edge, index) => (
              <GroupResultItem
                key={edge.node.id}
                disableResults={disableResults}
                edge={edge}
                index={index}
                onClickResult={onClickResult}
                selectedResultID={selectedResultID}
                selectedResultRef={selectedResultRef}
                setSelectedResultID={setSelectedResultID}
              />
            ));

          case "users":
            return results.edges.map((edge, index) => (
              <ResultItem
                key={edge.node.id}
                edge={edge}
                index={index}
                onClickResult={onClickResult}
                selectedResultID={selectedResultID}
                selectedResultRef={selectedResultRef}
                setSelectedResultID={setSelectedResultID}
              >
                <RecipientProfileLine recipient={edge.node as Recipient} />
              </ResultItem>
            ));
        }
      })}
    </ResultList>
  );
};

export default MoreResults;
