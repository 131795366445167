import type { Emoji as EmojiType } from "@emoji-mart/data";
import {
  FloatingArrow,
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import { animated, useTransition } from "@react-spring/web";
import noop from "lodash-es/noop";
import { useCallback, useState } from "react";
import { GetReactionsAPIResponse } from "stream-chat";

import unknownEmoji from "assets/icons-png/unknown-emoji.png";
import { hasEmoji } from "components/design-system/Emoji";
import useCustomReactionsData from "components/emoji/hooks/useCustomReactionsData";
import { Portal } from "components/Portal";
import useModalStore from "store/useModalStore";
import useSafeAreaInsetsStore from "store/useSafeAreaInsetsStore";
import { deviceHasMouse } from "utils/deviceHasMouse";
import tw from "utils/tw";

import AddReactionButton from "./AddReactionButton";
import { Reaction } from "./Reaction";
import ReactionsModal from "./ReactionsModal";
import ReactionTooltip from "./ReactionTooltip";

type Props = {
  allReactions: GetReactionsAPIResponse["reactions"];
  getAllReactions: () => Promise<GetReactionsAPIResponse | undefined>;
  handleReaction?: (emojiID: string) => void;
  isOwnReaction: (emojiID: string) => boolean;
  reactionCounts?: {
    [key: string]: number;
  } | null;
  reactions: string[];
  readonly?: boolean;
};

const ReactionListItems = ({
  allReactions,
  getAllReactions,
  handleReaction,
  isOwnReaction,
  reactionCounts,
  reactions,
  readonly = false,
}: Props) => {
  const [tooltip, setTooltip] = useState(false);
  const [arrowRef, setArrowRef] = useState<SVGSVGElement | null>(null);
  const hasMouse = deviceHasMouse();
  const padding = useSafeAreaInsetsStore.getState();

  const [currentTarget, setCurrentTarget] = useState("");

  const isTestEnv = process.env.NODE_ENV === "test";

  const { context, floatingStyles, refs } = useFloating({
    middleware: [
      offset(5),
      flip({
        padding,
      }),
      shift({
        padding,
      }),
      arrow({ element: arrowRef }),
    ],
    onOpenChange: setTooltip,
    open: !!currentTarget && tooltip,
    placement: "top",
    strategy: "fixed",
    whileElementsMounted: autoUpdate,
  });

  const { getFloatingProps, getReferenceProps } = useInteractions([
    useClick(context, {
      enabled: false,
    }),
    useDismiss(context),
    useHover(context, {
      delay: {
        close: 0,
        open: 500,
      },
    }),
    useRole(context, { role: "tooltip" }),
  ]);

  const transitions = useTransition(!!currentTarget && tooltip, {
    config: {
      friction: 100,
      mass: 2,
      tension: 2000,
    },
    enter: {
      opacity: 1,
      scale: 1,
    },
    from: {
      opacity: 0,
      scale: 0.75,
    },
    leave: {
      opacity: 0,
      scale: 0.75,
    },
  });

  const handleOnEmojiSelect = (emoji: EmojiType) => handleReaction?.(emoji.id || "");

  const handleReactionOnClick = (emojiID: string) => handleReaction?.(emojiID || "");

  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const handleOpenReactionsModal = useCallback(
    async (emojiID: string) => {
      openModal(
        <ReactionsModal
          getAllReactions={getAllReactions}
          openedWith={emojiID}
          reactionTypes={reactions}
        />
      );
    },
    [getAllReactions, openModal, reactions]
  );

  const { customReactions } = useCustomReactionsData(reactions);
  const customTarget = customReactions.get(currentTarget);

  const getCustomReaction = (emoji: string) => {
    const reaction = customReactions.get(emoji)?.imageURL;
    return typeof reaction === "string" && !reaction.length ? unknownEmoji : reaction;
  };

  return (
    <>
      <ul
        aria-label="Reactions List"
        className="flex flex-wrap pr-15 my-4 -mx-2 mr-16 max-w-full"
        data-testid="reactions-list"
        onPointerEnter={getAllReactions}
      >
        {reactions.map(emoji => (
          <li
            key={emoji}
            className="flex"
            {...(emoji === currentTarget && hasMouse
              ? getReferenceProps({
                  ref: refs.setReference,
                })
              : {})}
          >
            <Reaction
              count={reactionCounts?.[emoji] || 0}
              handleOpenReactionsModal={handleOpenReactionsModal}
              handleReaction={readonly ? noop : handleReactionOnClick}
              name={emoji}
              ownReaction={isOwnReaction(emoji)}
              setCurrentTarget={setCurrentTarget}
              url={!hasEmoji(emoji, true) ? getCustomReaction(emoji) : undefined}
              readonly={readonly}
            />
          </li>
        ))}
        <li className="flex">
          {readonly ? null : <AddReactionButton handleOnEmojiSelect={handleOnEmojiSelect} />}
        </li>
      </ul>

      <Portal id="overlays" zIndex="100">
        {transitions(
          (styles, open) =>
            open && (
              <animated.div
                className={tw(
                  "bg-background-body border-border-container border-thin py-5 px-10 rounded-md shadow-strong-ui text-footnote text-center",
                  "w-max max-w-[256px]"
                )}
                style={{
                  ...floatingStyles,
                  ...styles,
                }}
                {...getFloatingProps({
                  ref: refs.setFloating,
                })}
              >
                <ReactionTooltip
                  emoji={currentTarget}
                  emojiName={customTarget?.name ?? currentTarget}
                  emojiURL={customTarget?.imageURL}
                  reactions={allReactions.filter(r => r.type === currentTarget)}
                />

                {
                  // `!isTestEnv` prevents error when running tests,
                  // "NaN is an invalid value for the left css style property"
                  !isTestEnv && (
                    <FloatingArrow
                      ref={setArrowRef}
                      className="[&>path:first-of-type]:stroke-interactive-strong/15
              fill-background-modal"
                      context={context}
                      strokeWidth={0.5}
                    />
                  )
                }
              </animated.div>
            )
        )}
      </Portal>
    </>
  );
};

export default ReactionListItems;
