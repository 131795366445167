import { useRef } from "react";

import { GoBack, GoForward, HistoryButton } from "components/App/AppLayoutDesktop/AppHeader";
import { HelpMenu } from "components/App/Menus/HelpMenu";
import { ProfileMenu } from "components/App/Menus/ProfileMenu";
import WorkspaceMenu from "components/App/Menus/WorkspaceMenu";
import UpgradeButton from "components/SettingsModal/views/WorkspaceSettings/billing/UpgradeButton";
import QuickSearch from "components/views/search/QuickSearch";
import useElementBreakpoint from "hooks/useElementBreakpoint";
import useAppStateStore from "store/useAppStateStore";
import tw from "utils/tw";

/**
 * !!! WARNING !!!
 *
 * The widths of elements in this header are tightly coupled to the size and position of draggable elements
 * in the macOS app.
 *
 * Any changes here should be reflected in the macOS app. The draggable area is defined in the
 * `BridgeViewController.swift` file.
 */
const AppHeader = () => {
  const headerRef = useRef<HTMLDivElement>(null);
  const isWideView = useElementBreakpoint(headerRef, "wide");
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  return (
    <div id="app-header" ref={headerRef}>
      <div
        className={tw(
          "h-48 w-full",
          "flex items-center justify-between pl-[80px] select-none" // 80px is the width of the rail, and allows space for macOS window UI in the app
        )}
      >
        {/**
         * whatever adjustments are made to the content or width of adjacent DIV elements,
         * this DIV should remain centered, relative to the viewport
         */}
        <div
          className="flex grow min-w-0 items-center justify-center"
          data-testid="app-header-left"
        >
          <GoBack />
          <GoForward />
          <HistoryButton />
          <QuickSearch />
        </div>

        <div
          className={tw(
            "shrink-0",
            "flex items-center justify-end pl-16 pr-20 min-w-[136px] gap-8"
          )}
          data-testid="app-header-right"
        >
          <UpgradeButton isWideView={!!isWideView} />
          {breakpointMD && <HelpMenu />}
          <ProfileMenu />
          <WorkspaceMenu />
        </div>
      </div>
    </div>
  );
};

export default AppHeader;
