import { GroupEdge, GroupPreviewEdge } from "@utility-types/graphql";
import useMemberEdge from "hooks/useMemberEdge";
import { isMobile } from "utils/platform";

import useGroupApps from "./useGroupApps";

type Props = {
  groupEdge?: GroupEdge | GroupPreviewEdge;
  isAdmin?: boolean;
};

const useShowAppsOption = ({ groupEdge, isAdmin }: Props) => {
  const { memberEdge } = useMemberEdge(groupEdge);
  const group = memberEdge?.node;
  const persistentChatID = memberEdge?.persistentChatEdge.node.id;

  const appsAdded = group?.apps.edges;
  const { appsAvailable } = useGroupApps(group, persistentChatID);

  const showApps = !!(appsAdded?.length || (appsAvailable?.length && isAdmin)) && !isMobile();

  return showApps;
};

export default useShowAppsOption;
