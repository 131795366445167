import { last } from "lodash-es";
import { useMemo, useRef } from "react";
import { useHistory } from "react-router-dom";
import { UserResponse } from "stream-chat/dist/types/types";
import { StreamMessage, useChatContext } from "stream-chat-react";

import { GlueDefaultStreamChatGenerics } from "@utility-types";
import { HistoryState } from "components/Navigation/HistoryState";
import usePreviousRef from "hooks/usePreviousRef";

type Props<T extends GlueDefaultStreamChatGenerics> = {
  messages: StreamMessage<T>[];
  read?: Record<
    string,
    {
      last_read: Date;
      user: UserResponse<T>;
    }
  >;
  scrolledToEnd?: boolean;
};

// consider messages arriving just after we mount to be unread
const mountedUnreadWindow = 3 * 1000;

const useUnreadMarkLogic = <T extends GlueDefaultStreamChatGenerics>({
  messages,
  read,
  scrolledToEnd,
}: Props<T>): Date | undefined => {
  const { channel, client } = useChatContext();

  const history = useHistory<HistoryState>();
  const locationState = history.location.state?.historyLastRead || undefined;

  const lastMessage = last(messages);
  const mountedAt = useMemo(() => new Date(), []);
  const prevHasUnread = usePreviousRef((channel?.countUnread() || 0) > 0);
  const prevScrolledToEnd = usePreviousRef(scrolledToEnd);

  const readRef = useRef(read);
  readRef.current = read;

  const lastRead = useMemo<Date | undefined>(() => {
    if (!channel || !lastMessage?.id) return;

    if (locationState) return locationState;

    const hasUnread = (channel?.countUnread() || 0) > 0;
    const justMounted = +new Date() - +mountedAt < mountedUnreadWindow;
    if (
      hasUnread &&
      (justMounted || !prevScrolledToEnd.current || (prevHasUnread.current && !scrolledToEnd))
    ) {
      return readRef.current?.[client?.userID ?? ""]?.last_read;
    }
  }, [
    channel,
    client?.userID,
    lastMessage?.id,
    mountedAt,
    prevHasUnread,
    prevScrolledToEnd,
    locationState,
    scrolledToEnd,
  ]);

  // Persist unread line until user switches threads
  const savedLastRead = useRef<Date | undefined>();
  if (!savedLastRead.current) {
    savedLastRead.current = lastRead;
  }

  // Hide unread line when user replies
  if (lastMessage?.user?.id === client.userID) {
    savedLastRead.current = undefined;
  } else {
    return savedLastRead.current;
  }
};

export default useUnreadMarkLogic;
