import { useFetchGroupEdgeSimple } from "./group/useFetchGroupEdge";

const useWorkspaceID = (recipientID?: string) => {
  const { edge } = useFetchGroupEdgeSimple(recipientID);

  const workspaceID =
    edge?.__typename === "WorkspaceEdge" || edge?.__typename === "WorkspacePreviewEdge"
      ? edge.node.id
      : edge?.node.workspaceID; // GroupEdge or GroupPreviewEdge

  return workspaceID;
};

export default useWorkspaceID;
