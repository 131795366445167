import { useLayoutEffect } from "react";
import { Panel, PanelGroup } from "react-resizable-panels";
import { matchPath, useHistory, useLocation } from "react-router";

import { CreateMenuButton } from "components/design-system/ui/CreateMenu";
import JoinRequest from "components/design-system/ui/JoinRequest/JoinRequest";
import GroupProfilePane from "components/design-system/ui/ProfilePane/GroupProfilePane";
import UserProfilePane from "components/design-system/ui/ProfilePane/UserProfilePane";
import { RoutingPartition } from "components/routing/RoutingPartition";
import {
  GroupTabs,
  PathMatch,
  PathnameParams,
  SuperTab,
  routeParams as getRouteParams,
  useRoutePartition,
} from "components/routing/utils";
import ThreadPane from "components/threads/ThreadPane";
import ActivityDesktop from "components/views/activity/ActivityDesktop";
import AIThreadPane from "components/views/ai/AIMain/AIThreadPane";
import AISidebarDesktop from "components/views/ai/AISidebarDesktop";
import AICompose from "components/views/ai/AIViews/AICompose";
import ComposeDM from "components/views/dms/ComposeDM";
import DirectMessage from "components/views/dms/DirectMessage";
import DirectMessagesSidebarDesktop from "components/views/dms/DirectMessagesSidebarDesktop";
import EmptyView from "components/views/EmptyView";
import FeedViewDesktop from "components/views/feed/FeedViewDesktop";
import Group from "components/views/groups/Group";
import GroupsDirectory from "components/views/groups/GroupsDirectory/GroupsDirectory";
import GroupsSidebarDesktop from "components/views/groups/GroupsSidebarDesktop";
import InboxSidebarDesktop from "components/views/inbox/InboxSidebarDesktop";
import InboxApps from "components/views/inbox/InboxViews/InboxApps";
import InboxCompose from "components/views/inbox/InboxViews/InboxCompose";
import InboxJoinRequests from "components/views/inbox/InboxViews/InboxJoinRequests";
import DeepSearch from "components/views/search/deep-search/DeepSearch";
import ThreadsSidebarDesktop from "components/views/threads/ThreadsSidebarDesktop";
import tw from "utils/tw";

import AppMainTabs from "./AppMainTabs";
import AppPanelResizeHandle from "./AppPanelResizeHandle";
import SplitView from "./SplitView";

const AppMainSidebar = ({
  children,
  defaultWidth = "w-350",
}: WithChildren<{ panelId?: string; defaultWidth?: "w-350" | "w-540" }>) => {
  return (
    <>
      <Panel
        className={tw(
          "relative rounded-l-lg shrink-0 select-none",
          "flex flex-col h-full min-h-0 min-w-[240px] max-w-[540px]",
          defaultWidth
        )}
        defaultSize={defaultWidth === "w-540" ? 30 : 25}
        minSize={defaultWidth === "w-540" ? 20 : 15}
        style={{ overflow: "unset" }} // fixes unsightly Safari subpixel rendering glitch
        data-testid="app-main-sidebar"
        id="app-main-sidebar"
        order={0}
      >
        {children}
      </Panel>
      <AppPanelResizeHandle />
    </>
  );
};

const AppMainBody = ({ children }: WithChildren) => (
  <Panel
    className="relative flex grow min-w-[488px] h-full overflow-hidden" // 448px is the minimum width of two panes
    data-testid="app-main-body"
    id="app-main-body"
    order={1}
    minSize={63} // 63% is approx min width % of 488px at md (768px)
  >
    {children}
  </Panel>
);

const Inbox = () => {
  const location = useLocation();

  const view = () => {
    const { pathname, search } = location;
    const { d, v } = getRouteParams({ pathname, search });
    const params = matchPath<PathnameParams>(pathname, PathMatch.inbox)?.params;

    const primaryPane = () => {
      if (v === "compose-dm") {
        return <ComposeDM />;
      }
      if (v === "directory") {
        return <GroupsDirectory />;
      }
      if (params?.recipientID) {
        return <Group recipientID={params.recipientID} />;
      }
      if (params?.threadID?.startsWith("dft_")) {
        return <InboxCompose draftID={params.threadID} />;
      }
      if (params?.threadID || params?.userID) {
        return (
          <ThreadPane messageID={params.messageID} threadID={params.threadID || params.userID} />
        );
      }
      if (params?.appID) {
        return <InboxApps appID={params.appID} />;
      }

      const viewParams = matchPath<PathnameParams>(pathname, PathMatch.inboxViews)?.params;

      switch (viewParams?.view) {
        case "compose":
          return <InboxCompose />;
        case "requests":
          return <InboxJoinRequests />;
      }

      return <EmptyView />;
    };

    return (
      <SplitView {...params} d={d}>
        {primaryPane()}
      </SplitView>
    );
  };

  return (
    <>
      <AppMainSidebar>
        <InboxSidebarDesktop />
      </AppMainSidebar>
      <AppMainBody>{view()}</AppMainBody>
    </>
  );
};

const GlueAI = () => {
  const location = useLocation();

  const view = () => {
    const { pathname, search } = location;
    const { d } = getRouteParams({ pathname, search });

    const params = matchPath<PathnameParams>(pathname, PathMatch.ai)?.params;
    let primaryPane: React.ReactNode | undefined;

    if (params?.threadID || params?.userID) {
      primaryPane = (
        <AIThreadPane messageID={params.messageID} threadID={params?.threadID || params?.userID} />
      );
    } else {
      const viewParams = matchPath<PathnameParams>(pathname, PathMatch.aiViews)?.params;

      switch (viewParams?.view) {
        case "compose":
          primaryPane = <AICompose />;
          break;
      }
    }

    return (
      <SplitView {...params} d={d}>
        {primaryPane ?? <AICompose />}
      </SplitView>
    );
  };

  return (
    <>
      <AppMainSidebar>
        <AISidebarDesktop />
      </AppMainSidebar>
      <AppMainBody>{view()}</AppMainBody>
    </>
  );
};

const Feed = () => {
  const location = useLocation();
  const { pathname, search } = location;
  const { d } = getRouteParams({ pathname, search });

  const view = () => {
    return (
      <SplitView d={d} rounded="all">
        <FeedViewDesktop />
      </SplitView>
    );
  };

  return <AppMainBody>{view()}</AppMainBody>;
};

const Groups = () => {
  const location = useLocation();
  const { pathname, search } = location;
  const { d, v } = getRouteParams({ pathname, search });

  const view = () => {
    if (v === "directory") {
      return (
        <SplitView d={d} rounded="all">
          <GroupsDirectory />
        </SplitView>
      );
    }

    const exploreParams = matchPath<PathnameParams>(pathname, PathMatch.groupsExplore)?.params;
    if (exploreParams) {
      return (
        <SplitView d={d}>
          <Group defaultTab={GroupTabs.Chat} recipientID={"groups"} {...exploreParams} />
        </SplitView>
      );
    }

    const groupParams = matchPath<PathnameParams>(pathname, PathMatch.groups)?.params;
    if (groupParams?.recipientID) {
      return (
        <SplitView d={d}>
          <Group
            defaultTab={GroupTabs.Chat}
            recipientID={groupParams.recipientID}
            {...groupParams}
          />
        </SplitView>
      );
    }
  };

  return (
    <>
      {v !== "directory" && (
        <AppMainSidebar>
          <GroupsSidebarDesktop />
        </AppMainSidebar>
      )}
      <AppMainBody>{view()}</AppMainBody>
    </>
  );
};

const Threads = () => {
  const location = useLocation();
  const { pathname, search } = location;
  const { d } = getRouteParams({ pathname, search });

  const view = () => {
    const params = matchPath<PathnameParams>(pathname, PathMatch.threads)?.params;

    return <SplitView {...params} d={d} />;
  };

  return (
    <>
      <AppMainSidebar defaultWidth="w-540">
        <ThreadsSidebarDesktop />
      </AppMainSidebar>
      <AppMainBody>{view()}</AppMainBody>
    </>
  );
};

const DirectMessages = () => {
  const location = useLocation();
  const { pathname, search } = location;
  const { d, messageID } = getRouteParams({ pathname, search });

  const view = () => {
    const params = matchPath<PathnameParams>(pathname, PathMatch.dms)?.params;
    return (
      <SplitView {...params} d={d}>
        <DirectMessage messageID={messageID} userID={params?.userID} />
      </SplitView>
    );
  };

  return (
    <>
      <AppMainSidebar defaultWidth="w-540">
        <DirectMessagesSidebarDesktop />
      </AppMainSidebar>
      <AppMainBody>{view()}</AppMainBody>
    </>
  );
};

const Activity = () => {
  const location = useLocation();

  const view = () => {
    const { pathname, search } = location;
    const { d } = getRouteParams({ pathname, search });

    const params = matchPath<PathnameParams>(pathname, PathMatch.activity)?.params;

    if (params?.threadID) {
      return (
        <SplitView d={d}>
          <ThreadPane messageID={params?.messageID} threadID={params?.threadID} />
        </SplitView>
      );
    }

    if (params?.joinID) {
      return (
        <SplitView d={d}>
          <JoinRequest joinID={params?.joinID} />
        </SplitView>
      );
    }

    if (params?.recipientID) {
      return (
        <SplitView d={d}>
          <GroupProfilePane groupID={params?.recipientID} />
        </SplitView>
      );
    }

    if (params?.userID) {
      return (
        <SplitView d={d}>
          <UserProfilePane userID={params?.userID} />
        </SplitView>
      );
    }

    return <SplitView {...params} d={d} />;
  };

  return (
    <>
      <AppMainSidebar defaultWidth="w-540">
        <ActivityDesktop />
      </AppMainSidebar>
      <AppMainBody>{view()}</AppMainBody>
    </>
  );
};

const SearchView = () => {
  const location = useLocation();
  const params = getRouteParams(location);

  return (
    <SplitView rounded="all" {...params}>
      <DeepSearch />
    </SplitView>
  );
};

const RedirectHome = () => {
  const history = useHistory();

  useLayoutEffect(() => {
    history.replace("/inbox");
  }, [history]);

  return null;
};

const AppMainPartitions = ({ active }: { active: SuperTab | undefined }) => (
  <>
    <RoutingPartition isActive={active === "inbox"}>
      <Inbox />
    </RoutingPartition>

    <RoutingPartition isActive={active === "ai"}>
      <GlueAI />
    </RoutingPartition>

    <RoutingPartition isActive={active === "feed"}>
      <Feed />
    </RoutingPartition>

    <RoutingPartition isActive={active === "groups"}>
      <Groups />
    </RoutingPartition>

    <RoutingPartition isActive={active === "threads"}>
      <Threads />
    </RoutingPartition>

    <RoutingPartition isActive={active === "dms"}>
      <DirectMessages />
    </RoutingPartition>

    <RoutingPartition isActive={active === "activity"}>
      <Activity />
    </RoutingPartition>

    <RoutingPartition
      isActive={active === "search"}
      unmountInactive={true} // we should always unmount this when not active
    >
      <SearchView />
    </RoutingPartition>

    <RoutingPartition isActive={!active}>
      <RedirectHome />
    </RoutingPartition>
  </>
);

const AppMain = () => {
  const { superTab } = useRoutePartition();

  const panelGroup =
    superTab === "threads" || superTab === "dms" || superTab === "activity"
      ? "wide-items"
      : "narrow-items";

  return (
    <PanelGroup
      autoSaveId={`main:${panelGroup}`}
      className="flex grow min-w-0 overflow-hidden w-full z-0"
      direction="horizontal"
      id="app-main"
    >
      <div className="relative flex flex-col items-center w-80 min-h-0 shrink-0">
        <AppMainTabs />

        <div className="absolute bottom-24">
          <CreateMenuButton />
        </div>
      </div>

      <AppMainPartitions active={superTab} />
    </PanelGroup>
  );
};

export default AppMain;
