import ViewScrollContainer from "components/design-system/ui/ViewScrollContainer";
import { useEducationWelcomeCard } from "components/Education";
import { EducationCard } from "components/Education/EducationCard";
import GroupChip from "components/group/GroupChip";
import WorkspacesList from "components/SideBar/Groups/WorkspacesList";
import Sidebar from "components/SideBar/Sidebar";
import DirectoryButton from "components/SideBar/SidebarHeader/DirectoryButton";
import SidebarHeader from "components/SideBar/SidebarHeader/SidebarHeader";
import useOnce from "hooks/useOnce";
export const SIDEBAR_ID = "mega-tab-sidebar--groups";

const GroupsSidebarDesktop = () => {
  const { showCampaign, onWelcomeCardViewed, onWelcomeCardDismissed } =
    useEducationWelcomeCard("groups");

  useOnce(() => {
    onWelcomeCardViewed();
  });

  return (
    <Sidebar id={SIDEBAR_ID}>
      <SidebarHeader buttons={<DirectoryButton label="Directory" />} title="Groups" />

      <ViewScrollContainer
        accessories={
          showCampaign ? (
            <div className="absolute left-1/2 -translate-x-1/2 z-1 bottom-0 w-full pt-8 pb-16 px-16 pointer-events-none">
              <div className="mx-auto pointer-events-auto">
                <EducationCard
                  header="Groups"
                  onDismiss={onWelcomeCardDismissed}
                  body={
                    <>
                      <div className="mb-24">
                        Create groups to organize people around teams or topics.
                      </div>
                      <div className="flex flex-col items-center">
                        <div className="flex flex-col gap-4 items-center mx-4">
                          <div className="flex flex-row gap-4">
                            <GroupChip emoji="🧑‍💻" name="Development" />
                            <GroupChip emoji="🎉" name="Fun" />
                          </div>
                          <div>
                            <GroupChip emoji="🎨" name="Design" />
                          </div>
                        </div>
                      </div>
                    </>
                  }
                />
              </div>
            </div>
          ) : undefined
        }
        className="pt-8 px-8"
      >
        <WorkspacesList />
      </ViewScrollContainer>
    </Sidebar>
  );
};

export default GroupsSidebarDesktop;
