import { useRef, useState } from "react";
import { FieldValues, Path, PathValue, useFormContext, useWatch } from "react-hook-form";

import { formatNameEmoji } from "utils/formatNameEmoji";
import tw from "utils/tw";

import { EmojiListWrapper } from "../EmojiList";

import { TextInput } from "./TextInput";
import { TextInputProps } from "./types";

type EditableHeaderInputProps<TFieldValues extends FieldValues> = Pick<
  TextInputProps<TFieldValues>,
  "name"
> & {
  canEdit: boolean;
  onSubmit: () => void;
  onReset: () => void;
};

const EditableHeaderInput = <TFieldValues extends FieldValues>({
  name,
  canEdit,
  onSubmit,
  onReset,
}: EditableHeaderInputProps<TFieldValues>) => {
  const [editing, setEditing] = useState(false);
  const { setValue } = useFormContext<TFieldValues>();
  const value = useWatch<TFieldValues>({ name });
  const inputRef = useRef<HTMLInputElement | null>(null);

  const textClasses = "!text-headline-bold !text-primary";

  return (
    <EmojiListWrapper className="w-full">
      <TextInput
        name={name}
        disabled={!canEdit}
        inputRef={inputRef}
        className={tw(
          "border-none !pl-4 !py-0 rounded-none bg-transparent disabled:bg-transparent",
          textClasses
        )}
        config={{ required: true }}
        wrapperClassName="!my-0"
        onBlur={() => {
          setEditing(false);
          setValue(
            name,
            formatNameEmoji({ name: value?.trim?.() }).nameWithEmoji as PathValue<
              TFieldValues,
              Path<TFieldValues>
            >
          );
          onSubmit();
        }}
        onFocus={() => {
          setEditing(true);
        }}
        onKeyDown={e => {
          if (e.key === "Enter") {
            e.stopPropagation();
            inputRef.current?.blur();
          }
          if (e.key === "Escape") {
            e.stopPropagation();
            onReset();
            setTimeout(() => inputRef.current?.blur(), 0);
          }
        }}
        icon={canEdit ? (editing ? "Check" : "EditSimple") : undefined}
        iconSize={20}
        iconPosition="trailing"
        iconClassName="!text-icon-subtle hover:!text-icon-subtle-hover"
        iconTooltip={editing ? "Finish editing" : "Edit thread title"}
        iconTooltipStyle="inverted"
        iconTooltipPlacement="bottom"
        iconOnClick={
          editing
            ? e => {
                e.preventDefault();
                inputRef.current?.blur();
              }
            : e => {
                e.preventDefault();
                inputRef.current?.focus();

                // if the user blurs the input when the cursor is not at the end, subsequent clicks
                // will focus at the last cursor location, not the end of the text. This sets the cursor
                // to the end of the text. The timeout allows the input to be focused before the scroll
                // is set.
                setTimeout(() => {
                  if (inputRef.current) {
                    inputRef.current.setSelectionRange(value.length, value.length);
                    inputRef.current.scrollLeft = inputRef.current.scrollWidth;
                  }
                }, 0);
              }
        }
      />
    </EmojiListWrapper>
  );
};

export default EditableHeaderInput;
