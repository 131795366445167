import { WorkspaceAppEdge } from "@utility-types";
import { useWorkspaceAppsQuery } from "generated/graphql-operations";
import useAuthData from "hooks/useAuthData";
import env from "utils/processEnv";

export type WorkspaceAction = {
  actionName: string;
  appID: string;
  avatarUrl: string;
  description: string;
  label: string;
  path: string | null;
  threadID: string;
  workspaceID: string;
};

type WorkspaceActions = {
  actions: WorkspaceAction[];
  title: string;
};

const useAppsThreadActions = ({ threadID }: { threadID?: string }) => {
  const { authReady } = useAuthData();
  const { data } = useWorkspaceAppsQuery({ fetchPolicy: authReady ? "cache-first" : "cache-only" });

  const apps = data?.workspaces.edges.flatMap(workspaces =>
    workspaces.node.apps.edges.filter(app => app.node.threadActions)
  );

  if (!apps || !threadID) return [];

  const getWorkspaceName = (workspaceID: string) =>
    data?.workspaces.edges.filter(workspace => workspace.node.id === workspaceID)[0]?.node.name;

  const parseActions = (app: WorkspaceAppEdge) =>
    app.node.threadActions?.map(action => ({
      actionName: action.name,
      appID: app.node.id,
      avatarUrl: app.node.avatarURL,
      description: action.description,
      label: action.label,
      path: action.path,
      threadID: threadID,
      workspaceID: app.workspaceID,
    })) ?? [];

  const groupActionsByWorkspace = (
    apps: WorkspaceAppEdge[],
    getWorkspaceName: (id: string) => string | undefined
  ): WorkspaceActions[] => {
    let glueAIApp: WorkspaceAppEdge | undefined;
    const appsByWorkspace: Record<string, WorkspaceAppEdge[]> = {};
    apps.forEach(app => {
      if (app.node.id === env.glueAIAppID) {
        glueAIApp = app;
      } else {
        appsByWorkspace[app.workspaceID] = [...(appsByWorkspace[app.workspaceID] ?? []), app];
      }
    });
    const workspaceActions: WorkspaceActions[] = [];
    // Glue AI should always be in the global thread actions
    if (glueAIApp) {
      workspaceActions.push({
        actions: parseActions(glueAIApp),
        title: "Thread actions",
      });
    }
    const workspaceIds: string[] = Object.keys(appsByWorkspace);
    if (workspaceIds.length === 1) {
      // If there is only one workspace, put all actions in the global section
      const actions = (appsByWorkspace[workspaceIds[0] as string] || []).map(parseActions);
      // If the global section isn't already added, add it
      let globalSection = workspaceActions[0];
      if (!globalSection) {
        globalSection = {
          actions: [],
          title: "Thread actions",
        };
        workspaceActions.push(globalSection);
      }
      globalSection.actions.push(...actions.flat());
    } else if (workspaceIds.length > 1) {
      // If there are multiple workspaces, put each workspace's actions in its own section
      workspaceIds.forEach(workspaceId => {
        const actions = (appsByWorkspace[workspaceId] || []).map(parseActions);
        workspaceActions.push({
          actions: actions.flat(),
          title: getWorkspaceName(workspaceId) ?? "Unknown workspace",
        });
      });
    }
    return workspaceActions;
  };

  return groupActionsByWorkspace(apps, getWorkspaceName);
};

export default useAppsThreadActions;
