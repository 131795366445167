import { useMemo, useState } from "react";

import { nodeAs } from "@utility-types";
import Avatar from "components/design-system/Avatar/Avatar";
import Icon from "components/design-system/icons/Icon";
import { useFetchAppQuery, useFetchWorkspaceQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import tw from "utils/tw";

import { ConsentSubsection, filterConsentSectionsForDisplay } from "./consentSections";

const ConsentContent = (props: {
  appID: string;
  scopes: string[];
  workspaceID: string;
}) => {
  const { authReady } = useAuthData();
  const { data: appData } = useFetchAppQuery({
    fetchPolicy: authReady ? "cache-first" : "cache-only",
    variables: {
      id: props.appID,
    },
  });

  const { data: workspaceData } = useFetchWorkspaceQuery({
    fetchPolicy: authReady ? "cache-first" : "cache-only",
    variables: {
      id: props.workspaceID,
    },
  });

  const app = nodeAs(appData?.node, ["App"]);
  const workspace = nodeAs(workspaceData?.node, ["Workspace"]);

  const consentSections = useMemo(() => {
    if (!app) {
      return null;
    }
    return filterConsentSectionsForDisplay(props.scopes, app);
  }, [props.scopes, app]);

  const [expandedSubsections, setExpandedSubsections] = useState<Record<string, boolean>>({});

  const handleToggleSubsection = (subsection: ConsentSubsection) => {
    setExpandedSubsections({
      ...expandedSubsections,
      [subsection.id]: !expandedSubsections[subsection.id],
    });
  };

  if (!app || !workspace || !consentSections?.length) return null;

  return (
    <div className="flex flex-col">
      <div className="flex justify-center items-center space-x-4 mt-40">
        <Avatar avatarURL={app?.avatarURL} name={app?.name ?? ""} rounded={false} size="x-large" />
        <Icon icon="ChevronLeft" size={20} />
        <Icon icon="ChevronRight" size={20} />
        <Avatar
          avatarURL={workspace?.avatarURL}
          name={workspace?.name ?? ""}
          rounded={false}
          size="x-large"
        />
      </div>
      <span className="font-semibold text-[18px] text-center my-24">
        {app.name} is requesting permission to access the {workspace.name} workspace
      </span>
      {consentSections.map(section => (
        <div key={section.label}>
          <div className="my-8">
            <span className="font-semibold text-[16px]">{section.label}</span>
          </div>
          {section.subsections.map(subsection => (
            <div
              key={subsection.label}
              className="flex flex-col"
              onClick={() => {
                handleToggleSubsection(subsection);
              }}
            >
              <div className="flex cursor-pointer items-center my-8 none">
                <Icon icon="ChatRounded" size={20} />
                <span className="ml-8 flex-grow">{subsection.label}</span>
                <Icon
                  icon={expandedSubsections[subsection.id] ? "ChevronDown" : "ChevronRight"}
                  size={20}
                />
              </div>
              {subsection.data.map(datum =>
                datum.details.map(detail => (
                  <div
                    key={`${subsection.label}-detail-${detail}`}
                    className={tw("subsection-detail ml-40 my-8", {
                      hidden: !expandedSubsections[subsection.id],
                    })}
                  >
                    <span>{detail}</span>
                  </div>
                ))
              )}
              <hr className="h-0 border-t-1 border-t-border-container" />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default ConsentContent;
