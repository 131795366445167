import { TWMargin } from "components/design-system/types/margin";
import { VariantPropsOf, variantProps } from "components/helper/classNameVariants";

const className = {
  base: "capitalize inline-flex px-4 py-2 rounded-sm text-caption-bold",
  defaultVariants: {
    type: "default" as const,
  },
  variants: {
    type: {
      default: "",
      paid: "bg-background-success text-background-action-inverse hover:bg-background-success-hover",
      scheduled:
        "bg-background-warning text-text-warning hover:bg-background-warning-hover hover:text-text-warning-hover",
      unpaid:
        "bg-background-alert-strong text-background-action-inverse hover:bg-background-alert-strong-hover",
    },
  },
};

const badgeProps = variantProps(className);

type BadgeVariantProps = VariantPropsOf<typeof badgeProps>;

const Badge = ({ margin, type }: { margin?: TWMargin } & BadgeVariantProps) => {
  return <div {...badgeProps({ className: margin, type })}>{type}</div>;
};

export default Badge;
