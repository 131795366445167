import { ComponentProps, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useAbortController } from "use-abort-controller-hook";
import { useDebouncedCallback } from "use-debounce";

import Avatar from "components/design-system/Avatar/Avatar";
import { EmojiSheet } from "components/design-system/EmojiSheet";
import { InputButton, TextInput } from "components/design-system/Forms";
import { Icon } from "components/design-system/icons";
import { MagicLoading } from "components/MessageEditor/components/MagicLoading";
import { useEmojiSuggestionLazyQuery } from "generated/graphql";
import usePrevious from "hooks/usePrevious";
import tw from "utils/tw";

const hasValidCharacters = /[\p{L}\p{N}]/u;

type Props = {
  formSubmitting: boolean;
  inputVariant?: ComponentProps<typeof TextInput>["variant"];
  inputPlaceholder?: string;
};

const NameAndEmojiInputs = ({
  formSubmitting,
  inputVariant,
  inputPlaceholder = "Group name",
}: Props) => {
  const {
    watch,
    setValue,
    clearErrors,
    formState: { touchedFields },
  } = useFormContext();
  const { emoji, name } = watch();
  const prevName = usePrevious(name);

  const abortController = useAbortController();
  const [fetchEmojiSuggestion] = useEmojiSuggestionLazyQuery();
  const [emojiLoading, setEmojiLoading] = useState(false);
  const [suggestedEmoji, setSuggestedEmoji] = useState<string | undefined>();
  const firstRenderRef = useRef(true);

  const debouncedEmojiSuggestion = useDebouncedCallback(name => {
    (firstRenderRef.current || !emoji) && setEmojiLoading(true);
    fetchEmojiSuggestion({
      context: { fetchOptions: abortController },
      fetchPolicy: "no-cache",
      variables: { name },
    })
      .then(({ data }) => {
        setSuggestedEmoji(data?.emojiSuggestion.emoji ?? undefined);
        !emoji &&
          setValue("emoji", data?.emojiSuggestion.emoji || "", {
            shouldDirty: true,
            shouldTouch: false,
          });
      })
      .catch(err => {
        console.warn("Error: [emojiSuggestion] - ", err);
      })
      .finally(() => {
        setEmojiLoading(false);
      });
  }, 350);

  useEffect(() => {
    if (!firstRenderRef.current && prevName === name) return;

    firstRenderRef.current = false;

    abortController.abort();

    if (!name) {
      debouncedEmojiSuggestion.cancel();
      setEmojiLoading(false);
      setSuggestedEmoji(undefined);
      setValue("emoji", "");
      return;
    }

    debouncedEmojiSuggestion(name);
  }, [abortController, debouncedEmojiSuggestion, emoji, name, prevName, setValue]);

  const resetForm = () => {
    setValue("emoji", "", { shouldDirty: true });
    setValue("name", "", { shouldDirty: true });
    clearErrors("name");
  };

  return (
    <>
      <TextInput name="emoji" type="hidden" wrapperClassName="!m-0" disabled={formSubmitting} />
      <div className="flex grow shrink-0">
        <EmojiSheet
          onEmojiSelect={e => setValue("emoji", e.native ?? "❓", { shouldDirty: true })}
          suggestedEmoji={suggestedEmoji}
        >
          <InputButton
            className={tw(
              "flex justify-center border border-border-container rounded-lg w-40 h-40 p-12",
              { "!p-2": emoji }
            )}
            disabled={formSubmitting}
          >
            {emojiLoading ? (
              <MagicLoading />
            ) : touchedFields.emoji ? (
              emoji ? (
                <Avatar emojiProps={{ emoji, size: 20 }} background="transparent" />
              ) : (
                <Icon className="text-icon-secondary" icon="Reaction" size={16} />
              )
            ) : emoji ? (
              <Avatar emojiProps={{ emoji, size: 20 }} background="transparent" />
            ) : (
              <Icon className="text-icon-secondary" icon="Reaction" size={16} />
            )}
          </InputButton>
        </EmojiSheet>
        <TextInput
          className="bg-transparent"
          config={{
            required: true,
            pattern: {
              value: hasValidCharacters,
              message: "Group name must contain at least one letter or number",
            },
          }}
          name="name"
          variant={inputVariant}
          placeholder={inputPlaceholder}
          wrapperClassName="!my-0 ml-12 grow"
          icon={emoji || name ? "Close" : undefined}
          iconPosition="end"
          iconOnClick={resetForm}
          disabled={formSubmitting}
        />
      </div>
    </>
  );
};

export default NameAndEmojiInputs;
