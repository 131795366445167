import { useState } from "react";

import CodeEditor from "components/CodeEditor/CodeEditor";
import { Button } from "components/design-system/Button";
import { ModalProps } from "components/ModalKit/Modal";
import { LambdaFullScreen } from "components/Modals/LambdaFullScreen";
import { LambdaFieldsFragment, LambdasDocument, useUpdateLambdaMutation } from "generated/graphql";
import useLocalSettingsStore from "store/useLocalSettingsStore";
import useModalStore from "store/useModalStore";

type Props = ModalProps & {
  lambda: LambdaFieldsFragment;
};

export const LambdaFullScreenModal = ({ lambda, ...props }: Props) => {
  const { colorSchemeActive: theme } = useLocalSettingsStore(({ colorSchemeActive }) => ({
    colorSchemeActive,
  }));

  const { closeModal } = useModalStore(({ closeModal }) => ({
    closeModal,
  }));

  const [codeEditorValue, setCodeEditorValue] = useState(lambda.source);

  const handleCodeEditorChange = (newValue: string) => {
    setCodeEditorValue(newValue);
  };

  const [updateLambda] = useUpdateLambdaMutation();

  const handleSaveSource = async () => {
    await updateLambda({
      refetchQueries: [LambdasDocument],
      variables: {
        description: lambda.description,
        event: lambda.event,
        id: lambda.id,
        name: lambda.name,
        source: codeEditorValue,
      },
    });

    closeModal();
  };

  return (
    <LambdaFullScreen {...props} contentClassName="">
      <div className="p-16">
        <Button onClick={handleSaveSource} type="button">
          Save & Close
        </Button>
      </div>
      <div className="bg-white w-full h-screen">
        <div className="flex ml-3">
          <div className="bg-red-100 w-1/2 h-screen">
            <CodeEditor
              className="h-screen"
              height="100% !important"
              minHeight="100% !important"
              minWidth="100% !important"
              onChange={handleCodeEditorChange}
              placeholder="Glue it all together with JavaScript"
              theme={theme}
              value={lambda.source}
              width="100% !important"
            />
          </div>
          <div className="bg-blue-100 w-1/2">right</div>
        </div>
      </div>
    </LambdaFullScreen>
  );
};
