import { FieldPath, FieldValues, useFormContext } from "react-hook-form";

import useAuthData from "hooks/useAuthData";

import { GroupVisibilityRadioGroup } from "../GroupVisibilityRadioGroup";

type GroupVisibilityProps<TFieldValues extends FieldValues> = {
  name: FieldPath<TFieldValues>;
};

const GroupVisibility = <TFieldValues extends FieldValues>({
  name,
}: GroupVisibilityProps<TFieldValues>) => {
  const { authData } = useAuthData();
  const { watch } = useFormContext<TFieldValues>();
  const { workspaceID } = watch();
  const selectedWorkspace = authData?.workspaces.edges.find(w => w.node.id === workspaceID)?.node
    .name;
  return (
    <>
      <span className="text-subhead-bold text-text-secondary">
        Choose the visibility for this group in {selectedWorkspace ?? "this workspace"}:
        <GroupVisibilityRadioGroup name={name} />
        <div className="h-70" />
      </span>
    </>
  );
};

export default GroupVisibility;
