import { ComponentProps } from "react";

import { RecipientWithPreviews, ThreadPreview } from "@utility-types";
import Icon from "components/design-system/icons/Icon";
import { ProfileAvatar } from "components/ProfileItem";
import { routeToGroup, routeToThread, routeToUser } from "components/routing/utils";
import { formatGroupName } from "utils/group/formatGroupName";

import ProfileItemPrimitive from "./ProfileItemPrimitive";
type Props = {
  recipient?: RecipientWithPreviews | ThreadPreview;
};

type ProfileAvatarProps = ComponentProps<typeof ProfileAvatar>;
type AvatarProps = Partial<Omit<ProfileAvatarProps, "type">> & Pick<ProfileAvatarProps, "type">;

type IconType = ComponentProps<typeof Icon>["icon"];

const ProfileItem = ({ recipient }: Props) => {
  if (!recipient) return null;

  let title = "";
  let subtitle: string | null | undefined = "";
  let secondSubtitle: string | null | undefined = "";
  let avatarProps: AvatarProps | undefined = undefined;
  let icon: IconType | undefined = undefined;
  let route: string | undefined = undefined;

  const membersLabel = (count = 0) => `${count} ${count > 1 ? "members" : "member"}`;

  switch (recipient.__typename) {
    case "User":
      title = recipient.name;
      subtitle = recipient.bio;
      secondSubtitle = recipient.addressDomains?.[0];
      avatarProps = {
        avatarURL: recipient.avatarURL,
        name: recipient.name,
        type: "User",
      };
      route = routeToUser({ to: "secondary", userID: recipient.id });
      break;
    case "Group":
    case "GroupPreview":
      const { emoji, name } = formatGroupName({
        name: recipient.name,
      });
      title = name;
      subtitle = membersLabel(recipient.members?.totalCount);
      avatarProps = {
        emoji,
        emojiClassName: "text-[40px]",
        name,
        type: "Group",
      };
      route = routeToGroup({ to: "secondary", groupID: recipient.id });
      break;
    case "Workspace":
    case "WorkspacePreview":
      title = recipient.name;
      subtitle = recipient.addressDomains?.length ? recipient.addressDomains[0] : undefined;
      secondSubtitle = membersLabel(recipient.members?.totalCount);
      avatarProps = {
        avatarURL: recipient.avatarURL,
        name: recipient.name,
        type: "Workspace",
        useAvatar: true,
      };
      route = routeToGroup({ groupID: recipient.id, to: "secondary" });
      break;
    case "ThreadPreview":
      const groups = recipient.recipients.edges.filter(e => e.node.__typename === "GroupPreview");
      title = recipient.subject;
      subtitle = groups ? groups.map(g => g.node.name).join() : undefined;
      icon = "Thread";
      route = routeToThread({ threadID: recipient.id, to: "secondary" });
      break;
  }

  return (
    <ProfileItemPrimitive
      avatarURL={avatarProps?.avatarURL}
      emojiProps={{ emoji: avatarProps?.emoji }}
      iconProps={{ icon }}
      name={avatarProps?.name}
      route={route}
      subtitles={[subtitle, secondSubtitle]}
      title={title}
    />
  );
};

export default ProfileItem;
