import useLocalEducationCampaignStore, {
  DEFAULT_EDUCATION_CAMPAIGN_MAX_VIEW_COUNT,
  EducationCampaignSettings,
} from "store/useLocalEducationCampaignStore";

type EducationWelcomeCardPath = keyof EducationCampaignSettings;

export const useEducationWelcomeCard = (key: EducationWelcomeCardPath) => {
  const { welcomeCardEnabled, tabViewCount, updateCampaignSettings } =
    useLocalEducationCampaignStore(({ settings, updateSettings }) => ({
      updateCampaignSettings: updateSettings,
      welcomeCardEnabled: settings[key].welcomeCard.enabled,
      tabViewCount: settings[key].welcomeCard.viewCount,
    }));

  const onWelcomeCardViewed = () => {
    if (welcomeCardEnabled) {
      updateCampaignSettings({
        [key]: { welcomeCard: { viewCount: tabViewCount + 1 } },
      });
    }
  };

  const onWelcomeCardDismissed = () => {
    updateCampaignSettings({
      [key]: { welcomeCard: { enabled: false } },
    });
  };

  const showCampaign =
    welcomeCardEnabled && tabViewCount <= DEFAULT_EDUCATION_CAMPAIGN_MAX_VIEW_COUNT;

  return {
    showCampaign,
    onWelcomeCardViewed,
    onWelcomeCardDismissed,
  };
};
