import { useMemo } from "react";

import { WorkspaceEdge, nodeAs, nodeIs } from "@utility-types";
import { Button } from "components/design-system/Button";
import { InformationBubble } from "components/design-system/InformationBubble";
import { ModalProps } from "components/ModalKit/Modal";
import { Header, Main } from "components/ModalKit/Parts";
import { StandardModal } from "components/Modals";
import {
  FetchWorkspaceOrPreviewEdgeDocument,
  useAddWorkspaceAppMutation,
  useAppsQuery,
  useFetchWorkspaceOrPreviewEdgeQuery,
  useRemoveWorkspaceAppMutation,
} from "generated/graphql-operations";
import { AppConfigureMethod } from "generated/graphql-types";
import useCacheEvict from "hooks/state/useCacheEvict";
import useAuthData from "hooks/useAuthData";
import useMemberEdge from "hooks/useMemberEdge";
import useModalStore from "store/useModalStore";
import glueImageURL from "utils/glueImageURL";
import tw from "utils/tw";

import ConfirmRemoveAppModal from "./ConfirmRemoveAppModal";
import useConfigureApps from "./useConfigureApps";

type Props = {
  appID: string;
  workspaceID: WorkspaceEdge["node"]["id"];
} & ModalProps;

const AppConfigurationModal = ({ appID, workspaceID, ...props }: Props) => {
  const { authData, authReady } = useAuthData();
  const { evictNode } = useCacheEvict();

  const { data: workspaceData } = useFetchWorkspaceOrPreviewEdgeQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-first",
    skip: !(workspaceID && authData),
    variables: { id: `${workspaceID}-${authData?.me.id}` },
  });

  const { memberEdge } = useMemberEdge(nodeAs(workspaceData?.node, ["WorkspaceEdge"]));

  const addedApps = memberEdge?.node?.apps?.edges.slice();

  const { data: availableApps } = useAppsQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
  });

  const appEdge = useMemo(() => {
    return (
      addedApps?.find(edge => edge.node.id === appID) ??
      availableApps?.apps.edges.find(edge => edge.node.id === appID)
    );
  }, [addedApps, appID, availableApps?.apps.edges]);

  const isAdded = nodeIs(appEdge, ["WorkspaceAppEdge"]);

  const { configureApp } = useConfigureApps();
  const [addWorkspaceApp] = useAddWorkspaceAppMutation();
  const [removeWorkspaceApp] = useRemoveWorkspaceAppMutation();

  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const isThreadConfigurable =
    appEdge?.node.configureThread === AppConfigureMethod.Configure ||
    appEdge?.node.configureThreadPath !== null;

  const handleConfigureApp = () => {
    if (!appEdge) return;

    if (appEdge.node.configureWorkspacePath) {
      configureApp({
        app: appEdge.node,
        configurableID: workspaceID,
        workspaceID,
      });
      return;
    }

    return addWorkspaceApp({
      refetchQueries: [FetchWorkspaceOrPreviewEdgeDocument],
      variables: { input: { appID: appEdge.node.id, workspaceID } },
      /* New code */
      update: (cache, { data }) => {
        if (data?.addWorkspaceApp) {
          cache.modify({
            id: cache.identify(data.addWorkspaceApp),
            fields: {
              appUser: () => data.addWorkspaceApp.appUser,
            },
          });
        }
      },
      /* End of new code */
      ...(authData && {
        optimisticResponse: () => ({
          addWorkspaceApp: {
            __typename: "WorkspaceAppEdge",
            appUser: authData.me, // This isn't correct but we'll immediately update it with the correct value coming from the network
            creator: authData.me,
            id: `${workspaceID}-${appEdge.node.id}`,
            node: appEdge.node,
            webhooks: [],
            workspaceID,
          },
        }),
      }),
    });
  };

  const handleRemoveApp = async () => {
    if (!isAdded) return Promise.resolve();

    return removeWorkspaceApp({
      variables: { id: appEdge.id },
      update: cache => evictNode(appEdge, cache),
    });
  };

  return (
    <StandardModal
      header={
        <Header variant="bordered">
          <span className="text-xl font-semibold">App: {appEdge?.node.name}</span>
        </Header>
      }
      {...props}
    >
      <Main>
        {appEdge && (
          <div
            className={tw("px-24 py-16", {
              "pb-32": appEdge.node.description.length || isThreadConfigurable,
            })}
          >
            <div className="flex">
              <div className="w-[120px]">
                <img
                  alt={`${name} avatar`}
                  className="w-[110px] h-[110px] rounded-xl"
                  src={glueImageURL(appEdge.node.avatarURL, {
                    fit: "max",
                    h: 110,
                    w: 110,
                  })}
                />
              </div>
              <div className="grow">
                <span className="text-lg font-semibold">{appEdge?.node.name}</span>
                {isAdded && appEdge.creator ? (
                  <>
                    <br />
                    <span className="italic">added by </span>
                    <span className="font-semibold">{appEdge.creator.name}</span>
                  </>
                ) : null}

                {!isAdded ? (
                  <Button className="mt-16" icon="Plus" onClick={handleConfigureApp}>
                    Add to Workspace
                  </Button>
                ) : (
                  <div className="flex mt-16">
                    {appEdge.node.configureWorkspace === AppConfigureMethod.Configure ? (
                      <Button
                        buttonStyle="secondary"
                        icon="EditSimple"
                        onClick={handleConfigureApp}
                      >
                        Configure
                      </Button>
                    ) : null}
                    <Button
                      buttonStyle="simpleDestructive"
                      className={tw({
                        "!pl-0": appEdge.node.configureWorkspace === AppConfigureMethod.Add,
                      })}
                      icon="Trash"
                      onClick={() =>
                        openModal(
                          <ConfirmRemoveAppModal
                            app={appEdge.node}
                            container={memberEdge?.node.name}
                            removeApp={handleRemoveApp}
                          />
                        )
                      }
                    >
                      Remove From Workspace
                    </Button>
                  </div>
                )}
              </div>
            </div>
            {isThreadConfigurable && (
              <div className="mt-12">
                <InformationBubble>
                  To use this app, you'll need to configure it for a group or thread by choosing
                  "Apps" from the group or thread's context menu.
                </InformationBubble>
              </div>
            )}
            {appEdge.node.description.length ? (
              <div className="mt-24">{appEdge.node.description}</div>
            ) : null}
          </div>
        )}
      </Main>
    </StandardModal>
  );
};

export default AppConfigurationModal;
