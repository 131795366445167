import { WatchQueryFetchPolicy } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";

import { GroupEdge, GroupPreview, WorkspacePreview, nodeAs, nodeIs } from "@utility-types";
import {
  FetchGroupOrPreviewEdgeDocument,
  JoinableBy,
  MailboxCountsDocument,
  NotInGroupDocument,
  WorkspacesAndGroupsListDocument,
  useCancelRequestJoinGroupMutation,
  useJoinGroupMutation,
  useLeaveGroupMutation,
  useNotInGroupQuery,
  useRequestJoinGroupMutation,
} from "generated/graphql";
import useCacheEvict from "hooks/state/useCacheEvict";
import useAuthData from "hooks/useAuthData";
import useJoinedGroupSnackBar from "hooks/useJoinedGroupSnackBar";

type MembershipResult = {
  actionPending: boolean;
  hasError: boolean;
  joinRequested: boolean;
  loading: boolean;
  joinGroup: (() => void) | undefined;
  requestToJoin: () => void;
  cancelRequestToJoin: () => void;
  leaveGroup: (edge: GroupEdge) => void;
  preview: GroupPreview | WorkspacePreview | undefined;
};

/**
 * @param recipientID - The ID of the group or workspace to check membership for
 * @param fetchPolicy - The fetch policy to use for the membership query
 */
const useGroupMembership = (
  recipientID: string,
  fetchPolicy?: WatchQueryFetchPolicy
): MembershipResult => {
  const { authData, authReady } = useAuthData();
  const [actionPending, setActionPending] = useState(false);
  const [errors, setErrors] = useState(false);

  const { data, loading } = useNotInGroupQuery({
    fetchPolicy: authReady ? (fetchPolicy ?? "cache-and-network") : "cache-only",
    nextFetchPolicy: "cache-first",
    variables: { id: recipientID },
  });

  const [groupJoin, { error: joinGroupError }] = useJoinGroupMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      FetchGroupOrPreviewEdgeDocument,
      NotInGroupDocument,
      WorkspacesAndGroupsListDocument,
    ],
  });

  const [groupJoinRequest, { error: requestGroupError }] = useRequestJoinGroupMutation({
    awaitRefetchQueries: true,
    refetchQueries: [NotInGroupDocument],
  });

  const [groupCancelRequest, { error: cancelError }] = useCancelRequestJoinGroupMutation({
    awaitRefetchQueries: true,
    refetchQueries: [NotInGroupDocument],
  });

  const [leaveGroupMutation, { error: leaveError }] = useLeaveGroupMutation({
    awaitRefetchQueries: true,
    errorPolicy: "all",
    refetchQueries: [
      FetchGroupOrPreviewEdgeDocument,
      MailboxCountsDocument,
      NotInGroupDocument,
      WorkspacesAndGroupsListDocument,
    ],
  });
  const { evictNode } = useCacheEvict();

  const leaveGroup = useCallback(
    async (edge: GroupEdge) => {
      setActionPending(true);
      return leaveGroupMutation({ variables: { id: recipientID } })
        .then(() => {
          evictNode(edge);
          evictNode(edge.node);
        })
        .finally(() => setActionPending(false))
        .catch(error => {
          console.error("Error: [leaveGroup] - ", error);
        });
    },
    [evictNode, leaveGroupMutation, recipientID]
  );

  const preview = nodeAs(data?.node, ["GroupPreview", "WorkspacePreview"]);

  const showJoinedGroupSnackBar = useJoinedGroupSnackBar(preview);

  const joinGroup = useCallback(() => {
    setActionPending(true);
    groupJoin({
      variables: { id: recipientID },
    })
      .then(() => showJoinedGroupSnackBar())
      .finally(() => setActionPending(false))
      .catch(error => {
        console.error("Error: [requestToJoin] - ", error);
      });
  }, [groupJoin, recipientID, showJoinedGroupSnackBar]);

  const requestToJoin = useCallback(() => {
    setActionPending(true);
    groupJoinRequest({ variables: { joinableID: recipientID } })
      .finally(() => setActionPending(false))
      .catch(error => {
        console.error("Error: [requestToJoin] - ", error);
      });
  }, [groupJoinRequest, recipientID]);

  const cancelRequestToJoin = useCallback(() => {
    if (!data?.joinRequest?.id) return;
    setActionPending(true);
    groupCancelRequest({ variables: { id: data.joinRequest.id } })
      .finally(() => setActionPending(false))
      .catch(error => {
        console.error("Error: [cancelRequestToJoin] - ", error);
      });
  }, [data, groupCancelRequest]);

  useEffect(() => {
    setErrors(!!(joinGroupError || requestGroupError || cancelError || leaveError));
  }, [joinGroupError, requestGroupError, cancelError, leaveError]);

  const joinableWithoutApproval =
    nodeIs(preview, ["GroupPreview"]) &&
    preview?.joinableBy === JoinableBy.Workspace &&
    authData?.workspaces?.edges?.map(e => e.node.id).includes(preview?.workspaceID || "");

  return {
    actionPending,
    hasError: errors,
    joinRequested: !!data?.joinRequest,
    loading,
    joinGroup: joinableWithoutApproval ? joinGroup : undefined,
    requestToJoin,
    cancelRequestToJoin,
    leaveGroup,
    preview,
  };
};

export default useGroupMembership;
