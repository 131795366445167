import { CAPACITOR_WEB_DEVICE_ID_KEY } from "components/devices/hooks/useRegisterDevice";
import { FIREBASE_LOCAL_STORAGE_PREFIX, SIGNED_IN_LOCAL_STORAGE_PREFIX } from "constants/auth";
import env from "utils/processEnv";

const useClearStorage = () => () => {
  if (env.glueEnv === "development") return;

  const authKeys = Object.entries(localStorage)
    .filter(
      ([key]) =>
        key.startsWith(FIREBASE_LOCAL_STORAGE_PREFIX) ||
        key.startsWith(SIGNED_IN_LOCAL_STORAGE_PREFIX) ||
        key === CAPACITOR_WEB_DEVICE_ID_KEY // UUID for capacitor device registration
    )
    .map(([key, value]) => ({ key, value }));

  localStorage.clear();
  sessionStorage.clear();

  // We don't want to clear firebase or auth keys from local storage, as they are used for auth persistence.
  authKeys.forEach(({ key, value }) => {
    localStorage.setItem(key, value);
  });
};

export default useClearStorage;
