import { useHistory } from "react-router";

import { Button } from "components/design-system/Button";
import useHistoryStore from "store/useHistoryStore";

import DeepSearchResults from "./DeepSearchResults";

const DeepSearch = () => {
  const history = useHistory();
  const historyStore = useHistoryStore();

  return (
    <div className="bg-background-app-secondary w-full flex flex-col grow min-h-0">
      <div className="bg-background-body border-b-1 border-border-strong py-16">
        <div className="flex items-center justify-start h-28 max-w-[848px] mx-auto px-24 select-none text-title-3">
          <Button
            buttonStyle="icon-secondary"
            buttonType="none"
            className="mr-16"
            icon="Close"
            iconSize={24}
            onClick={() => history.push(historyStore.backStack?.[0]?.path ?? "/")}
          />
          Results
        </div>
      </div>

      <div className="overflow-auto pt-16 w-full">
        <DeepSearchResults />
      </div>
    </div>
  );
};

export default DeepSearch;
