import { useEditorEvent } from "@remirror/react";
import { useState } from "react";

import { useOnClickInsideEditor, useOnClickOutsideEditor } from ".";

const useDisableControls = () => {
  const [disabled, setDisabled] = useState(true);

  useOnClickOutsideEditor(() => setDisabled(true));

  // keeping this so buttons get enabled when we focus the editor with the `focusEditor` method
  useEditorEvent("focus", () => setDisabled(false));

  useOnClickInsideEditor(
    ({ target }) =>
      target instanceof HTMLElement &&
      // focus only if editor text input is clicked
      target.closest("[contenteditable='true']") &&
      setDisabled(false)
  );

  return disabled;
};

export default useDisableControls;
