import { useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";

import { Recipient } from "@utility-types";
import BackStackButton from "components/App/AppLayoutMobile/BackStackButton";
import MultiOptionDropdown from "components/design-system/Forms/MultiOptionDropdown";
import { RecipientsSelect } from "components/design-system/Forms/RecipientsSelect";
import PaneHeader from "components/design-system/ui/PaneHeader";
import { Addable, MessageableBy } from "generated/graphql";
import isGlueAIRecipient from "utils/thread/isGlueAIRecipient";

import { threadPermissionsOptions } from "../utils";

import AutoSuggestSubjectInput from "./AutoSuggestSubjectInput";
import { State } from "./DraftReducer";
import { DraftFormValues } from "./types";

type Props = {
  autoSuggestSubject: boolean;
  compose: State;
  isModal: boolean;
  onPermissionsChange: (permissions: {
    recipientsAddable: Addable;
    messageableBy: MessageableBy;
  }) => void;
  onRecipientsChange: (recipients: Recipient[]) => void;
  onSubjectChange: (subject: string) => void;
  onClose: (() => void) | undefined;
  readOnly: boolean;
};

const ThreadComposeHeader = ({
  autoSuggestSubject,
  compose,
  isModal,
  onPermissionsChange,
  onRecipientsChange,
  onSubjectChange,
  onClose,
  readOnly,
}: Props) => {
  const {
    getFieldState,
    watch,
    setValue,
    formState: { defaultValues },
  } = useFormContext<DraftFormValues>();

  const isPermissionsManuallyChanged = useRef(false);

  // Watch form values and call callbacks
  useEffect(() => {
    const subscription = watch((values, { name, type }) => {
      switch (name) {
        case "recipients":
          onRecipientsChange(values.recipients as Recipient[]);

          const hasRestrictedRecipients =
            values.recipients?.some(
              recipient => recipient?.messageableBy === MessageableBy.Admin
            ) ?? false;

          if (isPermissionsManuallyChanged.current === false) {
            // if the permissions haven't been manually changed,
            // set restricted permissions to true if a recipient is a restricted group
            // otherwise, set it to false
            setValue("permissions", {
              locked: values.permissions?.locked ?? defaultValues?.permissions?.locked ?? false,
              restricted: hasRestrictedRecipients,
            });
          }
          break;
        case "permissions":
          onPermissionsChange({
            recipientsAddable: values.permissions?.locked ? Addable.None : Addable.Anyone,
            messageableBy: values.permissions?.restricted
              ? MessageableBy.Admin
              : MessageableBy.Recipient,
          });

          if (type === "change") {
            // this was a manual change, set the ref accordingly
            isPermissionsManuallyChanged.current = true;
          }
          break;
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [
    getFieldState,
    onPermissionsChange,
    onRecipientsChange,
    watch,
    setValue,
    defaultValues?.permissions?.locked,
  ]);

  const isGlueAI = isGlueAIRecipient(compose.draftForm.recipients);

  // Render

  return (
    <PaneHeader onClose={onClose} padding={isModal ? "thread-compose-modal" : "thread-compose"}>
      <div className="flex flex-row items-start grow min-w-0">
        {!isModal && <BackStackButton />}
        <div className="flex grow min-w-0">
          <div className="flex flex-col grow min-w-0 md:gap-4">
            <AutoSuggestSubjectInput
              autoSuggestSubject={autoSuggestSubject}
              className="mr-28"
              compose={compose}
              onSubjectChange={onSubjectChange}
              isGlueAI={isGlueAI}
              readOnly={readOnly}
            />
            <div className="flex items-end mb-8 md:mb-4">
              <RecipientsSelect<DraftFormValues>
                autoFocus={false}
                borderWidth={0}
                className="z-2 w-full border-none"
                disabled={readOnly}
                filterMe={true}
                maxLines={3}
                name="recipients"
                placeholder="To groups, users, or emails..."
                wrapperClassName="!my-0 grow min-w-0"
                includeGlueAI
              />
              <div className="mb-6 mr-12">
                <MultiOptionDropdown
                  name="permissions"
                  icon="Unlock"
                  placeholder="Unlocked (default)"
                  placement="bottom-end"
                  options={threadPermissionsOptions}
                  variant="compact"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </PaneHeader>
  );
};

export default ThreadComposeHeader;
