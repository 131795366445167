import { nodeIs } from "@utility-types";
import type {
  GroupOrPreviewEdgeSimple,
  GroupEdgeSimple,
  WorkspaceEdgeSimple,
  GroupPreviewEdgeSimple,
  WorkspacePreviewEdgeSimple,
} from "@utility-types";
import { MemberRole, MessageableBy } from "generated/graphql";

/**
 * Determines if a group or workspace is messageable by the current user,
 *
 * @param edge - A group or workspace edge
 * @param workspaceEdges - The workspaces the user is a part of
 * @returns true if the group or workspace is messageable by the current user, false otherwise
 */
export const isGroupEdgeMessageable = (
  edge:
    | GroupPreviewEdgeSimple
    | GroupOrPreviewEdgeSimple
    | Pick<GroupEdgeSimple, "id" | "__typename" | "memberRole" | "node">
    | WorkspaceEdgeSimple
    | WorkspacePreviewEdgeSimple
    | undefined,
  workspaceEdges: WorkspaceEdgeSimple[] | undefined
): boolean => {
  if (!edge) return false;

  // return false if the edge is a workspace preview edge
  if (nodeIs(edge, ["WorkspacePreviewEdge"])) return false;

  // return false if the group is archived
  if (nodeIs(edge.node, ["Group", "GroupPreview"]) && edge.node.archivedAt) return false;

  // check if the current user is an admin of the group or workspace
  // For GroupOrPreviewEdges, we are accounting for groups the user isn't a member of but is an admin of the workspace they are in
  // We check for GroupPreviewEdges separately below
  if (!nodeIs(edge, ["GroupPreviewEdge"]) && edge.memberRole === MemberRole.Admin) return true;

  // if the group is messageable by anyone, return true
  if (edge.node.messageableBy === MessageableBy.Anyone) return true;

  // if the user is a member of the group (but not an admin), check if the group is unrestricted
  // group or workspace edges (as opposed to preview edges) indicate the user is a member of the group
  if (
    nodeIs(edge.node, ["Group", "Workspace"]) &&
    edge.node.messageableBy !== MessageableBy.Admin
  ) {
    return true;
  }

  // if the group is messageable by workspace, check if the group is in a workspace the user is a part of
  if (
    edge.node.messageableBy === MessageableBy.Workspace &&
    nodeIs(edge.node, ["Group", "GroupPreview"]) &&
    edge.node.workspaceID &&
    workspaceEdges?.map(workspaceEdge => workspaceEdge.node.id).includes(edge.node.workspaceID)
  ) {
    return true;
  }

  // if the group is messageable by admin, check if the user is an admin of the workspace
  if (
    edge.node.messageableBy === MessageableBy.Admin &&
    nodeIs(edge, ["GroupEdge", "GroupPreviewEdge"])
  ) {
    const workspaceEdge = workspaceEdges?.find(
      workspaceEdge => workspaceEdge.node.id === edge.node.workspaceID
    );

    return workspaceEdge?.memberRole === MemberRole.Admin;
  }

  return false;
};
