import { CommandFunction } from "@remirror/core";
import { exitCode } from "@remirror/pm/commands";
import {
  KeyBindings,
  chainCommands,
  command,
  convertCommand,
  findParentNodeOfType,
} from "remirror";
import { HardBreakExtension } from "remirror/extensions";

const SUPPORT_HARD_BREAK_TYPES = ["listItem", "blockquote"];

export default class GlueHardBreakExtension extends HardBreakExtension {
  @command()
  insertParagraphBreak(): CommandFunction {
    return ({ dispatch, state, tr }) =>
      (!findParentNodeOfType({
        selection: tr.selection,
        types: SUPPORT_HARD_BREAK_TYPES,
      }) && dispatch?.(tr.split(state.selection.to).scrollIntoView())) === undefined;
  }
  insertHardBreak(): CommandFunction {
    return ({ dispatch, tr }) =>
      (findParentNodeOfType({
        selection: tr.selection,
        types: SUPPORT_HARD_BREAK_TYPES,
      }) && dispatch?.(tr.replaceSelectionWith(this.type.create()).scrollIntoView())) === undefined;
  }

  createKeymap(): KeyBindings {
    return {
      "Alt-Shift-Enter": chainCommands(
        convertCommand(exitCode),
        () => this.store.commands.insertHardBreak() === undefined
      ),
      "Shift-Enter": chainCommands(
        convertCommand(exitCode),
        () => this.store.commands.insertParagraphBreak?.() === undefined
      ),
    };
  }
}
