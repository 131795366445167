import { useLayoutEffect } from "react";

const useViewportHeight = () => {
  // TODO switch to dvh unit once we have better browser support and remove this useLayoutEffect
  // Unit dvh reflects the current viewport height. This unit excludes the user agent's interface, unlike unit vh, and updates as the current viewport height changes.
  // Unit dvh reflects how much vertical viewport height the user agent's interface currently takes up. For instance, this will change as you scroll down a page on mobile, since the mobile URL bar moves out of your screen.
  useLayoutEffect(() => {
    // only attach a listener on mobile Safari. All other browsers use CSS vh.
    if (
      getComputedStyle(document.documentElement).getPropertyValue("--viewport-height").length === 0
    ) {
      return;
    }

    const handleResize = () => {
      document.documentElement.style.setProperty("--viewport-height", `${window.innerHeight}px`);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
};

export default useViewportHeight;
