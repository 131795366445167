import { CSSProperties, ElementType, FunctionComponentElement, cloneElement } from "react";

type SkeletonStyles = {
  /** setting for boarder radius */
  borderRadius?: string;
  height?: string;
  width?: string;
};

type Props = SkeletonStyles & {
  /**  add additional class to .glue-skeleton */
  className?: string;
  /** generated Skeleton count default 1 */
  count?: number;
  /** If parent is set to be displayed as flex, basis uses width */
  flex?: boolean;
  /** set grow factor of Skeleton, default is 1 true */
  flexGrow?: boolean;
  /** custom CSS for Skelton */
  style?: CSSProperties;
  /** wrapper for a Skelton */
  wrapper?: ElementType;
};

/**
 * Display a placeholder preview of content to reduce load-time frustration.
 * @param props - React props
 */
const Skeleton = ({
  borderRadius,
  className,
  count = 1,
  flex: parentFlex = false,
  flexGrow: grow = true,
  height,
  style,
  width,
  wrapper: Wrapper,
}: Props): JSX.Element => {
  const skeletonStyle = (width => {
    const obj = {
      ...style,
      ...(width && { width }),
      ...(height && { height }),
      ...(borderRadius && { borderRadius }),
    };
    if (parentFlex && obj.width) {
      delete obj.width;
    }
    return obj;
  })(width);
  const flexBasis = parentFlex ? width || "100%" : undefined;
  const skeletonClassName = `glue-skeleton${className ? ` ${className}` : ""}`;
  const elements = cloneElementForSkeletons(
    <span
      className={skeletonClassName}
      data-testid="skeleton"
      style={{
        ...skeletonStyle,
      }}
    >
      &zwnj;
    </span>,
    count
  );

  return (
    <span
      data-testid="skeleton-wrap"
      style={parentFlex ? { flexBasis: flexBasis, flexGrow: grow ? 1 : 0 } : {}}
    >
      {Wrapper ? (
        <Wrapper>
          {elements}
          &zwnj;
        </Wrapper>
      ) : (
        elements
      )}
    </span>
  );
};

/**
 * @param element create clone(s) from this element
 * @param count   optional clone count
 */
export function cloneElementForSkeletons(
  element: JSX.Element,
  count = 1
): FunctionComponentElement<unknown>[] {
  return [...Array(count)].map((_x, index) =>
    // not necessary to create a unique identifier for skeletons
    // https://reactjs.org/docs/reconciliation.html#recursing-on-children
    // https://robinpokorny.medium.com/index-as-a-key-is-an-anti-pattern-e0349aece318
    // biome-ignore lint: Avoid using the index of an array as key property in an element
    cloneElement(element, { key: index })
  );
}

export default Skeleton;
