import { captureMessage } from "@sentry/react";
import { partition } from "lodash-es";
import { useMemo } from "react";

import { useFetchTimezonesQuery } from "generated/graphql-operations";
import { browserSupportedTimezones } from "utils/timezone";

import useAuthData from "./useAuthData";

const useTimezoneList = () => {
  const { authReady } = useAuthData();
  const { data } = useFetchTimezonesQuery({
    fetchPolicy: authReady ? "cache-first" : "cache-only",
  });

  const supportedTimezones = useMemo(() => {
    if (!data) return [];

    const apiSupportedTimezones = data?.timezones.map(tz => tz.name) ?? [];
    const [supported, unsupported] = partition(apiSupportedTimezones, tz =>
      browserSupportedTimezones.includes(tz)
    );

    if (unsupported.length > 0) {
      captureMessage(
        `Warning: The following timezones from the API are not supported by this browser: ${unsupported}`
      );
    }

    return supported;
  }, [data]);

  return supportedTimezones;
};

export default useTimezoneList;
