import { cloneElement, forwardRef } from "react";

export type ChildrenToClone = JSX.Element;

type Props = {
  children: ChildrenToClone;
  [x: string]: unknown;
};

const CloneElement = forwardRef<HTMLElement, Props>(({ children, ...props }, ref) => {
  return cloneElement(children, { ...props, ref });
});

export default CloneElement;
