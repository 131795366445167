import { useCallback, useEffect, useRef } from "react";

import { useLongPress } from "hooks/useLongPress";

type LongPressEvents = keyof Pick<ReturnType<typeof useLongPress>, "events">["events"];

type Props = {
  isModalOpen: boolean;
  showModal: () => void;
};

const consecutiveClicks = 7;
let clickCount = 0;

export default function useMobileTabSequence({ isModalOpen, showModal }: Props) {
  const timerCallback = useRef<NodeJS.Timeout>();

  const resetTimer = useCallback(() => {
    timerCallback.current !== undefined && clearTimeout(timerCallback.current);
  }, []);

  const longPressEvents = useLongPress<HTMLDivElement>(
    () => {
      if (clickCount >= consecutiveClicks && !isModalOpen) {
        showModal();
      }

      clickCount = 0;
      resetTimer();
    },

    {
      cancelOnMovement: 10,
      disableHaptic: true,
      focus: false,
      onShortPress: event => {
        if (!event) return;

        if (event.clientY < 258) {
          ++clickCount;

          resetTimer();

          timerCallback.current = setTimeout(() => {
            clickCount = 0;
          }, 1580);
        }
      },
      threshold: 858,
    }
  ).events;

  const longPressEventTypes = Object.keys(longPressEvents).filter(
    (event): event is LongPressEvents => !!event
  );
  useEffect(() => {
    longPressEventTypes.forEach(eventType =>
      window.addEventListener(eventType, longPressEvents[eventType])
    );

    return () => {
      longPressEventTypes.forEach(eventType =>
        window.removeEventListener(eventType, longPressEvents[eventType])
      );
    };
  }, [longPressEventTypes, longPressEvents]);
}
