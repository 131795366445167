import tw from "utils/tw";

const PrimaryPill = () => (
  <span
    className={tw(
      "flex items-center justify-center shrink-0 h-20 px-8 py-4 rounded-[12px]",
      "bg-background-secondary text-caption-bold text-text-subtle",
      "group-hover/action-sheet-item:bg-background-secondary-hover"
    )}
  >
    Primary
  </span>
);

export default PrimaryPill;
