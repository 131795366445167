import { useApolloClient } from "@apollo/client";
import { useCallback } from "react";

import { nodeAs } from "@utility-types";
import CreateGroupModal from "components/group/CreateGroup/CreateGroupModal";
import InviteToGlueModal from "components/InviteToGlue/InviteToGlueModal";
import { SettingsModal } from "components/SettingsModal";
import ImportStatusModal from "components/SlackImport/ImportStatusModal";
import ReviewImportModal from "components/SlackImport/ReviewImportModal";
import SlackImportModal from "components/SlackImport/SlackImportModal";
import useComposeToGlue from "components/threads/hooks/useComposeToGlue";
import ThreadComposeModal from "components/threads/ThreadComposeModal";
import { WorkspaceModalJoin } from "components/workspace/WorkspaceModal";
import {
  FetchRecipientDocument,
  FetchRecipientQuery,
  FetchUserEdgeDocument,
  FetchUserEdgeQuery,
} from "generated/graphql";
import useModalStore from "store/useModalStore";
import { env } from "utils/processEnv";

import useAuthData from "./useAuthData";

enum Actions {
  ADD_WORKSPACE = "add_workspace",
  ASK_A_QUESTION = "ask_glueai",
  INVITE_COWORKERS = "invite_coworkers",
  CREATE_GROUP = "create_group",
  START_THREAD = "start_thread",
  CONNECT_APPS = "connect_apps",
  IMPORT_SLACK = "import_slack",
  IMPORT_STATUS = "import_status",
  REVIEW_IMPORT = "review_import",
  CONTACT_SUPPORT = "contact_support",
}

export const isGlueAction = (input: string): input is Actions => {
  return Object.values(Actions).includes(input as Actions);
};

export default function useShowActionModal() {
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const apolloClient = useApolloClient();
  const { authData } = useAuthData();

  const userID = authData?.me?.id;
  const workspaces = authData?.workspaces;

  const { group: helpGroup } = useComposeToGlue("support");

  return useCallback(
    async (params: {
      action: Actions | null;
      recipient?: string | null;
      workspaceID?: string | null;
    }) => {
      if (!userID) return;

      const recipientData = await apolloClient.query<FetchRecipientQuery>({
        query: FetchRecipientDocument,
        fetchPolicy: "cache-first",
        variables: { id: `${params?.recipient}` },
      });

      const dataGlueAIUser = await apolloClient.query<FetchUserEdgeQuery>({
        query: FetchUserEdgeDocument,
        fetchPolicy: "cache-first",
        variables: { id: `${env.glueAIBotID}-${userID}` },
      });
      const glueAI = nodeAs(dataGlueAIUser.data?.node, ["UserEdge"])?.node;
      const recipient = nodeAs(recipientData.data?.node, ["Workspace", "Group", "User"]);
      const workspaceEdge = workspaces?.edges[0];

      const getModalComponent = () => {
        switch (params.action) {
          case Actions.ASK_A_QUESTION:
            return glueAI ? <ThreadComposeModal initialDraft={{ recipients: [glueAI] }} /> : null;

          case Actions.ADD_WORKSPACE:
            return <WorkspaceModalJoin />;

          case Actions.INVITE_COWORKERS:
            return workspaceEdge?.memberRole === "admin" ? (
              <SettingsModal subView="members" view={workspaceEdge?.node.id} />
            ) : (
              <InviteToGlueModal />
            );

          case Actions.CREATE_GROUP:
            return workspaceEdge?.node ? (
              <CreateGroupModal workspaceID={workspaceEdge?.node.id} />
            ) : null;

          case Actions.START_THREAD:
            return (
              <ThreadComposeModal initialDraft={{ recipients: recipient ? [recipient] : [] }} />
            );

          case Actions.CONNECT_APPS:
            return <SettingsModal subView="apps" view={workspaces?.edges[0]?.node.id} />;

          case Actions.IMPORT_SLACK:
            return workspaces?.edges[0] ? (
              <SlackImportModal workspaceID={workspaces?.edges[0]?.node.id} />
            ) : null;

          case Actions.IMPORT_STATUS:
            return <ImportStatusModal workspaceID={`wks_${params.workspaceID}`} />;

          case Actions.REVIEW_IMPORT:
            return <ReviewImportModal workspaceID={`wks_${params.workspaceID}`} />;

          case Actions.CONTACT_SUPPORT:
            return (
              <ThreadComposeModal initialDraft={{ recipients: helpGroup ? [helpGroup] : [] }} />
            );

          default:
            return null;
        }
      };
      const modalComponent = getModalComponent();

      if (modalComponent) {
        openModal(modalComponent);
      }
    },
    [apolloClient, helpGroup, openModal, userID, workspaces?.edges]
  );
}
