import { isEqual } from "lodash-es";
import { RefObject, useEffect, useRef, useState } from "react";

import useShortcut from "hooks/useShortcut";

type Props<T> = {
  closeMenu?: () => void;
  data?: T[];
  isInfiniteList?: boolean;
  onSelectItem: (item: T, index: number, event: KeyboardEvent) => void;
  preventReset?: boolean;
  scrollProps?: ScrollIntoViewOptions;
  selectItemOnTabPress?: boolean;
  selectedItemRef?:
    | RefObject<HTMLLIElement | HTMLDivElement>
    | HTMLLIElement
    | HTMLDivElement
    | null;
  isOpen?: boolean;
};

const useMenuKeyboardShortcuts = <T,>({
  closeMenu,
  data,
  isInfiniteList = false,
  onSelectItem,
  preventReset,
  scrollProps = { behavior: "auto", block: "nearest", inline: "nearest" },
  selectedItemRef,
  selectItemOnTabPress = true,
  isOpen,
}: Props<T>) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const element =
    selectedItemRef && "current" in selectedItemRef ? selectedItemRef?.current : selectedItemRef;

  const handleElementScroll = () => {
    if (!element) return;
    element.scrollIntoView({
      ...scrollProps,
    });
  };

  useEffect(() => {
    if (isOpen && element) {
      element.scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center",
      });
    }
  }, [element, isOpen]);

  useShortcut(["Escape"], () => !!closeMenu && closeMenu());

  useShortcut(["ArrowUp"], () => {
    if (!data) return;
    let index = selectedIndex - 1;
    if (index < 0) index = isInfiniteList ? 0 : data.length - 1;
    setSelectedIndex(index);
    handleElementScroll();
  });

  useShortcut(["ArrowDown"], () => {
    if (!data) return;
    let index = selectedIndex + 1;
    if (index === data.length) index = isInfiniteList ? data.length - 1 : 0;
    setSelectedIndex(index);
    handleElementScroll();
  });

  const selectItem = (event: KeyboardEvent) => {
    if (!data) return;
    const selectedItem = data[selectedIndex];
    if (!selectedItem) return;
    event.preventDefault();
    onSelectItem(selectedItem, selectedIndex, event);
  };

  useShortcut(["Enter"], selectItem);

  useShortcut(["Tab"], selectItemOnTabPress ? selectItem : () => null);

  const prevData = useRef(data);
  useEffect(() => {
    //Reset index when data has changed
    if (isEqual(prevData.current, data)) return;
    prevData.current = data;
    if (isInfiniteList || preventReset) return;
    setSelectedIndex(0);
  }, [data, isInfiniteList, preventReset]);

  return {
    selectedItem: data?.[selectedIndex],
    setSelectedIndex,
  };
};

export default useMenuKeyboardShortcuts;
