import { useState } from "react";

import { useHasAppUpdate } from "components/App/AppUpdate";
import { Button } from "components/design-system/Button";
import { Dropdown } from "components/design-system/FloatingUi";
import { DropdownActionButton } from "components/design-system/FloatingUi/DropdownActionButton";
import { DropdownActionButtonGroup } from "components/design-system/FloatingUi/DropdownActionButtonGroup";
import { DropdownActions } from "components/design-system/FloatingUi/DropdownActions";
import useComposeToGlue from "components/threads/hooks/useComposeToGlue";
import { GLUE_DOCS } from "constants/glue-docs";
import { useComposeToGlueAI } from "hooks/glueAI/useComposeToGlueAI";
import useAuthData from "hooks/useAuthData";
import tw from "utils/tw";

export const HelpDocsButton = () => {
  return (
    <DropdownActionButton
      icon="ExternalLink"
      onClick={() => {
        window.open(GLUE_DOCS.BASE_URL, "_blank");
      }}
    >
      Help docs
    </DropdownActionButton>
  );
};

export const ReleaseNotesButton = () => {
  return (
    <DropdownActionButton
      icon="Info"
      onClick={() => {
        window.open(GLUE_DOCS.RELEASE_NOTES_URL, "_blank");
      }}
    >
      Release notes
    </DropdownActionButton>
  );
};

export const AskGlueAIButton = () => {
  const composeToGlueAI = useComposeToGlueAI();

  return (
    <DropdownActionButton icon="SparkleFilled" onClick={composeToGlueAI}>
      Ask Glue AI
    </DropdownActionButton>
  );
};

export const ContactSupportButton = () => {
  const { composeThreadToGlue } = useComposeToGlue("support");

  return (
    <DropdownActionButton icon="ChatRounded" onClick={() => composeThreadToGlue()}>
      Contact support
    </DropdownActionButton>
  );
};

export const HelpMenu = () => {
  const { authData } = useAuthData();
  const aiDisabled = authData?.workspaces.edges.length === 0;
  const [isOpen, setOpen] = useState(false);
  const { hasAppUpdate, applyAppUpdate } = useHasAppUpdate();

  return (
    <Dropdown
      content={
        <DropdownActions>
          <DropdownActionButtonGroup>
            <HelpDocsButton />
            <ReleaseNotesButton />
            {!aiDisabled && <AskGlueAIButton />}
          </DropdownActionButtonGroup>

          <DropdownActionButtonGroup>
            <ContactSupportButton />
          </DropdownActionButtonGroup>

          {hasAppUpdate && (
            <DropdownActionButtonGroup>
              <DropdownActionButton
                icon="Refresh"
                className="after:block after:w-6 after:h-6 after:bg-background-alert after:rounded-full after:ml-auto"
                onClick={applyAppUpdate}
              >
                <div className="flex flex-col items-start">
                  <div className="text-subhead-bold">Update available</div>
                  <div className="text-footnote">App will restart</div>
                </div>
              </DropdownActionButton>
            </DropdownActionButtonGroup>
          )}
        </DropdownActions>
      }
      placement="bottom-end"
      setOpen={setOpen}
      disableFlip
    >
      <Button
        buttonStyle="none"
        icon="Help"
        iconSize={20}
        className={tw(
          "text-icon-action-inverse-subtle relative !px-4 !py-4 !border-none",
          hasAppUpdate &&
            "before:block before:absolute before:top-3 before:right-4 before:z-2 before:w-10 before:h-10 before:bg-background-alert before:rounded-full before:border-2 before:border-background-app-base"
        )}
        type="button"
        tooltip={isOpen ? undefined : "Help"}
      />
    </Dropdown>
  );
};
