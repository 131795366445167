import unknownEmoji from "assets/icons-png/unknown-emoji.png";
import glueImageURL from "utils/glueImageURL";

import { emojiSet, getEmojiStyles, getNativeEmoji, hasEmoji } from "./data";

type Props = {
  name: string;
  size?: number;
  url?: string;
};

const ImageEmoji = ({ url, size, name }: Required<Props>) => {
  const src1 = glueImageURL(url, {
    fit: "max",
    h: size,
    w: size,
  });
  const src2 = glueImageURL(url, {
    fit: "max",
    h: size * 2,
    w: size * 2,
  });
  return (
    <img
      alt={name}
      className="inline-block"
      width={size}
      height={size}
      style={{ objectFit: "contain", maxHeight: size }}
      src={src2}
      srcSet={`${src1} 1x, ${src2} 2x`}
    />
  );
};

export const Emoji = ({
  name,
  size = 32,
  style,
  url,
  ...props
}: Props & React.HTMLAttributes<HTMLSpanElement>) => {
  if (url) {
    return <ImageEmoji name={name} url={url} size={size} />;
  }

  if (emojiSet === "native") {
    return (
      <span
        className="inline-block leading-none"
        data-testid={`${name}-emoji`}
        style={{
          fontFamily:
            "'Twemoji Mozilla', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji', 'EmojiOne Color', 'Android Emoji', System, sans-serif",
          fontSize: `${size}px`,
          ...style,
        }}
      >
        {getNativeEmoji(name)}
      </span>
    );
  }

  // In case there's an unsupported emoji
  if (!hasEmoji(name, true)) return <ImageEmoji name={name} url={unknownEmoji} size={size} />;

  return (
    <span
      className="inline-block"
      data-testid={`${name}-emoji`}
      style={{
        ...getEmojiStyles(name),
        height: size,
        width: size,
        ...style,
      }}
      {...props}
    />
  );
};
