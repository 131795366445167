import { useState } from "react";
import { useHistory } from "react-router-dom";

import { DraftEdge } from "@utility-types";
import { Button } from "components/design-system/Button";
import { SectionItem } from "components/design-system/ui/sections-sidebar";
import useToastStore, { ToastType } from "components/design-system/ui/ToastKit/useToastStore";
import { routeToThread, useRouteParams } from "components/routing/utils";
import { useInboxSidebarSectionsState } from "components/views/inbox/providers/InboxSidebarSectionsProvider";
import { DraftListDocument, useDeleteDraftMutation } from "generated/graphql";
import useCacheEvict from "hooks/state/useCacheEvict";
import useHistoryItem from "hooks/useHistoryItem";
import useDraftMessagesStore from "store/useDraftMessagesStore";
import useLocalSettingsStore from "store/useLocalSettingsStore";

import { useDraftsUtils } from "./hooks";
import MailboxSection from "./MailboxSection";

import { InboxListItem } from ".";

const sectionKey = "Draft";
const pageSize = 9;

const Drafts = () => {
  const history = useHistory();
  const { evictNode } = useCacheEvict();
  const { superTab, threadID: selectedID, view } = useRouteParams();
  const [deletingIDs, setDeletingIDs] = useState<Set<string>>(new Set());

  const { swipedOpenItemId, setState } = useInboxSidebarSectionsState(({ swipedOpenItemId }) => ({
    swipedOpenItemId,
  }));
  const setSwipedOpenItemId = (id?: string) => {
    setState({ swipedOpenItemId: id });
  };

  const { data, fetchMore, handleUndo } = useDraftsUtils({ pageSize });

  const draftTitle =
    data?.drafts.edges.find(d => d.node.id === selectedID)?.node.subject || "No Subject";

  useHistoryItem({
    title: draftTitle,
  });

  const [deleteDraft] = useDeleteDraftMutation();

  const { openToast } = useToastStore(({ openToast }) => ({ openToast }));
  const { removeDraft } = useDraftMessagesStore(({ removeDraft }) => ({
    removeDraft,
  }));

  const { collapsedSidebarSections } = useLocalSettingsStore(({ collapsedSidebarSections }) => ({
    collapsedSidebarSections,
  }));
  const collapsed = collapsedSidebarSections.includes(sectionKey);

  const reversedDrafts = data?.drafts.edges.slice().reverse() ?? [];

  const handleDeleteDraft = (draft: DraftEdge) => {
    if (deletingIDs.has(draft.node.id)) return;

    setDeletingIDs(prev => {
      prev.add(draft.node.id);
      return prev;
    });

    const removeDraftFromDeletingIDs = () => {
      setDeletingIDs(prev => {
        prev.delete(draft.node.id);
        return prev;
      });
    };

    deleteDraft({
      variables: { id: draft.node.id },
      refetchQueries: [DraftListDocument],
      update: c => evictNode(draft, c),
    })
      .then(() => {
        removeDraft({ draftID: draft.node.id });
        openToast({
          content: "Draft deleted",
          dismiss: 5000,
          icon: "Trash",
          type: ToastType.DRAFT,
          undo: () => handleUndo(draft.node.id),
        });
        if (selectedID === draft.node.id && reversedDrafts.length === 1) {
          history.push(`/${superTab || "inbox"}`);
        }
      })
      .catch(() => {
        removeDraftFromDeletingIDs();
      })
      .finally(() => {
        // Only clean up after animation completes
        setTimeout(removeDraftFromDeletingIDs, 300);
      });
  };

  const composeItem = (
    <SectionItem key="compose" className="mx-0 !px-0" flipId="compose">
      <InboxListItem
        bulkMode={false}
        icon="Edit"
        isSelected={true}
        subject={<span className="text-text-subtle">New thread</span>}
      />
    </SectionItem>
  );

  if ((!reversedDrafts.length && view !== "compose") || !reversedDrafts) return null;

  const sectionItems = (drafts: DraftEdge[]) => {
    if (collapsed) drafts = drafts.filter(d => d.node.id === selectedID);

    return drafts.map(d => (
      <SectionItem
        key={d.node.id}
        className="mx-0 !px-0"
        flipId={`draft-${d.node.id}`}
        onClick={(e: React.KeyboardEvent | React.MouseEvent) => {
          const to = e.ctrlKey || e.metaKey ? "secondary" : "primary";
          history.push(routeToThread({ superTab: "inbox", threadID: d.node.id, to }));
        }}
        itemData={d}
        setSwipedOpenItemId={setSwipedOpenItemId}
        swipedOpenItemId={swipedOpenItemId}
        type="draft"
      >
        <InboxListItem
          bulkMode={false}
          icon="Edit"
          isSelected={d.node.id === selectedID}
          subject={d.node.subject || <span className="italic text-text-secondary">No subject</span>}
        >
          <div className="grow flex justify-end invisible group-hover/item:visible">
            <Button
              buttonStyle="subtle"
              className="pr-0"
              icon="Trash"
              iconClassName="w-20 h-20"
              onPointerDown={e => {
                e.preventDefault();
                e.stopPropagation();
                handleDeleteDraft(d);
              }}
              disabled={deletingIDs.has(d.node.id)}
            />
          </div>
        </InboxListItem>
      </SectionItem>
    ));
  };

  const initialDrafts = reversedDrafts.slice(0, pageSize);
  const moreDrafts = reversedDrafts.slice(pageSize);

  const loadNextPage = () =>
    fetchMore({ variables: { before: data?.drafts.pageInfo.startCursor } });

  return (
    <MailboxSection
      divider="bottom"
      flipKey={data?.drafts.edges.map(d => d.node.id).join()}
      hasPreviousPage={data?.drafts.pageInfo.hasPreviousPage}
      moreItems={sectionItems(moreDrafts)}
      unreadCount={data?.drafts.totalCount}
      onClickMore={loadNextPage}
      sectionItems={[...(view === "compose" ? [composeItem] : []), ...sectionItems(initialDrafts)]}
      sectionKey={sectionKey}
      sectionTitle="Drafts"
      selectedItem={
        !initialDrafts.find(d => d.node.id === selectedID)
          ? sectionItems(moreDrafts.filter(d => d.node.id === selectedID))[0]
          : undefined
      }
    />
  );
};

export default Drafts;
