/* istanbul ignore file */
import { MutableRefObject, useEffect } from "react";

import { useThreadViewState } from "components/thread/ThreadView/provider/ThreadViewProvider";
import useNativeKeyboardStore from "store/useNativeKeyboardStore";
import { isNativeMobile } from "utils/platform";

import { MessageEditor } from "../../../MessageEditor/types";

const useScrollToTimelineEnd = (editor: MutableRefObject<MessageEditor | null>): void => {
  const { scrollToBottom } = useThreadViewState(({ scrollToBottom }) => ({
    scrollToBottom,
  }));

  const hasFocus = editor.current?.hasFocus;

  useEffect(
    () =>
      (isNativeMobile() &&
        useNativeKeyboardStore.subscribe(
          ({ animationState, keyboardHeight }) => ({
            animationState,
            keyboardHeight,
          }),
          ({ animationState }) => {
            if (!scrollToBottom) return;
            if (animationState === "paused") return;
            if (!hasFocus?.()) return;
            scrollToBottom();
          }
        )) ||
      undefined,
    [scrollToBottom, hasFocus]
  );
};

export default useScrollToTimelineEnd;
