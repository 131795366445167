import { Link } from "react-router-dom";
import type { GroupStyle } from "stream-chat-react/dist/components/MessageList/utils";

import type { GlueDefaultStreamChatGenerics } from "@utility-types";
import { MessageAttachments } from "components/Message/MessageAttachments";
import { Timestamp } from "components/Message/Timestamp";
import { isOnlyEmojis as isOnlyEmojisFunction } from "components/Message/utils";
import QuotedMessage from "components/QuotedMessage/QuotedMessage";
import { routeToThread } from "components/routing/utils";
import { useThreadViewState } from "components/thread/ThreadView/provider/ThreadViewProvider";
import { formatDateWithTime } from "utils/formatDate";
import env from "utils/processEnv";
import { streamMessageToGlueMessage } from "utils/stream/message";

import MessageSources from "./MessageSources";

export const MessageContent = ({
  firstGroupStyle,
  message,
  messageActionMenu,
  messageGlueStatus,
  messageText,
  reactionsList,
}: {
  collapsible?: boolean;
  firstGroupStyle?: GroupStyle;
  message: GlueDefaultStreamChatGenerics["messageType"];
  messageActionMenu?: JSX.Element | null;
  messageGlueStatus?: JSX.Element | null;
  messageText: JSX.Element | null;
  reactionsList?: JSX.Element | null;
}) => {
  const deleted = message.type === "deleted";

  const isGlueAI = message.user?.id === env.glueAIBotID;
  const isOnlyEmojis = isOnlyEmojisFunction(message.text);

  const { recipientID, threadID, threadPane } = useThreadViewState(
    ({ recipientID, threadID, threadPane }) => ({
      recipientID,
      threadID,
      threadPane,
    })
  );

  const messageAttachments: JSX.Element | null =
    !deleted && message.attachments?.length ? (
      <MessageAttachments
        attachments={message.attachments || []}
        firstGroupStyle={firstGroupStyle}
        messageId={message.id}
      />
    ) : null;

  const createdAt = new Date(message.created_at ?? new Date());
  const updatedAt = new Date(message.content_updated_at ?? createdAt);
  const isUpdated = updatedAt > createdAt && updatedAt < new Date(); // prevent tampering with ts

  return (
    <div className="relative w-full min-w-0">
      <div className="pr-16">
        {firstGroupStyle !== "top" && firstGroupStyle !== "single" && messageActionMenu}

        <div className={"str-chat-message-content"} data-testid="message-glue-content">
          {message.type !== "deleted" && message.quoted_message && message.quoted_message_id && (
            <div className="my-6">
              <Link
                className="block"
                to={() =>
                  routeToThread({
                    messageID: message.quoted_message_id,
                    recipientID,
                    threadID,
                    forceTo: threadPane,
                  })
                }
              >
                <QuotedMessage message={streamMessageToGlueMessage(message.quoted_message)} />
              </Link>
            </div>
          )}

          {message.text !== "" && (
            <span className={isOnlyEmojis ? "text-3xl" : ""} data-testid="message-glue-message">
              {messageText}
            </span>
          )}
          {message.text === "" && messageAttachments}

          {isGlueAI && <MessageSources message={message} />}
        </div>

        {message.text !== "" && messageAttachments}

        {isUpdated && message.user?.id !== env.glueAIBotID && (
          <div className={"text-caption text-text-subtle mt-2 mb-4"}>
            (edited&nbsp;
            <Timestamp
              customClass="text-caption text-text-subtle"
              date={updatedAt}
              formatDate={formatDateWithTime}
            />
            )
          </div>
        )}

        {message.text === "" && reactionsList}
      </div>

      {messageGlueStatus}
    </div>
  );
};
