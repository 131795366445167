import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Flipper } from "react-flip-toolkit";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useHistory } from "react-router";

import { Recipient, ThreadEdgeSimple, ThreadPreview, User, nodeAs, nodeIs } from "@utility-types";
import Avatar from "components/design-system/Avatar/Avatar";
import FindThread from "components/FindThread";
import { useRouteParams, userPath } from "components/routing/utils";
import ThreadListItem from "components/threads-list/ThreadListItem/ThreadListItem";
import { useInstantSearch } from "components/views/search/hooks";
import useChatRecipient from "hooks/useChatRecipient";
import tw from "utils/tw";

import EmptyListView from "./EmptyListView";
import FooterInvite from "./FooterInvite";
import SkeletonItems from "./SkeletonItems";

type DirectMessagesListProps = {
  data?: (ThreadEdgeSimple | User)[];
  hasNextPage: boolean;
  loadMore: () => void;
  loading: boolean;
  isDirectoryList?: boolean;
};

const recipientToThreadPreview = (recipient?: Recipient): ThreadPreview => ({
  __typename: "ThreadPreview",
  admin: null,
  id: recipient?.id ?? "",
  name: recipient?.name ?? "",
  recipients: { __typename: "RecipientConnection", edges: [] },
  subject: recipient?.name ?? "",
});

const DirectMessagesList = ({
  data,
  hasNextPage,
  loading,
  loadMore,
  isDirectoryList = false,
}: DirectMessagesListProps) => {
  const [userInput, setUserInput] = useState("");

  const hasUserInput = userInput.length > 0;

  const { search, searchResults } = useInstantSearch({
    resultsOrder: ["users"],
  });

  useEffect(() => {
    search({ groups: false, match: userInput, threads: false });
  }, [search, userInput]);

  const formRef = useRef<HTMLFormElement>(null);

  const { userID, v } = useRouteParams();
  const history = useHistory();
  const [swipedOpenItemId, setSwipedOpenItemId] = useState<string>();

  const foundUsers = useMemo(
    () =>
      [...searchResults.instantResults, ...(searchResults.moreResults || [])].flatMap(
        ({ resultType, edges }) => (resultType === "users" ? edges : [])
      ),
    [searchResults]
  );

  const chatRecipient = useChatRecipient();

  const filterFoundUsers = (edges?: (ThreadEdgeSimple | User)[]) =>
    edges?.filter(e => {
      const recipient = nodeIs(e, ["ThreadEdge"]) ? chatRecipient(e.node) : e;
      return foundUsers.find(u => u.node.id === recipient?.id);
    });

  const listItems = hasUserInput
    ? isDirectoryList
      ? foundUsers.map(u => u.node)
      : filterFoundUsers(data)
    : data;

  const [scrollSentryRef, { rootRef: scrollListRef }] = useInfiniteScroll({
    hasNextPage,
    loading,
    disabled: hasUserInput,
    onLoadMore: useCallback(() => {
      loadMore();
    }, [loadMore]),
  });

  const isSearching = searchResults.searching;
  const noListItems = !listItems?.length;
  const noSearchResults = hasUserInput && noListItems;
  const showFooter = (!hasNextPage || hasUserInput) && !isSearching;

  return (
    <div
      className={tw(
        "grow min-h-0 overflow-x-hidden translate-x-0",
        noListItems ? "overflow-y-hidden" : "overflow-y-auto"
      )}
      ref={scrollListRef}
    >
      {noSearchResults || listItems?.length ? (
        <FindThread
          className="mx-20"
          label={isDirectoryList ? "Find anyone you're connected to..." : "Find a DM"}
          ref={formRef}
          onChange={setUserInput}
        />
      ) : null}

      {listItems?.length ? (
        <Flipper element="ol" flipKey={hasUserInput ? false : listItems.map(c => c.id).join()}>
          {listItems.map(e => {
            const isThread = nodeIs(e, ["ThreadEdge"]);
            const user = isThread ? nodeAs(chatRecipient(e.node), ["User"]) : e;
            return (
              <ThreadListItem
                key={e.id}
                canArchive={false}
                setSwipedOpenItemId={setSwipedOpenItemId}
                swipedOpenItemId={swipedOpenItemId}
                avatarComponent={
                  <Avatar
                    avatarURL={user?.avatarURL}
                    name={user?.name}
                    rounded="rounded-md"
                    size="medium"
                  />
                }
                avatarSize={32}
                className="!rounded-none select-none md:!h-64"
                isSelected={userID === user?.id && v !== "compose-dm"}
                height="h-67"
                item={isThread ? e : recipientToThreadPreview(user)}
                onClick={() => history.push(userPath(undefined, user?.id))}
              />
            );
          })}
          {isSearching && <SkeletonItems count={3} />}
          {showFooter ? <FooterInvite /> : <div ref={scrollSentryRef} />}
        </Flipper>
      ) : isSearching || !listItems ? (
        <SkeletonItems />
      ) : (
        <EmptyListView isDirectoryList={isDirectoryList} noSearchResults={noSearchResults} />
      )}
    </div>
  );
};

export default DirectMessagesList;
