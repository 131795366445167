import { useEffect, useRef } from "react";

import { useEditorState } from "../providers/EditorProvider";

export default function useOnClickInsideEditor(handler: (e: PointerEvent) => void) {
  const { ref: editor } = useEditorState(({ ref }) => ({ ref }));

  const handlerRef = useRef(handler);
  handlerRef.current = handler;

  useEffect(() => {
    if (!editor) return;

    const onClick = (e: PointerEvent) => handlerRef.current(e);

    editor.addEventListener("pointerdown", onClick);

    return () => editor.removeEventListener("pointerdown", onClick);
  }, [editor]);
}
