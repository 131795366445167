import { memo } from "react";
import { UserResponse } from "stream-chat/dist/types/types";
import { EventComponentProps, useChatContext } from "stream-chat-react";

import { GlueDefaultStreamChatGenerics } from "@utility-types";
import MessageAvatar from "components/design-system/ui/MessageAvatar";
import { Author } from "components/Message/Author";
import { MessageAttachments } from "components/Message/MessageAttachments";
import { ThreadAttachment } from "components/Message/ThreadAttachment";
import { Timestamp } from "components/Message/Timestamp";
import { renderText } from "components/Message/utils";
import useMessageRoleBadge from "components/thread/ThreadView/stream-components/hooks/useMessageRoleBadge";
import tw from "utils/tw";

import { ReadReceipt } from "../Message/components";
import { GroupStyle } from "../MessageList/utils";

// e.g. [Thread Title](glue:thr_123abc)
const threadMarkdownLinkRegex = /\[[^\]]+\]\((glue:thr_[^)]+)\)\.?$/;

/**
 * MessageSystem - Custom render component to display system and channel event messages
 */
const UnMemoizedMessageSystem = <T extends GlueDefaultStreamChatGenerics>(
  props: EventComponentProps<T> & {
    groupStyle: GroupStyle;
    readBy?: UserResponse<T>[];
  }
) => {
  const { client } = useChatContext();

  const { groupStyle, message, readBy } = props;
  const { text: messageText, type, user } = message;
  const isMyMessage = client.userID === user?.id;

  const { roleBadge, authorName } = useMessageRoleBadge(message);

  const deletedMessageText = "This message was deleted.";

  const messageGlueStatus: JSX.Element = (
    <ReadReceipt isMyMessage={isMyMessage} message={message} readBy={readBy} />
  );

  const isShowingAuthor = groupStyle === "top" || groupStyle === "single";

  if (!user || (message.type !== "system" && message.type !== "deleted")) return null;

  const startedThreadID = messageText?.match(/glue:(thr_[^)]+)/)?.[1];
  const attachmentsOnly = !!startedThreadID && messageText?.startsWith("started ");

  // we don't accept all attachments in system messages
  const validAttachments = message.attachments?.filter(
    ({ __typename }) =>
      // @ts-ignore backwards compatibility for temporary "Thread" type usage
      __typename === "Thread" || __typename === "ThreadPreview"
  );
  const messageAttachments = validAttachments?.length ? (
    <MessageAttachments
      attachments={validAttachments}
      compact={!attachmentsOnly}
      messageId={message.id}
    />
  ) : startedThreadID ? (
    <ThreadAttachment threadID={startedThreadID} showFirstMessage={attachmentsOnly} />
  ) : null;

  const adjustedMessageText =
    type === "deleted"
      ? deletedMessageText
      : startedThreadID
        ? messageText?.replace(threadMarkdownLinkRegex, "a thread.")
        : messageText;

  return (
    <div
      className={tw(
        "str-chat-message relative pl-16",
        `str-chat-message--${groupStyle} str-chat-message--system str-chat-message--received`
      )}
      data-testid="message-glue"
    >
      <div className="flex">
        <div className="flex flex-col shrink-0 pr-12 w-48">
          {message.user && isShowingAuthor ? (
            <MessageAvatar
              image={message.user.image}
              isMyMessage={isMyMessage}
              name={message.user.name}
              userID={message.user.id}
            />
          ) : (
            <Timestamp
              customClass="text-xs mb-4 text-text-subtle text-right select-none invisible mt-auto capitalize transition-opacity duration-350 opacity-0 subsequent-timestamp"
              date={message.created_at}
              reactTestId="subsequent-timestamp"
            />
          )}
        </div>
        <article className="relative w-full min-w-0">
          {message.user && isShowingAuthor ? (
            <Author
              createdAt={message.created_at}
              isMyMessage={isMyMessage}
              messageType={message.type}
              name={authorName}
              onUserClick={() => null}
              userID={message.user.id}
              roleBadge={roleBadge}
            />
          ) : null}

          <div className="relative w-full min-w-0">
            <div className="pr-16">
              <div className="str-chat-message-content" data-testid="message-glue-content">
                {!attachmentsOnly && (
                  <div
                    className="mt-2 text-subhead italic text-text-subtle"
                    data-testid="message-glue-message"
                  >
                    {renderText(adjustedMessageText)}
                  </div>
                )}

                {type !== "deleted" && messageAttachments}
              </div>
            </div>
            {messageGlueStatus}
          </div>
        </article>
      </div>
    </div>
  );
};

export const MessageSystem = memo(UnMemoizedMessageSystem) as typeof UnMemoizedMessageSystem;
