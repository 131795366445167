import Avatar from "components/design-system/Avatar/Avatar";
import { TWMargin } from "components/design-system/types/margin";
import { Skeleton } from "components/Skeleton";
import useAuthData from "hooks/useAuthData";
import tw from "utils/tw";

const ProfileRow = ({
  margin = "m-0",
}: {
  margin?: TWMargin;
}) => {
  const { authData } = useAuthData();

  return (
    <div className={tw("flex gap-8 items-center h-48 px-8", margin)}>
      <Avatar
        avatarURL={authData?.me.avatarURL}
        loading={!authData}
        name={authData?.me.name}
        rounded="rounded-md"
        size="medium"
      />
      <div className="flex flex-col grow min-w-0">
        <span className="text-subhead-bold truncate">
          {authData ? authData.me.name : <Skeleton width="180px" height="32px" />}
        </span>
        <span className="text-footnote">
          {authData ? (
            authData.addresses.edges[0]?.node.address
          ) : (
            <Skeleton width="180px" height="24px" />
          )}
        </span>
      </div>
    </div>
  );
};

export default ProfileRow;
