import { useMemo } from "react";

import { Message } from "@utility-types";
import { Button } from "components/design-system/Button";
import MessageAvatar from "components/design-system/ui/MessageAvatar";
import { Author } from "components/Message/Author";
import { MessageAttachments } from "components/Message/MessageAttachments";
import { Timestamp } from "components/Message/Timestamp";
import { renderText } from "components/Message/utils";
import { Skeleton } from "components/Skeleton";
import usePinMessage from "hooks/message/usePinMessage";
import useAuthData from "hooks/useAuthData";
import { formatDateWithTime } from "utils/formatDate";
import { glueMessageToStreamMessage } from "utils/stream/message";

const MessageContent = ({
  message,
  messageGlueStatus,
}: {
  message: (Message & { type?: string }) | undefined;
  messageGlueStatus?: JSX.Element | null;
}) => {
  const messageText = useMemo(() => renderText(message?.text ?? ""), [message]);

  const deleted = message?.type === "deleted";

  const asStreamMessage =
    message &&
    glueMessageToStreamMessage({
      ...message,
    });

  const messageGlueAttachment: JSX.Element | null =
    message && !deleted ? (
      <MessageAttachments
        attachments={asStreamMessage?.attachments || []}
        messageId={message.id}
        compact
      />
    ) : null;

  return (
    <div className="relative w-full min-w-0">
      <div className="str-chat-message-content" data-testid="message-glue-content">
        {message ? (
          <div data-testid="message-glue-message" onClick={() => {}}>
            {messageText}
          </div>
        ) : (
          <Skeleton />
        )}

        {message?.text === "" && messageGlueAttachment}
      </div>

      {message?.text !== "" && messageGlueAttachment}
      {message?.updatedAt && (
        <div className={"text-caption text-text-subtle mt-2 mb-4"}>
          (edited&nbsp;
          <Timestamp
            customClass="text-caption text-text-subtle"
            date={message.updatedAt}
            formatDate={formatDateWithTime}
          />
          )
        </div>
      )}

      {messageGlueStatus}
    </div>
  );
};

const PinnedMessage = ({
  message,
  onClick,
}: { message: Message & { type?: string }; onClick: () => void }) => {
  const { authData } = useAuthData();

  const isMyMessage = message.user.id === authData?.me.id;

  const { unpinMessage } = usePinMessage(message.threadID);

  return (
    <div
      className="str-chat-message cursor-pointer relative flex items-start w-full min-w-0"
      onClick={onClick}
    >
      <div className="flex items-start bg-background-body border border-border-container px-16 py-8 rounded-lg w-full">
        <div className="flex items-start min-w-0 pointer-events-none">
          <div className="pr-12">
            <MessageAvatar
              clickable={false}
              image={message.user.avatarURL ?? undefined}
              isMyMessage={isMyMessage}
              name={message.user.name}
              userID={message.user.id}
            />
          </div>

          <div className="flex flex-col min-w-0 w-full">
            <Author
              className="!pr-0"
              clickable={false}
              createdAt={message.createdAt}
              isMyMessage={message.user.id === authData?.me.id}
              messageID={message.id}
              name={message.user.name}
              onUserClick={() => null}
              userID={message.user.id}
            />

            <MessageContent message={message} />
          </div>
        </div>

        <div className="str-chat-message-actions absolute z-1 top-0 right-0 flex items-center justify-between float-right invisible transition-opacity duration-350 opacity-0">
          <Button
            buttonStyle="subtle"
            icon="Close"
            iconSize={20}
            onClick={e => {
              e.stopPropagation();
              unpinMessage(message);
            }}
            type="button"
          />
        </div>
      </div>
    </div>
  );
};

export default PinnedMessage;
