import { ButtonHTMLAttributes, useCallback, useEffect, useRef, useState } from "react";
import { useResizeObserver } from "usehooks-ts";

import { variantProps } from "components/helper/classNameVariants";
import UnreadBadge from "components/Navigation/UnreadBadge";
import useAppStateStore from "store/useAppStateStore";
import tw from "utils/tw";

export type Tab<T extends string> = ButtonHTMLAttributes<HTMLButtonElement> & {
  badgeCount?: number;
  tab: T;
  tabTitle?: React.ReactNode;
  unreadMentionCount?: number;
};

type Props<T extends string> = {
  className?: string;
  onClickTab: (tab: T) => void;
  selectedTab: T;
  size?: "small" | "medium";
  tabs: Tab<T>[];
  variant?: "tab" | "filter";
};

const getTabProps = variantProps({
  defaultVariants: {
    button: "default" as const,
    size: "default" as const,
    selected: "default" as const,
    unselected: "default" as const,
  },
  variants: {
    button: {
      tab: "min-w-32 capitalize border-b-2 font-semibold hover:text-text-primary",
      filter: "border-1 px-12 py-5 rounded-[100px] text-footnote capitalize",
      default: "",
    },
    size: {
      // padding values below are 2px less than figma values to account for the 2px border
      small: "pt-0 pb-4 text-footnote",
      medium: "pt-8 pb-4 text-body",
      default: "",
    },
    selected: {
      tab: "border-accent-primary text-text-primary",
      filter: "bg-background-action border-transparent text-text-action-inverse",
      default: "",
    },
    unselected: {
      tab: "border-transparent text-text-subtle",
      filter:
        "bg-background-body border-border-container text-text-secondary hover:bg-background-secondary hover:text-text-secondary-hover",
      default: "",
    },
  },
});

const Tabs = <T extends string>({
  className,
  onClickTab,
  selectedTab,
  size = "medium",
  tabs,
  variant = "tab",
}: Props<T>): JSX.Element => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [showIndicator, setShowIndicator] = useState(false);
  const isSmallScreen = !breakpointMD;
  const handler = useCallback(() => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const maxScrollLeft = container.scrollWidth - container.clientWidth - 1;

    setShowIndicator(container.scrollLeft < maxScrollLeft && maxScrollLeft > 0);
  }, []);

  useResizeObserver({
    ref: containerRef,
    onResize: () => {
      if (isSmallScreen) return;

      handler();
    },
  });

  useEffect(() => {
    if (isSmallScreen) return;

    const container = scrollContainerRef.current;

    container?.addEventListener("scroll", handler);

    return () => {
      container?.removeEventListener("scroll", handler);
    };
  }, [handler, isSmallScreen]);

  return (
    <div
      ref={containerRef}
      className={tw(
        "relative",
        "after:content-[''] after:absolute after:top-0 after:right-0 after:bottom-0 after:w-32",
        "after:bg-[linear-gradient(to_left,rgb(var(--color-background-app)),transparent_90%)] after:z-1 after:opacity-0 after:pointer-events-none",
        { "after:!opacity-100": showIndicator }
      )}
    >
      <div
        ref={scrollContainerRef}
        className={tw(
          "flex items-baseline max-w-full overflow-x-auto",
          {
            "gap-16": variant === "tab",
            "gap-8": variant === "filter",
          },
          { "webkit-scrollbar-hidden": isSmallScreen },
          className
        )}
      >
        {tabs.map(({ badgeCount, tab, tabTitle, unreadMentionCount, ...props }) => (
          <button
            {...props}
            key={tab.toString()}
            className={tw(
              "flex items-center justify-center relative shrink-0 select-none",
              getTabProps({
                button: variant,
                size: variant === "tab" ? size : "default",
                [selectedTab === tab ? "selected" : "unselected"]: variant,
              }).className
            )}
            onClick={() => onClickTab(tab)}
          >
            {tabTitle || tab}
            {(badgeCount || 0) > 0 && (
              <UnreadBadge
                badge={{
                  color: unreadMentionCount ? "red" : "blue",
                  count: badgeCount || 0,
                }}
                className="absolute left-[100%] ml-2"
              />
            )}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
