import { useEffect, useRef } from "react";

import { default as GlueLogo } from "assets/glue/glue-logo-color-white.svg?react";
import { useNativeKeyboardManager } from "components/App/hooks";
import useNativeKeyboardStore from "store/useNativeKeyboardStore";
import useSafeAreaInsetsStore from "store/useSafeAreaInsetsStore";
import { isNativeIOS } from "utils/platform";
import tw from "utils/tw";

const Start = ({ children, fullHeight = false }: WithChildren<{ fullHeight?: boolean }>) => {
  const appRef = useRef<HTMLDivElement>(null);

  useNativeKeyboardManager(appRef);

  const safeAreaInsetTop = useSafeAreaInsetsStore(({ top }) => top);
  const appLayoutReady = !isNativeIOS() || safeAreaInsetTop !== 0;

  const spacerRef = useRef<HTMLDivElement>(null); // creates space on mobile, making the logo visible when the keyboard is closed
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(
    () =>
      useNativeKeyboardStore.subscribe(
        ({ keyboardHeight }) => keyboardHeight,
        keyboardHeight => {
          const { current: spacer } = spacerRef;
          if (!spacer) return;
          spacer.style.height = `calc(${
            keyboardHeight > 100 ? "0px" : "124px"
          } + env(safe-area-inset-top))`;

          const { current: content } = contentRef;
          if (!content) return;
          content.style.paddingBottom = `${keyboardHeight}px`;
        }
      ),
    []
  );

  return (
    <div
      className={tw(
        "absolute bg-background-app-base inset-0 overflow-hidden md:overflow-auto text-center z-1",
        appLayoutReady ? "opacity-100" : "opacity-0",
        "before:inline-block before:w-0 before:h-full before:align-middle before:content-[''] before:text-[0px]"
      )}
      id="start"
      ref={appRef}
    >
      <div className={"align-bottom md:align-middle inline-block relative w-full h-full md:h-auto"}>
        <div className="flex flex-col h-dvh md:h-full md:py-32 w-full">
          {!fullHeight && (
            <>
              <div
                className={tw(
                  "absolute top-[calc(env(safe-area-inset-top)_+_48px)] -z-1",
                  "md:mx-auto md:pb-48 md:shrink-0 md:static"
                )}
              >
                <GlueLogo className="h-40 md:h-50 max-w-full" />
              </div>

              <div
                ref={spacerRef}
                className="h-[calc(124px_+_env(safe-area-inset-top))] md:!h-0 transition-height"
              />
            </>
          )}

          <div
            ref={contentRef}
            className={tw(
              "flex bg-background-body text-text-primary relative",
              "mx-auto rounded-t-[32px] md:rounded-[32px]",
              "grow min-h-0 w-full md:w-[600px] md:h-[600px]",
              "p-24 md:p-32 pb-[calc(32px_+_env(safe-area-inset-bottom))] overflow-hidden",
              { "!rounded-none": fullHeight }
            )}
          >
            {children}
          </div>

          <div className="h-[98px] shrink-0 hidden md:block" />
        </div>
      </div>
    </div>
  );
};

export default Start;
