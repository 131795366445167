import { setUser as setSentryUser } from "@sentry/react";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";

import "../sentry"; // initialize

import analytics from "analytics";
import { POSTHOG_WORKSPACE_GROUP_KEY } from "constants/posthog";
import useAuthData from "hooks/useAuthData";
import { usePrimaryWorkspaceEdge } from "hooks/usePrimaryWorkspaceEdge";

export const useIdentifyUser = () => {
  const posthog = usePostHog();
  const { authData, authInitialized } = useAuthData();
  const primaryWorkspace = usePrimaryWorkspaceEdge();

  useEffect(() => {
    if (!authInitialized) return;

    if (authData?.me.id) {
      const user = { id: authData.me.id };
      setSentryUser(user);
      analytics.identify(user.id);
      posthog.identify(user.id);

      // Known issue: this only supports rolling out features to users based on they're primary workspace
      if (primaryWorkspace) {
        posthog.group(POSTHOG_WORKSPACE_GROUP_KEY, primaryWorkspace.node.id);
        // Following PostHog's documentation, we need to set the group properties for flags so they don't rely on extra server calls.
        posthog.setGroupPropertiesForFlags({
          [POSTHOG_WORKSPACE_GROUP_KEY]: { name: primaryWorkspace.node.name },
        });
      }
    } else {
      setSentryUser(null);
      analytics.reset();
      posthog.reset();
    }
  }, [authData?.me, authInitialized, posthog, primaryWorkspace]);
};
