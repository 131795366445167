import { useHistory } from "react-router";

import { currentPathWithSearch } from "components/routing/utils";
import Sidebar from "components/SideBar/Sidebar";
import ComposeButton from "components/SideBar/SidebarHeader/ComposeButton";

import DirectMessagesMainTabs from "./DirectMessagesMain/DirectMessagesMainTabs";

export const SIDEBAR_ID = "mega-tab-sidebar--dms";

const DirectMessagesSidebarDesktop = () => {
  const history = useHistory();

  const onClickCompose = () => history.push(currentPathWithSearch({ v: "compose-dm" }));

  return (
    <Sidebar id={SIDEBAR_ID}>
      <DirectMessagesMainTabs
        buttons={<ComposeButton onClick={onClickCompose} />}
        title="Direct messages"
        desktopHeader
      />
    </Sidebar>
  );
};

export default DirectMessagesSidebarDesktop;
