import { ReactNode, useMemo } from "react";

import tw from "utils/tw";

import { Icon, IconName } from "../icons";

import styles from "./Pill.module.css";

type Variant = "primary" | "secondary";

type BasePillProps<T extends string> = {
  name: T;
  count?: number;
  onClick?: (pillName: T) => void;
  selected?: boolean;
  variant?: Variant;
  children: ReactNode;
};

type PillWithoutIconProps<T extends string> = BasePillProps<T> & {
  icon?: never;
  iconSize?: never;
  iconPosition?: never;
};

type PillWithIconProps<T extends string> = BasePillProps<T> & {
  icon: IconName;
  iconSize?: number;
  iconPosition: "leading" | "trailing";
};

type PillProps<T extends string> = PillWithoutIconProps<T> | PillWithIconProps<T>;

const Pill = <T extends string>({
  name,
  onClick,
  selected,
  variant = "primary",
  icon,
  iconSize = 16,
  iconPosition,
  children,
}: PillProps<T>) => {
  const iconComponent = useMemo(
    () =>
      !!icon && (
        <Icon
          icon={icon}
          size={iconSize}
          className={tw(styles.icon, selected && styles.selected)}
        />
      ),
    [icon, iconSize, selected]
  );

  return (
    <li
      className={tw(
        styles.pill,
        styles[variant],
        selected && styles.selected,
        icon ? styles.small : styles.large,
        onClick && !selected && styles.interactive
      )}
      onClick={() => (!selected ? onClick?.(name) : null)}
    >
      {iconPosition === "leading" && iconComponent}
      {children}
      {iconPosition === "trailing" && iconComponent}
    </li>
  );
};

export default Pill;
