import React, { forwardRef } from "react";

import { Button } from "components/design-system/Button";
import { VariantPropsOf, variantProps } from "components/helper/classNameVariants";
import tw from "utils/tw";

const className = {
  base: "border-b border-border-container flex flex-col items-start shrink-0 w-full relative select-none",
  defaultVariants: {
    height: "default" as const,
    padding: "default" as const,
  },
  variants: {
    height: {
      default: "min-h-[73px]",
      none: "",
    },
    padding: {
      default: "px-16 py-10",
      none: "",
      "thread-compose": "pt-10 pr-8 pl-16 md:pt-16 md:pb-8",
      "thread-compose-modal": "pt-16 pr-8 pl-12 md:pl-16",
    },
  },
};

const componentProps = variantProps(className);
type ComponentVariantProps = VariantPropsOf<typeof componentProps>;

type Props = WithChildren &
  ComponentVariantProps & {
    onClose?: () => void;
    trailingContent?: React.ReactNode;
  };

const PaneHeader = forwardRef<HTMLDivElement, Props>(
  ({ children, onClose, height, padding, trailingContent }, ref) => {
    return (
      <div
        ref={ref}
        {...componentProps({
          height,
          padding,
        })}
      >
        <div className="flex items-center justify-start w-full">
          {!!onClose && (
            <Button
              buttonStyle="subtle"
              buttonType="text"
              className={tw("mr-12 p-8", {
                "relative left-16": padding === "none",
              })}
              icon="Close"
              iconSize={20}
              iconStroke={2}
              onClick={e => {
                e.stopPropagation();
                onClose();
              }}
            />
          )}

          <div className="relative flex items-center grow min-w-0 h-full">{children}</div>
        </div>

        {trailingContent && (
          <div className={tw("w-full", { "pl-48": !!onClose })}>{trailingContent}</div>
        )}
      </div>
    );
  }
);

export default PaneHeader;
