import { forwardRef } from "react";

import Skeleton, { cloneElementForSkeletons } from "components/Skeleton/Skeleton";
import tw from "utils/tw";

type Props = {
  count?: number;
  on?: boolean;
};

const GroupDirectorySkeletons = forwardRef<HTMLDivElement, Props>(
  ({ count = 1, on = true }: Props, forwardedRef) => {
    if (!on) return null;
    return (
      <div
        ref={forwardedRef}
        className={tw(
          "[&>div]:border-b-1 [&>div]:border-border-container-subtle", // Row borders
          "[&>div:last-child]:border-none" // Remove last row border
        )}
      >
        {on
          ? cloneElementForSkeletons(
              <div className={tw("group/row pr-20 relative", "flex items-center justify-between")}>
                <div className="grow min-w-0 mr-20 pl-20 py-12">
                  <div className="flex grow items-center max-w-full">
                    <Skeleton className="mr-8" height="40px" width="40px" />
                    <div className="flex flex-col">
                      <div className="flex items-center justify-start h-[18px]">
                        <Skeleton className="mr-8" height="15px" width="96px" />
                      </div>
                      <div className="flex items-center justify-start h-[18px]">
                        <Skeleton className="mr-8" height="15px" width="128px" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>,
              count
            )
          : null}
      </div>
    );
  }
);

export default GroupDirectorySkeletons;
