import React from "react";
import { isDayOrMoment, useTranslationContext } from "stream-chat-react";

import tw from "utils/tw";

export type DateSeparatorProps = {
  /** The date to format */
  date: Date;
  /** Override the default formatting of the date. This is a function that has access to the original date object. */
  formatDate?: (date: Date) => string;
  /** Set the position of the date in the separator, options are 'left', 'center', 'right', @default right */
  position?: "left" | "center" | "right";
  /** If following messages are not new */
  unread?: boolean;
};

const UnMemoizedDateSeparator = (props: DateSeparatorProps) => {
  const { date, formatDate } = props;

  const { tDateTimeParser } = useTranslationContext("DateSeparator");

  if (typeof date === "string") return null;

  const parsedDate = tDateTimeParser(date.toISOString());

  const formattedDate = formatDate
    ? formatDate(date)
    : isDayOrMoment(parsedDate)
      ? parsedDate.calendar()
      : parsedDate;

  return (
    <div
      className={tw(
        "flex justify-center items-center my-12 select-none",
        "before:block before:w-full before:ml-15 before:h-1 before:bg-background-subtle",
        "after:block after:w-full after:mr-15 after:h-1 after:bg-background-subtle"
      )}
    >
      <span
        className={tw(
          "flex items-center h-24 px-16 shrink-0 text-sm text-text-strong select-none",
          "bg-background-body border-border-container border-thin rounded-[12px] shadow-subtle-ui"
        )}
      >
        {formattedDate}
      </span>
    </div>
  );
};

/**
 * A simple date separator between messages.
 */
export const DateSeparator = React.memo(UnMemoizedDateSeparator) as typeof UnMemoizedDateSeparator;
