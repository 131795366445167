import { MouseEvent } from "react";
import { Link } from "react-router-dom";

import { Message, Recipient, ThreadPreview, ThreadSimple } from "@utility-types";
import Tooltip from "components/design-system/FloatingUi/Tooltip";
import Icon from "components/design-system/icons/Icon";
import LineClamp from "components/design-system/ui/LineClamp";
import { routeToThread } from "components/routing/utils";
import { Skeleton } from "components/Skeleton";
import useAuthData from "hooks/useAuthData";
import getRandomInt from "utils/getRandomInt";
import prettyUrlBreaks from "utils/prettyUrlBreaks";
import tw from "utils/tw";

import RecipientsLine from "./RecipientsLine";

type ThreadInfoProps = {
  headerType: "simple" | "feed" | "full";
  isPreviewOnly?: boolean;
  recipients: Recipient[] | undefined;
  replyTo:
    | {
        message: Message;
        thread: ThreadSimple | ThreadPreview;
      }
    | undefined;
  subject: string | undefined;
  onClick?: (e: MouseEvent) => void;
};

const ThreadInfo = ({
  headerType,
  onClick,
  recipients,
  replyTo,
  subject,
  isPreviewOnly = false,
}: ThreadInfoProps) => {
  const { authData } = useAuthData();
  const clickable = !!recipients && !!onClick;
  const showHover = clickable && headerType !== "feed";

  subject = subject ? prettyUrlBreaks(subject) : undefined;

  const Subject = () => {
    switch (true) {
      case !subject:
        return <Skeleton height="20px" width={`${getRandomInt(200, 300)}px`} />;
      default:
        return (
          <Tooltip
            content={headerType === "feed" ? "Open thread" : "Get thread details"}
            disabled={isPreviewOnly}
            placement="bottom"
            tooltipStyle="inverted"
          >
            <div
              className={tw(
                "flex min-w-0 items-center text-text-primary",
                {
                  "cursor-pointer select-none": clickable,
                },
                {
                  "cursor-default": isPreviewOnly,
                }
              )}
              onClick={clickable ? onClick : undefined}
            >
              <LineClamp>{subject}</LineClamp>

              {showHover ? (
                <Icon icon="ChevronDown" className="ml-2 shrink-0 text-icon-secondary" size={12} />
              ) : null}
            </div>
          </Tooltip>
        );
    }
  };

  return (
    <div className="min-w-0 md:-ml-6 p-2 w-full">
      <h2
        className={tw(
          "max-w-full px-4 py-2 m-0 inline-flex items-start",
          "text-headline-bold leading-5",
          {
            "hover:bg-background-secondary rounded-md": showHover,
          }
        )}
      >
        {replyTo ? (
          <Tooltip
            content={`From "${replyTo.thread.subject}"`}
            placement="bottom"
            tooltipStyle="inverted"
          >
            <Link
              data-tooltip="reply"
              onClick={e => e.stopPropagation()}
              to={routeToThread({
                messageID: replyTo.message.id,
                threadID: replyTo.thread.id,
                to: "primary",
              })}
            >
              <Icon
                className={tw("bg-background-link text-icon-action", "mt-2 mr-4 p-2 rounded-sm")}
                icon="CornerDownRight"
                size={16}
              />
            </Link>
          </Tooltip>
        ) : null}

        <Subject />
      </h2>

      {headerType !== "simple" && (
        <div
          className="flex items-center min-w-0 px-4 h-20 text-sm leading-[1.125rem] text-text-secondary w-full"
          data-testid="Thread Recipients List"
        >
          <RecipientsLine me={authData?.me} recipients={recipients} />
        </div>
      )}
    </div>
  );
};

export default ThreadInfo;
