import { usePostHog } from "posthog-js/react";

import { featureFlagConfigs, FeatureFlagKey, isLocalFeatureFlag } from "featureFlags";
import useFeatureFlagStore from "store/useFeatureFlagStore";

export const useFeatureFlagEnabled = (flag: FeatureFlagKey): boolean => {
  const posthog = usePostHog();
  const flagConfig = featureFlagConfigs[flag];

  const localFlag = useFeatureFlagStore(({ flags }) => {
    const key = flagConfig.key;
    if (isLocalFeatureFlag(key)) {
      return flags[key];
    }
    return undefined;
  });

  if (flagConfig.source === "posthog") {
    try {
      return posthog.isFeatureEnabled(flagConfig.key) ?? flagConfig.defaultValue ?? false;
    } catch (error) {
      let message = "[FeatureFlagError] Error fetching feature flag from PostHog";
      if (error && typeof error === "object" && "message" in error) {
        message += `: ${error.message}`;
      }

      console.error(message, flag);

      return flagConfig.defaultValue ?? false;
    }
  } else if (flagConfig.source === "local" && localFlag !== undefined) {
    return localFlag;
  }

  return false;
};
