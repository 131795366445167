import tw from "utils/tw";

import { Sidebar } from "./Sidebar";

const View = ({
  main,
  menu,
}: {
  main: React.ReactNode;
  menu: React.ReactNode;
}) => {
  return (
    <div className="flex relative h-full w-full">
      <div className="absolute inset-0">
        <div className="flex relative h-full w-full">
          <Sidebar>{menu}</Sidebar>

          <div
            className={tw(
              "bg-background-body flex flex-col w-full h-full",
              "absolute inset-0 md:relative md:inset-auto",
              "transition-transform",
              main ? "translate-x-0" : "translate-x-[100vw] md:translate-x-0"
            )}
          >
            {main}
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
