import {
  Placement,
  autoUpdate,
  flip,
  shift,
  safePolygon,
  useClick,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import { useState } from "react";

const isTestEnv = process.env.NODE_ENV === "test";

type Props = {
  keepOpenOnHover?: boolean;
  openDelay?: number; // ms
  openOnClick?: boolean;
  openOnHover?: boolean;
  placement?: Placement;
  role?: "dialog" | "tooltip";
  strategy?: "absolute" | "fixed"; // use "fixed" when rendering floating element in a portal
};

const useFloatingElement = ({
  keepOpenOnHover,
  openDelay,
  openOnClick,
  openOnHover,
  placement,
  role: roleProp = "dialog",
  strategy,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const { context, floatingStyles, refs } = useFloating({
    onOpenChange: setIsOpen,
    open: isOpen,
    placement,
    strategy,
    whileElementsMounted: autoUpdate,
    // on combining flip and shift: https://floating-ui.com/docs/flip#combining-with-shift
    middleware: [
      flip({
        crossAxis: false,
      }),
      shift(),
    ],
  });
  const click = useClick(context, {
    enabled: !!openOnClick,
    keyboardHandlers: true,
  });
  const dismiss = useDismiss(context);
  const hover = useHover(context, {
    delay: { close: 0, open: isTestEnv ? 0 : (openDelay ?? 0) },
    enabled: !!openOnHover,
    handleClose: keepOpenOnHover ? safePolygon({ blockPointerEvents: false }) : undefined,
    mouseOnly: !isTestEnv,
    move: false,
  });
  const role = useRole(context, { role: roleProp });

  const { getFloatingProps, getReferenceProps } = useInteractions([click, dismiss, hover, role]);

  return {
    anchorProps: getReferenceProps({
      ref: refs.setReference,
    }),
    floatingProps: getFloatingProps({
      ref: refs.setFloating,
      style: floatingStyles,
    }),
    isOpen,
    setIsOpen,
  };
};

export default useFloatingElement;
