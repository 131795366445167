import { Form } from "components/design-system/Forms";
import { InformationBubble } from "components/design-system/InformationBubble";
import useComposeToGlue from "components/threads/hooks/useComposeToGlue";
import { useWorkspaceSubscriptionPlansQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useModalStore from "store/useModalStore";

import ChoosePlan from "./billing/ChoosePlan";
import CurrentPlan from "./billing/CurrentPlan";
import Invoices from "./billing/Invoices";
import PaymentModal from "./billing/PaymentModal";
import { ChoosePlanFormData } from "./billing/types";
import usePlanRemainingDays from "./hooks/usePlanRemainingDays";
import useWorkspaceSubscription from "./hooks/useWorkspaceSubscription";

const WorkspaceBilling = ({ workspaceID }: { workspaceID: string }) => {
  const { authData, authReady } = useAuthData();

  const { data: plansData } = useWorkspaceSubscriptionPlansQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-and-network",
    skip: !authData?.me.id,
  });

  const availablePlans = plansData?.workspaceSubscriptionPlans;

  const { data: wksSubscriptionData, refetch } = useWorkspaceSubscription(workspaceID);
  const wksSubscription = wksSubscriptionData?.workspaceSubscription;

  const wksPlan = wksSubscription?.plan;

  const daysRemaining = usePlanRemainingDays();

  const { composeThreadToGlue } = useComposeToGlue("sales");

  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  if (!wksSubscription) return null;

  const handlePayment = (data: ChoosePlanFormData) => {
    openModal(
      <PaymentModal planID={data.plan} refetchSubscription={refetch} workspaceID={workspaceID} />
    );
  };

  return (
    <div className="px-0 py-20">
      <CurrentPlan
        daysRemaining={daysRemaining(wksSubscriptionData)}
        subscription={wksSubscription}
        workspaceID={workspaceID}
      />

      <Form<ChoosePlanFormData>
        onSubmit={handlePayment}
        useFormProps={{
          defaultValues: {
            plan: wksPlan?.id ?? availablePlans?.[0]?.id,
          },
        }}
      >
        <ChoosePlan
          subscription={wksSubscription}
          plans={availablePlans}
          workspaceID={workspaceID}
        />
      </Form>

      <Invoices subscription={wksSubscription} workspaceID={workspaceID} />

      <div className="px-20 md:px-32">
        <InformationBubble iconProps={{ icon: "ChatRounded" }}>
          For questions or help,{" "}
          <button
            className="font-bold text-text-link hover:text-text-link-hover"
            onClick={() => composeThreadToGlue()}
            type="button"
          >
            contact sales
          </button>
          . To learn more,{" "}
          <a
            className="font-bold text-text-link hover:text-text-link-hover"
            href="https://glue.ai/pricing"
            rel="noreferrer"
            target="_blank"
          >
            view plans
          </a>
          .
        </InformationBubble>
      </div>
    </div>
  );
};

export default WorkspaceBilling;
