import { groupBy } from "lodash-es";
import { useMemo } from "react";
import { Link } from "react-router-dom";

import { nodeAs } from "@utility-types";
import Avatar from "components/design-system/Avatar/Avatar";
import Hr from "components/design-system/Hr";
import { Timestamp } from "components/Message/Timestamp";
import { routeToThread } from "components/routing/utils";
import { Skeleton } from "components/Skeleton";
import { cloneElementForSkeletons } from "components/Skeleton/Skeleton";
import ThreadItemContentAttachment from "components/threads-list/ThreadItem/ThreadItemContentAttachment";
import { ItemType, parseData } from "components/threads-list/ThreadItem/utils";
import { MessageFieldsFragment } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useChatRecipient from "hooks/useChatRecipient";
import useAppStateStore from "store/useAppStateStore";
import { formatDate } from "utils/formatDate";
import getRandomInt from "utils/getRandomInt";
import { formatGroupName } from "utils/group/formatGroupName";
import getRecipients from "utils/thread/getRecipients";
import isGlueAIRecipient from "utils/thread/isGlueAIRecipient";
import tw from "utils/tw";

import { Results } from "../types";

const DeepSearchConversation = ({
  item,
  messages = [],
}: {
  item: ItemType;
  messages?: ({ __typename: "Message" } & MessageFieldsFragment)[];
}) => {
  const { authData } = useAuthData();
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  const data = parseData({ authData, item });
  const threadEdge = nodeAs(item, ["ThreadEdge", "ThreadPreviewEdge"]);
  const thread = threadEdge?.node || nodeAs(item, ["Thread", "ThreadPreview"]);

  const getChatRecipient = useChatRecipient();

  if (!thread) return null;

  const chatRecipient = thread?.__typename === "Thread" ? getChatRecipient(thread) : undefined;
  const recipients = getRecipients(item);

  const timestamp =
    thread.__typename === "Thread" ? (
      <Timestamp
        customClass="text-caption text-text-subtle"
        date={thread.lastMessage?.createdAt ?? thread.createdAt}
        formatDate={formatDate}
      />
    ) : (
      <span className="text-caption text-text-subtle shrink-0">{data.date}</span>
    );

  const { emoji, name: formattedName } = formatGroupName({
    name: chatRecipient?.name ?? "",
  });

  const icon = data.chatType
    ? "ChatRounded"
    : isGlueAIRecipient(recipients)
      ? "SparkleFilled"
      : "Thread";

  return (
    <div
      className={tw(
        "bg-background-body mb-16 overflow-hidden md:rounded-lg select-none shadow-level1",
        "flex flex-col"
      )}
      data-testid="deep-search-conversation"
    >
      <Link className="block" to={routeToThread({ threadID: thread.id, to: "secondary" })}>
        <div
          className={tw(
            "border-border-container flex gap-8 items-center px-24 py-8",
            "hover:bg-background-list-hover",
            {
              "border-b-1": !!messages.length,
            }
          )}
        >
          <Avatar
            avatarURL={data.chatType === "user" ? recipients[0]?.avatarURL : undefined}
            background="transparent"
            className="text-icon-secondary"
            emojiProps={{ emoji }}
            iconProps={{ icon }}
            size={breakpointMD ? "x-small" : "small"}
          />

          <div className="flex flex-col grow min-w-0">
            <div className="flex gap-8 items-baseline w-full">
              <div className="flex gap-8 items-baseline grow min-w-0">
                <div className="text-body-bold truncate">{formattedName || thread.subject}</div>
                <div className="text-footnote text-text-secondary truncate">
                  {data.chatType === "group"
                    ? "Group chat"
                    : data.chatType === "user"
                      ? "Direct message"
                      : data.recipients}
                </div>
              </div>

              {!messages.length && timestamp && <div className="shrink-0">{timestamp}</div>}
            </div>

            {!messages.length && (
              <div className="flex items-center justify-start text-footnote text-text-subtle">
                {data.messageSender ? (
                  <>
                    {(data.chatType !== "user" || data.messageSender === "You") && (
                      <div>
                        {data.messageSender}
                        {data.showSkeleton ? "" : ":"}&nbsp;
                      </div>
                    )}

                    {data.message ? (
                      <div className="min-w-0 truncate">{data.message}</div>
                    ) : (
                      <ThreadItemContentAttachment attachmentTypes={data.attachmentTypes} />
                    )}
                  </>
                ) : (
                  <div className="truncate">No messages yet.</div>
                )}
              </div>
            )}
          </div>
        </div>
      </Link>

      <div
        className={tw("flex flex-col gap-8 py-8", {
          hidden: !messages.length,
        })}
      >
        {messages.map((message, index) => {
          return (
            <Link
              key={message.id}
              to={routeToThread({
                messageID: message?.streamID ?? message.id,
                threadID: thread.id,
                to: "secondary",
              })}
            >
              <div className="hover:bg-background-list-hover">
                <div className="flex items-start ml-24 py-8 pr-24">
                  <Avatar
                    avatarURL={message.user.avatarURL}
                    margin="mr-12"
                    name={message.user.name}
                    rounded="rounded-md"
                    size="large"
                  />
                  <div className="w-full">
                    <div className="flex items-baseline">
                      <span className="grow min-w-0 mr-4 text-subhead-bold">
                        {message.user.name}
                      </span>
                      <Timestamp
                        customClass="text-caption text-text-subtle"
                        date={message.createdAt}
                        formatDate={formatDate}
                      />
                    </div>
                    <div className="text-body">{message.textPreview}</div>
                  </div>
                </div>

                {messages.length - 1 !== index && <Hr className="ml-24" />}
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

const DeepSearchConversationSkeleton = () => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  return (
    <div
      className="bg-background-body mb-16 overflow-hidden md:rounded-lg shadow-level1"
      data-testid="deep-search-conversation"
    >
      <div className="flex gap-8 items-center px-24 py-9">
        <Avatar loading size={breakpointMD ? "x-small" : "small"} />
        <div className="flex flex-col grow min-w-0">
          <div className="flex gap-8 items-baseline w-full">
            <div className="flex gap-8 items-baseline grow min-w-0">
              <div className="text-body-bold truncate">
                <Skeleton className="m-0" height="22px" width={`${getRandomInt(200, 300)}px`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DeepSearchConversations = ({
  threads,
  searching,
}: {
  threads: Results<"threads">;
  searching: boolean;
}) => {
  const messagesByThreadID = useMemo(
    () =>
      groupBy(
        [...(threads?.matchedMessages?.edges ?? [])].map(e => e.node),
        m => m.threadID
      ),
    [threads?.matchedMessages?.edges]
  );
  const skeletons = useMemo(
    () => cloneElementForSkeletons(<DeepSearchConversationSkeleton />, 5),
    []
  );

  if (!searching && !threads.edges.length)
    return (
      <div className="md:mt-[128px] px-24 py-16 text-center">
        <div className="text-body-bold text-text-primary">No conversations found</div>
        <div className="text-body text-text-secondary">
          Try using different keywords or check for typos.
        </div>
      </div>
    );

  return (
    <>
      {threads.edges.map(edge => (
        <DeepSearchConversation
          key={edge.node.id}
          item={edge}
          messages={messagesByThreadID[edge.node.id]}
        />
      ))}
      {searching && skeletons}
    </>
  );
};

export default DeepSearchConversations;
