import { nodeIs } from "@utility-types";
import {
  GroupPreviewSimpleFieldsFragment,
  GroupSimpleFieldsFragment,
  WorkspacePreviewSimpleFieldsFragment,
  WorkspaceSimpleFieldsFragment,
} from "generated/graphql-operations";

export const isInternalGroup = (
  node:
    | GroupPreviewSimpleFieldsFragment
    | GroupSimpleFieldsFragment
    | WorkspacePreviewSimpleFieldsFragment
    | WorkspaceSimpleFieldsFragment,
  workspaces: string[]
) => {
  const isInternalGroup =
    nodeIs(node, ["Group", "GroupPreview"]) && workspaces.includes(node.workspaceID ?? "");

  const isInternalWorkspace = nodeIs(node, ["Workspace"]) && workspaces.includes(node.id);

  const isInternal = isInternalGroup || isInternalWorkspace;

  return isInternal;
};
