import { captureException } from "@sentry/react";

import useOnce from "hooks/useOnce";
import { setSessionStorage } from "utils/sessionStorage";

const allowedHosts = [
  "api.typeform.com",
  "zoom.us",
  "github.com",
  "www.figma.com",
  "accounts.google.com",
  "app.clickup.com",
  "sentry.io",
  "auth.monday.com",
];

const validateLocation = (location: string | null) => {
  if (!location) return null;
  const url = new URL(location);
  if (url.protocol !== "https:") return null;
  if (allowedHosts.includes(url.host)) {
    return url.toString();
  }
  return null;
};

/*
 * When doing Masonry auth for 3rd party apps from the native app, we need to invoke
 * the native app at the end of the auth process. This is used to set a flag so that we
 * know to invoke the native app in IntegrationCallbackHandler at the end of the process.
 */
const IntegrationRedirectHandler = (): null => {
  useOnce(() => {
    const params = new URLSearchParams(window.location.search);
    const location = validateLocation(params.get("location"));
    if (location) {
      setSessionStorage("native-app-callback", "true");

      // Save webAuthState if present
      // webAuthState is necessary for Android to be able to map sign in attempts to caller
      // With Android, there's no straightforward way to open a URL and get the result
      // specifically from the window you opened. If you open 2 windows you could get results
      // back in any order. With webAuthState, Android is able to save this value on the
      // native side and then when we deliver the result in IntegrationCallbackHandler,
      // the native side is able to look up the corresponding call to deliver the result.
      const state = params.get("webAuthState");
      if (state) {
        setSessionStorage("web-auth-state", state);
      }
      window.location.href = location;
    } else {
      captureException(`Invalid integration redirect location: ${location}`);
    }
  });
  return null;
};

export default IntegrationRedirectHandler;
