import { ApolloProvider } from "@apollo/client";
import { createContext, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";

import usePrevious from "hooks/usePrevious";
import { zustandStoreResetFns } from "store/helper/glueCreate";
import sanitizeRedirect from "utils/sanitizeRedirect";

import {
  AuthData,
  FetchAuthData,
  useAuthedClient,
  useFetchAuthData,
  useFirebaseAuth,
} from "./hooks";

export type IAuthContext = {
  authData?: AuthData;
  authError: boolean;
  authInitialized: boolean;
  authNeeded: boolean;
  authReady: boolean;
  authURL: string | null;
  clearApolloCache: () => Promise<void>;
  fetchAuthData: FetchAuthData;
  signOut: () => void;
};

export type { AuthData } from "./hooks";

export const AuthContext = createContext({} as IAuthContext);

let continueURL: string | null;
if (window.location.pathname === "/signin") {
  // When signing in for Oauth, the Oauth flow will load the app with `/signin`
  // if the user is not already logged in. In this case we should have the user
  // sign in and then navigate to the `continue` URL. To complete the signin
  // process for this case we need to perform a GraphQL query with our access token
  // in order to cause a cookie to be set.
  continueURL = sanitizeRedirect(
    new URLSearchParams(window.location.search).get("continue") ?? undefined
  );
}

const storesToResetOnLogout = [
  "AppStateStore",
  "DraftMessagesStore",
  "HistoryStore",
  "LocalSettingsStore",
  "OnboardingStore",
  "RoutesStore",
];

export const AuthProvider = ({ children }: WithChildren) => {
  const { authError, authInitialized, authNeeded, authReady, authURL, getAuthToken, signOut } =
    useFirebaseAuth();
  const authReadyWas = usePrevious(authReady);

  const { apolloClient, clearApolloCache } = useAuthedClient(getAuthToken, authReady);
  const { authData, clearAuthData, fetchAuthData } = useFetchAuthData(apolloClient, signOut);

  const clearAppData = useCallback(async () => {
    await clearApolloCache();
    zustandStoreResetFns.forEach(({ func, name }) => {
      if (storesToResetOnLogout.includes(name)) {
        func();
      }
    });
  }, [clearApolloCache]);

  const history = useHistory();

  const handleSignOut = useCallback(() => {
    signOut();
    history.push("/");
  }, [signOut, history]);

  useEffect(() => {
    (async () => {
      try {
        if (authReady && !authReadyWas) {
          // signing in

          // If we are going to redirect back to the Oauth flow, we need to perform
          // a graphql query first to cause a cookie to be set.
          const refresh = continueURL != null;
          await fetchAuthData({ refresh });
          if (continueURL) {
            window.location.href = continueURL;
          }
        } else if (!authReady && authNeeded) {
          // signing out
          await clearAppData();
          await clearAuthData();
        }
      } catch (err) {
        console.warn("Error: [AuthProvider] -", err);
        // TODO: handle fetchAuthData() retry
        // openSnackbar("server"); // handled by Apollo ErrorLink
      }
    })();
  }, [
    authNeeded,
    authReady,
    authReadyWas,
    authURL,
    clearAppData,
    clearAuthData,
    fetchAuthData,
    history,
  ]);

  return (
    <AuthContext.Provider
      value={{
        authData,
        authError,
        authInitialized,
        authNeeded,
        authReady,
        authURL,
        clearApolloCache,
        fetchAuthData,
        signOut: handleSignOut,
      }}
    >
      <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
