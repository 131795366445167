import { UseTransitionProps, useTransition } from "@react-spring/web";
import { CSSProperties, useCallback, useEffect, useState } from "react";

import { defaultSpring } from "components/Animated/utils";
import useComponentMounted from "hooks/useComponentMounted";
import { useLayerState } from "providers/LayerProvider";
import useModalStore from "store/useModalStore";
import tw from "utils/tw";

import { CloseButton } from "./CloseButton";
import { Container } from "./Container";
import { Content } from "./Content";
import { useModalState } from "./ModalProvider";
import { Veil } from "./Veil";

type Props = {
  afterClose?: () => void;
  afterOpen?: () => void;
  alignment?: {
    x: "text-center" | "text-left" | "text-right";
    y: "align-bottom" | "align-middle" | "align-top";
  };
  children?: React.ReactNode;
  closeButton?: boolean;
  closeButtonPosition?: "auto" | "right";
  closeButtonClassName?: string;
  closeButtonTitle?: string;
  closeable?: boolean;
  containerScrolling?: boolean;
  contentClassName?: string;
  contentStyle?: CSSProperties;
  contentTransitions?: UseTransitionProps;
  disableOpacityTransition?: boolean;
};

export type ModalProps = Props;

const isTestEnv = process.env.NODE_ENV === "test";

export const Modal = ({
  afterClose,
  afterOpen,
  alignment = { x: "text-center", y: "align-middle" },
  children,
  closeable = true,
  closeButton = true,
  closeButtonPosition = "auto",
  closeButtonClassName = tw(
    "absolute z-1",
    {
      "top-1 left-0 right-auto px-10 h-[56px]": closeButtonPosition === "auto", // legacy mobile
    },
    {
      "md:right-0 md:left-auto md:px-[16px] md:h-[60px]": closeButtonPosition === "auto", // legacy desktop
    },
    {
      "w-40 h-44 top-8 right-8 left-auto md:top-16 md:size-24 md:right-16":
        closeButtonPosition === "right", // new Cyan style
    }
  ),
  closeButtonTitle = "Close",
  containerScrolling = true,
  contentClassName,
  contentStyle,
  contentTransitions,
  disableOpacityTransition,
}: Props) => {
  const {
    dragging: { dragStyles },
    enqueuedToClose,
    modalId,
    state: modalState,
  } = useModalState(({ dragging, enqueuedToClose, modalId, state }) => ({
    dragging,
    enqueuedToClose,
    modalId,
    state,
  }));

  const { immediate, removeModal, setBackdropId, topModalId } = useModalStore(
    ({ immediate, removeModal, setBackdropId, topModalId }) => ({
      immediate,
      removeModal,
      setBackdropId,
      topModalId,
    })
  );

  const isMounted = useComponentMounted();

  const [atRest, setAtRest] = useState(false);
  const [open, setOpen] = useState(true);

  const { layerIsActive } = useLayerState(({ layerIsActive }) => ({
    layerIsActive,
  }));

  const [containerOverflowY, setContainerOverflowY] = useState("");

  const handleCloseModal = useCallback(() => {
    if (atRest) {
      setOpen(false);
      useModalStore.getState().updateModalState({
        id: modalId,
        nextState: "closing",
      });
      if (modalId === topModalId) {
        useModalStore.setState({
          topModalState: "closing",
        });
      }
    }
  }, [atRest, modalId, topModalId]);

  useEffect(() => {
    if (!closeable) return;
    const handleEsc = (e: KeyboardEvent) => {
      if (modalId !== topModalId || !layerIsActive) return;
      if (e.key === "Escape") {
        e.preventDefault();
        e.stopPropagation();

        handleCloseModal();
      }
    };

    window.addEventListener("keydown", handleEsc);
    return () => window.removeEventListener("keydown", handleEsc);
  }, [closeable, handleCloseModal, layerIsActive, modalId, topModalId]);

  useEffect(() => {
    if (enqueuedToClose) {
      handleCloseModal();
    }
  }, [enqueuedToClose, handleCloseModal]);

  const transitions = useTransition(open, {
    config: defaultSpring,
    enter: {
      opacity: 1,
      ...contentTransitions?.enter,
      onRest: () => {
        if (atRest || !isMounted.current) return;

        setAtRest(true);
        useModalStore.getState().updateModalState({
          id: modalId,
          nextState: "open",
        });
        useModalStore.setState({
          topModalState: "open",
        });
        containerScrolling && setContainerOverflowY("overflow-y-auto");
        afterOpen?.();
      },
    },
    from: {
      opacity: 0,
      ...contentTransitions?.from,
    },
    immediate: isTestEnv || immediate,
    leave: {
      opacity: 0,
      ...contentTransitions?.leave,
      onRest: () => {
        if (open) return;
        removeModal?.(`${modalId}`);
        afterClose?.();
      },
      onStart: () => {
        setBackdropId?.(modalId);
        setContainerOverflowY("");
      },
    },
  });

  return transitions(
    (styles, open) =>
      open && (
        <Container
          className={tw("modal", containerOverflowY)}
          data-testid="modal-container"
          modalId={modalId}
        >
          <div
            className="fixed w-full h-full top-0"
            onClick={closeable ? handleCloseModal : undefined}
          />
          <Content
            alignment={alignment}
            contentClassName={tw(contentClassName, {
              "pointer-events-none": modalState === "closing",
            })}
            disableOpacityTransition={disableOpacityTransition}
            dragStyles={(closeable !== false && dragStyles) || {}}
            styles={{
              ...contentStyle,
              ...styles,
            }}
          >
            {children}
            {closeable && closeButton && (
              <CloseButton
                className={closeButtonClassName}
                handleClose={handleCloseModal}
                title={closeButtonTitle}
              />
            )}
            <Veil modalId={modalId} />
          </Content>
        </Container>
      )
  );
};
