import { last } from "lodash-es";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";

import { Mailbox, Recipient, ThreadEdgeFeed } from "@utility-types";
import { Button } from "components/design-system/Button";
import { EmptyListPrimitive } from "components/design-system/EmptyListPrimitive";
import { Icon } from "components/design-system/icons";
import { TabName, superTabNames } from "components/routing/utils";
import { cloneElementForSkeletons } from "components/Skeleton/Skeleton";
import ThreadComposeModal from "components/threads/ThreadComposeModal";
import { ThreadsOrder } from "generated/graphql";
import useSuperTabSideEffect from "hooks/__mobile__/useSuperTabSideEffect";
import useAuthData from "hooks/useAuthData";
import useModalStore from "store/useModalStore";
import tw from "utils/tw";

import FeedThreadItem, { FeedSkeletonItem } from "./FeedThreadItem";
import useThreadFeedData from "./hooks/useThreadFeedData";

const CaughtUpMarker = ({
  anyUnseen,
  hasMoreSeen,
  moreShown,
  onClickMore,
}: {
  anyUnseen: boolean;
  hasMoreSeen: boolean;
  moreShown: boolean;
  onClickMore: () => void;
}) => {
  const showCentered = !anyUnseen && !moreShown;

  return (
    <div
      className={tw("flex flex-col grow items-center p-24 md:px-0 mb-12", {
        "justify-center": showCentered,
      })}
    >
      {showCentered ? (
        <Icon
          className="my-24 text-interactive-subtle/50"
          icon="CheckCircle"
          size={80}
          strokeWidth={2}
        />
      ) : null}
      <div
        className={tw(
          "flex w-full justify-center items-center text-base font-semibold text-interactive-subtle",
          {
            "before:block after:block before:w-full after:w-full before:h-2 after:h-2 after:bg-interactive-subtle/25 before:bg-interactive-subtle/25":
              !showCentered,
          }
        )}
      >
        <span className="whitespace-nowrap select-none px-16">
          {!showCentered && (
            <Icon
              className="inline-block mr-6 -mt-2 text-interactive-subtle/75"
              icon="CheckCircle"
              size={24}
              strokeWidth={2}
            />
          )}
          You're all caught up!
        </span>
      </div>
      {!moreShown ? (
        <Button
          buttonStyle="simpleSecondary"
          className={tw("flex items-center justify-center mt-8", {
            invisible: !hasMoreSeen,
          })}
          onClick={onClickMore}
        >
          View Already Seen
        </Button>
      ) : null}
    </div>
  );
};

const UpdatesAvailableButton = ({
  onClick,
  updates,
}: {
  onClick: () => void;
  updates: number;
}) =>
  updates > 0 ? (
    <div className="z-1 flex sticky top-16 mt-16 mb-24 w-full items-center">
      <Button className="mx-auto my-2 rounded-half" onClick={onClick}>
        <Icon className="mr-12" icon="RefreshCW" size={16} /> {updates} update
        {updates > 1 ? "s" : ""} available
      </Button>
    </div>
  ) : null;

const pageSize = 10;

export const Feed = ({
  className,
  scrollEl,
  roundedCard = true,
  recipient,
  hideCompose = false,
}: {
  className?: string;
  scrollEl?: HTMLDivElement | null;
  roundedCard?: boolean;
  recipient?: Recipient;
  hideCompose?: boolean;
}) => {
  const { authData } = useAuthData();
  const mountedAt = useRef(new Date().toISOString());
  const recipientIsGroup =
    recipient?.__typename === "Group" || recipient?.__typename === "GroupPreview";
  const threadEdgesRef = useRef<ThreadEdgeFeed[]>([]);
  const [unseenEdges, setUnseenEdges] = useState<ThreadEdgeFeed[]>([]);
  const [seenEdges, setSeenEdges] = useState<ThreadEdgeFeed[]>([]);
  const [lastEdgeUnseen, setLastEdgeUnseen] = useState(false);
  const [moreShown, setMoreShown] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState({});
  const [updatesAvailable, setUpdatesAvailable] = useState(new Set<string>());
  const [feedStateVersion, setFeedStateVersion] = useState(0);

  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const openThreadCompose = useCallback(() => {
    openModal(
      <ThreadComposeModal initialDraft={recipient ? { recipients: [recipient] } : undefined} />
    );
  }, [openModal, recipient]);

  const {
    hasNextPage,
    loadNextPage,
    refresh,
    result: { error, loading, threadEdges },
  } = useThreadFeedData({
    excludeChats: true,
    mailbox: Mailbox.All,
    order: ThreadsOrder.Unseen,
    pageSize,
    recipientID: recipient?.id,
  });

  if (!threadEdgesRef.current.length) {
    threadEdgesRef.current = threadEdges?.slice() ?? [];
  }

  const getUnseenIDs = useCallback(
    (edges: ThreadEdgeFeed[], prevUnseenMessageIDs?: Set<string>) => {
      const unseenMessageID = new Set<string>();
      edges.slice(-pageSize).forEach(edge => {
        if (edge.isSeen || !edge.node.lastMessage) return;
        if (edge.node.lastMessage.user.id === authData?.me.id) return;
        if (edge.node.lastMessage.createdAt < mountedAt.current) return;
        if (prevUnseenMessageIDs?.has(edge.node.lastMessage.id)) return;
        unseenMessageID.add(edge.node.lastMessage.id);
      });
      return unseenMessageID;
    },
    [authData?.me.id]
  );

  const updateThreadList = useCallback(() => {
    refresh().then(({ data }) => {
      scrollEl?.scrollTo(0, 0);
      threadEdgesRef.current = data.threads?.edges.slice() ?? [];
      setMoreShown(false);
      setShouldUpdate({});
      setUpdatesAvailable(new Set());
      setFeedStateVersion(v => v + 1);
    });
  }, [refresh, scrollEl]);

  const updateThreadListRef = useRef(updateThreadList);
  updateThreadListRef.current = updateThreadList;

  const [scrollSentryRef, { rootRef: scrollListRef }] = useInfiniteScroll({
    disabled: !!error,
    hasNextPage,
    loading,
    onLoadMore: () => {
      loadNextPage().then(({ data }) => {
        data.threads.edges.findLast(edge => {
          if (!threadEdgesRef.current.find(e => e.node.id === edge.node.id)) {
            threadEdgesRef.current.unshift(edge);
          }
        });

        setShouldUpdate({});
      });
    },
    rootMargin: "0px 0px 400px 0px",
  });

  useEffect(() => {
    scrollListRef(scrollEl);
  }, [scrollEl, scrollListRef]);

  // Show updates available when we receive a new message
  useEffect(() => {
    if (!threadEdges || !threadEdgesRef.current.length) return;
    const prevUnseenIDs = getUnseenIDs(threadEdgesRef.current);
    const newUnseenIDs = getUnseenIDs(threadEdges, prevUnseenIDs);
    if (!newUnseenIDs.size) {
      return;
    }
    setUpdatesAvailable(newUnseenIDs);
  }, [authData?.me.id, getUnseenIDs, threadEdges]);

  // The name could be confusing because this is the first thread shown in the feed
  const lastThread = last(threadEdges);
  useEffect(() => {
    // Scroll to top when user creates a new thread.
    if (
      lastThread?.node.firstMessage?.user.id === authData?.me.id &&
      lastThread?.node.firstMessage?.id === lastThread?.node.lastMessage?.id
    ) {
      updateThreadListRef.current();
      return;
    }
  }, [authData?.me.id, lastThread]);

  // update feed only first time and when we trigger an update
  const hasLoaded = !!threadEdges;
  useLayoutEffect(() => {
    const edges = threadEdgesRef.current.slice().reverse();
    const lastUnseen = edges.findLast(e => !e.isSeen)?.cursor ?? "zzz";
    const unseenEdges = edges.filter(e => !e.isSeen && e.cursor >= lastUnseen);
    const seenEdges = edges.filter(e => e.isSeen && e.cursor < lastUnseen);
    const shouldShowMore = unseenEdges.length < 5 && seenEdges.length > 0;
    setUnseenEdges(unseenEdges);
    setSeenEdges(seenEdges);
    setLastEdgeUnseen(!(threadEdgesRef.current[0]?.isSeen ?? true));
    setMoreShown(moreShown => moreShown || shouldShowMore);
  }, [hasLoaded, shouldUpdate, feedStateVersion]);

  const willLoadMore = hasNextPage && (moreShown || lastEdgeUnseen);

  const { setTabSideEffects } = useSuperTabSideEffect(({ setTabSideEffects }) => ({
    setTabSideEffects,
  }));
  useEffect(() => {
    setTabSideEffects(superTabNames[TabName.Feed], () => updateThreadListRef.current());
  }, [setTabSideEffects]);

  const isEmpty = !!threadEdges && !threadEdges.length;

  return (
    <div key={feedStateVersion} className={tw("relative flex flex-col w-full", className)}>
      <UpdatesAvailableButton onClick={updateThreadList} updates={updatesAvailable.size} />

      {unseenEdges?.map(edge => (
        <FeedThreadItem key={edge.id} edge={edge} roundedCard={roundedCard} />
      ))}

      {isEmpty && (
        <EmptyListPrimitive
          padding="px-24 pt-32"
          icon="Thread"
          primaryText="No threads... yet"
          secondaryText={
            recipientIsGroup
              ? "Threads sent to this group will appear here."
              : "All your threads will appear here."
          }
        >
          {!hideCompose && (
            <Button
              buttonStyle="primary"
              className="!text-subhead-bold"
              onClick={openThreadCompose}
            >
              New thread
            </Button>
          )}
        </EmptyListPrimitive>
      )}
      {!!threadEdges &&
        (!willLoadMore || moreShown) &&
        (!updatesAvailable.size || !!unseenEdges.length) &&
        !isEmpty && (
          <CaughtUpMarker
            anyUnseen={!!unseenEdges.length}
            hasMoreSeen={hasLoaded && !!seenEdges.length}
            moreShown={moreShown}
            onClickMore={() => setMoreShown(true)}
          />
        )}

      {moreShown
        ? seenEdges?.map(edge => (
            <FeedThreadItem key={edge.id} edge={edge} roundedCard={roundedCard} />
          ))
        : null}

      {!threadEdges || willLoadMore ? (
        <>
          <div ref={scrollSentryRef} />
          {cloneElementForSkeletons(<FeedSkeletonItem />, 3)}
        </>
      ) : null}
    </div>
  );
};

export default Feed;
