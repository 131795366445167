import { Deploy } from "@gluegroups/cordova-plugin-ionic";
import { memo, useEffect, useState } from "react";

import NewVersionSnackbar from "components/SnackBar/NewVersionSnackbar";
import useAuthData from "hooks/useAuthData";
import { isNative, isNativeMac } from "utils/platform";

const ascTestDomains = new Set(["apple.com", "asc.gluegroups.com", "privaterelay.appleid.com"]);

export const SIMULATE_APP_UPDATE_AVAILABLE = "SIMULATE_APP_UPDATE_AVAILABLE";

export function useHasAppUpdate() {
  const { authData, clearApolloCache } = useAuthData();
  const userDomains = authData?.me.addressDomains ?? [];

  const [hasUpdate, setHasUpdate] = useState(false);

  // Native Mac update fetches updates from the native code
  useEffect(() => {
    if (!isNativeMac()) {
      return;
    }

    let mounted = true;
    const newVersionHandler = () => {
      if (mounted) {
        setHasUpdate(true);
      }
    };

    const effect = (async () => {
      await Deploy.subscribeNewPersistedBasePath(newVersionHandler);

      const pendingVersion = await Deploy.getPendingPersistedVersion();
      if (pendingVersion) {
        newVersionHandler();
      }
    })();

    return () => {
      mounted = false;
      (async () => {
        await effect;
        await Deploy.unsubscribeNewPersistedBasePath(newVersionHandler);
      })();
    };
  }, []);

  return {
    hasAppUpdate:
      !!localStorage.getItem(SIMULATE_APP_UPDATE_AVAILABLE) ||
      (hasUpdate &&
        // We don't support live update for native app without a non-test work domain
        isNative() &&
        userDomains.some(d => !ascTestDomains.has(d))),
    applyAppUpdate: async () => {
      setHasUpdate(false);

      localStorage.removeItem(SIMULATE_APP_UPDATE_AVAILABLE);

      await clearApolloCache(); // clear cache before update to avoid cache incompatibility issues

      if (isNative()) {
        Deploy.reloadApp();
      } else {
        window.location.reload();
      }
    },
  };
}

const AppUpdate = memo(() => {
  const { hasAppUpdate, applyAppUpdate } = useHasAppUpdate();

  if (!hasAppUpdate) {
    return null;
  }

  return <NewVersionSnackbar clickHandler={applyAppUpdate} />;
});

AppUpdate.displayName = "AppUpdate";

export default AppUpdate;
