import { useEffect, useRef } from "react";
import { useHistory } from "react-router";

import { Modal } from "components/ModalKit/Modal";
import { useModalState } from "components/ModalKit/ModalProvider";
import useAppStateStore from "store/useAppStateStore";
import useModalStore from "store/useModalStore";
import useNativeKeyboardStore from "store/useNativeKeyboardStore";
import tw from "utils/tw";

type Props = WithChildren<{
  contentClassName?: string;
  contentHandlesSafeArea?: boolean;
}>;

export const LambdaFullScreen = ({
  children,
  contentClassName,
  contentHandlesSafeArea = true,
}: Props) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  const history = useHistory();
  const {
    dragging: { bind },
    modalId,
  } = useModalState(({ dragging, modalId }) => ({
    dragging,
    modalId,
  }));
  const { closeModal } = useModalStore(({ closeModal }) => ({
    closeModal,
  }));

  // if location changes, close the modal.
  useEffect(() => history?.listen(() => closeModal(modalId)), [closeModal, history, modalId]);

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(
    () =>
      useNativeKeyboardStore.subscribe(
        ({ keyboardHeight }) => keyboardHeight,
        keyboardHeight => {
          const { current: element } = contentRef;
          if (!element) return;

          const keyboardOffset = `${Math.ceil(keyboardHeight)}px`;
          element.style.paddingBottom = contentHandlesSafeArea
            ? `calc(${keyboardOffset} - env(safe-area-inset-bottom))`
            : keyboardOffset;
        }
      ),
    [contentHandlesSafeArea]
  );

  return (
    <Modal
      alignment={
        breakpointMD
          ? undefined
          : {
              x: "text-center",
              y: "align-bottom",
            }
      }
      containerScrolling={false}
      contentClassName={tw("w-full overflow-hidden", contentClassName)}
      contentTransitions={
        breakpointMD
          ? undefined
          : {
              enter: {
                y: "0%",
              },
              from: {
                y: "100%",
              },
              leave: {
                y: "100%",
              },
            }
      }
      disableOpacityTransition={false}
    >
      <div
        ref={contentRef}
        className={tw("flex flex-col h-safe-viewport-[0px]", {
          "pb-[env(safe-area-inset-bottom)]": !contentHandlesSafeArea,
        })}
      >
        <div className="h-55 shrink-0 cursor-grab touch-none select-none" {...bind()} />
        <div className="item-center flex grow flex-col justify-center w-full">{children}</div>
        <div className="h-55 shrink-0" />
      </div>
    </Modal>
  );
};
