import { App as CapApp } from "@capacitor/app";
import { captureException } from "@sentry/react";
import axios from "axios";
import { User, isSignInWithEmailLink } from "firebase/auth";
import { useCallback, useEffect, useState } from "react";

import useNotificationsPermissions from "components/devices/hooks/useNotificationPermissions";
import { NotificationsPermissionsModal } from "components/Notification";
import { SIGNED_IN_LOCAL_STORAGE_PREFIX } from "constants/auth";
import NoAuthError from "providers/AuthProvider/NoAuthError";
import { useSnackbar } from "providers/SnackbarProvider";
import useAppStateStore from "store/useAppStateStore";
import useModalStore from "store/useModalStore";
import useOnboardingStore from "store/useOnboardingStore";
import env from "utils/processEnv";
import { getSessionStorage, removeSessionStorage } from "utils/sessionStorage";

import FirebaseProxy from "./FirebaseProxy";

const signedInKey = (user: User) => `${SIGNED_IN_LOCAL_STORAGE_PREFIX}${user.uid}`;
const signedInKeys = () =>
  Object.keys(localStorage).filter(key => key.startsWith(SIGNED_IN_LOCAL_STORAGE_PREFIX));

export const useFirebaseAuth = (): {
  authError: boolean;
  authInitialized: boolean;
  authNeeded: boolean;
  authReady: boolean;
  authURL: string | null;
  getAuthToken: () => Promise<string | undefined>;
  signOut: () => void;
} => {
  const [authNeeded, setAuthNeeded] = useState(() => signedInKeys().length === 0);
  const [authReady, setAuthReady] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [authURL, setAuthURL] = useState<null | string>(null);
  const [authInitialized, setAuthInitialized] = useState(false);

  const { openSnackbar } = useSnackbar();

  const { checkPermissions } = useNotificationsPermissions();

  const onFinishSignIn = useCallback((user: User) => {
    setAuthNeeded(false);
    setAuthReady(true);
    setAuthURL(null);

    localStorage.setItem(signedInKey(user), "true");
  }, []);

  // Deauthenticate Firebase user and revoke our refresh token
  const deauthenticate = useCallback(async () => {
    setAuthNeeded(true);
    setAuthReady(false);
    setAuthURL(null);

    const currentUser = FirebaseProxy.auth.currentUser;
    if (currentUser) {
      signedInKeys().forEach(key => localStorage.removeItem(key));
      await FirebaseProxy.auth.signOut();
    }
  }, []);

  const finishSignIn = useCallback(
    async (user: User) => {
      const signIn = async () =>
        axios.post(
          `${env.glueApiUrl}/auth/signin`,
          {
            skipWelcome: true,
            workspaceInviteLink: getSessionStorage("workspace-invite-link"),
          },
          { headers: { Authorization: `Bearer ${await user.getIdToken()}` } }
        );

      return signIn()
        .then(() => user.getIdToken(true).then(() => user.reload()))
        .then(() => onFinishSignIn(user))
        .finally(() => removeSessionStorage("workspace-invite-link"));
    },
    [onFinishSignIn]
  );

  const { openModal, closeAllModals } = useModalStore(({ openModal, closeAllModals }) => ({
    openModal,
    closeAllModals,
  }));

  // Firebase user is ready, sign in to Glue API if needed
  const authenticate = useCallback(
    async (user: User) => {
      setAuthError(false);

      if (localStorage.getItem(signedInKey(user))) {
        onFinishSignIn(user);
        return;
      }

      const handleSignInErr = (err: Error, errorHandler?: () => void) => {
        deauthenticate();
        setAuthError(true);

        if (axios.isAxiosError(err) && err.response?.status === 401) {
          openSnackbar("error", <NoAuthError />);
          return;
        }

        captureException(err);
        errorHandler?.();
        openSnackbar("server");
      };

      const startSignIn = async () => {
        if ((await user.getIdTokenResult()).claims.glue) {
          // Firebase token already authenticated with Glue API
          onFinishSignIn(user);
          closeAllModals();
          const permissions = await checkPermissions();
          if (permissions === "prompt") openModal(<NotificationsPermissionsModal />);
          return;
        }

        // Let user accept ToS / Privacy Policy before sign in
        useAppStateStore.setState({
          handleAuthSignIn: errorHandler =>
            finishSignIn(user).catch(err => handleSignInErr(err, errorHandler)),
        });
        useOnboardingStore.setState({
          onboarding: true,
          user: { name: user.displayName ?? "", avatarURL: user.photoURL },
        });
      };

      startSignIn().catch(handleSignInErr);
    },
    [
      checkPermissions,
      closeAllModals,
      deauthenticate,
      finishSignIn,
      onFinishSignIn,
      openModal,
      openSnackbar,
    ]
  );

  // Grab user from Firebase when the auth state changes
  useEffect(
    () =>
      FirebaseProxy.auth.onAuthStateChanged(async user => {
        await (user ? authenticate(user) : deauthenticate());
        setAuthInitialized(true);
      }),
    [authenticate, deauthenticate]
  );

  // Allow hook consumers to fetch the refreshed auth token
  const getAuthToken = useCallback(async () => FirebaseProxy.auth.currentUser?.getIdToken(), []);

  const convertNativeSignInURL = (url: string) => {
    /* spellchecker: disable */
    /**
     * This handler is called for the native app when the user selects the
     * link that is present in the sign in email. In order to complete the
     * sign in process, we will reload the app (setting document.location.href)
     * with query params extracted from the URL.
     *
     * Here is what the sign in link looks like (with some endlines added to
     * make the nested URLs/params more readable and with some sensitive
     * values obfuscated):
     *
     * https://gluegroupsdev.page.link/
     *   ?link=https://glue-dev-55b8.firebaseapp.com/__/auth/action
     *     ?apiKey%3DAAAAAAAA
     *     %26mode%3DsignIn
     *     %26oobCode%3DOOOOOOOOOOO
     *     %26continueUrl%3Dhttps://glue-web-jason.ngrok.io/
     *       ?ui_sid%253DetTCq5vXuvrLcQH3XkuyseWEs749OrlT
     *       %2526ui_sd%253D0
     *       %26lang%3Den
     *   &ibi=com.gluegroups.glue
     *   &ifl=IIIIIIIIIII
     *
     * When a user logs in with email on the web app, the following happens:
     * 1. gluegroupsdev.page.link redirects to the `link` value
     *      (glue-dev-55b8.firebaseapp.com)
     * 2. The query params present on the URL from #1 are extracted and added
     *      to the URL present in the `continueUrl` query param
     * 3. glue-dev-55b8.firebaseapp.com redirects to the continueUrl value,
     *      but with the additional query params from #2 added.
     *
     * The native app is invoked directly with the full link above. The code
     * below recreates that logic present in the redirect process above so
     * that the location it sets contains the same query params as if the app
     * had been loaded via the link in the browser.
     *
     * Other notes:
     * - `mode` is necessary for the FB libraries to identify the URL as an
     *     email sign in link
     * - `oobCode` is the OTP that is exchanged for user credentials
     * - `ui_sid` and `ui_sd` are added by firebaseui. They are necessary for
     *     for firebaseui to extract the email (stored in an encrypted cookie)
     *     that was entered after the app is reloaded.
     * - It is unknown if `apiKey` is important
     * - The value in `ifl` is identical to the value in `link`. Looking at
     *     the source code for the native library, it uses `link` instead
     *     of `ifl`.
     * - The code below is agnostic of hostname. Those values vary by
     *     environment.
     * - Some, but not all Android devices extract the `link` from the
     *     `link` param before invoking the app. This was observed on S21
     *     with Android 12 but not Pixel 3a VM with Adroid 11
     */
    /* spellchecker: enable */
    const embeddedLink = new URL(url).searchParams.get("link") || url;
    const embeddedLinkParams = Object.fromEntries(
      new URL(embeddedLink || "").searchParams.entries()
    );
    const { continueUrl: continueURL, ...embeddedLinkParamsNoContinue } = embeddedLinkParams;

    const continueURLParams = Object.fromEntries(new URL(continueURL || "").searchParams);

    const combinedQueryParams = {
      ...embeddedLinkParamsNoContinue,
      ...continueURLParams,
    };

    return `/?${new URLSearchParams(combinedQueryParams).toString()}`;
  };

  useEffect(() => {
    if (!authInitialized) {
      return;
    }

    const listenerHandle = CapApp.addListener("appUrlOpen", async ({ url }) => {
      if (!isSignInWithEmailLink(FirebaseProxy.auth, url)) return;
      if (!authNeeded) await deauthenticate();
      setAuthURL(convertNativeSignInURL(url));
    });

    return () => {
      (async () => (await listenerHandle).remove())();
    };
  }, [authInitialized, authNeeded, deauthenticate]);
  return {
    authError,
    authInitialized,
    authNeeded,
    authReady,
    authURL,
    getAuthToken,
    signOut: deauthenticate,
  };
};
