/**
 * Sets multiple React refs to the same node.
 */
const mergeRefs = (...refs: (React.MutableRefObject<HTMLElement | null> | null | undefined)[]) => {
  return (node: HTMLElement) => {
    for (const ref of refs) {
      if (ref) {
        ref.current = node;
      }
    }
  };
};

export default mergeRefs;
