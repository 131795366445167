import { DayPicker } from "react-day-picker";

import { Button } from "components/design-system/Button";

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

const Calendar = ({ className, ...props }: CalendarProps) => {
  return (
    <DayPicker
      className={className}
      classNames={{
        root: "w-full p-16 flex bg-background-body",
        months: "flex relative",
        nav: "absolute flex justify-between items-center w-full h-32",
        nav_button_previous: "absolute",
        nav_button_next: "absolute",
        month_caption: "flex justify-center items-center h-32",
        caption_label: "text-subhead-bold",
        month_grid: "flex flex-col w-full mt-4",
        weekdays: "flex gap-4",
        weekday:
          "flex justify-center items-center h-32 w-32 p-12 text-center text-footnote text-text-subtle",
        weeks: "flex flex-col mt-4 gap-4",
        week: "flex gap-4",
        day: "flex justify-center items-center h-32 w-32 text-center text-subhead cursor-pointer rounded-md hover:bg-background-list-hover",
        day_button: "w-full h-full cursor-pointer [&:disabled]:!cursor-default",
        outside: "text-text-disabled !cursor-default",
        disabled: "!text-text-disabled !cursor-default hover:!bg-background-body",
        selected: "bg-background-action text-text-action-inverse hover:!bg-background-action-hover",
      }}
      components={{
        Chevron: ({ orientation }) => (
          <Button
            icon={orientation === "left" ? "ChevronLeft" : "ChevronRight"}
            iconSize={24}
            buttonStyle="icon-secondary"
          />
        ),
      }}
      {...props}
    />
  );
};

export default Calendar;
