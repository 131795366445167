import { FeedbackButton } from "./FeedbackButton";

type Props = {
  handlePositiveFeedback: () => void;
  handleSendFeedback: () => void;
};

export const FeedbackWidget = ({ handlePositiveFeedback, handleSendFeedback }: Props) => (
  <div className="my-4 flex rounded-[24px] border border-border-container-subtle bg-background-body hover:border-interactive-subtle">
    <FeedbackButton onClick={handlePositiveFeedback} icon="ThumbsUp" />
    <FeedbackButton onClick={handleSendFeedback} icon="ThumbsDown" />
  </div>
);
