/* spellchecker: disable */
type ClassValue =
  | ClassValue[]
  | {
      [id: string]: ClassValue;
    }
  | string
  | number
  | null
  | boolean
  | undefined;

function toVal(mix: ClassValue[] | ClassValue) {
  let k: string | number;
  let y: ClassValue[] | ClassValue;
  let str = "";

  if (typeof mix === "string" || typeof mix === "number") {
    str += mix;
  } else if (typeof mix === "object") {
    if (Array.isArray(mix)) {
      for (k = 0; k < mix.length; k++) {
        if (mix[k]) {
          if ((y = toVal(mix[k]))) {
            str && (str += " ");
            str += y;
          }
        }
      }
    } else {
      for (k in mix) {
        if (mix?.[k]) {
          str && (str += " ");
          str += k;
        }
      }
    }
  }

  return str;
}
/**
 * Function takes any number of arguments which can be a string or object.
 *
 * className={tw("class1 class2", isTrue ? "classA" : "classB", className)}
 *
 * With only a single argument, it does not accept a string. Use a simple string instead:
 *
 * className="class1 class2"
 * className={isTrue ? "class1" : "class2"}
 *
 * For Tailwind CSS IntelliSense see example below.
 *
 * ```
 * const { className } = props;
 * const className = {
 *  body: tw(
 *   { [`platform-${isNativeMobile() ? "native" : "web"}`]: true,},
 *   "native:transition-opacity",
 *   className
 *  ),
 * };
 *
 * ```
 */
export default function tw(arg: Exclude<ClassValue, string>): string;
export default function tw(arg: ClassValue, arg2: ClassValue, ...args: ClassValue[]): string;

export default function tw(...args: ClassValue[]): string {
  let i = 0;
  let tmp: ClassValue[] | ClassValue;
  let x: ClassValue[] | ClassValue;
  let str = "";
  while (i < args.length) {
    if ((tmp = args[i++])) {
      if ((x = toVal(tmp))) {
        str && (str += " ");
        str += x;
      }
    }
  }
  return str;
}
