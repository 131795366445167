import { nodeAs } from "@utility-types";
import { useFetchThreadEdgeQuery, useFetchThreadEdgeSimpleQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import { IAuthContext } from "providers/AuthProvider";

type QueryOptions = {
  fetchPolicy?: "cache-and-network" | "cache-first" | "cache-only";
};

const buildQueryOptions = (
  { authData, authReady }: Pick<IAuthContext, "authData" | "authReady">,
  threadID: string | undefined,
  { fetchPolicy = "cache-first" }: QueryOptions
) => ({
  fetchPolicy: authReady ? fetchPolicy : ("cache-only" as const),
  nextFetchPolicy: "cache-first" as const,
  skip: !authData?.me.id || !threadID,
  variables: { id: `${threadID}-${authData?.me.id}` },
});

const nodeTypes = ["ThreadEdge" as const, "ThreadPreviewEdge" as const];

const useFetchThreadEdge = (threadID: string | undefined, queryOptions: QueryOptions = {}) => {
  const options = buildQueryOptions(useAuthData(), threadID, queryOptions);
  const { data, loading } = useFetchThreadEdgeQuery(options);
  return { threadEdge: nodeAs(data?.node, nodeTypes), loading };
};

export const useFetchThreadEdgeSimple = (
  threadID: string | undefined,
  queryOptions: QueryOptions = {}
) => {
  const options = buildQueryOptions(useAuthData(), threadID, queryOptions);
  const { data, loading } = useFetchThreadEdgeSimpleQuery(options);
  return { threadEdge: nodeAs(data?.node, nodeTypes), loading };
};

export default useFetchThreadEdge;
