import { Ref, useEffect } from "react";
import {
  DeepPartial,
  FieldValues,
  FormProvider,
  Path,
  SubmitHandler,
  UseFormProps,
  useForm,
} from "react-hook-form";

import usePrevious from "hooks/usePrevious";
import tw from "utils/tw";

type Props<TFieldValues extends FieldValues> = {
  autoComplete?: string;
  className?: string;
  formRef?: Ref<HTMLFormElement>;
  loading?: boolean;
  onSubmit?: SubmitHandler<TFieldValues>;
  resetDefaultValues?: boolean;
  initialFocusOnField?: Path<TFieldValues>;
  useFormProps?: Omit<UseFormProps<TFieldValues>, "defaultValues"> & {
    defaultValues: DeepPartial<TFieldValues>;
  };
};

export const Form = <TFieldValues extends FieldValues>({
  autoComplete = "off",
  children,
  className,
  formRef,
  loading,
  onSubmit,
  resetDefaultValues,
  useFormProps,
  initialFocusOnField,
  ...props
}: WithChildren<Props<TFieldValues>>) => {
  const prevLoading = usePrevious(loading);
  const methods = useForm<TFieldValues>(useFormProps);

  const { defaultValues } = useFormProps || {};
  const {
    formState: { isSubmitSuccessful },
    getValues,
    handleSubmit,
    reset,
    setFocus,
  } = methods;

  useEffect(() => {
    if (initialFocusOnField) {
      setFocus(initialFocusOnField);
    }
  }, [initialFocusOnField, setFocus]);

  useEffect(() => {
    if (!loading && prevLoading !== loading) {
      reset(defaultValues);
    }
  }, [defaultValues, loading, prevLoading, reset]);

  const currentValues = getValues();
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(resetDefaultValues ? defaultValues : currentValues, {
        keepDirty: false,
        keepValues: !resetDefaultValues,
      });
    }
  }, [currentValues, defaultValues, isSubmitSuccessful, reset, resetDefaultValues]);

  const submitFunction = async () => {
    try {
      await onSubmit?.(getValues());
    } catch (_e) {
      return;
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        ref={formRef}
        autoComplete={autoComplete}
        className={tw("m-0", className)}
        onSubmit={handleSubmit(submitFunction)}
        onReset={() => reset(undefined, { keepDirty: false })}
        {...props}
      >
        {children}
      </form>
    </FormProvider>
  );
};
