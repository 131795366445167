import { Button } from "components/design-system/Button";
import ThreadComposeModal from "components/threads/ThreadComposeModal";
import useGroupRecipients from "hooks/useGroupRecipients";
import useModalStore from "store/useModalStore";

const ComposeButton = ({
  className = "p-8 -my-8 -mr-8",
  onClick: onClickCompose,
}: { className?: string; onClick?: () => void }) => {
  const groupRecipients = useGroupRecipients();

  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const handleComposeInModal = () =>
    openModal(<ThreadComposeModal initialDraft={{ recipients: groupRecipients }} />);

  return (
    <Button
      buttonStyle="icon-primary"
      buttonType="icon"
      className={className}
      icon="Compose"
      iconSize={20}
      onClick={onClickCompose ? onClickCompose : handleComposeInModal}
      testId="new-thread-inbox-button"
    />
  );
};

export default ComposeButton;
