import { HTMLProps } from "react";

import { Button } from "components/design-system/Button";
import tw from "utils/tw";

import styles from "./DisposableElement.module.css";

type Props = HTMLProps<HTMLDivElement> & {
  children: JSX.Element;
  className?: string;
  onRemove: () => void;
};

const DisposableElement = ({ children, className, onRemove }: WithChildren<Props>): JSX.Element => (
  <div className={tw("group/disposable-element", styles["disposable-element"], className)}>
    <div className={styles["disposable-element-inner"]}>
      {children}

      <Button
        buttonStyle="none"
        buttonType="none"
        className={tw(
          "mouse:hidden group-hover/disposable-element:mouse:flex",
          styles["disposable-element-close"]
        )}
        data-testid="disposable-close-btn"
        onClick={onRemove}
      >
        <svg // fill and stroke colors are set in CSS, find ".disposable-element-close"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="none"
          viewBox="0 0 20 20"
        >
          <rect
            width="18"
            height="18"
            x="1"
            y="1"
            fill="#44494D"
            stroke="#fff"
            strokeWidth="2"
            rx="9"
          />
          <path stroke="#fff" strokeLinecap="round" strokeWidth="1.5" d="m7 7 6 6m0-6-6 6" />
        </svg>
      </Button>
    </div>
  </div>
);

export default DisposableElement;
