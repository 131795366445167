import { differenceInDays } from "date-fns";

import { WorkspaceSubscriptionQuery } from "generated/graphql";

const usePlanRemainingDays = () => {
  return (wksSubscriptionData: WorkspaceSubscriptionQuery) => {
    if (!wksSubscriptionData) return 0;

    const { currentPeriodEnd } = wksSubscriptionData.workspaceSubscription;

    if (!currentPeriodEnd) return 0;

    return Math.max(differenceInDays(new Date(currentPeriodEnd), new Date()) + 1, 0);
  };
};

export default usePlanRemainingDays;
