import { Token } from "@capacitor/push-notifications";
import { devtools, subscribeWithSelector } from "zustand/middleware";

import { NotificationPermissionStatus } from "generated/graphql";

import { glueCreateZustand } from "./helper/glueCreate";

type NotificationsPermissionStore = {
  permissionStatus?: NotificationPermissionStatus;
  token?: Token;
  tokenInitialized: boolean;
  setState: (values: Partial<NotificationsPermissionStore>) => void;
};

const STORE_NAME = "NotificationsPermissionStore";

const useNotificationPermissionsStore = glueCreateZustand<NotificationsPermissionStore>({
  name: STORE_NAME,
})(
  devtools(
    subscribeWithSelector(set => {
      return {
        permissionStatus: undefined,
        token: undefined,
        tokenInitialized: false,
        setState: values => set(state => ({ ...state, ...values })),
      };
    }),
    { name: STORE_NAME }
  )
);

export default useNotificationPermissionsStore;
