import { ComponentProps } from "react";

import { cloneElementForSkeletons } from "components/Skeleton/Skeleton";

import Section from "./Section";
import SectionBody from "./SectionBody";
import SectionHeader from "./SectionHeader";
import SectionItem from "./SectionItem";

type Props = {
  divider?: ComponentProps<typeof Section>["divider"];
  rows?: number;
  title: string;
};

const SectionSkeleton = ({ children, divider, rows = 5, title }: WithChildren<Props>) => (
  <Section divider={divider} header={<SectionHeader title={title} />}>
    <SectionBody
      sectionItems={cloneElementForSkeletons(
        <SectionItem className="mx-16">{children}</SectionItem>,
        rows
      )}
    />
  </Section>
);

export default SectionSkeleton;
