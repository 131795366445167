import { Button } from "components/design-system/Button";
import { Card } from "components/design-system/Card";
import { Icon } from "components/design-system/icons";

type InboxArchiveThreadsHintProps = {
  onDismiss: () => void;
};

const InboxArchiveThreadsHint = ({ onDismiss }: InboxArchiveThreadsHintProps) => {
  return (
    <Card className="relative !bg-background-app-secondary rounded-lg py-12 pl-8 pr-12 select-none !border-1 border-border-container shadow-none">
      <div className="flex flex-row gap-8 ">
        <Button
          buttonStyle="none"
          icon="Close"
          iconSize={16}
          className="absolute right-0 top-0 mt-2 mr-1 text-icon-subtle hover:text-interactive-subtle-hover"
          onClick={onDismiss}
        />
        <div className="my-auto">
          <Icon icon="Lightbulb" className="text-icon-action-highlight" size={20} />
        </div>
        <div className="flex flex-col gap-2">
          <div className="text-footnote-bold">Keep your inbox tidy</div>
          <div className="text-caption">
            Hover and click the checkmark&nbsp;
            <Icon inline icon="Check" strokeWidth={1} size={11} />
            &nbsp;to archive threads
          </div>
        </div>
      </div>
    </Card>
  );
};

export default InboxArchiveThreadsHint;
