import { environment } from "remirror";

import { RadioGroup } from "components/design-system/Forms";
import Key from "components/design-system/ui/Key";
import { isMobile } from "utils/platform";

import type { FormData } from "../types";

import { Field, FieldSet } from "./FieldSet";

const EditorPref = () => {
  if (isMobile()) return null; // is irrelevant on mobile

  const metaKey = environment.isMac ? "⌘" : "Ctrl";

  return (
    <FieldSet className="px-20 md:px-32" label="Editor options (this device)">
      <Field
        label={
          <>
            Press <Key>Enter</Key> to:
          </>
        }
        labelPosition="top"
      >
        <RadioGroup<FormData>
          groupClassName="!my-0"
          name="enterKeyBehavior"
          options={[
            {
              label: (
                <>
                  Start a new line (use <Key>{metaKey}</Key> <Key>Enter</Key> to send)
                </>
              ),
              value: "new-line",
            },
            {
              label: "Send a message",
              value: "send-message",
            },
          ]}
          wrapperClassName=""
        />
      </Field>
    </FieldSet>
  );
};

export default EditorPref;
