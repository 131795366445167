import { FieldValues, useFormContext } from "react-hook-form";

import { VariantPropsOf, variantProps } from "components/helper/classNameVariants";
import tw from "utils/tw";

import type { CheckboxProps } from "./types";

const className = {
  base: "absolute inset-0 border-1 flex items-center justify-center rounded-full text-footnote",
  defaultVariants: {
    checked: false as const,
    checkedDisabled: false as const,
    disabled: false as const,
    toggleStyle: "default" as const,
  },
  variants: {
    checked: {
      true: "bg-background-action border-transparent text-text-action-inverse",
      false: "bg-background-body border-border-container text-text-secondary",
    },
    checkedDisabled: {
      false: "",
      true: "bg-background-disabled",
    },
    disabled: {
      false: "cursor-pointer",
      true: "border-border-disabled !text-text-subtle",
    },
  },
};
const toggleProps = variantProps(className);

type ToggleVariantProps = VariantPropsOf<typeof toggleProps>;

export const ToggleCircle = <TFieldValues extends FieldValues>({
  config,
  label,
  labelClassName,
  name,
  wrapperClassName,
  ...props
}: CheckboxProps<TFieldValues> & ToggleVariantProps) => {
  const {
    formState: { isSubmitting },
    register,
    watch,
  } = useFormContext<TFieldValues>();

  const isChecked = watch(name);
  const disabled = props.disabled || isSubmitting;

  return (
    <div
      className={tw(
        "overflow-hidden relative",
        "before:block before:content-[''] before:pt-[100%]", // ::before, responsive aspect ratio (square)
        wrapperClassName
      )}
    >
      <input
        checked={!!isChecked}
        className="hidden"
        id={name}
        type="checkbox"
        {...props}
        disabled={disabled}
        {...register(name, config)}
      />

      <label
        {...toggleProps({
          checked: isChecked,
          checkedDisabled: isChecked && disabled,
          disabled,
        })}
        htmlFor={name}
      >
        {label}
      </label>
    </div>
  );
};

export default ToggleCircle;
