import { Pill } from "components/design-system/Pill";
import { useLinksCountsQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import tw from "utils/tw";

import Files from "./Files";
import Links from "./Links";
import Media from "./Media";
import { useSharedFilterState } from "./providers/SharedFilterProvider";
import SharedEmptyState from "./SharedEmptyState";
import { FiltersKeys, SharedFilters } from "./types";

const Shared = ({ isWide, groupID }: { isWide: boolean; groupID: string }) => {
  const { authReady } = useAuthData();
  const { filter, setState } = useSharedFilterState(({ filter }) => ({
    filter,
  }));

  const { data } = useLinksCountsQuery({
    variables: { recipientIDs: [groupID] },
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
  });

  const totalCount =
    (data?.files.totalCount ?? 0) + (data?.media.totalCount ?? 0) + (data?.links.totalCount ?? 0);

  const counts = {
    [SharedFilters.Files]: data?.files.totalCount,
    [SharedFilters.Media]: data?.media.totalCount,
    [SharedFilters.Links]: data?.links.totalCount,
  };

  const setFilter = (filter: FiltersKeys) => setState({ filter });

  return (
    <div className="flex flex-col grow min-w-0 max-w-[832px] py-16 gap-8">
      <ul
        className={tw("flex items-center h-52 p-12 gap-8 bg-background-body shadow-level1", {
          "rounded-lg": isWide,
        })}
      >
        {Object.values(SharedFilters).map(pill => {
          const count = pill !== SharedFilters.Recent ? counts[pill] : undefined;
          const selected = filter === pill;

          return (
            <Pill key={pill} name={pill} onClick={setFilter} selected={selected}>
              {pill}
              {!!count && (
                <span className={selected ? "!text-text-action-inverse" : "text-text-subtle"}>
                  {count > 999 ? "1K+" : count}
                </span>
              )}
            </Pill>
          );
        })}
      </ul>
      <div className="flex flex-col gap-8 pb-16">
        {!data || totalCount > 0 ? (
          <>
            <Files
              groupID={groupID}
              isWide={isWide}
              filter={filter}
              openDetailedView={() => setFilter(SharedFilters.Files)}
            />
            <Media
              groupID={groupID}
              isWide={isWide}
              filter={filter}
              openDetailedView={() => setFilter(SharedFilters.Media)}
            />
            <Links
              groupID={groupID}
              isWide={isWide}
              filter={filter}
              openDetailedView={() => setFilter(SharedFilters.Links)}
            />
          </>
        ) : (
          <SharedEmptyState filter={filter} />
        )}
      </div>
    </div>
  );
};

export default Shared;
