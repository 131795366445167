import { merge } from "lodash-es";
import { devtools, persist } from "zustand/middleware";

import { DeepPartial } from "@utility-types";

import { glueCreateZustand } from "./helper/glueCreate";

export const DEFAULT_EDUCATION_CAMPAIGN_MAX_VIEW_COUNT = 10;

const STORE_NAME = "LocalEducationCampaignStore";

export type EducationCampaignSettings = {
  groups: {
    welcomeCard: {
      viewCount: number;
      enabled: boolean; // v6
    };
  };
  feed: {
    welcomeCard: {
      viewCount: number;
      enabled: boolean; // v6
    };
  };
  inbox: {
    welcomeCard: {
      viewCount: number;
      enabled: boolean; // v6
    };
    archiveHint: {
      dismissed: boolean;
    };
  };
  aiSuperTab: {
    welcomeCard: {
      viewCount: number;
      enabled: boolean; // v6
    };
  };
};

const defaultSettings: EducationCampaignSettings = {
  groups: {
    welcomeCard: { viewCount: 0, enabled: false },
  },
  feed: { welcomeCard: { viewCount: 0, enabled: false } },
  inbox: {
    welcomeCard: { viewCount: 0, enabled: false },
    archiveHint: { dismissed: false },
  },
  aiSuperTab: {
    welcomeCard: { viewCount: 0, enabled: false },
  },
};

type EducationCampaignStore = {
  __typename: "EducationCampaignStore";
  settings: EducationCampaignSettings;
  updateSettings: (settings: DeepPartial<EducationCampaignSettings>) => void;
};

const isEducationCampaignStore = (
  value: unknown,
  version: number
): value is EducationCampaignStore =>
  typeof value === "object" &&
  value !== null &&
  "__typename" in value &&
  (value.__typename === "EducationCampaignStore" || (version < 5 && "settings" in value)); // __typename wasn't added until v5

const useLocalEducationCampaignStore = glueCreateZustand<EducationCampaignStore>({
  name: STORE_NAME,
})(
  devtools(
    persist(
      set => ({
        __typename: "EducationCampaignStore",
        settings: defaultSettings,
        updateSettings: settings => set(state => ({ ...merge(state, { settings }) })),
      }),
      {
        name: STORE_NAME,
        version: 6,
        migrate(persistedState, version) {
          if (!isEducationCampaignStore(persistedState, version)) {
            throw new Error(`Invalid persisted state: ${persistedState}`);
          }

          if (version === 4) {
            persistedState.settings.inbox.archiveHint = defaultSettings.inbox.archiveHint;
          }

          if (version === 5) {
            persistedState.settings = defaultSettings;
            return persistedState;
          }

          return persistedState;
        },
      }
    ),
    {
      name: STORE_NAME,
    }
  )
);

export default useLocalEducationCampaignStore;
