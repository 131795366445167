import { useEditorEvent, useRemirrorContext } from "@remirror/react";
import { useEffect, useRef } from "react";
import { getMarkRange, rangeHasMark } from "remirror";
import { LinkExtension } from "remirror/extensions";

import { ExternalObject } from "@utility-types";
import { HorizontalScrollingList } from "components/HorizontalScrollingList";
import { useDetectMention } from "components/MessageEditor/hooks";
import useEditorIsProcessing from "components/MessageEditor/hooks/useEditorIsProcessing";
import { GlueWysiwygPreset } from "components/MessageEditor/remirror-extensions/GlueWysiwyg";
import { matchURL } from "utils/matchURL";

import { allowsMarkType } from "../../utils";
import { DisposableElement } from "../DisposableElement";

import useCreateLinkPreview from "./hooks/useCreateLinkPreview";
import LinkPreview from "./LinkPreview";

type LinkPreviewCallback = Parameters<typeof useCreateLinkPreview>[0]["addLinkPreview"];

type AppUnfurlSetupCallback = Parameters<typeof useCreateLinkPreview>[0]["addAppUnfurlSetup"];

type PreviewState = Map<string, Parameters<LinkPreviewCallback>[0]>;

type Props = {
  addLinkPreview: (preview: Parameters<LinkPreviewCallback>[0]) => boolean;
  addAppUnfurlSetup: (setup: Parameters<AppUnfurlSetupCallback>[0]) => void;
  onChange: (state: PreviewState) => void;
  state: PreviewState;
  workspaceID?: string;
};

export const LinkPreviewList = ({
  addLinkPreview,
  addAppUnfurlSetup,
  onChange,
  state,
  workspaceID,
}: Props): JSX.Element | null => {
  const { chain, getState, helpers, manager } = useRemirrorContext<GlueWysiwygPreset>();

  const createLinkPreview = useCreateLinkPreview({
    addLinkPreview,
    addAppUnfurlSetup,
    workspaceID,
  });

  const createLinkPreviewRef = useRef(createLinkPreview);

  const linkPreviewUrl = useRef<string>();

  const { detectMention, mentionedID } = useDetectMention();

  const removePreview = (key: string) => {
    const temp = new Map(state);
    temp.delete(key);

    onChange(temp);
  };

  useEditorEvent("paste", ({ clipboardData }) => {
    if (!clipboardData) return;

    const { doc, selection } = manager.view.state;

    if (!allowsMarkType(selection, doc.type.schema.marks.link)) return;

    // Create Link preview for pasted <a> elements
    const pastedHTML = clipboardData.getData("text/html").trim();
    const clipboardText = clipboardData.getData("text/plain").trim();

    // This is creating a link if just one URL was pasted over selected text
    // Pasting more than one URL and replacing the selected text is handled by LinkExtension
    const pastedOnlyURL = matchURL(clipboardText);
    const pasteOverText = selection.from !== selection.to;
    if (pastedOnlyURL) {
      const updatedText = pasteOverText
        ? helpers.getTextBetween(selection.from, selection.to)
        : pastedOnlyURL.replace(/^mailto:/, ""); // remove mailto: from pasted URL

      window.requestAnimationFrame(() => {
        chain
          .replaceText({
            content: updatedText,
            selection: {
              from: selection.from,
              to: selection.from + clipboardText.length,
            },
          })
          .updateLink(
            { href: pastedOnlyURL },
            { from: selection.from, to: selection.from + updatedText.length }
          )
          .run();
      });
    }

    const links: {
      selection?: { from: number; to: number };
      text: string;
      url: string;
    }[] = [];

    if (pastedHTML.startsWith("<") && !pastedOnlyURL) {
      links.push(
        ...Array.from(
          new DOMParser()
            .parseFromString(pastedHTML.replace(/\n/g, "").normalize(), "text/html")
            .body.querySelectorAll(":not(span[data-mention-atom-id]) > a")
            .values()
        ).map(el => ({
          text: el.textContent || "",
          url: el.getAttribute("href") || "",
        }))
      );
    } else if (!clipboardText.match(/\n|\u2028/g)) {
      // We can't easily/reliably replace mentions across newlines,
      // so we're only attempting when pasting single line of text
      let from = selection.from;
      clipboardText.split(/(\S+)/g).forEach(text => {
        if (text.length === 0) return;

        const url = matchURL(text);
        if (url) {
          const selection = { from, to: from + text.length };
          links.push({ selection, text, url });
          from += mentionedID(url) ? 1 : text.length; // mention node length is 1
          return;
        }
        from += text.length;
      });
    }

    links.forEach(({ selection, text: label, url }) => {
      if (selection && !pasteOverText) {
        detectMention({ label, selection, url });
      }
      createLinkPreviewRef.current(url);
    });
  });

  useEditorEvent("keyup", () => {
    if (!linkPreviewUrl.current) return;

    const state = getState();

    let href: string | undefined;

    try {
      href = getMarkRange(state.doc.resolve(state.selection.from - 3), "link")?.mark.attrs.href;
    } catch (_error) {
      linkPreviewUrl.current = undefined;
      return;
    }

    if (!href || href !== linkPreviewUrl.current) {
      linkPreviewUrl.current = undefined;
      return;
    }

    // check that prev char is a punctuation, in other words we just stepped outside the link
    if (
      rangeHasMark({
        from: state.selection.from - 1,
        to: state.selection.to,
        trState: state,
        type: "link",
      })
    ) {
      return;
    }

    createLinkPreviewRef.current(linkPreviewUrl.current);

    linkPreviewUrl.current = undefined;
  });

  useEffect(() => {
    const linkExtension = manager.extensions.find(
      (ext): ext is LinkExtension => ext.name === "link"
    );

    /* istanbul ignore next */
    if (!linkExtension) return;

    if (linkExtension.hasHandlers("onUpdateLink")) return;

    linkExtension.addHandler("onUpdateLink", (_, meta) => {
      // If auto is false, it means we've created the link using link settings
      if (!meta.attrs.auto) {
        return createLinkPreviewRef.current(meta.attrs.href);
      }

      linkPreviewUrl.current = meta.attrs.href;
    });
  }, [manager.extensions]);

  useEffect(() => {
    createLinkPreviewRef.current = createLinkPreview;
  }, [createLinkPreview]);

  const loadingPreviews = Array.from(state.values()).some(
    (item: ExternalObject & { state: "loading" | "failed" | "finished" }) =>
      item.state === "loading"
  );
  useEditorIsProcessing(loadingPreviews);

  if (state.size === 0) return null;

  return (
    <HorizontalScrollingList className="pr-4 pb-10 pl-10" columns={2} gutter={0}>
      {[...state.values()].map(item => (
        <DisposableElement key={item.previewId} onRemove={() => removePreview(item.id)}>
          <LinkPreview key={item.previewId} item={item} />
        </DisposableElement>
      ))}
    </HorizontalScrollingList>
  );
};
