import { Form } from "components/design-system/Forms";
import SelectDropdown from "components/design-system/Forms/SelectDropdown";
import { UserMilestoneTeamSize as TeamSize } from "generated/graphql-types";
import useOnboardingStore, { teamSizeOptions } from "store/useOnboardingStore";

import ContentWrapper from "./ContentWrapper";
import Footer from "./Footer";

type FormValues = {
  teamSize: keyof typeof teamSizeOptions;
};

const SELF_SERVE_TEAM_SIZES = [TeamSize.Seed, TeamSize.Early];

const SelectTeamSize = () => {
  const { setState, teamSize, hasWorkspacesToJoin, workspace, currentStep } = useOnboardingStore(
    ({ setState, teamSize, hasWorkspacesToJoin, workspace, currentStep }) => ({
      setState,
      teamSize,
      hasWorkspacesToJoin,
      workspace,
      currentStep,
    })
  );

  const options = Object.entries(teamSizeOptions).map(([key, value]) => ({
    label: value,
    value: key,
  }));

  return (
    <ContentWrapper
      title="Tell us about your team"
      headline="We'll tailor your setup experience to match your team's needs."
    >
      <div className="flex flex-col gap-16 h-full">
        <Form<FormValues>
          className="flex flex-col h-full"
          onSubmit={({ teamSize }) => {
            setState({
              teamSize,
              isEnterprise: teamSize && !SELF_SERVE_TEAM_SIZES.includes(teamSize),
              view: "CreateWorkspace",
              currentStep: currentStep + 1,
            });
          }}
          useFormProps={{
            defaultValues: {
              teamSize,
            },
          }}
        >
          <SelectDropdown
            name="teamSize"
            placeholder="Choose your team size"
            options={options}
            selectValueByDefault={false}
            disabled={workspace?.type === "created"}
          />
          <Footer
            onClickBack={() =>
              setState({
                view:
                  hasWorkspacesToJoin && workspace?.type !== "created"
                    ? "JoinWorkspace"
                    : "CompleteYourProfile",
                currentStep:
                  currentStep - (hasWorkspacesToJoin && workspace?.type !== "created" ? 0 : 1),
              })
            }
            requireChanges={!teamSize}
            hideSkip
          />
        </Form>
      </div>
    </ContentWrapper>
  );
};

export default SelectTeamSize;
