import { useApolloClient } from "@apollo/client";
import { last, takeRight } from "lodash-es";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { Channel, Chat, Window, useChatContext } from "stream-chat-react";

import { GlueDefaultStreamChatGenerics, Mailbox, StreamGlueChannel } from "@utility-types";
import { readThreadEdge, readThreadList } from "apollo/cache/threadHelpers";
import {
  currentPathWithoutDrawer,
  routeParams,
  routePath,
  routeToThread,
} from "components/routing/utils";
import useInboxThreadActions from "hooks/thread/useInboxThreadActions";
import useAuthData from "hooks/useAuthData";
import useGoToThreadRecent from "hooks/useGoToThreadRecent";
import usePrevious from "hooks/usePrevious";
import { StreamClientContext, StreamMessageLimit } from "providers/StreamClientProvider";
import useAppStateStore from "store/useAppStateStore";

import { useThreadViewState } from "../provider/ThreadViewProvider";
import { DateSeparator } from "../stream-components/DateSeparator";
import { Message } from "../stream-components/Message";
import { MessageList } from "../stream-components/MessageList";
import { TypingIndicator } from "../stream-components/TypingIndicator";

import { ChannelLoading } from "./ChannelLoading";
import { ChannelLoadingError } from "./ChannelLoadingError";
import { ThreadChannelWatcher } from "./ThreadChannelWatcher";

const noop = () => undefined;

const ThreadChannelInner = ({ children }: WithChildren): JSX.Element | null => {
  const { appStatus, isSyncingState } = useAppStateStore(({ appStatus, isSyncingState }) => ({
    appStatus,
    isSyncingState,
  }));
  const { authData, authReady } = useAuthData();
  const cache = useApolloClient().cache;

  const { channel: activeChannel, setActiveChannel } =
    useChatContext<GlueDefaultStreamChatGenerics>();
  const { connectStatus, newStreamChannel, streamChannelRecent, streamClient } =
    useContext(StreamClientContext);

  const goToRecent = useGoToThreadRecent();
  const history = useHistory();

  const [historyMode, setHistoryMode] = useState(false);
  const loading = useRef(false);

  const { mailbox, messageID, recipientID, scrolledToEnd, subscribe, threadID } =
    useThreadViewState(({ mailbox, messageID, recipientID, scrolledToEnd, threadID }) => ({
      mailbox,
      messageID,
      recipientID,
      scrolledToEnd,
      threadID,
    }));

  const prevThreadID = usePrevious(threadID);
  const prevMessageID = usePrevious(messageID);

  const startLoading = useCallback(
    (historyMode: boolean) => {
      loading.current = true;
      setHistoryMode(historyMode);

      if (historyMode) {
        setActiveChannel(undefined);
      }
    },
    [setActiveChannel]
  );

  const doneLoading = useCallback(
    (channel: StreamGlueChannel) => {
      loading.current = false;

      setActiveChannel(channel);
    },
    [setActiveChannel]
  );

  const enterRecentMode = useCallback(
    async ({ threadID }: { messageID?: string; threadID: string }) => {
      const recentChannel = streamChannelRecent(threadID);

      if (!historyMode) {
        // limit recent messages to ensure fast thread rendering
        // and prevent memory leak over time with long-lived apps
        recentChannel.state.messages = takeRight(recentChannel.state.messages, StreamMessageLimit);
        doneLoading(recentChannel);
        return;
      }

      startLoading(false);

      // state changed in history mode can cause gaps in the state, so
      // we need to clear and re-fetch messages when moving to recent.
      recentChannel.state.clearMessages();

      await recentChannel.query({ messages: { limit: StreamMessageLimit } });

      doneLoading(recentChannel);
    },
    [doneLoading, historyMode, startLoading, streamChannelRecent]
  );

  const enterHistoryMode = useCallback(
    async ({
      messageID,
      threadID,
    }: {
      messageID?: string;
      threadID: string;
    }) => {
      if (!messageID) return;

      startLoading(true);

      const historyChannel = await newStreamChannel({
        messagesQueryParam: { id_around: messageID, limit: 100 },
        threadID,
      });

      doneLoading(historyChannel);
    },
    [doneLoading, newStreamChannel, startLoading]
  );

  // Change threads + enter / leave history mode
  useEffect(() => {
    const sameThread = prevThreadID === threadID;
    const sameMessage = prevMessageID === messageID;

    if (loading.current || !streamClient?.user?.id) return;

    if (activeChannel && sameThread && sameMessage) return;

    // Enter history mode if linked message is not in recent messages or
    // is in first few messages of current channel so we don't trigger loading
    const recentMessages = streamChannelRecent(threadID).state.messages;
    const historyMode = messageID && !recentMessages.find((m, i) => i > 2 && m.id === messageID);

    (historyMode ? enterHistoryMode : enterRecentMode)({
      messageID,
      threadID,
    });
  }, [
    activeChannel,
    enterHistoryMode,
    enterRecentMode,
    messageID,
    prevMessageID,
    prevThreadID,
    streamChannelRecent,
    streamClient?.user?.id,
    threadID,
  ]);

  useEffect(
    () =>
      subscribe(({ prevScrollTop, scrolledToEnd, scrolledToLinkedMessage }) => {
        if (!activeChannel) return;

        if (!scrolledToEnd || !historyMode) return;

        const lastMessageRecent = last(streamChannelRecent(threadID).state.messages);
        const lastMessageHistory = last(activeChannel.state.messages);

        if (!lastMessageRecent || !lastMessageHistory) return;

        if (lastMessageRecent.id !== lastMessageHistory.id) return;

        // Only exit history mode after we scrolled to linked message
        if (!scrolledToLinkedMessage) return;

        // If we just finished scrolling to a linked message we don't want to exit history mode.
        if (prevScrollTop === 0) return;

        goToRecent();
      }),
    [activeChannel, goToRecent, historyMode, streamChannelRecent, subscribe, threadID]
  );

  // Mark read on state change

  const markChannelRead = useCallback(
    (activeChannel: StreamGlueChannel) =>
      activeChannel.countUnread() !== 0 && activeChannel.markRead(),
    []
  );

  const { toggleThreadRead } = useInboxThreadActions();

  const markThreadRead = useCallback(
    (threadID: string) => {
      if (!authData?.me.id || !authReady) return;
      const threadEdge = readThreadEdge(`${threadID}-${authData.me.id}`, cache);
      if (threadEdge) {
        toggleThreadRead(threadEdge, true, { showSnackbar: false });
      }
    },
    [authData?.me.id, authReady, cache, toggleThreadRead]
  );

  // Mark Stream channel as read when active and at bottom of thread
  useEffect(() => {
    // Dependency on state.messages triggers mark-read on new messages
    if (!activeChannel || isSyncingState) return;

    // Mark as read after short timeout to avoid race with thread list
    // fetch and immediate shuffling of thread list. Also helps avoid
    // accidental mark as read if the user is switching quickly.
    if (appStatus === "active" && scrolledToEnd && !historyMode) {
      markChannelRead(activeChannel);
    }
  }, [
    activeChannel,
    activeChannel?.state.messages, // Dependency on messages triggers mark-read on new messages
    appStatus,
    historyMode,
    isSyncingState,
    markChannelRead,
    scrolledToEnd,
  ]);

  // Also mark local cache / API as read when changing threads
  // (needed for mark-as-unread, which Stream doesn't track)
  useEffect(() => {
    if (!activeChannel?.id || isSyncingState) return;

    markThreadRead(activeChannel.id);
  }, [activeChannel, isSyncingState, markThreadRead]);

  useEffect(
    () => () => {
      // allows us to unmount on route change, without `cannot set state` errors
      setActiveChannel(undefined);
    },
    [setActiveChannel]
  );

  // Handle deleted threads
  useEffect(() => {
    if (!activeChannel || !streamClient) return;

    const { unsubscribe } = streamClient.on(event => {
      if (event.channel_id !== threadID) return;
      switch (event.type) {
        case "channel.deleted":
        case "notification.channel_deleted":
        case "notification.removed_from_channel":
          const { d, recipientID, threadID } = routeParams(window.location);

          if (event.channel_id === d) {
            // thread is in the secondary pane in split view;
            // just close that pane.
            history.replace(currentPathWithoutDrawer());
            return;
          }

          if (event.channel_id === threadID) {
            if (mailbox === Mailbox.Ai) {
              history.replace(routePath({ superTab: "ai", view: "compose" }));
              return;
            }

            const nextThreadId = readThreadList({ recipientID }, cache)
              ?.threads.edges.filter(({ node }) => node.id !== threadID)
              .reverse()[0]?.node.id;

            history.replace(routeToThread({ threadID: nextThreadId || "", to: "primary" }));

            return;
          }

          break;
      }
    });

    return () => unsubscribe();
  }, [
    activeChannel,
    cache,
    history,
    mailbox,
    recipientID,
    setActiveChannel,
    streamClient,
    threadID,
  ]);

  if ((activeChannel?.disconnected ?? true) || connectStatus !== "connected" || loading.current) {
    return <ChannelLoading />;
  }

  return (
    <Channel
      DateSeparator={DateSeparator}
      LoadingErrorIndicator={ChannelLoadingError}
      LoadingIndicator={ChannelLoading}
      Message={Message}
      TypingIndicator={TypingIndicator}
      activeUnreadHandler={noop}
      doMarkReadRequest={noop}
    >
      {/* manages and updates channel state. must be inside Channel context. */}
      {!historyMode && <ThreadChannelWatcher />}
      <Window>
        {/* handle delay between threadID changing and activeChannel updating */}
        {activeChannel?.id === threadID ? (
          <MessageList
            historyMode={historyMode}
            messageID={messageID}
            messageLimit={StreamMessageLimit}
            hideNewMessageSeparator
          />
        ) : (
          <div className="str-chat-list" />
        )}

        {children}
      </Window>
    </Channel>
  );
};

const ThreadChannel = ({ children }: WithChildren): JSX.Element | null => {
  const { streamClient } = useContext(StreamClientContext);

  if (!streamClient) return null;

  return (
    <Chat client={streamClient}>
      <ThreadChannelInner children={children} />
    </Chat>
  );
};

export default ThreadChannel;
