import { first } from "lodash-es";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";

import { MessageEdge, ThreadEdgeFeed, nodeAs } from "@utility-types";
import { FetchThreadEdgePaginatedQueryResult } from "generated/graphql";
import useAuthData from "hooks/useAuthData";

type Props = {
  aboveLastReadMessages?: MessageEdge[];
  setAboveLastReadMessages: Dispatch<SetStateAction<MessageEdge[] | undefined>>;
  belowLastReadMessages?: MessageEdge[];
  setBelowLastReadMessages: Dispatch<SetStateAction<MessageEdge[] | undefined>>;
  fetchMore: FetchThreadEdgePaginatedQueryResult["fetchMore"];
  oldEdge: ThreadEdgeFeed;
  updatedEdge?: ThreadEdgeFeed;
  setEdge: Dispatch<SetStateAction<ThreadEdgeFeed>>;
  olderRepliesCount: number;
  moreRepliesCount: number;
};

const pageSize = 5;
const useFeedItemLoadMore = ({
  aboveLastReadMessages,
  setAboveLastReadMessages,
  belowLastReadMessages,
  setBelowLastReadMessages,
  fetchMore,
  oldEdge,
  updatedEdge,
  setEdge,
  olderRepliesCount,
  moreRepliesCount,
}: Props) => {
  const { authData } = useAuthData();

  const [loadingOlder, setLoadingOlder] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const edge = nodeAs(updatedEdge, ["ThreadEdge"]);

  const handleLoadOlderReplies = () => {
    setLoadingOlder(true);
    fetchMore({
      variables: {
        includeLastRead: true,
        beforeLastRead: first(
          aboveLastReadMessages?.length ? aboveLastReadMessages : belowLastReadMessages
        )?.cursor,
        lastReadLimit: Math.min(olderRepliesCount, pageSize) - 1,
      },
    })
      .then(response => {
        const threadEdge = nodeAs(response.data.node, ["ThreadEdge"]);
        if (!threadEdge) return;
        setAboveLastReadMessages([
          ...(threadEdge.node.lastRead?.edges ?? []),
          ...(aboveLastReadMessages ?? []),
        ]);
      })
      .finally(() => setLoadingOlder(false));
  };

  const handleLoadMoreUnreadMessages = () => {
    setLoadingMore(true);
    if (edge) {
      setEdge(value => ({
        ...value,
        node: {
          ...value.node,
          recentMessages: {
            ...value.node.recentMessages,
            replyCount: edge.node.recentMessages.replyCount,
          },
        },
      }));
    }
    fetchMore({
      variables: {
        beforeLastUnread: first(belowLastReadMessages)?.cursor,
        lastUnreadLimit: Math.min(moreRepliesCount, pageSize) - 1,
      },
    })
      .then(response => {
        const threadEdge = nodeAs(response.data.node, ["ThreadEdge"]);
        if (!threadEdge) return;
        setBelowLastReadMessages([
          ...threadEdge.node.lastUnread.edges,
          ...(belowLastReadMessages ?? []),
        ]);
      })
      .finally(() => setLoadingMore(false));
  };

  // Handles clicking on the N new reply button
  const handleLoadNewAvailableReplies = useCallback(() => {
    if (!edge) return;
    setEdge({ ...edge, isRead: true });
    setBelowLastReadMessages(value => [
      ...(value ?? []),
      ...(edge.node.lastUnread
        ? ((edge.node.lastUnread ?? []).edges ?? [])
        : edge.node.recentMessages.edges
      ).filter(
        n =>
          ![...(aboveLastReadMessages ?? []), ...(value ?? [])]?.find(m => m.node.id === n.node.id)
      ),
    ]);
  }, [aboveLastReadMessages, edge, setBelowLastReadMessages, setEdge]);

  // Updates the thread item when user sends a message in a thread.
  useEffect(() => {
    if (
      oldEdge.node.lastMessage?.id === edge?.node.lastMessage?.id ||
      edge?.node.lastMessage?.user.id !== authData?.me.id
    ) {
      return;
    }
    handleLoadNewAvailableReplies();
  }, [authData, oldEdge, edge, handleLoadNewAvailableReplies]);

  return {
    handleLoadOlderReplies,
    handleLoadMoreUnreadMessages,
    handleLoadNewAvailableReplies,
    loadingOlder,
    loadingMore,
  };
};

export default useFeedItemLoadMore;
