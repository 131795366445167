import { useHistory } from "react-router";

import {
  GroupEdge,
  GroupEdgeSimple,
  GroupPreviewEdge,
  GroupPreviewEdgeSimple,
  WorkspaceEdge,
  WorkspaceEdgeSimple,
  WorkspacePreviewEdge,
  WorkspacePreviewEdgeSimple,
  nodeAs,
  nodeIs,
} from "@utility-types";
import BackStackButton from "components/App/AppLayoutMobile/BackStackButton";
import { GroupProfileTab } from "components/group/GroupModal/GroupProfileModal";
import { GroupTabs, tabPath } from "components/routing/utils";
import { Tabs } from "components/Tabs";
import ThreadComposeTopButton from "components/threads/ThreadCompose/ThreadComposeTopButton";
import useThreadComposeMenuAction from "components/threads/ThreadHeader/hooks/useThreadComposeMenuAction";
import PinnedButton from "components/threads/ThreadHeader/PinnedButton";
import GroupActionMenu from "components/views/groups/GroupActionMenu";
import WorkspaceActionMenu from "components/workspace/WorkspaceActionMenu";
import useGroupRecipients from "hooks/useGroupRecipients";
import useMemberEdge from "hooks/useMemberEdge";
import useThreadEdgeMessageable from "hooks/useThreadEdgeMessageable";
import useAppStateStore from "store/useAppStateStore";
import { formatGroupName } from "utils/group/formatGroupName";

import { GroupAvatarAndTitle } from "./GroupAvatarAndTitle";
import { GroupNotificationsButton } from "./GroupNotificationsButton";

type Edge =
  | GroupEdgeSimple
  | GroupPreviewEdgeSimple
  | WorkspaceEdgeSimple
  | WorkspacePreviewEdgeSimple;

type EdgeFull = GroupEdge | GroupPreviewEdge | WorkspaceEdge | WorkspacePreviewEdge;

type GroupTabsType = keyof typeof GroupTabs;

const GroupHeader = ({
  edge,
  edgeFull,
  currentTab,
  onOpenModal,
  onOpenGroupModal,
}: {
  edge: Edge | undefined;
  edgeFull: EdgeFull | undefined;
  currentTab: GroupTabsType;
  onOpenModal: () => void;
  onOpenGroupModal: (params: {
    groupId: string;
    defaultTab: GroupProfileTab;
  }) => void;
}) => {
  const history = useHistory();

  const { memberEdge, previewEdge } = useMemberEdge(edge);
  const { memberEdge: memberEdgeFull } = useMemberEdge(edgeFull);

  const groupEdge = nodeAs(edge, ["GroupEdge", "GroupPreviewEdge"]);
  const groupEdgeFull = nodeAs(edgeFull, ["GroupEdge", "GroupPreviewEdge"]);
  const workspaceEdge = nodeAs(edge, ["WorkspaceEdge", "WorkspacePreviewEdge"]);

  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  const groupRecipients = useGroupRecipients();

  const { threadComposeAction, handleCompose } = useThreadComposeMenuAction(groupRecipients);

  const { emoji, name } = formatGroupName(edge?.node);

  const chat = memberEdgeFull?.persistentChatEdge.node;

  const isFollowed =
    groupEdge?.__typename === "GroupEdge" && groupEdge.threadSubscription === "inbox";

  const persistentChat = nodeIs(memberEdgeFull, ["GroupEdge"])
    ? memberEdgeFull.persistentChatEdge
    : undefined;

  const { value: isMessageable, reasons: isMessageableReasons } =
    useThreadEdgeMessageable(persistentChat);

  return (
    <>
      <div className="flex justify-between mb-8">
        <div className="flex gap-16 items-center truncate">
          <BackStackButton position="groupHeader" size="small" />

          <GroupAvatarAndTitle
            avatarURL={workspaceEdge ? workspaceEdge.node.avatarURL : undefined}
            name={name}
            emoji={emoji}
            isLoading={!edge}
            onClick={memberEdge ? onOpenModal : undefined}
          />
        </div>

        {workspaceEdge?.__typename === "WorkspaceEdge" ? (
          <WorkspaceActionMenu workspaceEdge={workspaceEdge} />
        ) : (
          <div className="flex grow items-center justify-end gap-8">
            {breakpointMD && isMessageable && <ThreadComposeTopButton onClick={handleCompose} />}

            {!previewEdge && !isMessageableReasons.archived && (
              <GroupNotificationsButton
                isFollowed={isFollowed}
                onClick={() => {
                  if (!groupEdge?.node.id) return;
                  onOpenGroupModal({
                    groupId: groupEdge.node.id,
                    defaultTab: GroupProfileTab.Notifications,
                  });
                }}
              />
            )}

            {chat && <PinnedButton threadID={chat.id} />}

            <GroupActionMenu
              groupEdge={groupEdgeFull}
              additionalActions={
                !breakpointMD ? [...(isMessageable ? [threadComposeAction] : [])] : []
              }
            />
          </div>
        )}
      </div>
      {previewEdge ? (
        <div className="my-16" />
      ) : (
        <Tabs<keyof typeof GroupTabs>
          onClickTab={tab =>
            history.push(
              tabPath(tab, {
                threadID: tab === "Chat" ? chat?.id : undefined,
              })
            )
          }
          selectedTab={currentTab}
          tabs={[
            {
              tab: GroupTabs.Chat,
              tabTitle: GroupTabs.Chat,
            },
            {
              tab: GroupTabs.Threads,
              tabTitle: GroupTabs.Threads,
            },
            {
              tab: GroupTabs.Feed,
              tabTitle: GroupTabs.Feed,
            },
            {
              tab: GroupTabs.Shared,
              tabTitle: GroupTabs.Shared,
            },
          ]}
        />
      )}
    </>
  );
};

export default GroupHeader;
