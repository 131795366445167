import { Group, GroupPreview, nodeIs, WorkspaceAppEdge } from "@utility-types";
import { useConfigureApps } from "components/apps";
import {
  AppConfigureMethod,
  FetchGroupOrPreviewEdgeDocument,
  MemberRole,
  useAddGroupMembersMutation,
  useRemoveGroupMembersMutation,
} from "generated/graphql";
import useRefetchOnAppActive from "hooks/useRefetchOnAppActive";

const useGroupApps = (group?: Group | GroupPreview, persistentChatID?: string) => {
  const [addGroupMembers] = useAddGroupMembersMutation();
  const [removeGroupMembers] = useRemoveGroupMembersMutation();

  useRefetchOnAppActive([FetchGroupOrPreviewEdgeDocument]);

  const isPreview = nodeIs(group, ["GroupPreview"]);

  const { configureApp, editApp, getAppsAvailable } = useConfigureApps();

  const appsAvailable = getAppsAvailable(!isPreview ? group?.apps.edges || [] : [])?.filter(
    app => app.node.configureGroup && app.workspaceID === group?.workspaceID
  );

  const addApp = (workspaceApp: WorkspaceAppEdge) => {
    if (!group || !persistentChatID || !group.workspaceID) return;

    if (workspaceApp.node.configureGroup === AppConfigureMethod.Add) {
      addGroupMembers({
        variables: {
          id: group.id,
          members: [{ member: workspaceApp.appUser.id, role: MemberRole.Default }],
        },
      });
      return;
    }
    configureApp({
      app: workspaceApp.node,
      closeModalProp: false,
      configurableID: group.id,
      workspaceID: group.workspaceID,
    });
  };

  const removeApp = (workspaceApp: WorkspaceAppEdge) => {
    if (!group || isPreview) return Promise.resolve();
    if (!workspaceApp) return Promise.resolve();
    return removeGroupMembers({
      variables: { id: group.id, memberIDs: workspaceApp.appUser.id },
    });
  };

  const wrappedEditApp = (workspaceApp: WorkspaceAppEdge) => {
    if (!group?.id) return Promise.resolve();
    return editApp(workspaceApp.node, group.id, workspaceApp.workspaceID);
  };

  return {
    addApp,
    appsAvailable,
    editApp: wrappedEditApp,
    removeApp,
  };
};

export default useGroupApps;
