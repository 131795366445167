import { ChangeEvent, DragEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { SlackImportStatus } from "@utility-types/graphql";
import { Button } from "components/design-system/Button";
import { Icon } from "components/design-system/icons";
import ProgressBar from "components/design-system/ProgressBar/";
import { fileToFileUpload } from "components/MessageEditor/stream-helpers";
import { Footer, Main } from "components/ModalKit/Parts";
import {
  useCreateSlackImportMutation,
  useLoadSlackImportMutation,
  useSlackImportQuery,
} from "generated/graphql";
import useFileUploader from "hooks/useFileUploader";
import useForceUpdate from "hooks/useForceUpdate";
import formatFileSize from "utils/formatFileSize";
import tw from "utils/tw";

import ContactSupportBubble from "./ContactSupportBubble";
import type { StepsProps } from "./types";

type CallBack = Parameters<typeof useFileUploader>[0]["onChange"];
type State = Parameters<CallBack>[0];

const ChooseFile = ({
  workspaceID,
  cancelButton,
  backButton,
  onClickNext,
}: StepsProps & { workspaceID: string; backButton: JSX.Element | null }) => {
  const forceUpdate = useForceUpdate();
  const [file, setFile] = useState<{
    id: string;
    name: string;
    size: string;
  }>();
  const [isDragging, setIsDragging] = useState(false);
  const [importStatus, setImportStatus] = useState<SlackImportStatus>();
  const uploadsRef = useRef<State>(new Map());
  const fileInputRef = useRef<HTMLInputElement>(null);
  const prevStatus = useRef<SlackImportStatus>();

  const [createSlackImport] = useCreateSlackImportMutation();
  const [loadSlackImport] = useLoadSlackImportMutation();

  const { data, startPolling, stopPolling } = useSlackImportQuery({
    fetchPolicy: "network-only",
    variables: { workspaceID },
  });

  const slackImport = data?.slackImport;
  const slackImportRef = useRef(slackImport);
  slackImportRef.current = slackImport;

  const handleDrag = (e: DragEvent<HTMLDivElement>, dragging: boolean) => {
    e.preventDefault();
    e.stopPropagation();
    if (file) return;
    setIsDragging(dragging);
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
    if (file) return;

    const newFile = e.dataTransfer.files?.[0];
    if (!newFile || newFile.type !== "application/zip" || !fileInputRef.current) {
      return;
    }

    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(newFile);
    fileInputRef.current.files = dataTransfer.files;

    fileInputRef.current.dispatchEvent(new Event("change", { bubbles: true }));
  };

  const onInputFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const temp = new Map();
    temp.set(file.name, fileToFileUpload(file));
    uploadsRef.current = temp;
    forceUpdate();
  };

  const handleFileUploaded = (state?: State) => {
    const file = [...(state?.values() || [])][0];
    if (!file) {
      uploadsRef.current = new Map();
      setFile(undefined);
      return;
    }
    const size = formatFileSize(Number(file.contentLength));
    setFile(f => ({ id: f?.id ?? file.id, name: file.name, size }));
    if (file.uploadInfo.state === "failed") {
      setImportStatus(SlackImportStatus.Invalid);
    }
  };

  const { uploadProgress, cancelUploadsRef } = useFileUploader({
    onChange: handleFileUploaded,
    orderedUploads: uploadsRef,
  });

  const [glueID, progress] = useMemo(
    () => [
      uploadProgress?.find(p => p.uploadID === file?.id)?.glueId,
      uploadProgress?.find(p => p.uploadID === file?.id)?.progress,
    ],
    [uploadProgress, file]
  );
  const prevGlueID = useRef<string | undefined>(glueID);

  const loadImport = useCallback(
    (fileID: string) => {
      loadSlackImport({ variables: { fileID, workspaceID } })
        .then(() => {
          setImportStatus(SlackImportStatus.Loading);
          if (prevStatus.current === undefined) {
            prevStatus.current = SlackImportStatus.Loading;
          }
          startPolling(5000);
        })
        .catch(() => {
          setImportStatus(SlackImportStatus.Invalid);
        });
    },
    [loadSlackImport, startPolling, workspaceID]
  );

  // Stop polling after slack import status changed
  useEffect(() => {
    if (slackImport?.status === prevStatus.current) return;
    if (
      (prevStatus.current === SlackImportStatus.Loading &&
        slackImport?.status !== SlackImportStatus.Loading) ||
      slackImport?.status === SlackImportStatus.Ready
    ) {
      setImportStatus(slackImport?.status);
      stopPolling();
    }
    prevStatus.current = slackImport?.status;
  }, [slackImport?.status, stopPolling]);

  // Create slack import and load import after file upload is complete
  useEffect(() => {
    if ((progress ?? 0) < 100 || !glueID || glueID === prevGlueID.current) {
      return;
    }
    prevGlueID.current = glueID;
    if (
      slackImportRef.current &&
      (slackImportRef.current.status === SlackImportStatus.Empty ||
        slackImportRef.current.status === SlackImportStatus.Invalid)
    ) {
      loadImport(glueID);
      return;
    }
    createSlackImport({ variables: { workspaceID } })
      .then(({ data }) => {
        setImportStatus(data?.createSlackImport.status);
        loadImport(glueID);
      })
      .catch(() => {
        setImportStatus(SlackImportStatus.Invalid);
      });
  }, [progress, workspaceID, createSlackImport, loadImport, glueID, importStatus]);

  const handleRemoveFile = () => {
    cancelUploadsRef.current();
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setImportStatus(undefined);
    prevGlueID.current = undefined;
    handleFileUploaded();
  };

  const UploadComplete = () => (
    <>
      <span className="text-footnote text-text-secondary text-nowrap">Upload complete</span>
      <Icon className="ml-16 text-icon-success" icon="Check" size={24} />
    </>
  );

  const UploadFailed = () => (
    <>
      <span className="text-footnote text-text-secondary text-nowrap">Upload failed.&nbsp;</span>
      <span
        className="text-footnote-bold text-text-secondary underline cursor-pointer text-nowrap"
        onClick={() => handleRemoveFile()}
      >
        Try again.
      </span>
      <Icon className="ml-16 text-icon-alert" icon="Info" size={24} />
    </>
  );

  const FileLoading = () => (
    <>
      <ProgressBar
        progress={Math.min(5, progress ?? 0)}
        indeterminate={
          progress === undefined || (progress === 100 && importStatus === SlackImportStatus.Loading)
        }
      />
      <Button
        className="ml-16"
        icon="CloseCircle"
        buttonStyle="icon-secondary"
        buttonType="icon"
        iconSize={24}
        onClick={handleRemoveFile}
        disabled={importStatus === SlackImportStatus.Loading}
      />
    </>
  );

  return (
    <>
      <Main className="flex flex-col px-32 py-16">
        <span className="text-body">
          Choose the .zip containing your exported Slack data. Do not unzip or edit the contents of
          the exported data. You will be able to manage how this data is imported to Glue.
        </span>
        <div
          className={tw("flex flex-col grow mt-10", {
            "flex justify-center items-center border-1 border-dashed border-border-action rounded-lg":
              isDragging,
          })}
          onDragEnter={e => handleDrag(e, true)}
          onDragLeave={e => handleDrag(e, false)}
          onDragOver={e => handleDrag(e, true)}
          onDrop={handleDrop}
        >
          <input
            type="file"
            ref={fileInputRef}
            onChange={onInputFileChange}
            accept=".zip"
            className="hidden"
          />

          {file ? (
            <>
              <div className="flex items-center px-16 py-8 border-1 border-border-container rounded-lg">
                <Icon icon="File" size={40} />
                <div className="ml-8 flex flex-col">
                  <span className="text-subhead-bold">{file.name}</span>
                  <span className="text-footnote text-text-secondary">{file.size}</span>
                </div>
                <div className="grow" />
                {progress === 100 &&
                (importStatus === SlackImportStatus.Ready ||
                  importStatus === SlackImportStatus.Invalid) ? (
                  <>
                    {importStatus === SlackImportStatus.Ready ? (
                      <UploadComplete />
                    ) : (
                      <UploadFailed />
                    )}
                  </>
                ) : (
                  <FileLoading />
                )}
              </div>
              <ContactSupportBubble contentType="file" />
            </>
          ) : !isDragging ? (
            <>
              <div className="flex justify-between items-center px-16 py-8 border-1 border-border-container rounded-lg h-56">
                <span className="text-subhead-bold text-text-subtle">
                  Choose or drag & drop a .zip file
                </span>
                <Button onClick={() => fileInputRef.current?.click()}>Choose file</Button>
              </div>
              <ContactSupportBubble contentType="file" />
            </>
          ) : (
            <span className="text-subhead-bold text-text-subtle">Drop .zip file here</span>
          )}
        </div>
      </Main>
      <Footer>
        {backButton}
        <div className="grow" />
        {cancelButton}
        <Button
          onClick={onClickNext}
          disabled={progress !== 100 || importStatus !== SlackImportStatus.Ready}
        >
          Next
        </Button>
      </Footer>
    </>
  );
};

export default ChooseFile;
