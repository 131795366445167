import { Form } from "components/design-system/Forms";
import MultiOptionDropdown from "components/design-system/Forms/MultiOptionDropdown";
import {
  ThreadPermissions as ThreadPermissionsType,
  threadPermissionsOptions,
} from "components/threads/utils";
import {
  Addable,
  MessageableBy,
  ThreadFieldsFragment,
  useUpdateThreadMutation,
} from "generated/graphql";

const ThreadPermissions = ({ thread }: { thread: ThreadFieldsFragment }) => {
  const [updateThread] = useUpdateThreadMutation({
    errorPolicy: "all",
  });

  const handleOnSubmit = (data: Record<ThreadPermissionsType, boolean>) => {
    const recipientsAddable = data[ThreadPermissionsType.Locked] ? Addable.None : Addable.Anyone;

    const messageableBy = data[ThreadPermissionsType.Restricted]
      ? MessageableBy.Admin
      : MessageableBy.Recipient;

    updateThread({
      optimisticResponse: {
        updateThread: {
          ...thread,
          recipientsAddable,
          messageableBy,
        },
      },
      variables: {
        id: thread.id,
        input: {
          recipientsAddable,
          messageableBy,
        },
      },
    });
  };

  const defaultValues = {
    permissions: {
      [ThreadPermissionsType.Locked]: thread.recipientsAddable === Addable.None,
      [ThreadPermissionsType.Restricted]: thread.messageableBy === MessageableBy.Admin,
    },
  };

  return (
    <Form useFormProps={{ defaultValues }} className="flex items-center gap-8">
      <span className="text-text-secondary">Thread permissions:</span>
      <MultiOptionDropdown
        name="permissions"
        icon="Unlock"
        placeholder="Unlocked (default)"
        options={threadPermissionsOptions}
        onDropdownClose={handleOnSubmit}
      />
    </Form>
  );
};

export default ThreadPermissions;
