import { MemberConnection } from "@utility-types";
import Avatar from "components/design-system/Avatar/Avatar";
import tw from "utils/tw";

type Props = {
  className?: string;
  members: MemberConnection;
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

const MembersAvatars = ({ className, members, onClick }: Props): JSX.Element => {
  const firstMembers = members.edges.slice(0, 5).reverse();
  return (
    <div
      className={tw(
        "group-members flex flex-row-reverse items-center ml-20 px-20",
        { "cursor-pointer": !!onClick },
        className
      )}
      onClick={onClick}
    >
      {members.totalCount ? (
        <div className="ml-10 text-sm font-semibold">{members.totalCount}</div>
      ) : null}
      {firstMembers.map(member => (
        <Avatar
          key={member.node.id}
          size="medium"
          border="background-body-1"
          margin="-ml-12"
          {...member.node}
        />
      ))}
    </div>
  );
};

export default MembersAvatars;
