import { Icon, type IconName } from "components/design-system/icons/Icon";
import tw from "utils/tw";

export type PromptSuggestionNames =
  | "Search conversations"
  | "Analyze docs"
  | "Analyze images"
  | "Search the web"
  | "Search Glue help";

export type PromptSuggestion = {
  name: PromptSuggestionNames;
  icon: IconName;
  options: string[];
};

const promptSuggestions: PromptSuggestion[] = [
  {
    name: "Search conversations",
    icon: "ChatRounded",
    options: [
      "and highlight key takeaways",
      "and find team decisions",
      "and gather project updates",
      "and discover recurring topics",
    ],
  },
  {
    name: "Analyze docs",
    icon: "File",
    options: [
      "and extract key action items",
      "and compare their main arguments",
      "and create an executive brief",
      "and identify conflicting information",
    ],
  },
  {
    name: "Analyze images",
    icon: "Image",
    options: [
      "and explain visual composition",
      "and describe brand consistency",
      "and suggest improvements",
      "and identify design patterns",
    ],
  },
  {
    name: "Search the web",
    icon: "Globe",
    options: [
      "and find primary sources",
      "and compile expert opinions",
      "and track trends over time",
      "and analyze competing viewpoints",
    ],
  },
  {
    name: "Search Glue help",
    icon: "Help",
    options: [
      "and walk me through getting started",
      "and explain thread organization",
      "and tell me how to enable notifications",
      "and tell me how to use groups",
    ],
  },
];

type Props = {
  selectedCategory: PromptSuggestion | null;
  onClickCategory: (category: PromptSuggestion) => void;
  onClickSuggestion: (suggestion: string) => void;
};

const AIPromptSuggestions = ({ selectedCategory, onClickCategory, onClickSuggestion }: Props) => {
  return !selectedCategory ? (
    <ul className="flex gap-8 flex-wrap justify-center">
      {promptSuggestions.map(suggestion => (
        <li
          className={tw(
            "flex items-center gap-4 border-1 border-border-container p-8 rounded-lg group/prompt-suggestion",
            "hover:border-border-container-hover hover:bg-background-body-hover cursor-pointer"
          )}
          key={suggestion.name}
          onClick={() => onClickCategory(suggestion)}
        >
          <Icon
            className="text-icon-secondary group-hover/prompt-suggestion:text-icon-secondary-hover"
            icon={suggestion.icon}
            size={20}
          />
          <span className="text-text-subtle text-subhead group-hover/prompt-suggestion:text-text-secondary-hover">
            {suggestion.name}
          </span>
        </li>
      ))}
    </ul>
  ) : (
    <ul className="flex flex-col w-full">
      {selectedCategory.options.map(option => (
        <li
          className={tw(
            "flex items-center h-44 border-b-1 last:border-none border-border-container px-8 mx-16",
            "cursor-pointer hover:bg-background-body-hover hover:border-background-body-hover hover:rounded-lg",
            "has-[+_li:hover]:border-transparent" // Hides the border of the element above
          )}
          key={option}
          onClick={() => onClickSuggestion(option)}
        >
          <span className="truncate">
            <span className="text-text-subtle">{selectedCategory.name}</span>
            <span className="ml-3">{option}</span>
          </span>
        </li>
      ))}
    </ul>
  );
};

export default AIPromptSuggestions;
