import { useEffect, useRef } from "react";

import useOnClickOutside from "hooks/useOnClickOutside";

import { useEditorState } from "../providers/EditorProvider";

export default function useOnClickOutsideEditor(handler: (e: PointerEvent) => void) {
  const editorRef = useRef<HTMLElement | null>(null);
  const overlaysRef = useRef<HTMLElement | null>(null);
  const { ref: editor } = useEditorState(({ ref }) => ({ ref }));

  useEffect(() => {
    editorRef.current = editor;
    overlaysRef.current = document.querySelector("#overlays");
  }, [editor]);

  useOnClickOutside(
    [editorRef, overlaysRef],
    e => e.target instanceof HTMLElement && e.target.nodeName !== "GRAMMARLY-POPUPS" && handler(e)
  );
}
