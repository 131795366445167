import { MutableRefObject, useCallback, useEffect, useState } from "react";
import { useResizeObserver } from "usehooks-ts";

import isElementScrolledToEnd from "utils/isElementScrolledToEnd";

const useElementScrolledToBottom = (
  element: MutableRefObject<HTMLElement | null>,
  bottomThreshold = 16
) => {
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  const handleScroll = useCallback(() => {
    const scrollContainer = element.current;
    if (!scrollContainer) return;

    const newIsScrolledToBottom = isElementScrolledToEnd(scrollContainer, bottomThreshold);

    if (isScrolledToBottom === newIsScrolledToBottom) return; // prevent unnecessary re-renders

    setIsScrolledToBottom(newIsScrolledToBottom);
  }, [element, bottomThreshold, isScrolledToBottom]);

  useEffect(() => {
    const scrollContainer = element.current;

    scrollContainer?.addEventListener("scroll", handleScroll);
    return () => scrollContainer?.removeEventListener("scroll", handleScroll);
  }, [element, handleScroll]);

  useResizeObserver({
    ref: element,
    onResize: () => {
      handleScroll();
    },
  });

  return isScrolledToBottom;
};

export default useElementScrolledToBottom;
