import { Icon } from "components/design-system/icons";
import { TWMargin } from "components/design-system/types/margin";
import tw from "utils/tw";

type Props = {
  children: React.ReactNode;
  marginClassName?: TWMargin;
};

export function PendingInvitationsBanner({ children, marginClassName }: Props) {
  return (
    <div
      role="alert"
      className={tw(
        "rounded bg-background-secondary border border-border-container mx-32 px-16 py-12 flex items-center text-footnote",
        marginClassName
      )}
    >
      <Icon icon="Info" className="text-icon-action mr-8" size={20} />
      {children}
    </div>
  );
}
