import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Recipient } from "@utility-types";
import { Button } from "components/design-system/Button";
import {
  currentPathWithSearch,
  routeParams as getRouteParams,
  routeToThread,
  userPath,
} from "components/routing/utils";
import { ThreadViewWithoutFocus } from "components/threads/ThreadView";
import NoPersistentChat from "components/user/NoPersistentChat";
import { ChatType, ThreadsMailbox, usePersistentChatsQuery } from "generated/graphql";
import useChatRecipient from "hooks/useChatRecipient";
import tw from "utils/tw";

import FindUser from "./components/FindUser";

const ComposeDM = ({ isModal }: { isModal?: boolean }) => {
  const location = useLocation();
  const { pathname, search } = location;
  const { superTab } = getRouteParams({ pathname, search });

  const [selectedElement, setSelectedElement] = useState<Recipient | undefined>();
  const { data } = usePersistentChatsQuery({
    variables: { chatType: ChatType.User, last: 200 },
  });

  const history = useHistory();

  const chatRecipient = useChatRecipient();
  const existingDM = data?.persistentChats.edges?.find(r => {
    return chatRecipient(r.node)?.id === selectedElement?.id;
  });
  const recipient = chatRecipient(existingDM?.node);

  return (
    <div
      className={tw(
        "relative flex flex-col px-0 h-full w-full",
        { "md:min-h-[50vh] md:max-h-[80vh]": isModal },
        { "md:min-h-[50%] md:max-h-[100%]": !isModal }
      )}
    >
      <div className={tw("flex items-center min-h-[60px]", "border-b-1 border-border-container")}>
        {!isModal && (
          <Button
            buttonStyle="simpleSecondary"
            className="ml-12"
            icon="Close"
            iconSize={20}
            onClick={() => history.push(currentPathWithSearch({ v: undefined }))}
          />
        )}

        <div className="px-16 md:px-12 text-headline-bold text-text-primary">New Message</div>
      </div>

      <div
        className={tw(
          "flex items-center gap-4 h-44 px-16",
          "border-b-1 border-border-container text-subhead"
        )}
      >
        <div>To:</div>
        <FindUser selectedElement={selectedElement} setSelectedElement={setSelectedElement} />
      </div>

      {selectedElement && !!existingDM ? (
        <div className="grow overflow-hidden">
          {/**
           * Using ThreadViewWithoutFocus here instead of ThreadView, due to ThreadView needing to be wrapped in the partition context provider;
           * ThreadViewWithoutFocus is a stripped down version of ThreadView that doesn't rely on the partition context.
           */}
          <ThreadViewWithoutFocus
            headerType="none"
            mailbox={ThreadsMailbox.All}
            onSubmit={() => {
              if (superTab === "inbox") {
                history.replace(routeToThread({ threadID: existingDM.node.id }));
                return;
              }

              history.replace(userPath(recipient?.id ?? selectedElement.id));
            }}
            recipientID={recipient?.id}
            threadID={existingDM.node.id}
          />
        </div>
      ) : (
        <div className="h-full flex items-end">
          <NoPersistentChat
            fullHeight={false}
            showHeader={false}
            userID={selectedElement?.id}
            isUserRequired
          />
        </div>
      )}
    </div>
  );
};

export default ComposeDM;
