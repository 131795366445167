import { ComponentProps, FormEvent } from "react";

import { Button } from "components/design-system/Button";
import tw from "utils/tw";

import buttonStyles from "../../buttonStyles";

type ButtonProps = ComponentProps<typeof Button>;

type EditorButtonProps = ButtonProps & {
  onClick?: (e: FormEvent<HTMLButtonElement>) => void;
  text: string;
  type: "button" | "submit" | "reset" | undefined;
};

type AllowedButtons = {
  cancelEditButton?: EditorButtonProps;
  deleteButton?: EditorButtonProps;
  saveDraftButton?: EditorButtonProps;
};

type Props = ButtonProps & {
  buttonVariant: "cancelEditButton" | "deleteButton" | "saveDraftButton";
  buttons: AllowedButtons;
};

export const SecondaryBarButton = ({ buttons, buttonVariant }: Props): JSX.Element | null => {
  if (buttons?.[buttonVariant]) {
    const { ...buttonProps } = buttons[buttonVariant];

    return (
      <Button
        buttonStyle="subtle"
        buttonType="text"
        className={tw(buttonStyles(), "ml-10 font-semibold leading-tight")}
        iconClassName="!mr-5"
        {...{
          ...buttonProps,
          onClick: (e: FormEvent<HTMLButtonElement>) => {
            if (buttonProps.onClick) {
              buttonProps.onClick(e);
            }
          },
          type: buttonProps.type,
        }}
      >
        {buttonProps.text}
      </Button>
    );
  }
  return null;
};
