import { useState } from "react";

import Button from "components/design-system/Button/Button";
import { Footer, Main } from "components/ModalKit/Parts";
import { useDeleteSlackImportMutation, useSlackImportQuery } from "generated/graphql";

import ContactSupportBubble from "./ContactSupportBubble";
import { StepsProps } from "./types";

const ImportInformation = ({
  cancelButton,
  onClickNext,
  workspaceID,
}: StepsProps & { workspaceID: string }) => {
  const [deletingSlackImport, setDeletingSlackImport] = useState(false);

  const { data: slackImport, loading: slackImportLoading } = useSlackImportQuery({
    fetchPolicy: "network-only",
    variables: { workspaceID },
  });
  const [deleteSlackImport] = useDeleteSlackImportMutation();

  const handleClickNext = () => {
    if (slackImport?.slackImport) {
      setDeletingSlackImport(true);
      deleteSlackImport({ variables: { workspaceID } }).finally(() => {
        setDeletingSlackImport(false);
        onClickNext();
      });
      return;
    }
    onClickNext();
  };
  return (
    <>
      <Main className="flex flex-col px-32 py-16 gap-4">
        <span className="text-body">
          This wizard will guide you step-by-step through the process of importing your Slack data
          into Glue.
          <br />
          <br />
          Before you begin,{" "}
          <a
            className="text-text-link"
            href="http://slack.com/services/export"
            rel="noreferrer"
            target="_blank"
          >
            visit the Slack export page
          </a>{" "}
          to download your team's Slack data. You must be an admin of your Slack workspace and
          signed into your account in order to access the page.
          <br />
          <br />
          After downloading the export file, do not unzip or edit the contents of the exported data.
          If you have questions about how to download your Slack data, follow{" "}
          <a
            className="text-text-link"
            href="https://slack.com/help/articles/201658943-Export-your-workspace-data"
            rel="noreferrer"
            target="_blank"
          >
            these instructions
          </a>
          .
        </span>
        <ContactSupportBubble />
      </Main>
      <Footer>
        {cancelButton}
        <Button onClick={handleClickNext} disabled={slackImportLoading || deletingSlackImport}>
          I have the export data
        </Button>
      </Footer>
    </>
  );
};

export default ImportInformation;
