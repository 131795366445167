import { useLayoutEffect } from "react";

import { ColorSchemeTypes } from "@utility-types";
import useLocalSettingsStore from "store/useLocalSettingsStore";

const useDarkModeSetting = (): void => {
  const colorSchemePreview = useLocalSettingsStore(({ colorSchemePreview }) => colorSchemePreview);
  useLayoutEffect(() => {
    const toggleDarkMode = ({ matches: enabled }: { matches: boolean }) => {
      document.documentElement.classList.toggle("dark", enabled); // for :root color-scheme
      document.documentElement.classList.toggle("night", enabled); // for :root color-scheme
      document.body.classList.toggle("dark", enabled); // for tailwind
      document.body.classList.toggle("night", enabled); // for tailwind
      useLocalSettingsStore.setState({
        colorSchemeActive: enabled ? ColorSchemeTypes.Dark : ColorSchemeTypes.Light,
      });
    };

    if (colorSchemePreview === "default") {
      const prefersDarkMedia = window.matchMedia("(prefers-color-scheme: dark)");
      prefersDarkMedia.addEventListener("change", toggleDarkMode);
      toggleDarkMode(prefersDarkMedia);
      return () => prefersDarkMedia.removeEventListener("change", toggleDarkMode);
    }
    toggleDarkMode({ matches: colorSchemePreview === "dark" });
  }, [colorSchemePreview]);
};

export default useDarkModeSetting;
