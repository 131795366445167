import { createZustandStoreContext } from "create-zustand-store-context";
import { useEffect, useMemo } from "react";

import useLayerStackStore from "store/useLayerStackStore";
import generateRandomId from "utils/generateRandomId";

export enum LayerStackPriority {
  App = 0,
  ChatDrawer = 1,
  AppDrawer = 2,
  Modal = 3,
  Topmost = 4, // Photoswipe, etc.
}

export type LayerStore = {
  layerID: string;
  layerIsActive: boolean;
  zIndex: number;
};

const defaultState: LayerStore = {
  layerID: "",
  layerIsActive: true,
  zIndex: LayerStackPriority.App,
};

const {
  storeContext,
  useState: useLayerState,
  useStore: useLayerStore,
} = createZustandStoreContext<LayerStore>({
  defaultState,
  name: "LayerProvider",
});

const LayerStoreContext = storeContext;

const LayerProvider = ({
  children,
  id,
  isActive = true,
  zIndex = 0,
}: WithChildren & { id?: string; isActive?: boolean; zIndex?: number }) => {
  const layerID = useMemo(() => id || generateRandomId(), [id]);

  const currentStore = useLayerStore(undefined, {
    layerID,
    layerIsActive: isActive,
    zIndex,
  });

  useEffect(
    () =>
      useLayerStackStore.subscribe(({ activeLayerId }) => {
        currentStore.setState({ layerIsActive: activeLayerId === layerID });
      }),
    [currentStore, layerID]
  );

  const { addLayer, removeLayer } = useLayerStackStore.getState();

  useEffect(() => {
    if (isActive === false) return;

    addLayer({ layerID, zIndex });

    return () => {
      removeLayer(layerID);
    };
  }, [addLayer, isActive, layerID, removeLayer, zIndex]);

  return <LayerStoreContext.Provider value={currentStore}>{children}</LayerStoreContext.Provider>;
};

const MockLayerProvider = ({ children }: WithChildren) => {
  const currentStore = useLayerStore(undefined, {
    layerID: "mock-layer-id",
    layerIsActive: true,
    zIndex: LayerStackPriority.App,
  });

  return <LayerStoreContext.Provider value={currentStore}>{children}</LayerStoreContext.Provider>;
};

export { LayerProvider, MockLayerProvider, useLayerState };
