import { Form } from "components/design-system/Forms";
import Hr from "components/design-system/Hr";
import { Icon } from "components/design-system/icons";
import Key from "components/design-system/ui/Key";
import FlagToggleSwitch from "components/FeatureFlags/FlagToggleSwitch";
import { environmentFlagNames } from "featureFlags";
import useFeatureFlagStore from "store/useFeatureFlagStore";

const FeatureFlags = () => {
  const { flags: defaultValues } = useFeatureFlagStore(({ flags }) => ({
    flags,
  }));

  return (
    <Form
      className="contents"
      useFormProps={{
        defaultValues,
      }}
    >
      <div className="grow min-h-0 overflow-y-auto py-24">
        <div className="px-20 md:px-32">
          <div className="text-body-bold">Local flags</div>
          <div className="text-text-subtle">
            Flags configured in <Key>featureFlags/configs.local.ts</Key>.
          </div>
          {environmentFlagNames.map(keyName => (
            <FlagToggleSwitch key={keyName} keyName={keyName} />
          ))}
        </div>

        <Hr />

        <div className="mt-32 px-20 md:px-32">
          <div className="text-body-bold">External flags</div>
          <div className="text-text-subtle">
            External flags are managed in{" "}
            <a
              className="inline-flex gap-4 items-center text-body-bold"
              href="https://us.posthog.com/"
              rel="noreferrer"
              target="_blank"
            >
              PostHog
              <Icon icon="ExternalLink" size={12} />
            </a>
            .
          </div>
        </div>
      </div>
    </Form>
  );
};

export default FeatureFlags;
