import { useMemo } from "react";

import { Node } from "generated/graphql";

// useMemberEdge
// - Allows safe type discrimination on union of member and preview edges
// to make it easier to render a view based on edge membership.
const previewSuffix = "PreviewEdge" as const;

type PreviewEdge = { __typename: `${string}${typeof previewSuffix}` };

type MemberType<T> = Exclude<T, PreviewEdge>;

type PreviewType<T> = Extract<T, PreviewEdge>;

export const useMemberEdge = <T extends { __typename: `${string}Edge`; node: Node }>(
  edge: T | undefined | null
): {
  memberEdge?: MemberType<T>;
  previewEdge?: PreviewType<T>;
} =>
  useMemo(
    () =>
      edge?.__typename.endsWith(previewSuffix)
        ? { previewEdge: edge as PreviewType<T> }
        : edge
          ? { memberEdge: edge as MemberType<T> }
          : {},
    [edge]
  );

export default useMemberEdge;
