import { useFetchThreadMetadataQuery, useUserSettingsQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
export type UseLlmModelResult = {
  defaultModel?: string;
  defaultModelLoading?: boolean;
};

const useLlmModel = (threadID = ""): UseLlmModelResult => {
  const { authReady } = useAuthData();
  const { data: userSettings } = useUserSettingsQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
  });

  const { data: threadMetadata } = useFetchThreadMetadataQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    skip: !threadID,
    variables: { threadID },
  });

  return {
    defaultModel:
      threadMetadata?.threadMetadata?.aiSettings?.chatModel ||
      userSettings?.settings.llmSettings.chatModel,
    defaultModelLoading: !threadMetadata,
  };
};

export default useLlmModel;
