import { useEffect, useMemo, useRef } from "react";

/**
 * Creates DOM element to be used as React root.
 */
function createPortalRoot(id: string, zIndex?: string): HTMLElement {
  const portal = document.createElement("div");
  portal.setAttribute("data-testid", `portal${id !== "portal" ? `-${id}` : ""}`);
  portal.setAttribute("id", id);
  if (zIndex) {
    portal.style.zIndex = zIndex;
  }
  return portal;
}

/**
 * Appends element as last child of body.
 */
function appendPortal(portalElement: HTMLElement) {
  document.body.appendChild(portalElement);
}

/**
 * Attaches the element to the root.
 * Can be replaced via the `attachment` prop; for example,
 * if wanting to prepend, instead of append.
 */
function defaultAttachment(portal: HTMLElement, element: HTMLElement) {
  portal.appendChild(element);
}

/**
 * Hook to create a React Portal.
 * Automatically handles creating and tearing-down the root elements (no SRR
 * makes this trivial), so there is no need to ensure the parent target already
 * exists.
 * @example
 * const target = usePortal({ attachment, element, id, zIndex })
 * return createPortal(children, target)
 * @param attachment Attaches the element to the root.
 * @param element The HTML element that will wrap content in the portal.
 * @param id Optionally, id of the persistent target container, e.g 'tooltips'.
 * @returns {HTMLElement} The DOM node to use as the Portal target.
 */

type Props = {
  attachment?: (portal: HTMLElement, element: HTMLElement) => void;
  element?: string;
  id?: string;
  zIndex?: string;
};

const defaultPortalID = "portal";

export function usePortal({
  attachment = defaultAttachment,
  element = "div",
  id = defaultPortalID,
  zIndex,
}: Props): HTMLElement {
  const elementRef = useRef<HTMLElement>();
  const persistent = id !== defaultPortalID;

  const portalRoot = useMemo(() => {
    // Look for existing target dom element to append to
    const existingPortalRoot = document.querySelector(`#${id}`);
    // Parent is either a new root or the existing dom element
    const portalRoot =
      existingPortalRoot instanceof HTMLElement ? existingPortalRoot : createPortalRoot(id, zIndex);

    // If there is no existing DOM element, add a new one.
    if (!existingPortalRoot) {
      appendPortal(portalRoot);
    }

    return portalRoot;
  }, [id, zIndex]);

  useEffect(() => {
    // Add the detached element to the parent
    if (elementRef.current) {
      attachment(portalRoot, elementRef.current);
    }

    return function removeElement() {
      elementRef.current?.remove();

      if (
        !persistent &&
        portalRoot.childNodes.length <= 0 &&
        portalRoot.matches('[data-testid="portal-root"]')
      ) {
        portalRoot.remove();
      }
    };
  }, [attachment, elementRef, persistent, portalRoot]);

  /**
   * It's important we evaluate this lazily:
   * - We need first render to contain the DOM element, so it shouldn't happen
   *   in useEffect. We would normally put this in the constructor().
   * - We can't do 'const elementRef = useRef(document.createElement('div))',
   *   since this will run every single render (that's a lot).
   * - We want the ref to consistently point to the same DOM element and only
   *   ever run once.
   * @link https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
   */
  function getRootElem() {
    if (persistent) return portalRoot;

    if (!elementRef.current) {
      elementRef.current = document.createElement(element);
      elementRef.current.setAttribute("data-testid", "portal-element");
    }

    return elementRef.current;
  }

  return getRootElem();
}
