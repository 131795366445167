import BlockComponent, { BlockComponentProps } from "./BlockComponent";
import BlockMarkdown from "./BlockMarkdown";
import BlockPlainText from "./BlockPlainText";
import { SectionBlock } from "./blockTypes";

type BlockSectionProps = BlockComponentProps & {
  block: SectionBlock;
};

const BlockSection = ({ block, handlers }: BlockSectionProps) => {
  return (
    <div className="py-8">
      <div className="flex flex-wrap justify-between gap-4">
        <div className="w-1/2 grow">
          <span className="text-gray-200">
            {block.text && block.text.type === "markdown" ? (
              <BlockMarkdown text={block.text} handlers={handlers} />
            ) : (
              <BlockPlainText text={block.text} />
            )}
          </span>
          {block.fields && (
            <div className="grid grid-cols-2 gap-4 mt-2">
              {block.fields.map((field, i) =>
                field.type === "markdown" ? (
                  // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                  <BlockMarkdown key={i} text={field} handlers={handlers} />
                ) : (
                  // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                  <BlockPlainText key={i} text={field} />
                )
              )}
            </div>
          )}
        </div>
        {block.accessory && (
          <BlockComponent block={block.accessory} handlers={handlers} parentType={block.type} />
        )}
      </div>
    </div>
  );
};

export default BlockSection;
