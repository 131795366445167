import { FileType, GlueFile } from "@utility-types";
import env from "utils/processEnv";

export type GlueFileSimple = Pick<GlueFile, "fileType" | "id" | "name" | "previewable" | "url">;

/**
 * @summary Takes a glue:fil_ mention and returns a GlueFileSimple Object.
 * @param {string} url
 * @param {React.ReactNode} children
 * @returns {GlueFileSimple | null }
 */

export const parseFileMention = (url: string, children: React.ReactNode): GlueFileSimple | null => {
  const prefix = url.split("_")[0];
  if (prefix !== "glue:fil") return null;

  const fileType: FileType = (new URL(url).searchParams.get("fileType") as FileType) ?? "file";

  const id = new URL(url).pathname;

  const name = Array.isArray(children) && typeof children[0] === "string" ? children[0] : "";

  const previewable = url.includes("previewable");

  return {
    fileType,
    id,
    previewable,
    name,
    url: `${env.glueApiUrl}/file/${id}/download`,
  };
};
