import { useFormContext, useWatch } from "react-hook-form";

import useRequiredFields from "./useRequiredFields";

const useDisableSubmit = (requireChanges = true) => {
  const {
    formState: { errors, isDirty, isSubmitting, isValid },
  } = useFormContext();

  const requiredFields = useRequiredFields();
  const formValues = useWatch();

  const missingFields = requiredFields.filter(f => !formValues[f]);

  const formInvalid = !!missingFields.length || !!Object.keys(errors).length || !isValid;
  return formInvalid || isSubmitting || (!isDirty && requireChanges);
};

export default useDisableSubmit;
