import { useCallback } from "react";

import { useSnackbar } from "providers/SnackbarProvider";

const limit = 12;

type Previews = {
  linkPreviews: unknown[];
  uploads: unknown[];
};

type UseValidateAttachments = {
  validateAttachments: ({ linkPreviews, uploads }: Previews, newAttachments?: number) => boolean;
};

const useValidateAttachments = (): UseValidateAttachments => {
  const { openSnackbar } = useSnackbar();

  const validateAttachments = useCallback(
    ({ linkPreviews, uploads }: Previews, newAttachments = 1) => {
      const fileAttachmentCount = uploads.length || 0;
      const linkPreviewCount = linkPreviews.length || 0;
      if (fileAttachmentCount + linkPreviewCount + newAttachments > limit) {
        openSnackbar("error", `Message cannot have more than ${limit} attachments.`, 5000);
        return false;
      }

      return true;
    },
    [openSnackbar]
  );

  return { validateAttachments };
};

export default useValidateAttachments;
