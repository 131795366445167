import { Icon } from "components/design-system/icons";
import { useModalState } from "components/ModalKit/ModalProvider";
import tw from "utils/tw";

const ViewContent = <T extends string>({
  children,
  resetInitialSubView,
  setView,
  title,
}: WithChildren<{
  resetInitialSubView?: () => void;
  setView: (view: T | undefined) => void;
  title: React.ReactNode;
}>) => {
  const {
    dragging: { bind },
  } = useModalState(({ dragging }) => ({ dragging }));

  return (
    <div className="bg-background-body md:border-l border-border-container flex flex-col grow h-full">
      <div
        className={tw(
          "flex items-center justify-center md:justify-start h-[56px]",
          "border-b border-border-container px-48 md:px-32 shrink-0 text-headline-bold md:text-subhead-bold text-text-primary",
          { "cursor-grab select-none touch-none": !!bind }
        )}
        {...bind()}
      >
        <button
          className={tw("md:hidden", "absolute left-0 h-44 pr-8 pl-16 z-1 transition-transform")}
          onClick={() => {
            resetInitialSubView?.();
            setView(undefined);
          }}
        >
          <Icon icon="ChevronLeft" size={24} />
        </button>

        <div className="overflow-hidden text-center md:text-left w-full">{title}</div>
      </div>

      <div className="flex flex-col grow min-h-0 h-full">{children}</div>
    </div>
  );
};

export default ViewContent;
