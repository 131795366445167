import { useEffect, useRef } from "react";

const useDragOverListener = (): void => {
  const dragOverTimeout = useRef<NodeJS.Timeout | undefined>();
  useEffect(() => {
    const body = document.body;
    function dragOverHandler(event: DragEvent | React.DragEvent<HTMLDivElement>) {
      event.preventDefault();
      const { dataTransfer, target } = event;
      if (!dataTransfer) return;
      if (!target || !(target instanceof HTMLElement)) return;
      if (dataTransfer.types[0] !== "Files") return;

      body.classList.add("drag-active");
      if (target.classList.contains("drop-zone-target")) {
        dataTransfer.dropEffect = "copy";
      } else {
        dataTransfer.dropEffect = "none";
      }
      if (dragOverTimeout.current) {
        clearTimeout(dragOverTimeout.current);
      }
      dragOverTimeout.current = setTimeout(() => {
        body.classList.remove("drag-active");
      }, 58);
    }
    body.addEventListener("dragover", dragOverHandler);
    return () => {
      body.removeEventListener("dragover", dragOverHandler);
      if (dragOverTimeout.current) {
        clearTimeout(dragOverTimeout.current);
      }
    };
  }, []);
};

export default useDragOverListener;
