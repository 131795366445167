import { ComponentProps } from "react";
import { FieldValues, Path, useController, useFormContext } from "react-hook-form";

import { RecipientsSelect as OriginalRecipientsSelect } from "components/RecipientsSelect";
import tw from "utils/tw";

import { Label } from "./Label";

type Props<T extends FieldValues, TName extends Path<T> = Path<T>> = Omit<
  ComponentProps<typeof OriginalRecipientsSelect>,
  "onChange" | "value"
> & {
  label?: string;
  labelClassName?: string;
  name: TName;
  onChange?: ComponentProps<typeof OriginalRecipientsSelect>["onChange"];
  wrapperClassName?: string;
};

export const RecipientsSelect = <T extends FieldValues>({
  label,
  labelClassName,
  name,
  onChange,
  wrapperClassName,
  ...props
}: Props<T>) => {
  const {
    control,
    formState: { isSubmitting },
  } = useFormContext<T>();

  const {
    field: { value, onChange: fieldOnChange },
  } = useController<T>({ name, control });

  return (
    <div className={tw("my-20", wrapperClassName)} data-testid="recipients-select-input">
      {label && (
        <Label className={labelClassName} htmlFor={name}>
          {label}
        </Label>
      )}
      <OriginalRecipientsSelect
        disabled={props.disabled || isSubmitting}
        onChange={recipients => {
          fieldOnChange(recipients);
          onChange?.(recipients);
        }}
        value={value}
        {...props}
      />
    </div>
  );
};
