import { Form, SubmitButton, TextInput } from "components/design-system/Forms";
import { Footer, Header, Main } from "components/ModalKit";
import { useModalState } from "components/ModalKit/ModalProvider";
import { StandardModal } from "components/Modals";
import {
  LambdaSecretFieldsFragment,
  LambdaSecretsDocument,
  useCreateSecretMutation,
  useUpdateSecretMutation,
} from "generated/graphql";
import useModalStore from "store/useModalStore";

type FormData = {
  name: string;
  value: string;
};

type Props = {
  lambdaId: string;
  secret?: LambdaSecretFieldsFragment;
};

const EditSecretModal = ({ lambdaId, secret }: Props) => {
  const { modalId } = useModalState(({ modalId }) => ({
    modalId,
  }));
  const { closeModal } = useModalStore(({ closeModal }) => ({
    closeModal,
  }));
  const [createSecret] = useCreateSecretMutation();
  const [updateSecret] = useUpdateSecretMutation();

  const handleSubmit = async (data: FormData) => {
    const { name, value } = data;

    if (secret) {
      await updateSecret({
        refetchQueries: [LambdaSecretsDocument],
        variables: {
          secretID: secret.id,
          value,
        },
      });

      return;
    }

    await createSecret({
      refetchQueries: [LambdaSecretsDocument],
      variables: {
        lambdaID: lambdaId,
        name,
        value,
      },
    });

    closeModal(modalId);
  };

  return (
    <StandardModal>
      <Header variant="bordered">
        <h3 className="m-0">{secret ? "Update Secret Value" : "Create Secret"}</h3>
      </Header>
      <Form
        onSubmit={handleSubmit}
        useFormProps={{
          defaultValues: {
            name: secret?.name,
            value: "",
          },
        }}
      >
        <Main className="p-16 md:px-32">
          <div className="flex-col grow">
            <TextInput<FormData>
              config={{ required: true }}
              disabled={!!secret}
              label="Name"
              labelClassName="text-interactive-strong"
              name="name"
              wrapperClassName="mt-0 mb-20"
            />
            <TextInput<FormData>
              config={{ required: true }}
              label="Value"
              labelClassName="text-interactive-strong"
              name="value"
              wrapperClassName="mt-0 mb-20"
            />
          </div>
        </Main>
        <Footer flexboxClassName="justify-end">
          <SubmitButton>{secret ? "Update Secret" : "Create Secret"}</SubmitButton>
        </Footer>
      </Form>
    </StandardModal>
  );
};

export default EditSecretModal;
