import { Skeleton } from "components/Skeleton";

export const GroupProfileSection = ({
  title,
  children,
  loading = false,
  titleSkeletonWidth = "100px",
}: {
  title: string;
  children: React.ReactNode;
  loading?: boolean;
  titleSkeletonWidth?: string;
}) => {
  return (
    <section>
      {loading ? (
        <Skeleton width={titleSkeletonWidth} />
      ) : (
        <div className="text-headline-bold text-text-primary select-none">{title}</div>
      )}
      {children}
    </section>
  );
};
