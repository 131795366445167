/* spellchecker: disable */
import LinkifyIt from "linkify-it";

const linkifier = new LinkifyIt();
linkifier.add("ionic:", "http:"); // Capacitor, internal URL
linkifier.add("webcal:", null);

const ALLOWED_PROTOCOLS = ["http:", "https:", "ftp:", "ftps:", "mailto:"];

const VALID_TLD = new RegExp(/\.[a-z]{2,}$/);

export const hasValidTLD = (url: string) => {
  const { host } = new URL(url);
  return VALID_TLD.test(host);
};

export const hasValidProtocol = (url: string) =>
  ALLOWED_PROTOCOLS.some(protocol => url.startsWith(protocol));

export const matchURL = (url: string): string | undefined => {
  if (typeof url !== "string") {
    return;
  }

  if (!linkifier.test(url)) return;

  const match = linkifier.match(url);

  if (!match || match.length > 1 || (match[0]?.index ?? 0) > 0 || (match[0]?.text ?? "") !== url) {
    return;
  }

  const matchedURL = match[0]?.url;
  if (!matchedURL) {
    return;
  }

  return matchedURL.startsWith("//") ? `https:${matchedURL}` : matchedURL;
};

export { linkifier };
