import { ButtonHTMLAttributes } from "react";

import InformationBubble from "components/design-system/InformationBubble/InformationBubble";
import useComposeToGlue from "components/threads/hooks/useComposeToGlue";

const ContactButton = ({ children }: Pick<ButtonHTMLAttributes<HTMLButtonElement>, "children">) => {
  const { composeThreadToGlue } = useComposeToGlue("support");
  const handleContact = () => {
    composeThreadToGlue();
  };

  return (
    <button className="text-text-link text-footnote-bold cursor-pointer" onClick={handleContact}>
      {children}
    </button>
  );
};

const ContactSupportBubble = ({
  contentType = "default",
}: { contentType?: "default" | "file" }) => (
  <InformationBubble className="mt-auto">
    {contentType === "file" ? (
      <>
        <span className="text-footnote-bold">Max file size: 100MB.</span> To upload larger files,{" "}
        <ContactButton>contact support</ContactButton>.
      </>
    ) : (
      <>
        Need help? <ContactButton>Contact support</ContactButton>.
      </>
    )}
  </InformationBubble>
);

export default ContactSupportBubble;
