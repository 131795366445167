import { ElementRef } from "react";
import { MutableRefObject } from "react";

import { FileAttachButton } from "components/FileUploader";
import { MessageEditor } from "components/MessageEditor";
import { FormattingBar } from "components/MessageEditor/components/controls/FormattingBar";
import { MentionButton } from "components/MessageEditor/components/controls/MentionButton";
import tw from "utils/tw";

import type { PromptSuggestionNames } from "../AIPromptSuggestions";

import AIModelSelect from "./AIModelSelect";

type Props = {
  onAttachFiles: (files: File[]) => void;
  editor: MutableRefObject<ElementRef<typeof MessageEditor> | null>;
  selectedCategory?: PromptSuggestionNames;
  threadID?: string;
  isReply: boolean;
};

const AIButtonBar = ({ onAttachFiles, editor, threadID, isReply, selectedCategory }: Props) => {
  return (
    <div className={tw("flex gap-10 overflow-hidden", isReply && "ml-3")}>
      <div className="flex gap-10">
        <FileAttachButton
          key="file-attach-button"
          onAttachFiles={onAttachFiles}
          tooltip={selectedCategory === "Analyze images" ? "Attach image files" : undefined}
          tooltipOpen={selectedCategory === "Analyze images" || selectedCategory === "Analyze docs"}
          tooltipPlacement="bottom"
          tooltipStyle="inverted"
        />
        <MentionButton
          key="mention-button"
          tooltip="Mention threads, groups, or people"
          tooltipOpen={selectedCategory === "Search conversations"}
          tooltipPlacement="bottom"
          tooltipStyle="inverted"
        />
        <FormattingBar
          options={["codeBlock"]}
          tooltipPlacement="bottom"
          tooltipStyle="inverted"
          disableMargins
          showAllButtons
          alwaysActive
          show
        />
      </div>
      <AIModelSelect
        mode="simplified"
        onModalClose={() => editor.current?.focusEditor("end")}
        threadID={threadID}
      />
    </div>
  );
};

export default AIButtonBar;
