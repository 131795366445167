import { Icon } from "components/design-system/icons";
import glueImageURL from "utils/glueImageURL";
import { trimUrl } from "utils/trimUrl";
import tw from "utils/tw";

type InformationSummaryProps = {
  compact?: boolean;
  description?: string | null;
  icon?: { url: string } | null;
  title?: string;
  url?: string;
};

const InformationSummary = ({
  compact = false,
  description,
  icon,
  title,
  url,
}: InformationSummaryProps): JSX.Element => (
  <div className="flex items-center justify-between grow min-w-0 basis-[50%] px-12 py-8">
    <div className="group/info-summary flex flex-col w-full min-w-0 max-h-128">
      {title && (
        <div className="flex flex-row items-center">
          {icon && (
            <img
              alt="favicon"
              className="w-16 h-16 mr-8 rounded-sm shrink-0"
              src={glueImageURL(icon.url)}
            />
          )}

          <div
            className={tw(
              "group-hover/info-summary:underline",
              "break-words line-clamp-1 min-w-0 text-ellipsis",
              "text-body-bold text-text-primary"
            )}
          >
            {title}
          </div>
        </div>
      )}

      {url && (
        <div className="flex items-center">
          <div className="shrink-0 mt-1 mr-8 p-2">
            <Icon icon="Link" size={12} strokeWidth={1} />
          </div>
          <div className="text-footnote text-text-subtle truncate">{trimUrl(url)}</div>
        </div>
      )}

      {description && (
        <div
          className={tw(
            "break-words mt-4 text-ellipsis text-text-secondary text-subhead",
            compact ? "line-clamp-1" : "line-clamp-3"
          )}
        >
          {description}
        </div>
      )}
    </div>
  </div>
);

export default InformationSummary;
