import type { Emoji } from "@emoji-mart/data";
import { useEffect, useState } from "react";

import { Message, ThreadPreview, User } from "@utility-types";
import { emojiMap, getNativeEmoji, useEmoji } from "components/design-system/Emoji";
import useFeedReactions from "components/Feed/hooks/useFeedReactions";
import ReactionListItems from "components/Reactions/ReactionListItems";
import { routeToThread } from "components/routing/utils";
import { MessagePreview } from "components/views/activity/ActivityMain/MessagePreview";
import { FeedType, MessageFieldsFragment } from "generated/graphql";
import useAuthData from "hooks/useAuthData";

import ActivityItemLayout from "./ActivityItemLayout";

type Reaction = {
  emoji: Emoji;
};

type Props = {
  actor: User;
  actorCount: number;
  isRead: boolean;
  object: Message;
  onClick: (e: React.MouseEvent<HTMLLIElement>) => void;
  selected: boolean;
  reactions: string[];
  target: ThreadPreview;
  timestamp: string;
};

const ActivityItemReactedToMessage = ({
  actor,
  actorCount,
  object: message,
  reactions,
  target,
  ...props
}: Props) => {
  const [reactionEmojis, setReactionEmojis] = useState<Emoji[]>([]);
  const { data: emojiData } = useEmoji();

  useEffect(() => {
    const rMap = new Map<string, Reaction>();
    reactions.forEach(r => {
      const emoji = emojiData.emojis[emojiMap[r] || r];
      if (emoji) {
        rMap.set(emoji.id, { emoji });
      }
    });
    setReactionEmojis([...rMap].map(r => r[1].emoji));
  }, [emojiData, reactions]);

  const lastEmoji = reactionEmojis[0]?.id;

  return (
    <ActivityItemLayout
      avatarProps={{
        emojiProps: {
          emoji: lastEmoji ? getNativeEmoji(lastEmoji) : undefined,
        },
        background: "transparent",
      }}
      linkTo={toSecondary =>
        routeToThread({
          superTab: "activity",
          messageID: message?.streamID ?? undefined,
          threadID: target.id,
          to: toSecondary ? "secondary" : "primary",
        })
      }
      title={`${actor.name} ${
        actorCount >= 2 ? ` and ${actorCount - 1} other${actorCount > 2 ? "s" : ""} ` : ""
      } reacted in ${target.name}`}
      type={FeedType.Reactions}
      {...props}
    >
      <ItemBody message={message} />
    </ActivityItemLayout>
  );
};

function ItemBody({ message }: { message: MessageFieldsFragment }) {
  const messageReactions = useFeedReactions({ message });
  const user = useAuthData();

  if (!user?.authData) return null;

  return (
    <>
      <MessagePreview message={message} authData={user.authData} title={message.user.name} />
      {messageReactions.allReactions.length > 0 && (
        <div className="mt-8">
          <ReactionListItems
            getAllReactions={async () => ({
              duration: "0",
              reactions: messageReactions.allReactions,
            })}
            isOwnReaction={(emojiID: string) =>
              !!messageReactions.ownReactions?.find(r => r.type === emojiID)
            }
            readonly
            {...messageReactions}
          />
        </div>
      )}
    </>
  );
}

export default ActivityItemReactedToMessage;
