import { GroupPreview, User, WorkspacePreviewSimple } from "@utility-types";
import { routeToGroup } from "components/routing/utils";
import { FeedType } from "generated/graphql";
import { formatGroupName } from "utils/group/formatGroupName";

import ActivityItemLayout from "./ActivityItemLayout";

type Props = {
  actor: User;
  isRead: boolean;
  object: GroupPreview;
  onClick: (e: React.MouseEvent<HTMLLIElement>) => void;
  selected: boolean;
  target: WorkspacePreviewSimple;
  timestamp: string;
};

const ActivityItemAddedGroup = ({ object, target, ...props }: Props): JSX.Element => {
  const { name: groupName, emoji } = formatGroupName(object);
  return (
    <ActivityItemLayout
      avatarProps={{
        emojiProps: { emoji },
        name: groupName,
        background: "transparent",
      }}
      groupType="Default"
      linkTo={toSecondary =>
        routeToGroup({
          groupID: object.id,
          to: toSecondary ? "secondary" : undefined,
        })
      }
      title={`New group in ${target.name}`}
      type={FeedType.Groups}
      {...props}
    >
      <div>
        <div className="text-text-primary text-subhead-bold">{groupName || target.name}</div>
        <div className="flex text-text-primary text-body">
          {object.members.totalCount} member
          {object.members.totalCount !== 1 ? "s" : ""}
          {object.description ? " · " : ""}
          {object.description}
        </div>
      </div>
    </ActivityItemLayout>
  );
};

export default ActivityItemAddedGroup;
