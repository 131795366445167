import { Workspace } from "@utility-types";
import Avatar from "components/design-system/Avatar/Avatar";
import { TWMargin } from "components/design-system/types/margin";
import IdentityBadge from "components/design-system/ui/IdentityBadge/IdentityBadge";
import { Skeleton } from "components/Skeleton";
import tw from "utils/tw";

const ProfileRow = ({
  margin = "m-0",
  workspace,
}: {
  margin?: TWMargin;
  workspace?: Workspace;
}) => (
  <div className={tw("flex gap-8 items-center h-48 px-8", margin)}>
    <Avatar
      avatarURL={workspace?.avatarURL}
      loading={!workspace}
      name={workspace?.name}
      rounded="rounded-md"
      size="medium"
    />
    <div className="flex flex-col grow min-w-0">
      <span className="text-subhead-bold truncate">
        {workspace ? workspace.name : <Skeleton width="180px" height="32px" />}
      </span>
      <span className="text-footnote">
        {workspace ? (
          <IdentityBadge recipient={workspace} />
        ) : (
          <Skeleton width="180px" height="24px" />
        )}
      </span>
    </div>
  </div>
);

export default ProfileRow;
