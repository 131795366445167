import { ComponentProps, ElementRef, MutableRefObject, useEffect, useRef } from "react";

import { Recipient } from "@utility-types";
import { MessageEditor } from "components/MessageEditor";
import MessageEditorContainer from "components/MessageEditor/MessageEditorContainer";
import { useLayerState } from "providers/LayerProvider";
import useAppStateStore from "store/useAppStateStore";
import { isNativeIOS } from "utils/platform";
import isGlueAIRecipient from "utils/thread/isGlueAIRecipient";

import { State } from "./DraftReducer";
import ReplyToMessage from "./ReplyToMessage/ReplyToMessage";

type Props = {
  accessory: React.ReactNode;
  autoFocus: boolean;
  compose: State;
  editor: MutableRefObject<ElementRef<typeof MessageEditor> | null>;
  onChange: () => void;
  onMention?: (mention: Recipient) => void;
  sendDraft: () => void;
  variant?: ComponentProps<typeof MessageEditorContainer>["variant"];
};

const DraftComposeWrapper = ({ children, wrap }: WithChildren<{ wrap: boolean }>) => {
  return wrap ? <div className="flex grow">{children}</div> : children;
};

const ThreadComposeEditor = ({
  accessory,
  autoFocus,
  compose,
  editor,
  onChange,
  onMention,
  sendDraft,
  variant = "thread-compose",
}: Props) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  const isGlueAI = isGlueAIRecipient(compose.draftForm.recipients);

  const { layerIsActive } = useLayerState(({ layerIsActive }) => ({
    layerIsActive,
  }));

  // Set readonly editor when layer is inactive
  useEffect(() => {
    editor.current?.setReadOnly(!layerIsActive);
  }, [editor, layerIsActive]);

  const messageRef = useRef(compose.draftForm.message);
  messageRef.current = compose.draftForm.message;

  // Prevent editor message from being lost when toggling Glue AI
  useEffect(() => {
    editor.current?.setMessage(messageRef.current);
  }, [editor, isGlueAI]);

  useEffect(() => {
    if (!autoFocus || editor.current?.hasFocus()) return;
    const focusEditor = () => editor.current?.focusEditor("start");
    isNativeIOS() ? setTimeout(focusEditor, 350) : focusEditor();
  }, [autoFocus, editor]);

  const placeholder = compose.draftForm.replyToMessage
    ? "Reply..."
    : isGlueAI
      ? "Message Glue AI..."
      : "Send a message...";

  return (
    <MessageEditorContainer showHelperText={!breakpointMD} variant={variant} safeAreaPadding>
      {compose.draftForm.replyToMessage && (
        <ReplyToMessage message={compose.draftForm.replyToMessage} />
      )}

      <DraftComposeWrapper wrap={!!compose.draftForm.replyToMessage}>
        <MessageEditor
          ref={editor}
          accessory={accessory}
          enterKeyBehavior="new-line"
          onChange={onChange}
          onMention={onMention}
          placeholder={placeholder}
          showSendButton={!breakpointMD}
          submitForm={sendDraft}
        />
      </DraftComposeWrapper>
    </MessageEditorContainer>
  );
};

export default ThreadComposeEditor;
