import { ComponentProps, useEffect, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { RadioGroup } from "components/design-system/Forms";
import { NotifyMessagesSetting, NotifyMessagesSoundSetting } from "generated/graphql-types";

import type { FormData } from "../types";

import { Field, FieldSet } from "./FieldSet";

const NotificationSoundsPref = () => {
  const { setValue } = useFormContext<FormData>();
  const { notifyMessages, notifyMessagesSound } = useWatch<FormData>();

  const notifyMessagesOptions = useMemo<ComponentProps<typeof RadioGroup<FormData>>["options"]>(
    () => [
      {
        label: "All notifications",
        value: NotifyMessagesSoundSetting.Inbox,
        disabled: notifyMessages !== NotifyMessagesSetting.Inbox,
      },
      {
        label: "Mentions, replies, & direct messages",
        value: NotifyMessagesSoundSetting.Direct,
        disabled: notifyMessages === NotifyMessagesSetting.None,
      },
      {
        label: "Don't play any sounds",
        value: NotifyMessagesSoundSetting.None,
      },
    ],
    [notifyMessages]
  );

  useEffect(() => {
    if (notifyMessages === NotifyMessagesSetting.None) {
      setValue("notifyMessagesSound", NotifyMessagesSoundSetting.None);
    } else if (
      notifyMessages === NotifyMessagesSetting.Direct &&
      notifyMessagesSound === NotifyMessagesSoundSetting.Inbox
    ) {
      setValue("notifyMessagesSound", NotifyMessagesSoundSetting.Direct);
    }
  }, [notifyMessages, notifyMessagesSound, setValue]);

  return (
    <FieldSet className="px-20 md:px-32" label="Sounds">
      <Field label="Play notification sounds for:" labelPosition="top">
        <RadioGroup<FormData>
          groupClassName="!my-0"
          name="notifyMessagesSound"
          options={notifyMessagesOptions}
        />
      </Field>
    </FieldSet>
  );
};

export default NotificationSoundsPref;
