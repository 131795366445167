import {
  PointerEventHandler,
  PointerEvent as ReactPointerEvent,
  useCallback,
  useEffect,
  useRef,
} from "react";

import useNativeHaptics from "./native/useNativeHaptics";

function isPointerEvent<Target>(
  event: LongPressEvent<Target> | Event
): event is ReactPointerEvent<Target> {
  if (event instanceof PointerEvent) return true;

  return !(event instanceof Event) && event.nativeEvent instanceof PointerEvent;
}

type Coordinates = {
  x: number;
  y: number;
} | null;

function getCurrentPosition<Target>(event: LongPressEvent<Target>): Coordinates {
  if (isPointerEvent(event)) {
    return {
      x: event.pageX || 0,
      y: event.pageY || 0,
    };
  }

  /* istanbul ignore next */
  return null;
}

type LongPressEvent<Target = Element> = ReactPointerEvent<Target>;

type LongPressCallback<Target = Element> = (event: LongPressEvent<Target> | PointerEvent) => void;

type LongPressResult<Target> = {
  events: {
    pointercancel: (event: Event) => void;
    pointerdown: (event: Event) => void;
    pointerleave: (event: Event) => void;
    pointermove: (event: Event) => void;
    pointerup: (event: Event) => void;
  };
  handlers: {
    onPointerCancel: PointerEventHandler<Target>;
    onPointerDown: PointerEventHandler<Target>;
    onPointerLeave: PointerEventHandler<Target>;
    onPointerMove: PointerEventHandler<Target>;
    onPointerUp: PointerEventHandler<Target>;
  };
};

interface LongPressOptions<Target = Element> {
  /**
   * If long press should be canceled when detected movement while pressing.
   *
   * Use number value to specify move tolerance in pixels.
   *
   * default value 0
   *
   * */
  cancelOnMovement?: number;

  /**
   * Disable haptic feedback on long press
   */
  disableHaptic?: boolean;

  /**
   * Will focus target/currentTarget.
   *
   * default value true
   */
  focus?: boolean;

  /**
   * Called when press is released
   *
   * before threshold time elapses,
   *
   * therefore before long press occurs.
   */
  onCancel?: LongPressCallback<Target>;

  /**
   * Called when press is released
   *
   * (after triggering callback).
   *
   */
  onFinish?: LongPressCallback<Target>;

  /**
   * Handler for onTouchMove and onMouseMove props,
   *
   * also allowing to make some operations on event
   *
   * before triggering cancelOnMovement.
   */
  onMove?: LongPressCallback<Target>;

  /**
   * Called when the user performs a short press.
   *
   * This occurs when the touch is released prior to the threshold
   *
   * and the touch has not moved more than cancelOnMovement.
   */
  onShortPress?: LongPressCallback<Target>;

  /**
   * Called when element is initially pressed
   *
   * (before starting timer which detects long press).
   *
   */
  onStart?: LongPressCallback<Target>;

  /**
   * Time user need to hold click or tap before long press callback is triggered
   */
  threshold?: number;
}

/**
 * Hook to detect long press and optional callbacks
 * If long press is canceled, onClick or on tab, an optional canceled callback is called
 */

export function useLongPress<
  Target extends Element = Element,
  Callback extends LongPressCallback<Target> = LongPressCallback<Target>,
>(
  callback: Callback,
  {
    cancelOnMovement = 0,
    disableHaptic = false,
    focus = true,
    onCancel,
    onFinish,
    onMove,
    onShortPress,
    onStart,
    threshold = 250,
  }: LongPressOptions<Target> = {}
): LongPressResult<Target> {
  const isLongPressActive = useRef(false);
  const isPressed = useRef(false);
  const timerCallback = useRef<NodeJS.Timeout>();
  const savedCallback = useRef(callback);
  const startPosition = useRef<Coordinates>(null);

  const focusElement = useCallback((event: LongPressEvent<Target> | Event) => {
    const { currentTarget, target } = event;
    const element = currentTarget || target;
    const outlineNone = "outline-none";
    const pointerEvent = event instanceof PointerEvent;
    const isNotFocusable = currentTarget === null || currentTarget === window;

    let hasOutlineNoneClass = false;

    if (pointerEvent && isNotFocusable) return;

    if (!(element instanceof HTMLElement)) return;

    hasOutlineNoneClass = element.classList.contains(outlineNone);

    element.tabIndex = -1;
    element.classList.add(outlineNone);
    element.focus();

    setTimeout(() => {
      if (!element) return;

      element.blur();
      element.removeAttribute("tabIndex");
      !hasOutlineNoneClass && element.classList.remove(outlineNone);
    }, 0);
  }, []);

  const { lightImpactHaptic } = useNativeHaptics();

  const shortPressInvalidated = useRef(false);

  const finish = useCallback(
    (event: LongPressEvent<Target> | Event) => {
      // Ignore events other than pointer
      if (!isPointerEvent(event)) return;

      startPosition.current = null;

      // Trigger onFinish callback only if timer was active
      if (isLongPressActive.current) {
        onFinish?.(event);
      } else if (isPressed.current) {
        // Otherwise if not active trigger onCancel
        if (!shortPressInvalidated.current) {
          focus && focusElement(event);

          onShortPress?.(event);
        }
        onCancel?.(event);
      }

      isLongPressActive.current = false;

      isPressed.current = false;

      timerCallback.current !== undefined && clearTimeout(timerCallback.current);

      timerCallback.current = undefined;

      shortPressInvalidated.current = false;
    },
    [focus, focusElement, onCancel, onFinish, onShortPress]
  );

  const start = useCallback(
    (event: LongPressEvent<Target> | Event) => {
      // Prevent multiple start triggers
      if (isPressed.current) {
        return;
      }

      // Ignore events other than pointer
      if (!isPointerEvent(event)) return;

      startPosition.current = getCurrentPosition(event);

      // When touched trigger onStart and start timer
      onStart?.(event);

      isPressed.current = true;

      timerCallback.current = setTimeout(() => {
        if (event?.target instanceof Element && !document.body.contains(event?.target)) {
          shortPressInvalidated.current = true;
          return finish(event);
        }

        if (savedCallback.current) {
          savedCallback.current(event);

          isLongPressActive.current = true;

          focus && focusElement(event);

          !disableHaptic && lightImpactHaptic();
        }

        shortPressInvalidated.current = false;
      }, threshold);
    },
    [onStart, threshold, finish, focus, focusElement, disableHaptic, lightImpactHaptic]
  );

  const cancel = useCallback(
    (event: LongPressEvent<Target> | Event) => {
      shortPressInvalidated.current = true;
      finish(event);
    },
    [finish]
  );

  const handleMove = useCallback(
    (event: LongPressEvent<Target> | Event) => {
      if (!isPointerEvent(event)) return;

      onMove?.(event);

      if (cancelOnMovement && startPosition.current) {
        const currentPosition = getCurrentPosition(event);

        /* istanbul ignore else */
        if (currentPosition) {
          const movedDistance = {
            x: Math.abs(currentPosition.x - startPosition.current.x),
            y: Math.abs(currentPosition.y - startPosition.current.y),
          };

          // If moved outside move tolerance box then cancel long press
          if (movedDistance.x > cancelOnMovement || movedDistance.y > cancelOnMovement) {
            shortPressInvalidated.current = true;
            finish(event);
          }
        }
      }
    },
    [finish, cancelOnMovement, onMove]
  );

  useEffect(
    () => (): void => {
      // Clear timeout on unmount
      timerCallback.current !== undefined && clearTimeout(timerCallback.current);
    },
    []
  );

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  const pointerHandlers = {
    events: {
      pointercancel: cancel,
      pointerdown: start,
      pointerleave: cancel,
      pointermove: handleMove,
      pointerup: finish,
    },
    handlers: {
      onPointerCancel: cancel,
      onPointerDown: start,
      onPointerLeave: cancel,
      onPointerMove: handleMove,
      onPointerUp: finish,
    },
  };

  return { ...pointerHandlers };
}
