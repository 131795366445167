import { create } from "zustand";
import { devtools } from "zustand/middleware";

import type { Recipient } from "@utility-types";

export type ReplyPayload = {
  action: "reply";
  initialRecipients: Recipient[];
  message: { id: string; threadID: string };
  recipientsRemoved: number;
};

type AppDrawerStore = {
  closeDrawer: () => void;
  isOpen: boolean;
  openDrawer: (payload?: AppDrawerStore["payload"]) => void;
  payload?: ReplyPayload;
  setState: (values: Partial<AppDrawerStore>) => void;
};

const useAppDrawerStore = create<AppDrawerStore>()(
  devtools(
    set => ({
      closeDrawer: () => set(() => ({ isOpen: false, payload: undefined })),
      isOpen: false,
      openDrawer: payload => set(() => ({ isOpen: true, payload })),
      setState: values => set(state => ({ ...state, ...values })),
    }),
    { name: "AppDrawerState" }
  )
);

export default useAppDrawerStore;
