import env from "utils/processEnv";

import { BaseFlagConfig, FlagEnvironment } from "./types";

export enum LocalFeatureFlag {
  LAMBDAS = "LAMBDAS",
  REACT_SCAN = "REACT_SCAN",
  SERVICE_WORKER = "SERVICE_WORKER",
}

export type LocalFeatureFlagConfig = BaseFlagConfig & {
  source: "local";
  key: LocalFeatureFlag;
  /** Environments where flag can be used. Value determines default setting. */
  environments: { [K in FlagEnvironment]?: boolean };
  /** Whether the flag can be toggled. */
  toggleable: boolean;
};

export const localFeatureFlagConfigs: Record<LocalFeatureFlag, LocalFeatureFlagConfig> = {
  [LocalFeatureFlag.LAMBDAS]: {
    source: "local",
    key: LocalFeatureFlag.LAMBDAS,
    environments: { development: false, staging: false, production: false },
    toggleable: true,
  },
  [LocalFeatureFlag.REACT_SCAN]: {
    source: "local",
    key: LocalFeatureFlag.REACT_SCAN,
    environments: { development: false },
    toggleable: true,
  },
  [LocalFeatureFlag.SERVICE_WORKER]: {
    source: "local",
    key: LocalFeatureFlag.SERVICE_WORKER,
    environments: { development: false, staging: false },
    toggleable: true,
  },
};

/** Properly typed names for local feature flags. */
export const localFeatureFlagNames = Object.keys(localFeatureFlagConfigs) as LocalFeatureFlag[];

/** Returns true if a given string matches a local feature flag. */
export const isLocalFeatureFlag = (name: string): name is LocalFeatureFlag => {
  return localFeatureFlagNames.includes(name as LocalFeatureFlag);
};

/** Local feature flags that are available in the current environment. */
export const environmentFlagNames = (
  Object.keys(localFeatureFlagConfigs) as LocalFeatureFlag[]
).reduce<LocalFeatureFlag[]>((acc, key) => {
  if (env.glueEnv in localFeatureFlagConfigs[key].environments) {
    acc.push(key as LocalFeatureFlag);
  }
  return acc;
}, []);
