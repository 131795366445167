import { ComponentProps } from "react";

import { RadioGroup } from "components/design-system/Forms";
import { NotifyMessagesSetting } from "generated/graphql";

import type { FormData } from "../types";

import { Field, FieldSet } from "./FieldSet";

const notifyMessagesOptions: ComponentProps<typeof RadioGroup<FormData>>["options"] = [
  {
    label: "All inbox messages",
    value: NotifyMessagesSetting.Inbox,
  },
  {
    label: "Mentions, replies, & direct messages",
    value: NotifyMessagesSetting.Direct,
  },
  {
    label: "Nothing",
    value: NotifyMessagesSetting.None,
  },
];

const InboxNotificationsPref = () => {
  return (
    <FieldSet className="px-20 md:px-32" label="Inbox notifications">
      <Field label="Notify me about:" labelPosition="top">
        <RadioGroup<FormData>
          groupClassName="!my-0"
          name="notifyMessages"
          options={notifyMessagesOptions}
          wrapperClassName=""
        />
      </Field>
    </FieldSet>
  );
};

export default InboxNotificationsPref;
