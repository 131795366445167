import { LocalNotifications } from "@capacitor/local-notifications";
import { AndroidSettings, IOSSettings, NativeSettings } from "capacitor-native-settings";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useWatch } from "react-hook-form";

import { Button } from "components/design-system/Button";
import { Icon, IconName } from "components/design-system/icons";
import TwoTierInfoBox from "components/design-system/TwoTierInfoBox";
import useNotificationPermissions, {
  NOTIFICATION_CHANNEL_ID,
} from "components/devices/hooks/useNotificationPermissions";
import { MacSystemNotificationsModal } from "components/Modals/MacSystemNotificationsModal";
import useComposeToGlue from "components/threads/hooks/useComposeToGlue";
import { GLUE_DOCS } from "constants/glue-docs";
import { NotificationPermissionStatus, NotifyMessagesSoundSetting } from "generated/graphql-types";
import useAppStateStore from "store/useAppStateStore";
import useModalStore, { ModalStore } from "store/useModalStore";
import { Platform, getPlatform, isNative } from "utils/platform";
import { randomInt32 } from "utils/randomInt32";

import { FormData } from "../types";

import { FieldSet } from "./FieldSet";

type Content =
  | {
      content: React.ReactNode;
      iconProps: {
        className: string;
        icon: IconName;
        size: number;
      };
    }
  | undefined;

const FOR_BEST_EXPERIENCE =
  "For the best experience, we recommend using our desktop and mobile apps.";

const iconProps = {
  granted: {
    className: "text-icon-success",
    icon: "CheckCircle" as const,
    size: 20,
  },
  denied: {
    className: "text-brand-tangerine-highlight",
    icon: "AlertTriangle" as const,
    size: 20,
  },
  prompt: {
    className: "text-icon-action",
    icon: "AlertCircle" as const,
    size: 20,
  },
};

const commonGranted = (
  platform: Platform,
  sendTestNotification: () => void,
  isSending: boolean,
  hasSentTestNotification: boolean
) => {
  return {
    content: (
      <div className="flex flex-col gap-12" data-testid={`${platform}-granted`}>
        <div className="flex flex-col gap-2">
          <div className="text-footnote-bold">Notifications are on</div>
          <div>
            You'll get notifications based on the above preferences and your system settings.
          </div>
        </div>
        {platform === "web" && (
          <div className="text-footnote text-text-subtle">{FOR_BEST_EXPERIENCE}</div>
        )}
        <div className="flex flex-row flex-wrap gap-8 items-center content-center self-stretch">
          <Button
            icon="Bell"
            buttonStyle="secondary"
            disabled={isSending}
            onClick={sendTestNotification}
            type="button"
            className="active:text-interactive-primary-hover active:border-interactive-primary-hover"
            iconClassName="!mr-6"
          >
            Send test notification
          </Button>
          {isSending && (
            <div className="flex flex-row items-center gap-4">
              <Icon icon="Loader" size={16} className="text-icon-secondary animate-spin" />
              <span className="text-footnote text-text-subtle">Sending...</span>
            </div>
          )}
          {!isSending && hasSentTestNotification && (
            <div className="flex flex-row items-center gap-4">
              <Icon icon="Check" size={16} className="text-icon-secondary" />
              <span className="text-footnote text-text-subtle">
                Sent. Didn't get it?{" "}
                <a
                  className="text-text-action hover:text-text-action-hover"
                  href={GLUE_DOCS.PUSH_NOTIFICATIONS_TROUBLESHOOTING_URL}
                  rel="noreferrer"
                  target="_blank"
                >
                  See troubleshooting tips.
                </a>
              </span>
            </div>
          )}
        </div>
      </div>
    ),
    iconProps: iconProps.granted,
  };
};

const commonPrompt = (platform: Platform, requestPermissions: () => void) => {
  return {
    content: (
      <div className="flex flex-col gap-2" data-testid={`${platform}-prompt`}>
        <div className="text-footnote-bold">Turn on notifications</div>
        <div>Tap below, then select “Allow” to get notifications from Glue.</div>
        <div className="mt-12">
          <Button type="button" icon="Bell" onClick={requestPermissions}>
            Turn on notifications
          </Button>
        </div>
      </div>
    ),
    iconProps: iconProps.prompt,
  };
};

type GetContentOptions = {
  openModal: ModalStore["openModal"];
  closeModal: ModalStore["closeModal"];
  requestPermissions: () => Promise<void>;
  sendTestNotification: () => void;
  isSending: boolean;
  hasSentTestNotification: boolean;
};

const getContent = ({
  openModal,
  closeModal,
  requestPermissions,
  sendTestNotification,
  isSending,
  hasSentTestNotification,
}: GetContentOptions): Record<Platform, Record<NotificationPermissionStatus, Content>> => ({
  android: {
    granted: commonGranted("android", sendTestNotification, isSending, hasSentTestNotification),
    denied: {
      content: (
        <div className="flex flex-col" data-testid="android-denied">
          <div className="text-footnote-bold">
            To get notifications from Glue, you'll need to turn them on in your iOS Settings:
          </div>
          <ol className="list-decimal list-inside [&>li]:py-2">
            <li>Open App info for Glue.</li>
            <li>Tap Notifications.</li>
            <li>Toggle All Glue notifications on.</li>
          </ol>
          <div className="mt-12">
            <Button
              icon="ExternalLink"
              iconLast
              onClick={() => {
                NativeSettings.openAndroid({
                  option: AndroidSettings.AppNotification,
                });
              }}
            >
              Open App info
            </Button>
          </div>
        </div>
      ),
      iconProps: iconProps.denied,
    },
    prompt: commonPrompt("android", requestPermissions),
  },
  ios: {
    granted: commonGranted("ios", sendTestNotification, isSending, hasSentTestNotification),
    denied: {
      content: (
        <div className="flex flex-col" data-testid="ios-denied">
          <div className="text-footnote-bold">
            To get notifications from Glue, you'll need to turn them on in your iOS Settings:
          </div>
          <ol className="list-decimal list-inside [&>li]:py-2">
            <li>Open Settings &gt; Apps &gt; Glue.</li>
            <li>Tap Notifications.</li>
            <li>Toggle Allow Notifications to On.</li>
          </ol>
          <div className="mt-12">
            <Button
              icon="ExternalLink"
              iconLast
              onClick={() => {
                NativeSettings.openIOS({
                  option: IOSSettings.App,
                });
              }}
            >
              Open iOS Settings
            </Button>
          </div>
        </div>
      ),
      iconProps: iconProps.denied,
    },
    prompt: commonPrompt("ios", requestPermissions),
  },
  mac: {
    granted: commonGranted("mac", sendTestNotification, isSending, hasSentTestNotification),
    denied: {
      content: (
        <div className="flex flex-col" data-testid="mac-denied">
          <div className="text-footnote-bold">
            To get notifications from Glue, you'll need to turn them on in your System Settings:
          </div>
          <ol className="list-decimal list-inside [&>li]:py-2">
            <li>Open System Settings from the Apple menu.</li>
            <li>Select Notifications in the sidebar.</li>
            <li>Find Glue in the list of apps.</li>
            <li>Toggle Allow Notifications to On.</li>
          </ol>
        </div>
      ),
      iconProps: iconProps.denied,
    },
    prompt: commonPrompt("mac", async () => {
      const modalId = "mac-system-notifications";
      try {
        openModal(<MacSystemNotificationsModal />, {
          id: modalId,
        });
        await requestPermissions();
      } finally {
        closeModal(modalId);
      }
    }),
  },
  web: {
    granted: commonGranted("web", sendTestNotification, isSending, hasSentTestNotification),
    denied: {
      content: (
        <div className="flex flex-col gap-4" data-testid="web-denied">
          <div className="text-footnote-bold">
            To get notifications from Glue, you'll need to turn them on in your browser's settings.
          </div>
          <div className="text-footnote text-text-subtle">{FOR_BEST_EXPERIENCE}</div>
        </div>
      ),
      iconProps: iconProps.denied,
    },
    prompt: {
      content: (
        <div className="flex flex-col gap-4" data-testid="web-prompt">
          <div className="text-footnote-bold">Turn on notifications</div>
          Click below, then select “Allow” to get notifications from Glue.
          <div className="mt-8">
            <Button icon="Bell" onClick={requestPermissions}>
              Turn on notifications
            </Button>
          </div>
          <div className="mt-8 text-footnote text-text-subtle">{FOR_BEST_EXPERIENCE}</div>
        </div>
      ),
      iconProps: iconProps.prompt,
    },
  },
});

const DEFAULT_TEST_NOTIFICATION_TIMEOUT_MS = 1200;
const TEST_NOTIFICATION_TITLE = "Glue notifications are live! 🎉";
const TEST_NOTIFICATION_BODY = "";

const PushNotificationsPref = ({ preferencesModalId }: { preferencesModalId?: string }) => {
  const { composeThreadToGlue } = useComposeToGlue("support");
  const { permissionStatus, requestPermissions } = useNotificationPermissions();
  const platform = getPlatform();
  const { notifyMessagesSound } = useWatch<FormData>();
  const { openModal, closeModal } = useModalStore(({ openModal, closeModal }) => ({
    openModal,
    closeModal,
  }));

  const [isSending, setIsSending] = useState(false);
  const [hasSentTestNotification, setHasSentTestNotification] = useState(false);
  const [notification, setNotification] = useState<Notification | number | null>(null);

  useEffect(
    () => () => {
      // Close the notification, so it works when the modal is closed and reopened
      if (notification && typeof notification !== "number") {
        notification.close();
      } else if (typeof notification === "number") {
        LocalNotifications.removeDeliveredNotifications({
          notifications: [{ id: notification, title: "", body: "" }],
        });
      }
    },
    [notification]
  );

  const sendTestNotification = useCallback(async () => {
    setIsSending(true);

    const id = randomInt32();
    const timeout = platform === "android" ? 5000 : DEFAULT_TEST_NOTIFICATION_TIMEOUT_MS;

    if (isNative()) {
      if (typeof notification === "number") {
        await LocalNotifications.removeDeliveredNotifications({
          notifications: [
            { id: notification, title: TEST_NOTIFICATION_TITLE, body: TEST_NOTIFICATION_BODY },
          ],
        });
      }
      await LocalNotifications.schedule({
        notifications: [
          {
            id,
            channelId: NOTIFICATION_CHANNEL_ID,
            title: TEST_NOTIFICATION_TITLE,
            silent: false, // allows the notification to be shown when the app is in the foreground
            sound: "", // iOS only plays a sound if `sound` is defined, does not need to be valid
            body: TEST_NOTIFICATION_BODY,
          },
        ],
      });
      setNotification(id);
    } else {
      const scheduleNotification = () => {
        return useAppStateStore.setState({
          activeNotification: {
            message: {
              id: id.toString(),
              title: TEST_NOTIFICATION_TITLE,
            },
            playSound: notifyMessagesSound !== NotifyMessagesSoundSetting.None,
            onCreate: (n: Notification) => {
              n.addEventListener("close", () => setNotification(null));
              setNotification(n);
            },
          },
        });
      };
      if (notification && typeof notification !== "number") {
        notification.addEventListener("close", scheduleNotification);
        notification.close();
      } else {
        scheduleNotification();
      }
    }

    setHasSentTestNotification(true);
    await new Promise(resolve => setTimeout(resolve, timeout));
    setIsSending(false);
  }, [notifyMessagesSound, platform, notification]);

  const content: Content = useMemo(() => {
    if (!permissionStatus) return undefined;

    return getContent({
      openModal,
      closeModal,
      requestPermissions,
      sendTestNotification,
      isSending,
      hasSentTestNotification,
    })[platform][permissionStatus];
  }, [
    permissionStatus,
    platform,
    requestPermissions,
    openModal,
    closeModal,
    sendTestNotification,
    isSending,
    hasSentTestNotification,
  ]);

  if (!content) return null;

  return (
    <FieldSet
      className="px-20 md:px-32"
      label={`Push notifications (${platform === "web" ? "this browser" : "this device"})`}
    >
      <TwoTierInfoBox
        upper={content}
        lower={{
          content: (
            <>
              Need help?{" "}
              {permissionStatus === "granted" ? (
                <button
                  type="button"
                  className="text-text-action hover:text-text-action-hover"
                  onClick={() => {
                    composeThreadToGlue({ parentModalId: preferencesModalId });
                  }}
                >
                  Contact Support.
                </button>
              ) : (
                <a
                  className="text-text-action hover:text-text-action-hover"
                  href={GLUE_DOCS.PUSH_NOTIFICATIONS_URL}
                  rel="noreferrer"
                  target="_blank"
                >
                  Check out our notification guide.
                </a>
              )}
            </>
          ),
          iconProps: { icon: "Help", size: 20 },
        }}
      />
    </FieldSet>
  );
};

export default PushNotificationsPref;
