import { forwardRef } from "react";

import tw from "utils/tw";

type Props = {
  className?: string;
  roundedClassName?: `rounded-${string}` | `md:rounded-${string}`;
};

const Card = forwardRef<HTMLDivElement, WithChildren<Props>>(
  ({ children, className, roundedClassName = "rounded-lg" }: WithChildren<Props>, ref) => {
    return (
      <div
        ref={ref}
        className={tw(
          "bg-background-body shadow-level1 border-thin border-border-container",
          roundedClassName,
          className
        )}
      >
        {children}
      </div>
    );
  }
);

export default Card;
