import tw from "utils/tw";

export const className = {
  listItem: "flex relative",
  actionBar: "flex grow",
  actionLeft: "flex grow items-center max-w-[90%] px-12 text-icon-action-inverse",
  actionRight:
    "flex grow justify-end max-w-[90%] pr-8 ml-auto bg-background-app-base text-icon-action-inverse",
  actionButton: "w-44 justify-center !px-0",
  actionIcon: "w-24 h-24",
  date: "flex items-center justify-center font-normal text-sm text-text-action-inverse mr-8",
  swipeElement: tw(
    "absolute top-0 left-0 w-full bg-background-body rounded-md",
    "touch-pan-y transform-gpu"
  ),
};
