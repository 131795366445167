import { useRef, useState } from "react";
import { useFormContext } from "react-hook-form";

import { nodeIs } from "@utility-types/graphql";
import { Button } from "components/design-system/Button";
import { Checkbox, Form, TextInput } from "components/design-system/Forms";
import { fileToFileUpload } from "components/MessageEditor/stream-helpers";
import { apiURL } from "components/routing/utils";
import { FREE_TRIAL_DAYS } from "constants/free-trial-days";
import {
  AuthConfigDocument,
  ThreadsMailbox as Mailbox,
  MemberRole,
  UserMilestone,
  WorkspacesAndGroupsListDocument,
  useCompleteMilestoneMutation,
  useCreateWorkspaceMutation,
  useFetchJoinApprovalsQuery,
  useThreadListQuery,
  useUpdateWorkspaceMutation,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useFileUploader from "hooks/useFileUploader";
import useForceUpdate from "hooks/useForceUpdate";
import useOnboardingStore from "store/useOnboardingStore";

import ContentWrapper from "./ContentWrapper";
import Footer from "./Footer";
import ProfileImage from "./ProfileImage";

type CallBack = Parameters<typeof useFileUploader>[0]["onChange"];
type State = Parameters<CallBack>[0];

type FormValues = {
  avatarURL?: string | null;
  name: string;
  joinable: boolean;
};

const FormContent = ({ domain, formSubmitting }: { domain?: string; formSubmitting: boolean }) => {
  const { authReady } = useAuthData();
  const { setState, currentStep, hasWorkspacesToJoin, teamSize, workspace } = useOnboardingStore(
    ({ setState, currentStep, hasWorkspacesToJoin, teamSize, workspace }) => ({
      setState,
      currentStep,
      hasWorkspacesToJoin,
      teamSize,
      workspace,
    })
  );

  const forceUpdate = useForceUpdate();
  const { setValue, watch } = useFormContext<FormValues>();
  const { name } = watch();
  const uploadsRef = useRef<State>(new Map());

  const { data: joinApprovalsData } = useFetchJoinApprovalsQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
  });
  const { data: threadListData } = useThreadListQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    variables: { mailbox: Mailbox.Inbox },
  });

  const pendingInvitationsCount =
    joinApprovalsData?.joinApprovals.edges.filter(j =>
      nodeIs(j.node.joinable, ["WorkspacePreview", "GroupPreview"])
    ).length || 0;

  const threadsCount = threadListData?.threads.totalCount || 0;

  const invitationsAndThreadsCount = pendingInvitationsCount + threadsCount;

  const uploadChange = (state: State) => {
    const file = [...state.values()][0];
    if (!file) return;
    setValue("avatarURL", file.url || file.uploadInfo?.previewUri || "", {
      shouldDirty: true,
    });
  };

  useFileUploader({
    onChange: uploadChange,
    orderedUploads: uploadsRef,
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const temp = new Map();
    temp.set(file.name, fileToFileUpload(file));
    uploadsRef.current = temp;

    forceUpdate();
  };

  return (
    <ContentWrapper
      title="Create a workspace"
      headline={`This is your shared space to work together as a team. Start your fully featured ${FREE_TRIAL_DAYS}-day free trial of Glue.`}
    >
      <div className="flex flex-col gap-24 md:gap-32">
        <ProfileImage onChange={handleFileChange} name={name} type="workspace" />
        <TextInput
          name="name"
          placeholder="Name"
          variant="line"
          config={{ required: true }}
          disabled={formSubmitting}
        />
        {domain && (
          <Checkbox
            className="text-border-disabled"
            wrapperClassName="!my-0"
            name="joinable"
            disabled={formSubmitting}
          >
            <div className="text-caption text-text-subtle flex items-center">
              Let anyone with a&nbsp;
              <strong className="text-caption-bold">{domain}</strong>&nbsp;join this workspace.
            </div>
          </Checkbox>
        )}
        {invitationsAndThreadsCount > 0 && !workspace && (
          <div className="flex justify-center mt-48">
            <Button
              buttonStyle="secondary"
              onClick={() => {
                setState({
                  view: "Review",
                  currentStep: currentStep + 1,
                  totalSteps: 3,
                  goBackTo: "CreateWorkspace",
                });
              }}
            >
              Continue without a workspace
            </Button>
          </div>
        )}
      </div>
      <Footer
        onClickBack={() =>
          setState({
            view:
              hasWorkspacesToJoin && workspace?.type !== "created"
                ? teamSize
                  ? "SelectTeamSize"
                  : "JoinWorkspace"
                : "SelectTeamSize",
            currentStep:
              currentStep -
              (hasWorkspacesToJoin && workspace?.type !== "created" && !teamSize ? 0 : 1),
          })
        }
        submitDisabled={formSubmitting}
        formSubmitting={formSubmitting}
        requireChanges={false}
        hideSkip
      />
    </ContentWrapper>
  );
};

const CreateWorkspace = () => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const { setState, currentStep, workspace, teamSize, totalSteps } = useOnboardingStore(
    ({ setState, currentStep, workspace, teamSize, totalSteps }) => ({
      setState,
      currentStep,
      workspace,
      teamSize,
      totalSteps,
    })
  );
  const { authData } = useAuthData();
  const domain = authData?.me.addressDomains[0];
  const domainName = domain?.split(".")[0];
  const suggestedWorkspaceName = domainName
    ? `${domainName?.charAt(0).toUpperCase()}${domainName?.slice(1)}`
    : undefined;

  const [createWorkspace] = useCreateWorkspaceMutation({
    refetchQueries: [AuthConfigDocument, WorkspacesAndGroupsListDocument],
  });

  const [updateWorkspace] = useUpdateWorkspaceMutation({
    refetchQueries: [AuthConfigDocument, WorkspacesAndGroupsListDocument],
  });

  const [completeMilestone] = useCompleteMilestoneMutation();

  const createNewWorkspace = (input: FormValues) => {
    setFormSubmitting(true);
    return (
      workspace
        ? updateWorkspace({
            variables: {
              id: workspace.id,
              input: {
                ...input,
                domains: input.joinable ? (domain ? [domain] : []) : [],
              },
            },
          })
        : createWorkspace({
            variables: {
              input: {
                ...input,
                domains: input.joinable ? (domain ? [domain] : []) : [],
                members: [],
              },
            },
          })
    )
      .then(({ data }) => {
        if (!data) return;
        const isNewWorkspace = "createWorkspace" in data;
        const newWorkspace = isNewWorkspace ? data.createWorkspace : data.updateWorkspace;

        if (isNewWorkspace) {
          completeMilestone({
            variables: { input: { milestone: UserMilestone.Subscribed, teamSize } },
          });
        }

        setState({
          view: "ChooseApps",
          totalSteps: isNewWorkspace ? 6 : totalSteps,
          currentStep: currentStep + 1,
          workspace: {
            ...newWorkspace,
            admin: newWorkspace.admin?.name ?? "",
            members: newWorkspace.members.totalCount,
            joinable: input.joinable,
            role: MemberRole.Admin,
            type: "created",
          },
          hasWorkspacesToJoin: false,
        });
      })
      .finally(() => setFormSubmitting(false));
  };

  const domainAvatarURL = domain
    ? apiURL(`/proxy/${encodeURIComponent(`https://logo.clearbit.com/${domain}`)}/image`)
    : null;

  return (
    <Form<FormValues>
      className="w-full"
      onSubmit={createNewWorkspace}
      useFormProps={{
        defaultValues: {
          joinable: !!domain,
          ...workspace,
          name: workspace?.name ?? suggestedWorkspaceName,
          avatarURL: workspace?.avatarURL ?? domainAvatarURL,
        },
      }}
    >
      <FormContent domain={domain} formSubmitting={formSubmitting} />
    </Form>
  );
};

export default CreateWorkspace;
