import LessButton from "./LessButton";
import MoreLessButton from "./MoreLessButton";

type Props = {
  addButton?: JSX.Element;
  hasNextPage?: boolean;
  hideButton?: boolean;
  lessButtonClassName?: string;
  loading?: boolean;
  moreButtonClassName?: string;
  moreCollapsed: boolean;
  moreFooter?: JSX.Element;
  moreItems: JSX.Element[];
  moreUnreadCount?: number;
  moreUnreadMentionCount?: number;
  onClickLess: () => void;
  onClickMore: () => void;
};

const ExpandableSection = ({
  addButton,
  hasNextPage = false,
  hideButton = false,
  lessButtonClassName,
  loading = false,
  moreButtonClassName,
  moreCollapsed,
  moreFooter,
  moreItems,
  moreUnreadCount = 0,
  moreUnreadMentionCount = 0,
  onClickLess,
  onClickMore,
}: Props): JSX.Element => (
  <>
    {/* Show divider and other items next */}
    {!moreCollapsed ? (
      <>
        {addButton}

        <LessButton className={lessButtonClassName} hide={hideButton} onClick={onClickLess} />

        {moreItems}

        {moreFooter}
      </>
    ) : null}

    {addButton}

    {/* Finally show the "Show more" / "Show less" control */}
    {(moreItems.length || hasNextPage) && !hideButton ? (
      <MoreLessButton
        className={moreButtonClassName}
        hasMore={moreCollapsed || hasNextPage}
        loading={loading}
        onClick={onClickMore}
        unreadCount={moreUnreadCount}
        unreadMentionCount={moreUnreadMentionCount}
      />
    ) : null}
  </>
);

export default ExpandableSection;
