import { useApolloClient } from "@apollo/client";
import { PropsWithChildren, useEffect } from "react";
import { LoadingErrorIndicatorProps } from "stream-chat-react";

import { readThreadEdge } from "apollo/cache/threadHelpers";
import { Icon } from "components/design-system/icons";
import useCacheEvict from "hooks/state/useCacheEvict";
import useAuthData from "hooks/useAuthData";

import { useThreadViewState } from "../provider/ThreadViewProvider";

export const ChannelLoadingError = ({
  error,
}: PropsWithChildren<LoadingErrorIndicatorProps>): JSX.Element => {
  const { authData } = useAuthData();
  const { evictNode } = useCacheEvict();
  const cache = useApolloClient().cache;

  const { threadID } = useThreadViewState(({ threadID }) => ({
    threadID,
  }));

  const isAccessError = error?.message.includes("not allowed to access");
  const isNetworkError = error?.message === "Network Error";

  useEffect(() => {
    if (authData?.me.id && isAccessError) {
      const threadEdge = readThreadEdge(`${threadID}-${authData.me.id}`, cache);
      if (threadEdge) evictNode(threadEdge);
    }
  }, [cache, authData?.me.id, evictNode, isAccessError, threadID]);

  return (
    <div className="flex justify-center items-center p-40 w-full h-full">
      <div className="mb-100">
        {isNetworkError ? (
          <>
            <h2 className="flex text-accent-error">
              <Icon className="mr-10" icon="ZapOff" /> Problem loading thread.
            </h2>
            <p className="mb-20">
              <span className="font-semibold">Glue cannot connect.</span>
              &nbsp;There may be a problem with your internet connection.
            </p>{" "}
          </>
        ) : (
          <>
            <h2 className="flex text-accent-error">
              <Icon className="mr-10" icon="AlertTriangle" /> Problem loading thread.
            </h2>
            <p className="mb-20">
              We're sorry, something went wrong. Please contact{" "}
              <a href={"mailto:support@gluegroups.com?subject=Error loading thread"}>
                support@gluegroups.com
              </a>{" "}
              and provide the error message below.
            </p>
            <div className="text-sm text-text-subtle">
              Error while loading thread ID {threadID}. {error?.message?.replace("Stream", "")}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ChannelLoadingError;
