import { useApolloClient } from "@apollo/client";
import { useCallback } from "react";

import { nodeIs } from "@utility-types";
import {
  FetchGroupOrPreviewEdgeSimpleDocument,
  FetchGroupOrPreviewEdgeSimpleQuery,
} from "generated/graphql-operations";
import useAuthData from "hooks/useAuthData";
import { isGroupEdgeMessageable } from "utils/group/isGroupEdgeMessageable";

export const useIsGroupMessageable = () => {
  const apolloClient = useApolloClient();
  const { authData } = useAuthData();

  const queryGroupEdge = useCallback(
    (id: string) =>
      apolloClient.query<FetchGroupOrPreviewEdgeSimpleQuery>({
        fetchPolicy: "cache-first",
        query: FetchGroupOrPreviewEdgeSimpleDocument,
        variables: { id },
      }),
    [apolloClient]
  );

  return useCallback(
    async (groupId: string) => {
      if (!authData?.workspaces?.edges || !authData?.me.id) return false;

      const { data } = await queryGroupEdge(`${groupId}-${authData.me.id}`);

      if (!data?.node || !nodeIs(data.node, ["GroupEdge", "GroupPreviewEdge", "WorkspaceEdge"])) {
        return false;
      }

      return isGroupEdgeMessageable(data?.node, authData.workspaces.edges);
    },
    [queryGroupEdge, authData?.workspaces?.edges, authData?.me.id]
  );
};
