import { MentionAtomChangeHandler } from "@remirror/extension-mention-atom";
import { ChangeReason } from "@remirror/pm/suggest";
import {
  MentionAtomNodeAttributes,
  MentionAtomState,
  UseMentionAtomProps,
  useEditorEvent,
  useRemirrorContext,
} from "@remirror/react";
import { useExtension } from "@remirror/react-core";
import { UseMenuNavigationReturn, useMenuNavigation } from "@remirror/react-hooks";
import { useCallback, useMemo, useRef, useState } from "react";

import GlueMentionAtomExtension from "../remirror-extensions/GlueMentionAtomExtension";
import { GlueWysiwygPreset } from "../remirror-extensions/GlueWysiwyg";

import { useOnClickInsideEditor, useOnClickOutsideEditor } from ".";

interface UseMentionAtomReturn<Data extends MentionAtomNodeAttributes = MentionAtomNodeAttributes>
  extends UseMenuNavigationReturn<Data> {
  state: MentionAtomState<Data> | null;
}

/**
 * A hook that provides the state for social mention atoms that responds to
 * keybindings and key-presses from the user.
 *
 * The difference between this and the `useMention` is that `useMention` creates
 * editable mentions that can be changed over an over again. This creates atom
 * mention which are inserted into the editor as non editable nodes. Backspacing
 * into this node will delete the whole mention.
 *
 * In order to properly support keybindings you will need to provide a list of
 * data that is to be shown to the user. This allows for the user to press the
 * arrow up and arrow down key.
 *
 * You can also add other supported attributes which will be added to the
 * mention node, like `href` and whatever you decide upon.
 *
 * @param props - the props that can be passed through to the mention atom.
 */
export default function useMentionAtom<
  Data extends MentionAtomNodeAttributes = MentionAtomNodeAttributes,
>(
  props: UseMentionAtomProps<Data> & { onMention?: (item: Data) => void }
): UseMentionAtomReturn<Data> {
  const {
    direction,
    dismissKeys,
    focusOnClick,
    ignoreMatchesOnDismiss = true,
    items,
    onMention,
    submitKeys,
    replacementType,
  } = props;

  const [state, setState] = useState<MentionAtomState<Data> | null>(null);
  const { helpers } = useRemirrorContext<GlueWysiwygPreset>();
  const isOpen = !!state;

  const userInteractionRef = useRef(false);

  useEditorEvent("keydown", () => {
    userInteractionRef.current = true;
  });

  useOnClickInsideEditor(() => (userInteractionRef.current = true));

  const onDismiss = useCallback(
    (ignore = true) => {
      if (!state) {
        return false;
      }

      const { name, range } = state;

      if (ignore && ignoreMatchesOnDismiss) {
        // Ignore the current mention so that it doesn't show again for this
        // matching area
        helpers.getSuggestMethods().addIgnored({ from: range.from, name, specific: true });
      }

      // Remove the matches.
      setState(null);

      return true;
    },
    [helpers, ignoreMatchesOnDismiss, state]
  );

  const onDismissRef = useRef(onDismiss);
  onDismissRef.current = onDismiss;

  const onSubmit = useCallback(
    (item: Data) => {
      if (item.disabled) return false;

      if (!state) {
        // When there is no state, defer to the next keybinding.
        return false;
      }

      state.command({ replacementType, ...item });
      onMention?.(item);

      return true;
    },
    [state, onMention, replacementType]
  );

  const menu = useMenuNavigation({
    direction,
    dismissKeys,
    focusOnClick,
    isOpen,
    items,
    onDismiss,
    onSubmit,
    submitKeys,
  });

  /**
   * The is the callback for when a suggestion is changed.
   */
  const onChange: MentionAtomChangeHandler = useCallback((props, command) => {
    const { changeReason, exitReason, match, name, query, range, text } = props;

    if (changeReason === ChangeReason.Move) return;

    if (
      // Either exit reason or change reason must have a value
      !changeReason ||
      exitReason ||
      match.input.includes("]") ||
      // we only want to show suggestion results when the user interacted with the editor
      !userInteractionRef.current
    ) {
      setState(null);

      return;
    }

    setState({
      ...{
        command: attrs => {
          command(attrs);
          setState(null);
        },
        name,
        query,
        range,
        reason: changeReason,
        text,
      },
    });
  }, []);

  // Add the handlers to the `GlueMentionAtomExtension`
  useExtension(GlueMentionAtomExtension, ({ addHandler }) => addHandler("onChange", onChange), [
    onChange,
  ]);

  useOnClickOutsideEditor(() => onDismissRef.current(false));

  return useMemo(() => ({ ...menu, state }), [menu, state]);
}
