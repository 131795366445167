import { KeenSliderOptions, TrackDetails, useKeenSlider } from "keen-slider/react";
import { type CSSProperties, useCallback, useEffect, useRef, useState } from "react";

import useOnce from "hooks/useOnce";
import tw from "utils/tw";

const DEFAULT_WHEEL_SIZE = 16;

const wheelSlidesPerView = (loop: KeenSliderOptions["loop"]) => (loop ? 9 : 1);

/**
 * Returns the KeenSliderOptions necessary to initialize a the KeenSlider to pass to the Wheel.
 */
export const useWheelOptions = ({
  options,
  loop,
  initialIndex = 0,
  onChange,
}: {
  options: unknown[];
  loop: boolean;
  initialIndex?: number;
  onChange: (index: number) => void;
}) =>
  useRef<KeenSliderOptions>({
    slides: {
      number: options.length,
      origin: loop ? "center" : "auto",
      perView: wheelSlidesPerView(loop),
    },
    vertical: true,
    initial: initialIndex,
    loop,
    animationEnded: s => {
      onChange(s.track.details.rel);
    },
    mode: "free-snap",
  });

export type WheelProps = {
  sliderContainerRef: ReturnType<typeof useKeenSlider<HTMLDivElement>>[0];
  slider: ReturnType<typeof useKeenSlider<HTMLDivElement>>[1];
  /** The index of the initial value set on the wheel. */
  initialIndex?: number;
  length: number;
  perspective?: "left" | "right" | "center";
  /**
   * Formats how the value is displayed on the wheel.
   *
   * @param relative The relative index of the wheel item.
   * @param absolute The absolute index of the wheel item.
   */
  formatValue?: (relative: number, absolute: number) => string | undefined;
  width: number;
};

/**
 * A selection wheel component that closely mimics the design of the native iOS selection wheel.
 *
 * The implementation is based on the keen-slider examples found [here](https://keen-slider.io/examples#datetimepicker).
 */
export const Wheel = ({
  slider,
  sliderContainerRef,
  length,
  formatValue,
  width,
  perspective = "center",
}: WheelProps) => {
  const [sliderState, setSliderState] = useState<TrackDetails | null>(
    slider.current?.track.details ?? null
  );
  const loop = slider.current?.options.loop;
  const slidesPerView = wheelSlidesPerView(loop);

  useOnce(() => {
    if (slider.current) {
      slider.current.on("detailsChanged", s => setSliderState(s.track.details));
    }
  });

  const [radius, setRadius] = useState(0);
  useEffect(() => {
    if (slider.current) {
      setRadius(slider.current.size / 2);
    }
  }, [slider]);

  useEffect(() => {
    const slideDegree = 360 / DEFAULT_WHEEL_SIZE;
    slider.current?.update({
      ...slider.current.options,
      dragSpeed: val => {
        const height = slider.current?.size ?? 0;
        return (
          val * (height / ((height / 2) * Math.tan(slideDegree * (Math.PI / 180))) / slidesPerView)
        );
      },
    });
  }, [slidesPerView, slider]);

  const slideValues = useCallback(() => {
    if (!sliderState) {
      return [];
    }

    const values = [];
    for (let i = 0; i < length; i++) {
      const slide = sliderState.slides.at(i);
      const offset = loop ? 1 / 2 - 1 / slidesPerView / 2 : 0;
      const distance = slide ? (slide.distance - offset) * slidesPerView : 0;

      const rotate =
        Math.abs(distance) > DEFAULT_WHEEL_SIZE / 2
          ? 180
          : distance * (360 / DEFAULT_WHEEL_SIZE) * -1;

      const rel = sliderState.rel;
      const selected = i === rel;
      let opacity = 1;
      if (!selected) {
        const fadeFactor = 5;
        const diff = loop
          ? Math.min(length - Math.abs(rel - i), Math.abs(rel - i))
          : Math.abs(rel - i);
        opacity = Math.max(0.8 - diff / fadeFactor, 0.2);
      }

      const style: CSSProperties = {
        opacity,
        transform: `rotateX(${rotate}deg) translateZ(${radius}px)`,
        WebkitTransform: `rotateX(${rotate}deg) translateZ(${radius}px)`,
      };

      const value = formatValue
        ? (formatValue(i, sliderState.abs + Math.round(distance)) ?? "Unknown")
        : i;
      values.push({ style, value });
    }
    return values;
  }, [sliderState, length, loop, slidesPerView, radius, formatValue]);

  const perspectiveStyles: CSSProperties =
    perspective === "right"
      ? {
          perspectiveOrigin: "calc(50% + 100px) 50%",
          transform: "translateX(10px)",
          WebkitTransform: "translateX(10px)",
        }
      : {
          perspectiveOrigin: "calc(50% - 100px) 50%",
          transform: "translateX(-10px)",
          WebkitTransform: "translateX(-10px)",
        };

  const shadowStyles: CSSProperties = {
    left: 0,
    height: "calc(42% + 2px)",
    width: "100%",
    position: "relative",
    transform: `translateZ(${radius}px)`,
    WebkitTransform: `translateZ(${radius}px)`,
    pointerEvents: "none",
    zIndex: 5,
  };

  return (
    <div
      className="wheel keen-slider block h-full w-full overflow-visible select-none"
      style={{
        color: "rgb(170, 170, 170, 1)",
      }}
      ref={sliderContainerRef}
    >
      <div
        className="wheel__shadow-top border-b-1 border-background-subtle border-solid"
        style={{
          ...shadowStyles,
          marginTop: "2px",
        }}
      />
      <div
        className="wheel__inner flex items-center justify-center h-1/6 w-full"
        style={{
          ...perspectiveStyles,
          perspective: "1000px",
          transformStyle: "preserve-3d",
        }}
      >
        <div className="wheel__slides h-full relative" style={{ width: `${width}px` }}>
          {slideValues().map(({ style, value }, i) => (
            <div
              className={tw(
                "text-text-primary wheel__slide items-center backface-visibility-hidden flex text-xl font-normal h-full w-full absolute justify-center",
                "transition-opacity duration-200 ease-in-out",
                perspective === "right" ? "text-left" : "text-right"
              )}
              style={style}
              key={`${i}-${value}`}
              onClick={() => slider.current?.moveToIdx(i)}
            >
              <span className="w-full">{value}</span>
            </div>
          ))}
        </div>
      </div>
      <div
        className="wheel__shadow-bottom border-t-1 border-background-subtle border-solid"
        style={{
          ...shadowStyles,
          marginTop: "-2px",
        }}
      />
    </div>
  );
};
