import { Recipient } from "@utility-types";
import Avatar from "components/design-system/Avatar/Avatar";
import Icon from "components/design-system/icons/Icon";
import IdentityBadge from "components/design-system/ui/IdentityBadge/IdentityBadge";
import { parseItemData } from "components/ProfileItem/parseItemData";
import { useFetchGroupEdgeSimple } from "hooks/group/useFetchGroupEdge";
import useAuthData from "hooks/useAuthData";
import useRecipientDomains from "hooks/workspace/useRecipientDomains";
import useAppStateStore from "store/useAppStateStore";
import { formatGroupName } from "utils/group/formatGroupName";
import { isGroupEdgeMessageable } from "utils/group/isGroupEdgeMessageable";
import { isInternalGroup } from "utils/group/isInternalGroup";
import tw from "utils/tw";

import { ResultEdgeType } from "../types";

import type { Props } from "./RecipientsResults";

export const ResultItem = <T extends "groups" | "users">({
  children,
  disabled = false,
  index,
  onClickResult,
  edge,
  selectedResultID,
  selectedResultRef,
  setSelectedResultID,
}: WithChildren<
  {
    disabled?: boolean;
    index: number;
    edge: ResultEdgeType<T>;
  } & Pick<
    Props<T>,
    "onClickResult" | "selectedResultID" | "selectedResultRef" | "setSelectedResultID"
  >
>) => {
  const isSelected = selectedResultID === edge.node.id;

  return (
    <li
      ref={isSelected ? selectedResultRef : undefined}
      className={tw("cursor-pointer select-none", {
        [disabled ? "bg-background-subtle/60" : "bg-accent-highlight/25"]: isSelected,
        "pointer-events-none": disabled,
      })}
      onClick={e => onClickResult?.(edge.node, index, e)}
      onFocus={() => setSelectedResultID?.(edge.node.id)}
      onMouseMove={() => !isSelected && setSelectedResultID?.(edge.node.id)}
    >
      {children}
    </li>
  );
};

export const RecipientProfileLine = ({
  disabled = false,
  hideIdentityBadge,
  recipient,
}: {
  disabled?: boolean;
  hideIdentityBadge?: boolean;
  recipient?: Recipient;
}) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  const data = parseItemData(recipient);
  const { emoji, name: formattedName } = formatGroupName({
    id: data.id,
    name: data.name,
  });

  const { workspace } = useRecipientDomains(recipient);

  const description = recipient?.bio || workspace?.name;
  const showSkeleton = !data.id;

  return (
    <div
      className={tw(
        "flex items-center justify-start",
        "h-56 md:h-36 px-16",
        disabled && "grayscale"
      )}
    >
      <Avatar
        avatarURL={data.avatarURL}
        emojiProps={{ emoji }}
        margin="mr-8"
        name={data.name}
        background={data.avatarURL || emoji ? "transparent" : "subtle"}
        size={breakpointMD ? "x-small" : "small"}
        className={disabled ? "opacity-50 grayscale" : undefined}
      />

      <div className="flex flex-wrap md:flex-nowrap md:gap-6 items-baseline justify-start grow min-w-0 overflow-hidden">
        <div
          className={tw(
            "text-body-bold truncate w-full md:w-auto",
            disabled ? "text-text-subtle" : "text-text-primary"
          )}
        >
          {formattedName}
        </div>

        <div className="flex flex-nowrap overflow-hidden gap-6">
          {description && (
            <div className="text-footnote text-text-subtle truncate">{description}</div>
          )}

          {!showSkeleton && !hideIdentityBadge && (
            <div className="flex items-center">
              <IdentityBadge recipient={recipient} />
            </div>
          )}

          {disabled && (
            <div className="flex flex-nowrap items-center gap-4 ml-2 -mb-1">
              <Icon icon="ChatRoundedOff" className="text-icon-subtle" size={12} />
              <span className="text-text-subtle text-caption">Only admins can post</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const GroupResultItem = ({
  disableResults = false,
  edge,
  index,
  ...rest
}: { edge: ResultEdgeType<"groups">; disableResults?: boolean; index: number } & Pick<
  Props<"groups">,
  "onClickResult" | "selectedResultID" | "selectedResultRef" | "setSelectedResultID"
>) => {
  const { authData } = useAuthData();
  const { edge: fetchedGroupEdge, loading } = useFetchGroupEdgeSimple(edge.node.id);

  const groupEdge = fetchedGroupEdge ?? edge;

  const isInternal = isInternalGroup(groupEdge.node, authData?.me.workspaceIDs ?? []);
  const isMessageable = isGroupEdgeMessageable(groupEdge, authData?.workspaces?.edges);
  const isDisabled = disableResults && !isMessageable;

  return (
    <ResultItem<"groups">
      key={`search-results-${groupEdge.node.id}`}
      disabled={isDisabled || loading}
      edge={groupEdge}
      index={index}
      {...rest}
    >
      <RecipientProfileLine
        disabled={isDisabled}
        hideIdentityBadge={isInternal}
        recipient={groupEdge.node}
      />
    </ResultItem>
  );
};
