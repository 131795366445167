import { useActive, useChainedCommands, useCommands } from "@remirror/react";
import { ComponentProps, useCallback, useRef } from "react";
import { environment } from "remirror";

import { SlideInOut } from "components/Animated";
import { Button } from "components/design-system/Button";
import { HorizontalScrollingList } from "components/HorizontalScrollingList";
import { QuoteReply } from "components/Icons";
import { useDisableControls } from "components/MessageEditor/hooks";
import { Styles } from "components/MessageElements";
import useLocalSettingsStore from "store/useLocalSettingsStore";
import tw from "utils/tw";

import { GlueWysiwygPreset } from "../../../remirror-extensions/GlueWysiwyg";
import LinkButton from "../../LinkSettings/LinkButton";
import { BlockButton } from "../BlockButton";
import { MarkButton } from "../MarkButton";

type FormattingBarOptions = "bold" | "italic" | "blockquote" | "codeBlock" | "link" | "bulletList";

type Props = {
  alwaysActive?: boolean;
  show: boolean;
  showAllButtons: boolean;
  options?: FormattingBarOptions[];
  disableMargins?: boolean;
} & Pick<ComponentProps<typeof Button>, "tooltipPlacement" | "tooltipStyle">;

const FormattingBar = ({
  alwaysActive,
  show,
  showAllButtons,
  options = ["bold", "italic", "blockquote", "codeBlock", "link", "bulletList"],
  disableMargins = false,
  tooltipPlacement,
  tooltipStyle,
}: Props): JSX.Element => {
  const { toggleBlockquote, toggleBold, toggleBulletList, toggleItalic, updateCodeBlock } =
    useCommands<GlueWysiwygPreset>();
  const chain = useChainedCommands<GlueWysiwygPreset>();
  const active = useActive<GlueWysiwygPreset>(true);

  const getLocalSettingsState = useLocalSettingsStore.getState;

  const commandsRef = useRef({
    toggleBlockquote,
    toggleBold,
    toggleBulletList,
    toggleCodeBlock: (active?: boolean) => {
      const language = getLocalSettingsState().codeblockLanguageSetting;
      // Patch can't toggle from codeBlock to paragraph
      // class attribute persists on paragraph node so we remove them before the toggle
      if (active) {
        updateCodeBlock({
          class: Styles.paragraph,
          language,
        });
      }

      chain
        .toggleCodeBlock({
          language,
        })
        .formatCodeBlock()
        .run();
    },
    toggleItalic,
  });

  const commands = useCallback(
    (
      method:
        | "toggleBlockquote"
        | "toggleBold"
        | "toggleBulletList"
        | "toggleCodeBlock"
        | "toggleItalic",
      active?: boolean
    ) => {
      if (method === "toggleCodeBlock") {
        commandsRef.current[method](active);
        return;
      }
      commandsRef.current[method]();
    },
    []
  );

  const isDisabled = useDisableControls();
  const disabled = alwaysActive ? false : isDisabled;

  const buttons = () => {
    const altChar = environment.isMac ? "⌘" : "Ctrl";
    return options.map(option => {
      switch (option) {
        case "bold":
          return (
            <MarkButton
              key="strong"
              active={active.bold()}
              /* istanbul ignore next */
              commands={commands}
              disabled={disabled}
              icon="Bold"
              toggle="toggleBold"
              tooltip={`Bold - ${altChar} B`}
              tooltipPlacement={tooltipPlacement}
              tooltipStyle={tooltipStyle}
            />
          );
        case "italic":
          return (
            <MarkButton
              key="italic"
              active={active.italic()}
              commands={commands}
              /* istanbul ignore next */
              disabled={disabled}
              icon="Italic"
              toggle="toggleItalic"
              tooltip={`Italic - ${altChar} I`}
              tooltipPlacement={tooltipPlacement}
              tooltipStyle={tooltipStyle}
            />
          );
        case "blockquote":
          return (
            <BlockButton
              key="blockquote"
              active={active.blockquote()}
              commands={commands}
              /* istanbul ignore next */
              disabled={disabled}
              icon={QuoteReply}
              toggle="toggleBlockquote"
              tooltip="Blockquote"
              tooltipPlacement={tooltipPlacement}
              tooltipStyle={tooltipStyle}
            />
          );
        case "codeBlock":
          return (
            <BlockButton
              key="codeBlock"
              active={active.codeBlock()}
              commands={commands}
              /* istanbul ignore next */
              disabled={disabled}
              icon="Code"
              toggle={"toggleCodeBlock"}
              tooltip="Code"
              tooltipPlacement={tooltipPlacement}
              tooltipStyle={tooltipStyle}
            />
          );
        case "link":
          return (
            <LinkButton
              key="link"
              /* istanbul ignore next */
              disabled={disabled}
              icon="Link"
              tooltip="Link"
              tooltipPlacement={tooltipPlacement}
              tooltipStyle={tooltipStyle}
            />
          );
        case "bulletList":
          return (
            <BlockButton
              key="bulletList"
              active={active.bulletList()}
              commands={commands}
              /* istanbul ignore next */
              disabled={disabled}
              icon="BulletedList"
              toggle="toggleBulletList"
              tooltip="Bulleted list"
              tooltipPlacement={tooltipPlacement}
              tooltipStyle={tooltipStyle}
            />
          );
      }
    });
  };

  return (
    <SlideInOut
      className={tw(
        "overflow-hidden",
        { "mx-3": show && !disableMargins },
        { "!ml-3": showAllButtons && !disableMargins }
      )}
      enter={{ opacity: "1", x: "0" }}
      from={{ opacity: "0", x: showAllButtons ? "0" : "-100%" }}
      leave={{ opacity: "0", x: "-100%" }}
      show={showAllButtons || show}
    >
      <HorizontalScrollingList itemWidth={28} scrollSnapType="none" showTail={false}>
        {buttons()}
      </HorizontalScrollingList>
    </SlideInOut>
  );
};

export default FormattingBar;
