import { NodeViewComponentProps } from "@remirror/react";
import { ComponentType } from "react";
import { MentionAtomExtension } from "remirror/extensions";

import { Styles } from "components/MessageElements";
import { RecipientTooltip } from "components/RecipientTooltip";
import env from "utils/processEnv";
import tw from "utils/tw";

class GlueMentionAtomExtension extends MentionAtomExtension {
  ReactComponent: ComponentType<NodeViewComponentProps> = ({ node, selected }) => {
    const { id, label } = node.attrs;
    const isGlueAIMention = id === env.glueAIBotID;
    return (
      <RecipientTooltip
        className={tw("mention", Styles.mention, {
          [Styles.mentionGlueAI]: isGlueAIMention,
          "!bg-border-link": selected && !isGlueAIMention,
          "!bg-border-magic": selected && isGlueAIMention,
        })}
        id={id}
      >
        {label}
      </RecipientTooltip>
    );
  };
}

export default GlueMentionAtomExtension;
