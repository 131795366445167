import { uniqBy } from "lodash-es";
import { useEffect, useMemo } from "react";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";

import { Recipient, Workspace, WorkspaceEdge } from "@utility-types";
import EditableAvatar from "components/Avatar/EditableAvatar";
import { Form, Label, SubmitButton, TextInput, Toggle } from "components/design-system/Forms";
import Hr from "components/design-system/Hr";
import { apiURL } from "components/routing/utils";
import { useLlmProviders } from "components/SettingsModal/views/WorkspaceSettings/hooks/useLlmProviders";
import { ProviderApiKeyInput } from "components/SettingsModal/views/WorkspaceSettings/ProviderApiKeyInput";
import GroupChatPermissions from "components/workspace-group/GroupChatPermissions";
import { MemberRole as MemberRoleValue, MessageableBy } from "generated/graphql";
import {
  FetchWorkspaceOrPreviewEdgeDocument,
  WorkspacesAndGroupsListDocument,
  useUpdateWorkspaceMutation,
} from "generated/graphql-operations";
import useAuthData from "hooks/useAuthData";

const FormFields = ({
  domainAvatarURL,
  workspace,
}: { domainAvatarURL: string | null; workspace?: Workspace }) => {
  const { authData } = useAuthData();
  const authDomain = authData?.me.addressDomains[0];
  const availableAIProviders = useLlmProviders(workspace?.id);

  const { setValue, watch } = useFormContext<FormData>();
  const { avatarURL, joinable, name } = watch();

  useEffect(() => {
    let nextAvatarURL = avatarURL === domainAvatarURL ? null : avatarURL;
    nextAvatarURL ??= joinable ? domainAvatarURL : null;
    setValue("avatarURL", nextAvatarURL);
  }, [avatarURL, domainAvatarURL, joinable, setValue]);

  return (
    <div className="py-24">
      <div className="px-20 md:px-32">
        <div className="text-body-bold">Workspace settings</div>
        <div className="text-text-subtle">
          Workspaces are shared environments for teams, consisting of users and groups.
        </div>

        <div className="flex justify-start items-center gap-16 mt-20">
          <EditableAvatar
            avatarURL={avatarURL}
            name={name}
            onAvatarUpdate={avatarUrl => setValue("avatarURL", avatarUrl, { shouldDirty: true })}
            rounded="rounded-lg"
            size="x-large"
          />
          <div className="grow">
            <Label className="text-body-bold" htmlFor="name">
              Name
            </Label>
            <TextInput<FormData>
              autoComplete="off"
              config={{ required: true }}
              data-testid="workspace-name"
              name="name"
              placeholder="Company name"
              wrapperClassName="!my-0"
            />
          </div>
        </div>
      </div>

      {authDomain && (
        <div className="my-32 px-20 md:px-32">
          <span className="text-body-bold">Domain Access</span>
          <Toggle
            label={
              <span>
                Allow anyone with <strong>{authDomain}</strong> email address to join?
              </span>
            }
            labelClassName="justify-between w-full"
            name="joinable"
            wrapperClassName="!my-0"
          />
        </div>
      )}

      <Hr />

      <div className="mt-32 px-20 md:px-32">
        <GroupChatPermissions isWorkspace />
      </div>

      {workspace?.id && (
        <div className="mt-32">
          <Hr />
          <div className="flex flex-col gap-12 mt-20 px-20 md:px-32">
            <div>
              <h2 className="m-0 text-base font-bold">Glue AI</h2>
              <div className="text-body text-text-subtle">Add API keys to lift usage limits:</div>
            </div>

            {availableAIProviders?.map(({ id, provider, name, iconSrc, keyPreview }) => (
              <ProviderApiKeyInput
                key={provider}
                id={id}
                keyPreview={keyPreview}
                provider={provider}
                iconSrc={iconSrc}
                name={name}
                workspaceID={workspace?.id}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

type MemberRole = { member: string; role: MemberRoleValue };

type WorkspaceInput = {
  avatarURL: string | null;
  domains: string[];
  members: MemberRole[];
  name: string;
  chatMessageableBy: MessageableBy;
  messageableBy: MessageableBy;
};

type FormData = {
  avatarURL?: string | null;
  joinable: boolean;
  members: Recipient[];
  name: string;
  chatMessageableBy: MessageableBy;
};

type Props = {
  loading?: boolean;
  workspaceEdge?: WorkspaceEdge;
};

const memberRoles = (recipients: Recipient[]): MemberRole[] =>
  uniqBy(
    recipients.map(r => ({
      member: r.id,
      role: MemberRoleValue.Default,
    })),
    "member"
  );

const SettingsForm = ({ loading, workspaceEdge }: Props) => {
  const { authData } = useAuthData();
  const authDomain = authData?.me.addressDomains[0];

  const workspace = workspaceEdge?.node;

  const defaultDomains = useMemo(
    () => workspace?.domains || (authDomain ? [authDomain] : []),
    [authDomain, workspace?.domains]
  );

  const domainAvatarURL = useMemo(() => {
    const domain = defaultDomains[0];
    if (domain) {
      return apiURL(`/proxy/${encodeURIComponent(`https://logo.clearbit.com/${domain}`)}/image`);
    }

    return null;
  }, [defaultDomains]);

  const isJoinable = useMemo(
    () => !!(authDomain && defaultDomains.includes(authDomain)),
    [authDomain, defaultDomains]
  );

  const [updateWorkspace] = useUpdateWorkspaceMutation({
    errorPolicy: "all",
  });

  const onSave = useCallback(
    (workspaceID: string, input: WorkspaceInput) => {
      if (!workspaceEdge) return Promise.resolve();

      return updateWorkspace({
        refetchQueries: [WorkspacesAndGroupsListDocument, FetchWorkspaceOrPreviewEdgeDocument],
        variables: { id: workspaceID, input },
      });
    },
    [updateWorkspace, workspaceEdge]
  );

  const onSubmit = useCallback(
    (data: FormData) =>
      onSave(workspace?.id || "", {
        ...data,
        avatarURL: data.avatarURL || null,
        domains: data.joinable ? (authDomain ? [authDomain] : []) : [],
        members: memberRoles(data.members),
        messageableBy:
          workspace?.messageableBy === MessageableBy.Anyone
            ? MessageableBy.Anyone
            : data.chatMessageableBy === MessageableBy.Recipient
              ? MessageableBy.Workspace
              : data.chatMessageableBy,
      }),
    [authDomain, onSave, workspace?.id, workspace?.messageableBy]
  );

  return (
    <Form
      className="contents"
      loading={loading}
      onSubmit={onSubmit}
      useFormProps={{
        defaultValues: {
          avatarURL: workspace?.avatarURL,
          joinable: isJoinable,
          members: workspace?.members.edges.map(e => e.node) || (authData ? [authData.me] : []),
          chatMessageableBy: workspace?.chatMessageableBy ?? MessageableBy.Recipient,
          name: workspace?.name || "",
        },
      }}
    >
      <div className="grow min-h-0 overflow-y-auto">
        <FormFields domainAvatarURL={domainAvatarURL} workspace={workspace} />
      </div>
      <div className="border-t border-border-container flex items-center justify-end h-[calc(64px_+_env(safe-area-inset-bottom))] shrink-0 px-20 pb-[env(safe-area-inset-bottom)]">
        <SubmitButton className="justify-center w-full md:w-auto">Save</SubmitButton>
      </div>
    </Form>
  );
};

export default SettingsForm;
