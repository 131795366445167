import { useModalState } from "components/ModalKit/ModalProvider";
import { AccountSettingsView } from "components/SettingsModal/types";
import { WorkspaceModalJoin } from "components/workspace/WorkspaceModal";
import useAuthData from "hooks/useAuthData";
import useModalStore from "store/useModalStore";
import { isNative } from "utils/platform";
import env from "utils/processEnv";
import tw from "utils/tw";

import FeatureFlags from "../Developer/FeatureFlags";
import RouteMemory from "../Developer/RouteMemory";
import Sandbox from "../Developer/Sandbox";
import { SidebarItem } from "../Sidebar";
import View from "../View";
import ViewContent from "../ViewContent";

import { EditProfile } from "./EditProfile";
import { Preferences } from "./Preferences";
import ProfileRow from "./ProfileRow";

const AccountSettings = ({
  modalId,
  setView,
  view,
}: {
  modalId: string;
  setView: React.Dispatch<React.SetStateAction<AccountSettingsView | undefined>>;
  view?: AccountSettingsView;
}) => {
  const { authData } = useAuthData();
  const { openModal } = useModalStore(({ openModal }) => ({ openModal }));
  const {
    dragging: { bind },
  } = useModalState(({ dragging }) => ({ dragging }));

  return (
    <View
      main={
        view && (
          <>
            {view === "profile" && (
              <ViewContent<AccountSettingsView> setView={setView} title="Profile">
                <EditProfile parentModalId={modalId} />
              </ViewContent>
            )}
            {view === "preferences" && (
              <ViewContent<AccountSettingsView> setView={setView} title="Preferences">
                <Preferences parentModalId={modalId} />
              </ViewContent>
            )}
            {view === "feature-flags" && (
              <ViewContent<AccountSettingsView> setView={setView} title="Feature Flags">
                <FeatureFlags />
              </ViewContent>
            )}
            {view === "route-memory" && (
              <ViewContent<AccountSettingsView> setView={setView} title="Route Memory">
                <RouteMemory />
              </ViewContent>
            )}
            {view === "sandbox" && (
              <ViewContent<AccountSettingsView> setView={setView} title="Sandbox">
                <Sandbox />
              </ViewContent>
            )}
          </>
        )
      }
      menu={
        <div
          className={tw("flex flex-col relative h-full w-full", {
            "pointer-events-none md:pointer-events-auto": view,
          })}
        >
          <div
            className={tw(
              "border-b-1 flex items-center h-56 mb-16 md:mb-0 px-16",
              "border-border-container justify-center text-headline-bold text-text-primary",
              "md:border-transparent md:justify-start md:text-footnote-bold md:text-text-secondary",
              { "cursor-grab select-none touch-none": !!bind }
            )}
            {...bind()}
          >
            <span className="hidden md:block">Account</span>
            <span className="block md:hidden">Account Settings</span>
          </div>

          <div className="grow min-h-0 overflow-x-hidden relative">
            <div className="bg-background-body md:bg-background-app px-12 md:px-8 select-none">
              <ProfileRow margin="mb-12" />

              <SidebarItem
                icon="User"
                isActive={view === "profile"}
                onClick={() => setView("profile")}
              >
                Profile
              </SidebarItem>
              <SidebarItem
                icon="Tool"
                isActive={view === "preferences"}
                onClick={() => setView("preferences")}
              >
                Preferences
              </SidebarItem>

              {authData?.workspaces.edges.length && (
                <div className="flex items-center h-44 md:h-32 mt-12 px-8 text-footnote-bold text-text-secondary">
                  Workspaces
                </div>
              )}
              {authData?.workspaces.edges.map((w, i) => {
                return (
                  <SidebarItem
                    key={w.node.id}
                    avatarURL={w.node.avatarURL ?? undefined}
                    isActive={view === w.node.id}
                    showPrimaryPill={i === 0}
                    name={w.node.name}
                    onClick={() => setView(w.node.id)}
                  >
                    {w.node.name}
                  </SidebarItem>
                );
              })}
              <SidebarItem
                icon="Plus"
                onClick={() => {
                  openModal(<WorkspaceModalJoin />, {
                    closeModals: modalId ? [modalId] : undefined,
                  });
                }}
              >
                {isNative() ? "Join a workspace" : "Add a workspace"}
              </SidebarItem>

              {env.glueEnv !== "production" && (
                <>
                  <div className="flex items-center h-44 md:h-32 mt-12 px-8 text-footnote-bold text-text-secondary">
                    Developer
                  </div>

                  <SidebarItem
                    icon="Flag"
                    isActive={view === "feature-flags"}
                    onClick={() => setView("feature-flags")}
                  >
                    Feature Flags
                  </SidebarItem>
                  <SidebarItem
                    icon="Globe"
                    isActive={view === "route-memory"}
                    onClick={() => setView("route-memory")}
                  >
                    Route Memory
                  </SidebarItem>
                  <SidebarItem
                    icon="Code"
                    isActive={view === "sandbox"}
                    onClick={() => setView("sandbox")}
                  >
                    Sandbox
                  </SidebarItem>
                </>
              )}
            </div>
          </div>
        </div>
      }
    />
  );
};

export default AccountSettings;
