import emojiRegex from "emoji-regex";

import { RenderMarkdown } from "md";
import { RenderMarkdownOptions } from "md/RenderMarkdown";
import { parseMarkdown, removeSubjectMarkdown } from "md/util";

export const isOnlyEmojis = (text?: string): boolean => {
  if (!text) return false;

  const noEmojis = text.replace(emojiRegex(), "");
  const noSpace = noEmojis.replace(/[\s\n]/gm, "");

  return !noSpace;
};

export const renderText = (text?: string) => {
  return renderTextWithOptions(text);
};

export const renderTextWithOptions = (
  text?: string,
  options?: RenderMarkdownOptions
): JSX.Element | null => {
  if (text) {
    const markdown = parseMarkdown(removeSubjectMarkdown(text), "<br>");
    return RenderMarkdown(markdown, options);
  }
  return null;
};
