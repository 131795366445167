const className = {
  blockquote: "pl-15 my-6 ml-0 border-l-4 border-border-container" as const,
  button:
    "!inline-block align-baseline hover:underline !p-0 border-none text-text-primary font-semibold" as const,
  code: "py-2 px-4 font-mono text-sm font-normal whitespace-pre-wrap break-words bg-background-secondary rounded-md" as const,
  codeBlock:
    "pre-code py-28 px-10 my-6 font-mono text-sm font-normal whitespace-pre-wrap break-words bg-background-secondary rounded-lg border border-border-container" as const,
  Header: "font-semibold",
  HeaderOne: "mb-0 mt-16 first:mt-0 text-xl" as const,
  HeaderFour: "mb-0 mt-16 first:mt-0 text-base" as const,
  HeaderThree: "mb-0 mt-16 first:mt-0 text-base" as const,
  HeaderTwo: "mb-0 mt-16 first:mt-0 text-lg" as const,
  horizontalRule: "my-12 h-1 bg-border-container border-none" as const,
  hyperLink: "hover:underline text-text-link" as const,
  listItem: "ml-20" as const,
  mention: String.raw`px-2 py-1 rounded-md text-text-link !no-underline outline-0 border-1 border-transparent bg-background-link hover:border-border-link leading-5`,
  mentionGlueAI:
    "before:!text-text-magic-inverse !bg-background-magic-subtle !text-text-magic-inverse hover:!border-border-magic" as const,
  mentionUser: "before:content-['@'] before:text-icon-link",
  mentionOwn:
    "!bg-background-link-strong !text-text-link-strong hover:!border-border-link-strong before:text-icon-link-strong" as const,
  orderedList: "list-decimal [&:not(li>&)]:mt-8" as const,
  paragraph: "mb-0 mt-8" as const,
  strong: "font-semibold" as const,
  table:
    "mt-8 border border-border-container border-separate border-spacing-0 rounded-lg overflow-hidden" as const,
  tableBody: "" as const,
  tableCell: "px-12 py-6 border-t border-r last:border-r-none border-border-container" as const,
  tableHead: "font-semibold" as const,
  tableHeader:
    "px-12 py-6 border-r last:border-r-none border-border-container bg-background-secondary" as const,
  tableRow: "" as const,
  unorderedList: "list-nested [&:not(li>&)]:mt-8 " as const,
};

export default className;
