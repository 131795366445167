import { useCallback, useEffect, useRef } from "react";

import analytics from "analytics";
import {
  ApplicationBackgrounded,
  ApplicationInstalled,
  ApplicationOpened,
  ApplicationUpdated,
} from "analytics/events/appLifecycle";
import { versionInfo } from "components/App/version";
import useAppStateStore from "store/useAppStateStore";

const LOCAL_STORAGE = "APP_STATE_TRACKER_";
const LOCAL_STORAGE_PREVIOUS_BUILD = `${LOCAL_STORAGE}PREVIOUS_BUILD`;
const LOCAL_STORAGE_PREVIOUS_VERSION = `${LOCAL_STORAGE}PREVIOUS_VERSION`;

/**
 * This implements the standard application lifecycle events documented by
 * rudderstack here:
 *
 * https://www.rudderstack.com/docs/rudderstack-api/api-specification/application-lifecycle-events-spec/
 *
 * This also sends an extra build field which is documented in the Segment
 * variant of this same spec.
 */
export const useAppStateManager = (): void => {
  const sendFirstTracks = useCallback((build: string, version: string) => {
    const previousVersion = localStorage.getItem(LOCAL_STORAGE_PREVIOUS_VERSION);
    const previousBuild = localStorage.getItem(LOCAL_STORAGE_PREVIOUS_BUILD);
    const buildAndVersion = {
      build,
      version: version,
    };
    const firstLaunch = !previousBuild || !previousVersion;
    const versionChanged = previousBuild !== build || previousVersion !== version;

    if (firstLaunch || versionChanged) {
      localStorage.setItem(LOCAL_STORAGE_PREVIOUS_BUILD, build);
      localStorage.setItem(LOCAL_STORAGE_PREVIOUS_VERSION, version);
    }

    if (firstLaunch) {
      analytics.track(ApplicationInstalled, buildAndVersion);
    } else if (versionChanged) {
      analytics.track(ApplicationUpdated, {
        ...buildAndVersion,
        previousBuild,
        previousVersion,
      });

      // According to the native mobile spec, we should not send opened
      // when we send updated
      return;
    }

    analytics.track(ApplicationOpened, {
      ...buildAndVersion,
      fromBackground: false,
    });
  }, []);

  const isFirstTrack = useRef(true);

  const onAppStatusChange = useCallback(
    (appStatus: ReturnType<(typeof useAppStateStore)["getState"]>["appStatus"]) => {
      const isFirst = isFirstTrack.current;
      if (appStatus === "unknown" || (appStatus === "inactive" && isFirst)) {
        // If the app starts up in the background, don't start tracking anything
        // until we first become active.
        return;
      }

      const { buildSha: build, version } = versionInfo;

      if (isFirst) {
        isFirstTrack.current = false;
        sendFirstTracks(build, version);
        return;
      }

      if (appStatus === "active") {
        analytics.track(ApplicationOpened, {
          build,
          fromBackground: true,
          version,
        });
      } else {
        analytics.track(ApplicationBackgrounded);
      }
    },
    [sendFirstTracks]
  );

  useEffect(
    () =>
      useAppStateStore.subscribe(
        ({ appStatus }) => appStatus,
        appStatus => {
          onAppStatusChange(appStatus);
        }
      ),
    [onAppStatusChange]
  );
};

export default useAppStateManager;
