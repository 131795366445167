import React from "react";
import { ChannelNotifications, MessageNotificationProps } from "stream-chat-react";

import { ConnectionStatus } from "./ConnectionStatus";
import { CustomNotification } from "./CustomNotification";

export type MessageListNotificationsProps = {
  MessageNotification: React.ComponentType<MessageNotificationProps>;
  notifications: ChannelNotifications;
};

export const MessageListNotifications = (props: MessageListNotificationsProps): JSX.Element => {
  const { notifications } = props;

  return (
    <div className="str-chat-list-notifications">
      {notifications.map(notification => (
        <CustomNotification key={notification.id} active={true} type={notification.type}>
          {notification.text}
        </CustomNotification>
      ))}
      <ConnectionStatus />
    </div>
  );
};
