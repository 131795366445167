import { captureException } from "@sentry/react";
import { Component, ErrorInfo, ReactElement, cloneElement } from "react";

interface Props {
  children: JSX.Element | null;
  debug?: boolean;
  message?: ReactElement;
  onError?: (e: Error) => void;
}

interface State {
  lastError: Error | undefined;
  sentryEventID?: string;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    lastError: this.props.debug ? new Error("Debugging") : undefined,
    sentryEventID: undefined,
  };

  public static getDerivedStateFromError(e: Error): State {
    // Update state so the next render will show the fallback UI.
    const sentryEventID = captureException(e);
    return { lastError: e, sentryEventID };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render(): JSX.Element | null {
    if (this.state.lastError) {
      this.props.onError?.(this.state.lastError);

      return this.props.message ? cloneElement(this.props.message, { ...this.state }) : null;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
