import Avatar from "components/design-system/Avatar/Avatar";
import tw from "utils/tw";

type GroupChipProps = {
  emoji: string;
  name: string;
  onClick?: () => void;
  className?: string;
};

const GroupChip = ({ emoji, name, onClick, className }: GroupChipProps) => {
  return (
    <div
      className={tw("flex items-center h-32 pr-4 bg-background-subtle rounded-lg", className)}
      onClick={onClick}
    >
      <Avatar emojiProps={{ emoji, size: 20 }} background="transparent" />
      <span className="text-subhead ml-2 mr-4">{name}</span>
    </div>
  );
};

export default GroupChip;
