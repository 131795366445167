import Icon from "components/design-system/icons/Icon";
import tw from "utils/tw";

const ViewHeader = ({
  children,
  onClose,
  imgSrc,
  name,
}: WithChildren<{
  onClose?: () => void;
  imgSrc?: string;
  name: string;
}>) => {
  return (
    <div className="bg-background-body border-border-strong border-b-1 shrink-0">
      <div
        className={tw(
          "max-w-[832px] mx-auto mt-10 px-16 w-full",
          "flex items-center justify-start",
          {
            "mb-10": !children,
          }
        )}
      >
        {onClose && (
          <button
            className="group p-8 -ml-8 mr-8"
            onClick={e => {
              e.stopPropagation();
              onClose();
            }}
            title="Return to My feed"
          >
            <Icon
              className="text-icon-secondary group-hover:text-icon-secondary-hover"
              icon="Close"
              size={24}
              strokeWidth={2}
            />
          </button>
        )}
        {imgSrc && (
          <div className="flex items-center grow-0 h-24 mr-8">
            <img alt="My feed icon" className="rounded-sm w-24 h-24 shrink-0" src={imgSrc} />
          </div>
        )}
        <div className="font-semibold leading-7 text-xl">{name}</div>
      </div>

      {children}
    </div>
  );
};

export default ViewHeader;
