import { useHistory } from "react-router";

import { Button } from "components/design-system/Button";
import { defaultRoutesStack, superTabsDefaults } from "components/routing/utils";
import useRoutesStore from "store/useRoutesStore";

const RouteMemory = () => {
  const history = useHistory();

  return (
    <div className="grow min-h-0 overflow-y-auto py-24">
      <div className="flex flex-col gap-6 px-20 md:px-32">
        <div className="flex gap-12 items-center">
          <Button
            onClick={() => {
              useRoutesStore.setState({
                restoreRoute: "/inbox",
                routes: superTabsDefaults,
                routesStack: defaultRoutesStack,
              });
              history.push("/inbox");
            }}
            type="button"
          >
            Clear routes
          </Button>
        </div>

        <div className="text-footnote">
          {Object.entries(useRoutesStore.getState().routes).map(entry => {
            const [key, value] = entry;
            return (
              <p key={key}>
                <strong>{`${key}:`}</strong>
                <br />
                {`${value}`}
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RouteMemory;
