import { DraftEdge } from "@utility-types";
import {
  DraftConnection,
  DraftFieldsFragment,
  DraftFieldsFragmentDoc,
  useSaveDraftMutation,
} from "generated/graphql";

const saveDraftsOption = (index?: number): Parameters<typeof useSaveDraftMutation>[0] => ({
  update: (cache, { data }) => {
    const draft: DraftFieldsFragment | undefined = data?.saveDraft;
    if (!draft) return;
    cache.modify({
      fields: {
        drafts(existingDrafts: DraftConnection, { readField }) {
          cache.writeFragment({
            data: draft,
            fragment: DraftFieldsFragmentDoc,
            fragmentName: "DraftFields",
            id: cache.identify(draft),
          });

          const existingEdges = readField<DraftEdge[]>("edges", existingDrafts) ?? [];

          const isDraftInCache = existingEdges?.some(
            edge => readField("id", edge.node) === draft.id
          );

          const draftEdge = {
            __typename: "DraftEdge" as const,
            cursor: draft.id,
            node: draft,
          };

          // If draft is not in cache, we add it at the end of the list
          if (!isDraftInCache) {
            let existingEdgesCopy = [...existingEdges];
            if (index !== undefined) {
              existingEdgesCopy.splice(index, 0, draftEdge);
            } else {
              existingEdgesCopy = [...existingEdgesCopy, draftEdge];
            }
            return {
              ...existingDrafts,
              __typename: "DraftConnection",
              edges: existingEdgesCopy,
            };
          }

          return existingDrafts;
        },
      },
    });
  },
});

export default saveDraftsOption;
