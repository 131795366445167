import { ElementRef, useCallback, useEffect, useRef, useState } from "react";
import { useChatContext } from "stream-chat-react";
import { useDebouncedCallback } from "use-debounce";

import { GlueDefaultStreamChatGenerics, Recipient } from "@utility-types";
import GroupProfileModal, { GroupProfileTab } from "components/group/GroupModal/GroupProfileModal";
import { MessageEditor } from "components/MessageEditor";
import { CreateThreadButton } from "components/MessageEditor/components/controls/CreateThreadButton";
import MessageEditorContainer from "components/MessageEditor/MessageEditorContainer";
import { SettingsModal } from "components/SettingsModal";
import { useValidateMessage } from "components/thread/hooks";
import ThreadInfoModal from "components/thread/ThreadView/components/ThreadInfoModal";
import useScrollToTimelineEnd from "components/thread/ThreadView/hooks/useScrollToTimelineEnd";
import useSendMessage from "components/thread/ThreadView/hooks/useSendMessage";
import { useThreadViewState } from "components/thread/ThreadView/provider/ThreadViewProvider";
import { DraftListDocument, MessageableBy, useDeleteDraftMutation } from "generated/graphql";
import useCacheEvict from "hooks/state/useCacheEvict";
import useGoToThreadRecent from "hooks/useGoToThreadRecent";
import useGroupRecipients from "hooks/useGroupRecipients";
import useThreadRecipients from "hooks/useThreadRecipients";
import { useLayerState } from "providers/LayerProvider";
import useMessageEditorStore from "store/useMessageEditorStore";
import useModalStore from "store/useModalStore";
import useNativeKeyboardStore from "store/useNativeKeyboardStore";
import generateRandomId from "utils/generateRandomId";

import useAppUnfurlSetupMessage from "./hooks/useAppUnfurlSetupMessage";
import NotMessageableBanner from "./NotMessageableBanner";
import useRestoreReply from "./ThreadCompose/hooks/useRestoreReply";
import ThreadReplyHeader from "./ThreadReplyHeader";

type Props = {
  onMention?: (recipient: Recipient) => void;
  onSubmit?: () => void;
};

const ThreadReply = ({ onMention, onSubmit }: Props): JSX.Element => {
  const { evictNode } = useCacheEvict();
  const { client } = useChatContext<GlueDefaultStreamChatGenerics>();
  const editor = useRef<ElementRef<typeof MessageEditor> | null>(null);
  const isEditorNull = !editor.current;
  const goToThreadRecent = useGoToThreadRecent();
  const [deleteDraftMutation] = useDeleteDraftMutation();
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const [showSuggestion, setShowSuggestion] = useState(false);
  const [suggestionDismissed, setSuggestionDismissed] = useState(false);

  const {
    threadID,
    channel,
    addDraft,
    removeDraft,
    draft,
    draftID,
    draftIDRef,
    dispatch,
    compose,
    sendDraft,
  } = useRestoreReply({
    editor,
    onLoadEnd: () => setShowSuggestion(true),
  });

  const {
    editorIsActive,
    threadPane,
    threadWorkspaceID,
    messageableBy,
    threadStarterID,
    isPersistentChat,
    recipientID,
  } = useThreadViewState(
    ({
      editorIsActive,
      threadPane,
      threadWorkspaceID,
      messageableBy,
      threadStarterID,
      isPersistentChat,
      recipientID,
    }) => ({
      editorIsActive,
      threadPane,
      threadWorkspaceID,
      messageableBy,
      threadStarterID,
      isPersistentChat,
      recipientID,
    })
  );

  // NOTE: Do not change this pattern threadPane + threadID + random id
  const randomEditorID = generateRandomId(`${threadPane}${threadID}`);
  const [hideEditor, setHideEditor] = useState(false);
  const { validateMessage } = useValidateMessage();
  const sendMessage = useSendMessage();
  const { sendSetupMessages } = useAppUnfurlSetupMessage();

  const userHasRecentlySentMessage = () => {
    const currentTime = Date.now();
    if (!channel?.state.messages) return false;
    for (let i = channel.state.messages.length - 1; i > 0; i--) {
      const currentMessage = channel.state.messages[i];
      if (!currentMessage) return;
      //Breaks the loop once messages have more than 5 minutes since they were sent
      //so we don't iterate over all the messages, just the most recent ones
      if ((currentTime - new Date(currentMessage.created_at).getTime()) / 1000 > 300) return false;
      //Breaks the loop if the user is found in the current message
      if (currentMessage?.user?.id === client?.user?.id) return true;
    }
    return false;
  };

  const groupRecipients = useGroupRecipients();
  const groupRecipientsRef = useRef(groupRecipients);
  groupRecipientsRef.current = groupRecipients;

  const threadRecipients = useThreadRecipients({
    threadID: channel?.id,
  }).recipients;
  const threadRecipientsRef = useRef(threadRecipients);
  threadRecipientsRef.current = threadRecipients;

  const onSubjectChange = useCallback(
    (subject: string) =>
      dispatch({
        type: "change",
        draftForm: {
          subject,
          recipients: groupRecipientsRef.current.length
            ? groupRecipientsRef.current
            : threadRecipientsRef.current,
        },
      }),
    [dispatch]
  );

  const updateDraft = useDebouncedCallback(
    useCallback(() => {
      if (!channel?.id || !editor.current) return;

      const message = editor.current.getMessage();
      const hasNoText = !message.text.length;
      const hasNoAttachments = !message.attachments.length;

      dispatch({ type: "change", draftForm: { message } });

      if (hasNoAttachments && hasNoText) {
        removeDraft({ threadID: channel.id });
        return;
      }
      addDraft({
        threadID: channel.id,
        message,
        draftID: !suggestionDismissed ? draftIDRef.current : undefined,
      });
    }, [addDraft, channel?.id, dispatch, draftIDRef, removeDraft, suggestionDismissed]),
    500
  );

  const onInputChange = () => {
    if (userHasRecentlySentMessage()) channel?.keystroke();

    updateDraft();
  };

  const onFormSubmit = () => {
    if (!editor.current || !channel) return;

    const message = editor.current.getStreamMessage();
    if (!validateMessage(message)) return;

    goToThreadRecent();

    if (showSuggestion && draftID && compose.draftForm.subject?.length) {
      sendDraft();
    } else {
      const appUnfurlSetups = editor.current.getAppUnfurlSetups();
      (async () => {
        const sentMessage = await sendMessage(message);
        if (!sentMessage) return;
        sendSetupMessages(sentMessage.id, threadID, appUnfurlSetups);
      })();
    }

    setSuggestionDismissed(false);

    onSubmit?.();

    channel.id && removeDraft({ threadID: channel.id });
    !showSuggestion &&
      draftID &&
      draft &&
      deleteDraftMutation({
        refetchQueries: [DraftListDocument],
        update: c => evictNode(draft, c),
        variables: { id: draftID },
      });

    editor.current.reset();
    editor.current.focusEditor();
  };

  useScrollToTimelineEnd(editor);

  useEffect(
    () => () => {
      if (!channel || channel.disconnected || !channel.isTyping) return;

      channel?.stopTyping().catch(err => {
        // ignore deleted channel
        if (err.status !== 404) {
          console.warn("Error: stopTyping -", err);
        }
      });
    },
    [channel]
  );

  const { layerIsActive } = useLayerState(({ layerIsActive }) => ({
    layerIsActive,
  }));

  useEffect(
    () =>
      useMessageEditorStore.subscribe(({ editors }) => {
        layerIsActive &&
          setHideEditor(
            [...editors.values()].filter(({ editor: { mode } }) => mode === "edit").length > 0
          );
      }),
    [layerIsActive]
  );

  useEffect(() => {
    editor.current?.setReadOnly(!editorIsActive);
  }, [editorIsActive, isEditorNull]);

  const [safeAreaPadding, setSafeAreaPadding] = useState(false);
  useEffect(
    () =>
      useNativeKeyboardStore.subscribe(
        ({ keyboardHeight }) => keyboardHeight,
        keyboardHeight => {
          setSafeAreaPadding(keyboardHeight > 100);
        }
      ),
    []
  );

  const onDismissSuggestion = () => {
    editor.current?.focusEditor();
    setSuggestionDismissed(true);
    dispatch({ type: "change", draftForm: { subject: undefined } });
    if (!draftID) return;
    channel?.id && addDraft({ threadID: channel.id, draftID: undefined });
    draft &&
      deleteDraftMutation({
        refetchQueries: [DraftListDocument],
        update: c => evictNode(draft, c),
        variables: { id: draftID },
      });
  };

  const openSettingsModal = useCallback(() => {
    if (!isPersistentChat) {
      openModal(<ThreadInfoModal threadID={threadID} />);
      return;
    }

    if (!recipientID) return;

    const isWorkspace = recipientID?.startsWith("wks_");
    if (isWorkspace) {
      openModal(<SettingsModal subView="settings" view={recipientID} />);
      return;
    }

    openModal(<GroupProfileModal groupID={recipientID} defaultTab={GroupProfileTab.Settings} />);
  }, [isPersistentChat, openModal, recipientID, threadID]);

  return (
    <MessageEditorContainer
      hideEditor={hideEditor}
      safeAreaPadding={safeAreaPadding}
      variant="thread-reply"
      outerElement={
        messageableBy !== undefined && messageableBy !== MessageableBy.Recipient ? (
          <NotMessageableBanner
            isPersistentChat={!!isPersistentChat}
            isThreadStarter={threadStarterID === client?.user?.id}
            onClickCTA={openSettingsModal}
            variant="editor"
          />
        ) : null
      }
    >
      {showSuggestion && isPersistentChat && (
        <ThreadReplyHeader
          compose={compose}
          showSuggestion={showSuggestion}
          setShowSuggestion={setShowSuggestion}
          setSuggestionDismissed={onDismissSuggestion}
          onSubjectChange={onSubjectChange}
        />
      )}
      <MessageEditor
        ref={editor}
        testId="thread-reply-message-editor"
        bottomBarSections={
          !showSuggestion && isPersistentChat
            ? [
                <CreateThreadButton
                  showSubjectSuggestion={() => {
                    editor.current?.focusEditor();
                    setShowSuggestion(true);
                    setSuggestionDismissed(false);
                  }}
                  readOnly={!!editor.current?.readOnly()}
                />,
              ]
            : []
        }
        editorId={randomEditorID}
        onChange={onInputChange}
        onMention={onMention}
        placeholder="Send a message..."
        submitForm={onFormSubmit}
        sendButtonMode={
          showSuggestion && !!draftID && compose.draftForm.subject?.length ? "post" : "send"
        }
        showThreadActions
        workspaceID={threadWorkspaceID}
      />
    </MessageEditorContainer>
  );
};

export default ThreadReply;
