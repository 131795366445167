import { Clipboard } from "@capacitor/clipboard";
import { type BaseSyntheticEvent, type MutableRefObject, useMemo, useRef } from "react";
import {
  type MessageContextValue,
  showMessageActionsBox,
  useReactionClick,
  useUserHandler,
} from "stream-chat-react";
import type { GroupStyle } from "stream-chat-react/dist/components/MessageList/utils";

import type { GlueDefaultStreamChatGenerics } from "@utility-types";
import analytics from "analytics";
import { FeedbackProvided } from "analytics/events/feedback";
import { FeedbackWidget } from "components/GlueAIFeedback/FeedbackWidget";
import useGlueAIFeedback from "components/GlueAIFeedback/hooks/useGlueAIFeedback";
import { FeedbackType } from "components/GlueAIFeedback/types";
import type { BlockInteractionHandlers } from "components/Masonry/Blocks/BlockComponent";
import { renderText as defaultRenderText } from "components/Message/utils";
import { ConfirmationAlert } from "components/Modals";
import { routeURL } from "components/routing/utils";
import { useThreadViewState } from "components/thread/ThreadView/provider/ThreadViewProvider";
import useNativeHaptics from "hooks/native/useNativeHaptics";
import { useSnackbar } from "providers/SnackbarProvider";
import useAppStateStore from "store/useAppStateStore";
import useMessageEditorStore from "store/useMessageEditorStore";
import useModalStore from "store/useModalStore";
import newSyntheticEvent from "utils/newSyntheticEvent";
import env from "utils/processEnv";
import { streamMessageToGlueMessage } from "utils/stream/message";

import { MessageActions as DefaultMessageActions, MessageActionMenu } from "../../MessageActions";
import { MessageMetadataInfoModal } from "../../MessageActions/MessageMetadataInfo";
import { MessageInput as DefaultMessageInput } from "../../MessageInput";
import useReplyInThreadModal from "../hooks/useReplyInThreadModal";

import { MessageComposite } from "./MessageComposite";
import { ReactionsList } from "./ReactionsList";

import { ReadReceipt } from ".";

export function MessageWithEditor<T extends GlueDefaultStreamChatGenerics>({
  actionWrapperRef,
  clearEditingState,
  editing,
  getMessageActions,
  groupStyles,
  handleDelete,
  handleEdit,
  handleFlag,
  handleMute,
  handlePin,
  handleReaction,
  handleRetry,
  initialMessage,
  isMyMessage: isMyMessageFunction,
  lastReceivedId,
  message,
  MessageActions = DefaultMessageActions,
  MessageInput = DefaultMessageInput,
  messageWrapperRef,
  onUserClick: propOnUserClick,
  onUserHover: propOnUserHover,
  reactionSelectorRef,
  readBy,
  renderText = defaultRenderText,
  setEditingState,
}: MessageContextValue<T> & {
  MessageActions?: typeof DefaultMessageActions;
  MessageInput?: typeof DefaultMessageInput;
  actionWrapperRef: MutableRefObject<HTMLDivElement | null>;
  messageWrapperRef: React.MutableRefObject<HTMLDivElement | null>;
  reactionSelectorRef: React.MutableRefObject<HTMLDivElement | null>;
  blockInteractionHandlers: BlockInteractionHandlers;
}) {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));
  const { lightImpactHaptic } = useNativeHaptics();
  const { openSnackbar } = useSnackbar();

  const handleDeleteMessage = (e: BaseSyntheticEvent) => {
    openModal(
      <ConfirmationAlert
        confirmLabel="Delete Message"
        header="Delete this message?"
        onConfirm={async () => await handleDelete(e)}
        isDestructive
      />
    );
  };

  const { isReactionEnabled } = useReactionClick(message, reactionSelectorRef, messageWrapperRef);

  const handleReactionWithHaptic = (reaction: string, e: BaseSyntheticEvent) => {
    lightImpactHaptic();
    return handleReaction?.(reaction, e);
  };

  const deleted = message.type === "deleted";
  // TODO: add support for Glue-only actions
  const hideActions = message.in_stream === false; // don't hide if undefined

  const contentRef = useRef<HTMLDivElement>(null);

  const { onUserClick } = useUserHandler(message, {
    onUserClickHandler: propOnUserClick,
    onUserHoverHandler: propOnUserHover,
  });

  const { threadID, threadPane, isMessageable, lastMessageID, threadWorkspaceID } =
    useThreadViewState(
      ({ threadID, threadPane, isMessageable, lastMessageID, threadWorkspaceID }) => ({
        threadID,
        threadPane,
        isMessageable,
        lastMessageID,
        threadWorkspaceID,
      })
    );

  const firstGroupStyle: GroupStyle = groupStyles?.[0] || "single";

  const isInThread = !message.cid || message.cid.split(":")?.pop() === threadID; // e.g. message is not from parent thread
  const isDelivered = isInThread && !deleted && message.status === "received";
  const isMyMessage = isMyMessageFunction();

  const isEphemeral = message.type === "ephemeral";

  const showActionsMenu =
    deleted && isMyMessage ? false : showMessageActionsBox(getMessageActions());

  const messageText = useMemo(
    () => renderText(deleted ? "*This message was deleted.*" : message.text),
    [deleted, message.text, renderText]
  );

  const handleQuoteReply = () => {
    const editors = useMessageEditorStore.getState().editors;
    const targetUid = `${threadPane}${threadID}`;
    const isDev = env.glueEnv === "development";

    const matchingEditor = Array.from(editors.values()).find(
      ({ editor, uid }) =>
        (isDev || editor.mode === "compose") && uid.includes(targetUid) && !editor.readOnly()
    );

    if (matchingEditor) {
      matchingEditor.editor.setQuotedMessage(streamMessageToGlueMessage(message));
    }
  };

  const { handleReplyInThread } = useReplyInThreadModal(message);

  const handleCopyMessageLink = () => {
    Clipboard.write({
      url: routeURL({
        messageID: message.id,
        threadID: threadID,
      }),
    }).then(() => openSnackbar("info", "Share link copied to clipboard.", 5000));
  };

  const handleShowInfo = () => {
    return openModal(<MessageMetadataInfoModal message={message} />);
  };

  const { openFeedbackForm } = useGlueAIFeedback();

  const handleSendFeedback = () => {
    message.ai_response_to !== undefined &&
      openFeedbackForm({
        promptMessageId: message.ai_response_to,
        response: message,
        threadID,
        threadWorkspaceID,
      });
  };

  const handlePositiveFeedback = () => {
    if (message.reaction_counts?.["+1"] === undefined) {
      analytics.track(FeedbackProvided, {
        feedbackType: FeedbackType.GENERATED_RESPONSE,
        score: +1,
        threadId: threadID,
      });
    }

    handleReactionWithHaptic?.("+1", newSyntheticEvent("reaction"));
  };

  const messageAction: JSX.Element | null =
    (isInThread && showActionsMenu && (
      <MessageActions
        getMessageActions={getMessageActions}
        handleCopyMessageLink={handleCopyMessageLink}
        handleDelete={handleDeleteMessage}
        handleEdit={handleEdit}
        handleFlag={handleFlag}
        handleMute={handleMute}
        handlePin={handlePin}
        handleQuoteReply={handleQuoteReply}
        handleReplyInThread={handleReplyInThread}
        handleRetry={handleRetry}
        handleShowInfo={handleShowInfo}
        handleSendFeedback={handleSendFeedback}
        message={message}
        setEditingState={setEditingState}
      />
    )) ||
    null;

  const showReactions = message.latest_reactions?.length && isReactionEnabled && isDelivered;

  const isGlueAIResponse =
    message.user?.id === env.glueAIBotID && message.ai_response_to !== undefined;

  const showFeedbackWidget = isGlueAIResponse && message.id === lastMessageID;

  const reactionsList =
    showReactions || showFeedbackWidget ? (
      <div className="flex items-center gap-4">
        {showFeedbackWidget && (
          <FeedbackWidget
            handlePositiveFeedback={handlePositiveFeedback}
            handleSendFeedback={handleSendFeedback}
          />
        )}
        {!!showReactions && <ReactionsList handleReaction={handleReactionWithHaptic} />}
      </div>
    ) : null;

  const showReplyAction = isInThread && !deleted && !isEphemeral && breakpointMD;

  const messageActionMenu = MessageActionMenu({
    actionWrapperRef,
    handleReaction: handleReactionWithHaptic,
    handleQuoteReply,
    handleReplyInThread,
    initialMessage,
    message: message,
    messageAction,
    showMetadataOptions: isGlueAIResponse && !isEphemeral,
    showReaction:
      isReactionEnabled && isInThread && !deleted && !isEphemeral && !showFeedbackWidget,
    showQuoteReply: isMessageable && showReplyAction,
    showReplyInThread: isMessageable && showReplyAction,
  });

  const messageGlueStatus: JSX.Element | null = isDelivered ? (
    <ReadReceipt isMyMessage={isMyMessage} message={message} readBy={readBy} />
  ) : null;

  return editing ? (
    <div
      ref={messageWrapperRef}
      className="str-chat-message str-chat-message--editing px-15"
      data-testid="message-glue-edit"
    >
      <MessageInput
        clearEditingState={clearEditingState}
        message={message}
        messageWrapperRef={messageWrapperRef}
      />
    </div>
  ) : (
    <MessageComposite
      {...{
        contentRef,
        firstGroupStyle,
        handleRetry: () => handleRetry(message),
        initialMessage,
        isMyMessage,
        lastReceivedId,
        message,
        messageActionMenu: hideActions ? null : messageActionMenu,
        messageGlueStatus,
        messageText,
        messageWrapperRef,
        onUserClick,
        reactionsList,
      }}
    />
  );
}
