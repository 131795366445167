import { RefObject, useLayoutEffect, useRef, useState } from "react";
import { useResizeObserver } from "usehooks-ts";

const useElementBreakpoint = (ref: RefObject<HTMLElement>, widthProp: number | "wide") => {
  const breakpointWidth = widthProp === "wide" ? 832 : widthProp;

  // avoid calling offsetWidth in the top level of the render function; it will force layout on every render, hence `() => ref.current?.offsetWidth ...`
  const [breakpoint, setBreakpoint] = useState<boolean>(
    () => (ref.current?.offsetWidth ?? 0) >= breakpointWidth
  );

  const onResize = (width = 0) => {
    const isBreakpoint = width ? width >= breakpointWidth : breakpoint;
    if (isBreakpoint === breakpoint) return;
    setBreakpoint(isBreakpoint);
  };
  const onResizeRef = useRef(onResize);
  onResizeRef.current = onResize;

  useLayoutEffect(() => {
    if (!ref.current) return;
    onResizeRef.current(ref.current.offsetWidth);
  }, [ref]);

  useResizeObserver({
    ref: {
      current: ref.current, // stupid fix for a bug in usehooks-ts, https://github.com/juliencrn/usehooks-ts/issues/632#issuecomment-2695988742
    },
    onResize: ({ width }) => {
      onResizeRef.current(width);
    },
  });

  return breakpoint;
};

export default useElementBreakpoint;
