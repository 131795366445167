import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import { StreamMessage, useMessageListScrollManager } from "stream-chat-react";

import { GlueDefaultStreamChatGenerics } from "@utility-types";
import { useThreadViewState } from "components/thread/ThreadView/provider/ThreadViewProvider";
import isElementScrolledToEnd from "utils/isElementScrolledToEnd";

export type UseScrollLocationLogicParams<T extends GlueDefaultStreamChatGenerics> = {
  currentUserId?: string;
  historyMode: boolean;
  messages?: StreamMessage<T>[];
  scrolledUpThreshold?: number;
};

type UseScrollLocationLogicResponse = {
  hasNewMessages: boolean;
  listRef: React.MutableRefObject<HTMLDivElement | null>;
  onScroll: (params: {
    element: HTMLDivElement;
    offsetHeight: number;
    scrollHeight: number;
    scrollTop: number;
  }) => void;
  scrollToBottom: () => void;
};

const useScrollLocationLogic = <T extends GlueDefaultStreamChatGenerics>(
  params: UseScrollLocationLogicParams<T>
): UseScrollLocationLogicResponse => {
  const threadViewUpdater = useThreadViewState().setState;

  const { historyMode, messages = [], scrolledUpThreshold = 200 } = params;
  const [hasNewMessages, setHasNewMessages] = useState(false);

  const closeToBottom = useRef(false);
  const closeToTop = useRef(false);
  const prevScrollTopRef = useRef(0);

  const listRef = useRef<HTMLDivElement>(null);

  const scrollTo: Element["scrollTo"] = useCallback(
    (...args: unknown[]) => {
      if (!listRef.current) return;

      if (typeof args[0] === "number" && typeof args[1] === "number") {
        return listRef.current.scrollTo(args[0], args[1]);
      }

      if (args[0] && typeof args[0] === "object") {
        return listRef.current.scrollTo(args[0]);
      }
    },
    [listRef]
  );

  const scrollToBottom = useCallback(() => {
    if (!listRef.current) return;
    if (typeof listRef.current?.scrollBy !== "function") return; // for jest

    listRef.current.scrollTo({ top: listRef.current.scrollHeight });
  }, []);

  const scrollToEnd = useCallback(() => {
    if (historyMode) return;

    scrollToBottom();

    setHasNewMessages(false);
  }, [historyMode, scrollToBottom]);

  const updateScrollTop = useMessageListScrollManager({
    messages,
    onScrollBy: scrollBy => {
      if (typeof listRef.current?.scrollBy !== "function") return; // for jest
      // don't need to adjust scroll position for browser's that support overflow-anchor (Chrome)
      if (CSS.supports("overflow-anchor", "auto")) return;

      listRef.current?.scrollBy({ top: scrollBy });
    },
    scrollContainerMeasures: () => ({
      offsetHeight: listRef.current?.offsetHeight || 0,
      scrollHeight: listRef.current?.scrollHeight || 0,
    }),
    scrolledUpThreshold,
    scrollToBottom: scrollToEnd,
    showNewMessages: () => setHasNewMessages(true),
  });

  const onScroll = useCallback(
    ({
      element,
      offsetHeight,
      scrollHeight,
      scrollTop,
    }: {
      element: HTMLDivElement;
      offsetHeight: number;
      scrollHeight: number;
      scrollTop: number;
    }) => {
      updateScrollTop(scrollTop);

      threadViewUpdater({
        prevScrollTop: prevScrollTopRef.current,
        scrolledToEnd: isElementScrolledToEnd(element, 10),
        scrollHeight,
        scrollToBottom,
        scrollTop,
      });

      prevScrollTopRef.current = scrollTop;

      closeToBottom.current = scrollHeight - (scrollTop + offsetHeight) < scrolledUpThreshold;
      closeToTop.current = scrollTop < scrolledUpThreshold;

      if (closeToBottom.current) {
        setHasNewMessages(false);
      }
    },
    [updateScrollTop, threadViewUpdater, scrollToBottom, scrolledUpThreshold]
  );

  useLayoutEffect(() => {
    if (listRef?.current) {
      scrollToEnd();
      threadViewUpdater({ scrollTo });
    }
  }, [listRef, scrollTo, scrollToEnd, threadViewUpdater]);

  return {
    hasNewMessages,
    listRef,
    onScroll,
    scrollToBottom: scrollToEnd,
  };
};

export default useScrollLocationLogic;
