import { LocalNotifications } from "@capacitor/local-notifications";
import { Component, ReactNode } from "react";

import { NOTIFICATION_CHANNEL_ID } from "components/devices/hooks/useNotificationPermissions";
import { randomInt32 } from "utils/randomInt32";

type BaseOptions = Pick<NotificationOptions, "data" | "body"> & {
  title: string;
  tag: string;
};

type ParsedOptions = BaseOptions & {
  body: string;
  title: string;
};

export type GetNotificationInstance = CreateNotification<BaseProps>["getNotificationsInstance"];

type DefaultProps = {
  timeout?: number;
};

type BaseProps = {
  getNotificationInstance?: ((M: GetNotificationInstance) => void)[];
  options: BaseOptions;
} & DefaultProps;

type State = {
  NotificationInstance: { [key: string]: Notification };
};

const defaultProps: DefaultProps = {
  timeout: 5800,
};

abstract class CreateNotification<P extends BaseProps> extends Component<P, State> {
  static defaultProps: DefaultProps = defaultProps;
  /**
   * If the value is...
   *
   * - `true` or a `Notification` instance, the notification has been handled.
   * - `undefined`, the notification has not been created.
   */
  notifications: {
    [key: string]: Notification | true | undefined;
  } = {};

  constructor(props: P) {
    super(props);

    this.state = {
      NotificationInstance: {},
    };

    if (this.props.getNotificationInstance) {
      this.props.getNotificationInstance.forEach(m => m(this.getNotificationsInstance.bind(this)));
    }
  }

  abstract doNotification(): Promise<void> | void;

  private hasMounted = false;
  async componentDidMount() {
    if (this.hasMounted) return;
    this.hasMounted = true;

    const opt = this.getParsedOptions();
    if (this.notifications[opt.tag]) {
      return;
    }
    if (opt.title) {
      this.doNotification();
    }
  }

  render(): ReactNode {
    const opt = this.getParsedOptions();
    return <div data-testid={`local-notification-${opt.tag}`} data-title={`${opt.title}`} />;
  }

  getNotificationsInstance(tag: string): Notification | undefined {
    if (this.notifications[tag] === true) {
      return undefined;
    }
    return this.notifications[tag];
  }

  getParsedOptions(): ParsedOptions {
    return {
      ...this.props.options,
      body: (this.props.options.body ?? "").trim(),
      title: this.props.options.title.trim(),
    };
  }
}

type CreateNativeNotificationProps = BaseProps & {
  playSound: boolean;
};

export class CreateNativeNotification extends CreateNotification<CreateNativeNotificationProps> {
  async doNotification(): Promise<void> {
    const opt = this.getParsedOptions();
    LocalNotifications.checkPermissions().then(res => {
      if (res.display !== "granted") {
        return;
      }
      LocalNotifications.schedule({
        notifications: [
          {
            id: randomInt32(),
            channelId: NOTIFICATION_CHANNEL_ID,
            title: opt.title,
            body: opt.body,
            extra: opt.data,
            silent: true,
            sound: this.props.playSound ? "" : undefined,
            threadIdentifier: opt.data?.target,
          },
        ],
      });
    });
  }

  async componentDidUpdate(prevProps: CreateNativeNotificationProps) {
    // componentDidUpdate is only required for native
    const opt = this.getParsedOptions();
    if (!window.Notification || !!this.notifications[opt.tag]) {
      return;
    }
    if (opt.title && opt.tag && opt.tag !== prevProps.options.tag) {
      this.doNotification();
    }
  }
}

type BrowserNotificationOptions = NotificationOptions & BaseOptions;

type CreateBrowserNotificationProps = {
  options: BrowserNotificationOptions;
  onCreate?: (n: Notification) => void;
  onClick?: (e: Event, tag: string | undefined) => void;
  onClose?: (e: Event, tag: string | undefined) => void;
  onShow?: (e: Event, tag: string | undefined) => void;
  onError?: (e: Event, tag: string | undefined) => void;
} & BaseProps;

export class CreateBrowserNotification extends CreateNotification<CreateBrowserNotificationProps> {
  doNotification(): void {
    const opt = this.getParsedOptions();
    if (!window.Notification || window.Notification.permission !== "granted") {
      return;
    }

    // we already bail early if not supported
    const n = new window.Notification(opt.title, opt);

    this.props.onCreate?.(n);

    n.onshow = e => {
      if (this.props.onShow) {
        this.props.onShow(e, opt.tag);
      }

      if (this.props.timeout && this.props.timeout > 0) {
        setTimeout(() => {
          this.close(n);
        }, this.props.timeout);
      }
    };
    n.onclick = e => {
      if (this.props.onClick) {
        this.props.onClick(e, opt.tag);
      }
    };
    n.onclose = e => {
      if (this.props.onClose) {
        this.props.onClose(e, opt.tag);
      }
    };
    n.onerror = e => {
      if (this.props.onError) {
        this.props.onError(e, opt.tag);
      }
    };
    this.notifications[opt.tag] = n;
  }

  close(n: Notification): void {
    if (n && typeof n.close === "function") {
      n.close();
    }
  }
}
