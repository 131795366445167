import { RecommendedBadge } from "components/design-system/Badge";
import { RadioGroup } from "components/design-system/Forms/RadioGroup";
import { MessageableBy } from "generated/graphql-types";

const GroupChatPermissions = ({ isWorkspace = false }: { isWorkspace?: boolean }) => (
  <div>
    <span className="text-body-bold">{isWorkspace ? "General group" : "Group"} permissions</span>
    <RadioGroup
      name="chatMessageableBy"
      groupClassName="mt-4 mb-0"
      options={[
        {
          label: "Everyone can post and reply",
          description: "By default, everyone can post threads and reply in the group chat",
          value: MessageableBy.Recipient,
          children: <RecommendedBadge />,
        },
        {
          label: "Only admins",
          description: "Only admins can post threads and reply in the group chat",
          value: MessageableBy.Admin,
        },
      ]}
      optionStyle="bordered"
    />
  </div>
);

export default GroupChatPermissions;
