import { useCallback, useState } from "react";

import type { WorkspaceEdgeSimple } from "@utility-types";
import { Button, HeaderButton } from "components/design-system/Button";
import { Dropdown } from "components/design-system/FloatingUi";
import {
  ActionSheetItemGroups,
  DropdownActions,
} from "components/design-system/FloatingUi/DropdownActions";
import CreateGroupModal from "components/group/CreateGroup/CreateGroupModal";
import { SettingsModal } from "components/SettingsModal";
import { WorkspaceSettingsView } from "components/SettingsModal/types";
import WorkspaceProfileItem from "components/workspace/WorkspaceProfileItem";
import { MemberRole } from "generated/graphql";
import useAppStateStore from "store/useAppStateStore";
import useModalStore from "store/useModalStore";
import tw from "utils/tw";

type Props = {
  workspaceEdge: WorkspaceEdgeSimple;
  isAdmin?: boolean;
  isSidebar?: boolean;
};

const WorkspaceActionMenu = ({
  workspaceEdge,
  isAdmin = workspaceEdge.memberRole === MemberRole.Admin,
  isSidebar,
}: Props) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const handleOpenWorkspaceModal = useCallback(
    (subView?: WorkspaceSettingsView) => {
      openModal(<SettingsModal subView={subView} view={workspaceEdge.node.id} />, {
        id: workspaceEdge.node.id,
      });
    },
    [openModal, workspaceEdge.node.id]
  );

  const actions: ActionSheetItemGroups[] = [
    {
      name: "workspaceCommonActions",
      items: [
        {
          icon: "Groups",
          onClick: () => handleOpenWorkspaceModal("groups"),
          text: "Groups",
        },
        {
          icon: "Users",
          onClick: () => handleOpenWorkspaceModal("members"),
          text: "Members",
        },
        {
          hidden: !breakpointMD,
          icon: "LightningBolt",
          onClick: () => handleOpenWorkspaceModal("apps"),
          text: "Apps",
        },
      ],
    },
    {
      name: "workspaceAdminActions",
      items: [
        {
          icon: "Plus",
          onClick: () => {
            const workspace = workspaceEdge.node;
            if (!workspace) return;

            openModal(<CreateGroupModal workspaceID={workspace.id} />);
          },
          text: "Create group",
        },
        {
          hidden: !isAdmin,
          icon: "Settings",
          onClick: () => handleOpenWorkspaceModal("settings"),
          text: "Workspace settings",
        },
      ],
    },
  ];

  const [open, setOpen] = useState(false);

  return (
    <Dropdown
      className="max-w-[200px]" // mt-5
      content={<DropdownActions actions={actions} setOpen={setOpen} />}
      disableFlip={!isSidebar}
      header={
        <div className="pt-10 pr-16 pb-12 pl-10 border-b-thin border-background-subtle cursor-pointer">
          <WorkspaceProfileItem workspace={workspaceEdge.node} />
        </div>
      }
      setOpen={setOpen}
    >
      {isSidebar ? (
        <div
          className={tw(
            "group/workspace-actions flex items-center overflow-hidden rounded-md -ml-4 pl-4 pr-2 h-28 cursor-pointer",
            "text-text-secondary hover:text-text-secondary-hover hover:bg-background-app-subtle",
            { "bg-background-app-subtle": open }
          )}
        >
          <span className="truncate leading-[18px]">{workspaceEdge.node.name}</span>
          <Button
            buttonStyle="none"
            className="border-none inline !p-0 text-icon-secondary group-hover/workspace-actions:text-icon-secondary-hover"
            icon="MenuVertical"
            iconSize={20}
          />
        </div>
      ) : (
        <HeaderButton icon="MenuVertical" />
      )}
    </Dropdown>
  );
};

export default WorkspaceActionMenu;
