import { useMemo } from "react";
import { useHistory } from "react-router";

import { SearchTabs, routeParams as getRouteParams, tabPath } from "components/routing/utils";
import { Tabs } from "components/Tabs";

import { useInstantSearch } from "../hooks";
import { mergeResultsOfType } from "../utils";

import DeepSearchConversations from "./DeepSearchConversations";
import DeepSearchGroups from "./DeepSearchGroups";
import DeepSearchPeople from "./DeepSearchPeople";

type SearchTabsType = keyof typeof SearchTabs;

const DeepSearchResults = () => {
  const history = useHistory();

  const routeParams = getRouteParams(history.location);

  const currentTab = (() => {
    switch (routeParams.t) {
      case SearchTabs.Conversations:
        return SearchTabs.Conversations;
      case SearchTabs.Groups:
        return SearchTabs.Groups;
      case SearchTabs.People:
        return SearchTabs.People;
      default:
        return SearchTabs.Conversations;
    }
  })();

  const { searchResults } = useInstantSearch({
    sharedState: true,
    resultsOrder: ["users", "groups", "threads", "archivedGroups"],
  });
  const { instantResults, moreResults } = searchResults;

  const threadResults = useMemo(
    () => mergeResultsOfType(instantResults, moreResults, "threads"),
    [instantResults, moreResults]
  );

  const groupResults = useMemo(
    () => mergeResultsOfType(instantResults, moreResults, "groups"),
    [instantResults, moreResults]
  );

  const archivedGroupResults = useMemo(
    () => mergeResultsOfType(instantResults, moreResults, "archivedGroups"),
    [instantResults, moreResults]
  );

  const userResults = useMemo(
    () => mergeResultsOfType(instantResults, moreResults, "users"),
    [instantResults, moreResults]
  );

  const tabs = [
    {
      tab: SearchTabs.Conversations,
      tabTitle: (
        <>
          <span>{SearchTabs.Conversations}</span>
          <span className="ml-4">{threadResults.totalCount}</span>
        </>
      ),
    },
    {
      tab: SearchTabs.Groups,
      tabTitle: (
        <>
          <span>{SearchTabs.Groups}</span>
          <span className="ml-4">{groupResults.totalCount + archivedGroupResults.totalCount}</span>
        </>
      ),
    },
    {
      tab: SearchTabs.People,
      tabTitle: (
        <>
          <span>{SearchTabs.People}</span>
          <span className="ml-4">{userResults.totalCount}</span>
        </>
      ),
    },
  ];

  return (
    <div className="max-w-[848px] mx-auto md:px-24">
      <Tabs<SearchTabsType>
        variant="filter"
        className="px-20 md:px-0 pb-16"
        onClickTab={tab => history.push(tabPath(tab, { superTab: "search" }))}
        selectedTab={currentTab}
        tabs={tabs}
      />

      {currentTab === SearchTabs.Conversations && (
        <DeepSearchConversations searching={searchResults.searching} threads={threadResults} />
      )}
      {currentTab === SearchTabs.Groups && (
        <DeepSearchGroups
          searching={searchResults.searching}
          groups={groupResults}
          archivedGroups={archivedGroupResults}
        />
      )}
      {currentTab === SearchTabs.People && (
        <DeepSearchPeople searching={searchResults.searching} people={userResults} />
      )}
    </div>
  );
};

export default DeepSearchResults;
