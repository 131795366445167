import { ReactNode } from "react";

import { Button } from "components/design-system/Button";
import { Card } from "components/design-system/Card";
import Illustration, {
  IllustrationName,
} from "components/design-system/illustrations/Illustration";

export type EducationCardProps = {
  header: string;
  body: ReactNode;
  illustration?: IllustrationName;
  onDismiss?: () => void;
};

export const EducationCard = ({ header, body, illustration, onDismiss }: EducationCardProps) => {
  return (
    <Card className="px-24 py-32 relative select-none shadow-level3">
      <Button
        buttonStyle="none"
        icon="Close"
        iconSize={20}
        className="absolute right-0 top-0 mr-5 mt-8 text-icon-secondary hover:text-interactive-subtle-hover"
        onClick={onDismiss}
      />
      <div className="flex flex-col items-center">
        {illustration && <Illustration className="w-56 h-56 mb-16" illustration={illustration} />}
        <div className="flex flex-col items-center text-center">
          <div className="text-subhead-bold m-0 mb-4">{header}</div>
          <div className="text-subhead text-center m-0">{body}</div>
        </div>
      </div>
    </Card>
  );
};
