import { ElementRef, MutableRefObject, useEffect, useRef } from "react";

import { MessageEditor } from "components/MessageEditor";
import MessageEditorContainer from "components/MessageEditor/MessageEditorContainer";
import useModalStore from "store/useModalStore";
import { isNativeIOS } from "utils/platform";
import tw from "utils/tw";

import type { PromptSuggestionNames } from "../AIPromptSuggestions";

import AIButtonBar from "./AIButtonBar";

type Props = {
  editor: MutableRefObject<ElementRef<typeof MessageEditor> | null>;
  onChange: () => void;
  onAttachFiles: (files: File[]) => void;
  sendDraft: () => void;
  selectedCategory?: PromptSuggestionNames;
  safeAreaPadding?: boolean;
  threadID?: string;
  workspaceID?: string;
  isReply?: boolean;
  isModal?: boolean;
};

const AIMessageEditor = ({
  editor,
  onChange,
  onAttachFiles,
  sendDraft,
  selectedCategory,
  safeAreaPadding,
  threadID,
  workspaceID,
  isReply = false,
  isModal = false,
}: Props) => {
  const { topModalState } = useModalStore(({ topModalState }) => ({ topModalState }));

  // Focus the editor when it mounts
  const hasMounted = useRef(false);
  useEffect(() => {
    if (
      editor.current?.hasFocus() ||
      (isModal && topModalState !== "open" && topModalState !== "opening") ||
      hasMounted.current
    ) {
      return;
    }
    hasMounted.current = true;
    const focusEditor = () => editor.current?.focusEditor("start");
    isNativeIOS() ? setTimeout(focusEditor, 350) : focusEditor();
  }, [editor, isModal, topModalState]);

  return (
    <MessageEditorContainer
      showHelperText={isReply}
      variant={!isReply ? "thread-compose-ai" : "thread-reply-ai"}
      safeAreaPadding={safeAreaPadding}
    >
      <div
        className={tw(
          "flex relative w-full h-full overflow-hidden ai-gradient-border",
          !isReply && "rounded-[20px] border",
          isReply && "rounded-none md:rounded-[20px] border-t md:border"
        )}
      >
        <div className="flex w-full pt-6 px-10">
          <MessageEditor
            ref={editor}
            accessory={null}
            enterKeyBehavior="new-line"
            onChange={onChange}
            placeholder="Message Glue AI..."
            submitForm={sendDraft}
            customButtonBar={
              <AIButtonBar
                onAttachFiles={onAttachFiles}
                selectedCategory={selectedCategory}
                editor={editor}
                threadID={threadID}
                isReply={isReply}
              />
            }
            workspaceID={workspaceID}
          />
        </div>
      </div>
    </MessageEditorContainer>
  );
};

export default AIMessageEditor;
