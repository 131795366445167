import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useChatContext } from "stream-chat-react";
import { useAbortController } from "use-abort-controller-hook";

import { nodeAs } from "@utility-types/graphql";
import { Thread } from "@utility-types/graphql";
import { GlueDefaultStreamChatGenerics } from "@utility-types/stream";
import { MessageEditor } from "components/MessageEditor/types";
import { routeToThread } from "components/routing/utils";
import { useThreadViewState } from "components/thread/ThreadView/provider/ThreadViewProvider";
import useRestoreDraft from "components/threads/hooks/useRestoreDraft";
import { useFetchDraftQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useAppStateStore from "store/useAppStateStore";
import useDraftMessagesStore from "store/useDraftMessagesStore";
import { isMobile } from "utils/platform";

import { useDraftActions } from ".";

const useRestoreReply = ({
  editor,
  onLoadEnd,
}: { editor: React.RefObject<MessageEditor>; onLoadEnd?: () => void }) => {
  const authReady = useAuthData();
  const history = useHistory();
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  const { threadID, threadWorkspaceID } = useThreadViewState(({ threadID, threadWorkspaceID }) => ({
    threadID,
    threadWorkspaceID,
  }));
  const { channel } = useChatContext<GlueDefaultStreamChatGenerics>();

  const { addDraft, getDraft, removeDraft } = useDraftMessagesStore(
    ({ addDraft, getDraft, removeDraft }) => ({
      addDraft,
      getDraft,
      removeDraft,
    })
  );

  const [draftID, setDraftID] = useState<string | undefined>();
  const abortController = useAbortController();
  const { data: draftData, loading: draftLoading } = useFetchDraftQuery({
    context: {
      fetchOptions: abortController,
    },
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-first",
    skip: !draftID,
    variables: { draftID: draftID ?? "" },
  });

  const draft = nodeAs(draftData?.node, ["Draft"]);

  const onFinish = (thread?: Thread | "deleted") => {
    if (!thread || typeof thread === "string") return;
    dispatch({ type: "reset" });
    setDraftID(undefined);
    breakpointMD && history.push(routeToThread({ threadID: thread.id, to: "secondary" }));
  };

  const { compose, dispatch, sendDraft } = useDraftActions({
    draft,
    onFinish,
  });

  useRestoreDraft({
    draft,
    draftLoading,
    compose,
    dispatch,
    editor,
    onLoadEnd,
    draftID,
  });

  const draftIDRef = useRef<string | undefined>();
  useEffect(() => {
    if (!compose.draftID || !channel?.id) return;
    addDraft({ threadID: channel.id, draftID: compose.draftID });
    draftIDRef.current = compose.draftID;
    setDraftID(compose.draftID);
  }, [addDraft, channel?.id, compose.draftID]);

  // Draft restored
  useEffect(() => {
    if (channel?.id && editor.current && getDraft(channel.id)) {
      const df = getDraft(channel.id);
      !!df?.message && editor.current.setMessage(df.message)?.focus();
      df?.draftID && setDraftID(df?.draftID);
    }
    if (!isMobile()) editor.current?.focusEditor("end");
  }, [channel?.id, editor, getDraft]);

  return {
    channel,
    threadID,
    threadWorkspaceID,
    draft,
    draftID,
    draftIDRef,
    dispatch,
    compose,
    addDraft,
    removeDraft,
    sendDraft,
  };
};

export default useRestoreReply;
