import { ComponentProps, memo } from "react";

import { SecondaryBarButton } from "../SecondaryBarButton";

type AllowedButtons = ComponentProps<typeof SecondaryBarButton>["buttons"];

type Props = {
  buttons?: AllowedButtons;
};

const SecondaryBar = ({ buttons }: Props): JSX.Element | null => {
  if (!buttons) return null;

  const getKeys = <A extends AllowedButtons>(obj: A) => Object.keys(obj) as (keyof A)[];

  return (
    <div className="flex flex-wrap justify-between px-10 pt-12">
      <div className="control-bar secondary-bar flex justify-end w-full">
        {getKeys(buttons).map(buttonVariant => (
          <SecondaryBarButton key={buttonVariant} buttonVariant={buttonVariant} buttons={buttons} />
        ))}
      </div>
    </div>
  );
};

export default memo(SecondaryBar, (prev, next) => prev.buttons === next.buttons);
