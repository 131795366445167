type EdgeIDComponents = {
  nodeID: string;
  userID: string;
};

/**
 * Parses an edge ID into its component parts (nodeID and userID)
 * @param edgeID - The full edge ID in the format "nodeID-userID"
 * @returns An object containing the nodeID and userID, or null if invalid format
 */
export function parseEdgeID(edgeID: string | null | undefined): EdgeIDComponents | null {
  if (!edgeID) {
    return null;
  }

  const parts = edgeID.split("-");

  if (parts.length !== 2) {
    return null;
  }

  const [nodeID, userID] = parts;

  if (!nodeID || !userID) {
    return null;
  }

  return {
    nodeID,
    userID,
  };
}
