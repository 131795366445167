import { last } from "lodash-es";

import { Attachment, StreamGlueAttachment } from "@utility-types";

const linkRegex = new RegExp(/\[(\S+)\]\((\S+)\)/);
const lastLinkRegex = new RegExp(`${linkRegex.source}$`);

export const removeLonelyLinks = (
  message: string,
  attachments?: (Attachment | StreamGlueAttachment)[]
) => {
  if (attachments?.length !== 1 || !attachments?.[0]) return message;
  const attachment = attachments[0];

  let attachmentURL = "";
  if ("url" in attachment && typeof attachment.url === "string") {
    attachmentURL = attachment.url;
  }
  if (!attachmentURL) return message;

  const messageParts = message.trim().split(/\s/);
  const lastElement = last(messageParts);
  if (!lastElement) return message;

  const lastLink = lastElement.match(lastLinkRegex);
  if (!lastLink || messageParts.filter(e => e.match(linkRegex)).length > 1) return message;

  const [linkMatch, linkText, linkURL] = lastLink;
  if (!linkMatch) return message;

  if (linkText === linkURL && attachmentURL === linkURL) {
    return message.replace(linkMatch, "").trim();
  }

  return message;
};
