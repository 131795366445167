import { useApolloClient } from "@apollo/client";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

import { Form } from "components/design-system/Forms";
import GroupChip from "components/group/GroupChip";
import NameAndEmojiInputs from "components/group/NameAndEmojiInputs";
import {
  FetchWorkspaceOrPreviewEdgeDocument,
  JoinableBy,
  WorkspacesAndGroupsListDocument,
  useCreateGroupMutation,
  useUpdateGroupMutation,
} from "generated/graphql";
import useOnboardingStore from "store/useOnboardingStore";
import filterActiveQueries from "utils/filterActiveQueries";
import { formatGroupName } from "utils/group/formatGroupName";

import ContentWrapper from "./ContentWrapper";
import Footer from "./Footer";

type FormValues = {
  emoji?: string;
  name: string;
};

const suggestions: Required<FormValues>[] = [
  { emoji: "🚀", name: "Marketing" },
  { emoji: "👩‍💻", name: "Development" },
  { emoji: "💰", name: "Sales" },
  { emoji: "🎨", name: "Design" },
  { emoji: "🎉", name: "Fun" },
];

const GroupSuggestion = ({ emoji, name }: Required<FormValues>) => {
  const { setValue } = useFormContext<FormValues>();
  const handleClick = () => {
    setValue("emoji", emoji, { shouldDirty: true });
    setValue("name", name, { shouldDirty: true, shouldValidate: true });
  };

  return <GroupChip emoji={emoji} name={name} onClick={handleClick} className="cursor-pointer" />;
};

const FormContent = ({ formSubmitting }: { formSubmitting: boolean }) => {
  const { currentStep, setState, groups } = useOnboardingStore(
    ({ currentStep, setState, groups }) => ({ currentStep, setState, groups })
  );
  const { watch } = useFormContext<FormValues>();
  const { name } = watch();

  return (
    <>
      <NameAndEmojiInputs
        formSubmitting={formSubmitting}
        inputVariant="line"
        inputPlaceholder="Team name..."
      />
      {!name && (
        <div className="flex flex-wrap gap-6 w-full py-12 px-11 mt-16 border rounded-lg border-border-magic bg-background-body">
          {suggestions.map(({ emoji, name }) => (
            <GroupSuggestion key={name} emoji={emoji} name={name} />
          ))}
        </div>
      )}
      <Footer
        onClickBack={() => setState({ view: "ChooseApps", currentStep: currentStep - 1 })}
        onClickSkip={() => setState({ view: "InviteMembers", currentStep: currentStep + 1 })}
        formSubmitting={formSubmitting}
        submitDisabled={formSubmitting}
        requireChanges={!groups}
      />
    </>
  );
};

const CreateGroup = () => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const { currentStep, setState, groups, workspace } = useOnboardingStore(
    ({ currentStep, setState, groups, workspace }) => ({
      currentStep,
      setState,
      groups,
      workspace,
    })
  );
  const apolloClient = useApolloClient();

  const [createGroup] = useCreateGroupMutation({
    refetchQueries: filterActiveQueries(apolloClient, [
      FetchWorkspaceOrPreviewEdgeDocument,
      WorkspacesAndGroupsListDocument,
    ]),
  });

  const [updateGroup] = useUpdateGroupMutation({
    refetchQueries: filterActiveQueries(apolloClient, [
      FetchWorkspaceOrPreviewEdgeDocument,
      WorkspacesAndGroupsListDocument,
    ]),
  });

  const createNewGroup = ({ name, emoji }: FormValues) => {
    setFormSubmitting(true);
    const groupName = formatGroupName({
      name: `${emoji}${name}`,
    }).nameWithEmoji;
    return (
      groups?.[0]
        ? updateGroup({
            variables: {
              id: groups[0].id,
              input: { name: groupName },
            },
          })
        : createGroup({
            variables: {
              input: {
                name: groupName,
                workspaceID: workspace?.id,
                joinableBy: JoinableBy.Workspace,
              },
            },
          })
    )
      .then(({ data }) => {
        if (!data) return;
        setState({
          view: "InviteMembers",
          currentStep: currentStep + 1,
          groups: [
            {
              id: "createGroup" in data ? data.createGroup.id : data.updateGroup.id,
              name,
              emoji,
              type: "created",
            },
          ],
        });
      })
      .finally(() => setFormSubmitting(false));
  };

  return (
    <Form<FormValues>
      className="w-full"
      onSubmit={createNewGroup}
      useFormProps={{ defaultValues: { ...groups?.[0] }, mode: "onTouched" }}
    >
      <ContentWrapper
        title="Create your first group."
        headline="Groups are great for teams, projects or topics."
      >
        <FormContent formSubmitting={formSubmitting} />
      </ContentWrapper>
    </Form>
  );
};

export default CreateGroup;
