import { ObservableQuery } from "@apollo/client";
import { ReactNode, useCallback, useState } from "react";
import { Virtuoso } from "react-virtuoso";

import { nodeAs } from "@utility-types";
import { Button } from "components/design-system/Button";
import IdentityBadge from "components/design-system/ui/IdentityBadge/IdentityBadge";
import { MembersListHeader } from "components/MembersList/MembersListHeader";
import { MembersListItem } from "components/MembersList/MembersListItem";
import { PendingMembersListItem } from "components/MembersList/PendingMembersListItem";
import { ConfirmationAlert } from "components/Modals";
import { cloneElementForSkeletons } from "components/Skeleton/Skeleton";
import AddMembersModal from "components/workspace-group/AddMembersModal";
import {
  EmptyMembersList,
  LabelSkeleton,
  LoadingSkeletons,
} from "components/workspace-group/MembersListComponents";
import {
  FetchGroupOrPreviewEdgeQuery,
  GroupFieldsFragment,
  MemberRole,
  useAddGroupMembersMutation,
  useApproveJoinApprovalMutation,
  useCancelRequestJoinGroupMutation,
  useRemoveGroupMembersMutation,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useModalStore from "store/useModalStore";

type GroupMemberEdge = GroupFieldsFragment["members"]["edges"][number];
type PendingApprovalEdge = GroupFieldsFragment["pendingApprovals"]["edges"][number];
type SortedMemberItem = { isPending: false; data: GroupMemberEdge };
type PendingApprovalItem = { isPending: true; data: PendingApprovalEdge };
type Item = SortedMemberItem | PendingApprovalItem;

type Props = {
  group: GroupFieldsFragment;
  fetchMore: ObservableQuery<FetchGroupOrPreviewEdgeQuery>["fetchMore"];
  hasError: boolean;
  isLoading: boolean;
  isAdmin: boolean;
  scrollContainerRef: React.RefObject<HTMLElement>;
  isSearchLoading: boolean;
};

const GroupMembers = ({
  group,
  isAdmin,
  hasError,
  fetchMore,
  isLoading,
  scrollContainerRef,
  isSearchLoading,
}: Props) => {
  const { authData } = useAuthData();
  const [editingMemberId, setEditingMemberId] = useState<string | null>(null);
  const { members, pendingApprovals } = group;
  const [approveJoinApproval] = useApproveJoinApprovalMutation({
    refetchQueries: ["FetchGroupOrPreviewEdge"],
  });

  const [cancelRequestJoinGroup] = useCancelRequestJoinGroupMutation({
    refetchQueries: ["FetchGroupOrPreviewEdge"],
  });
  const [removeGroupMembers] = useRemoveGroupMembersMutation();
  const [updateGroupMembers] = useAddGroupMembersMutation();
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const loadMore = useCallback(() => {
    if (hasError || isLoading) {
      return;
    }

    // we display pending approvals first, so we fetch more of those first
    if (pendingApprovals?.pageInfo?.hasNextPage) {
      return fetchMore({
        variables: {
          pendingApprovalsAfter: pendingApprovals.pageInfo.endCursor,
        },
      }).catch(err => {
        console.warn("Error: [WorkspaceJoinApprovals] - ", err.message);
      });
    }

    if (members.pageInfo.hasNextPage) {
      return fetchMore({
        variables: {
          pendingApprovalsAfter: pendingApprovals.pageInfo.endCursor, // this must be persisted through subsequent loads
          membersAfter: members.pageInfo.endCursor,
        },
      }).catch(err => {
        console.warn("Error: [WorkspaceMembers] - ", err.message);
      });
    }
  }, [
    fetchMore,
    hasError,
    isLoading,
    members.pageInfo.endCursor,
    members.pageInfo.hasNextPage,
    pendingApprovals.pageInfo.endCursor,
    pendingApprovals.pageInfo?.hasNextPage,
  ]);

  const handleAddMember = () => {
    openModal(
      <AddMembersModal currentMembers={members.edges.map(m => m.node)} groupOrWorkspace={group} />
    );
  };

  const handleUpdateRole = ({ id, role }: { id: string; role: MemberRole }) =>
    updateGroupMembers({
      variables: {
        id: group.id,
        members: [{ member: id, role }],
      },
    });

  const handleDelete = (memberId: string) => {
    const member = members.edges.find(m => m.node.id === memberId);

    if (!member) {
      return;
    }

    openModal(
      <ConfirmationAlert
        confirmLabel="Remove user"
        header={`Remove ${member.node.name} from the group?`}
        isDestructive
        onConfirm={() =>
          removeGroupMembers({
            variables: { id: group.id, memberIDs: [member.node.id] },
          })
        }
      />
    );
  };

  const handleApprovePendingApproval = async (approvalId: string) => {
    const approval = pendingApprovals.edges.find(a => a.node.id === approvalId);

    if (!approval) {
      return;
    }

    await approveJoinApproval({ variables: { joinApprovalID: approvalId } });
  };

  const handleCancelPendingApproval = async (approvalId: string) => {
    const approval = pendingApprovals.edges.find(a => a.node.id === approvalId);

    if (!approval) {
      return;
    }

    await cancelRequestJoinGroup({ variables: { id: approvalId } });
  };

  const items: Item[] = [
    ...pendingApprovals.edges.map<PendingApprovalItem>(edge => ({
      isPending: true,
      data: edge,
    })),
  ];

  if (!pendingApprovals?.pageInfo?.hasNextPage) {
    items.push(
      ...members.edges.map<SortedMemberItem>(edge => ({
        isPending: false,
        data: edge,
      }))
    );
  }

  const Header = () => (
    <MembersListHeader
      addNewMemberButton={
        isAdmin && (
          <Button
            icon="Plus"
            buttonStyle="simplePrimary"
            className="!text-subhead-bold py-4"
            buttonType="text"
            onClick={handleAddMember}
          >
            Add new member
          </Button>
        )
      }
    >
      {isSearchLoading ? (
        <LabelSkeleton />
      ) : (
        <>
          {members.totalCount} members
          {pendingApprovals.totalCount > 0 && `, ${pendingApprovals.totalCount} pending`}
        </>
      )}
    </MembersListHeader>
  );

  const Footer = () => (
    <div className="native:pb-safe-area mt-4">
      {(pendingApprovals.pageInfo.hasNextPage || members.pageInfo.hasNextPage) &&
        cloneElementForSkeletons(
          <div className="h-[68px]">
            <LoadingSkeletons />
          </div>,
          3
        )}
    </div>
  );

  return (
    <div className="native:pb-safe-area pt-12">
      {items.length > 0 ? (
        !isSearchLoading ? (
          <Virtuoso
            customScrollParent={scrollContainerRef.current ?? undefined}
            className="native:pb-safe-area h-full w-full"
            data={items}
            endReached={loadMore}
            increaseViewportBy={100}
            components={{
              Header,
              Footer,
            }}
            itemContent={(_, { isPending, data }) => {
              let item: ReactNode;
              if (isPending === true) {
                const {
                  node: { id, requester, joining, admin, requestedAt },
                } = data;
                const joiningUser = nodeAs(joining, ["User"]);
                if (!joiningUser) {
                  return null;
                }
                item = (
                  <PendingMembersListItem
                    key={id}
                    approvalID={id}
                    isAdmin={isAdmin}
                    isByMe={requester.id === authData?.me.id}
                    isForMe={joining.id === authData?.me.id}
                    isInvite={requester.id === admin?.id}
                    joining={joiningUser}
                    requestedAt={new Date(requestedAt)}
                    onApprove={handleApprovePendingApproval}
                    onCancel={handleCancelPendingApproval}
                  />
                );
              } else {
                item = (
                  <MembersListItem
                    key={data.node.id}
                    member={{
                      ...data.node,
                      role: data.memberRole,
                      badge: <IdentityBadge recipient={data.node} />,
                    }}
                    onUpdateRole={handleUpdateRole}
                    onDelete={handleDelete}
                    isEditing={editingMemberId === data.node.id}
                    setIsEditing={(state: boolean) =>
                      setEditingMemberId(state ? data.node.id : null)
                    }
                    isAdmin={isAdmin}
                    isMe={authData?.me.id === data.node.id}
                  />
                );
              }

              return <div className="py-8">{item}</div>;
            }}
          />
        ) : (
          <>
            <Header />
            <div className="flex flex-col py-8 gap-8">
              {cloneElementForSkeletons(<LoadingSkeletons />, 3)}
            </div>
          </>
        )
      ) : (
        <EmptyMembersList handleAddMember={isAdmin ? handleAddMember : undefined} />
      )}
    </div>
  );
};

export default GroupMembers;
