import { useState } from "react";

import SnackBar from "./SnackBar";

type Props = {
  clickHandler: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const NewVersionSnackbar = ({ clickHandler }: Props): JSX.Element | null => {
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(true);

  const closeSnackbar = (): void => setSnackbarOpen(false);

  const refreshContent = () => (
    <>
      ✨
      <p>
        <span className="ml-4 font-semibold">Update available.</span>
        &nbsp;Refresh to get the latest version of Glue.
      </p>
      <button
        className="ml-50 font-bold border-none"
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          clickHandler(e);
          closeSnackbar();
        }}
      >
        Refresh
      </button>
    </>
  );

  return (
    <SnackBar content={refreshContent} onClose={closeSnackbar} show={snackbarOpen} type="info" />
  );
};

export default NewVersionSnackbar;
