import { Icon } from "components/design-system/icons";

const NetworkErrorSnackbar = (): JSX.Element => (
  <>
    <Icon className="icon" icon="ZapOff" size={18} />
    <p>
      <span className="font-semibold">Glue cannot connect.</span>&nbsp;There may be a problem with
      your internet connection.
    </p>
  </>
);

export default NetworkErrorSnackbar;
