import { useApolloClient } from "@apollo/client";
import {
  ActionPerformed as LocalActionPerformed,
  LocalNotificationSchema,
  LocalNotifications,
} from "@capacitor/local-notifications";
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
} from "@capacitor/push-notifications";
import { Badge } from "@capawesome/capacitor-badge";
import { useCallback } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { readThreadEdge } from "apollo/cache/threadHelpers";
import { routeToGroup, routeToThread, routeToUser } from "components/routing/utils";
import useSidebarCounts from "components/SideBar/hooks/useSidebarCounts";
import useAuthData from "hooks/useAuthData";
import useAppStateStore from "store/useAppStateStore";
import { isNative } from "utils/platform";

import useNotificationPermissions from "../../devices/hooks/useNotificationPermissions";
import usePushNotificationToken from "../../devices/hooks/usePushNotificationToken";

export const useNativeDeviceManager = (): void => {
  const apolloClient = useApolloClient();
  const { authData } = useAuthData();
  const history = useHistory();
  const { unreadInboxCount } = useSidebarCounts();
  const { appStatus } = useAppStateStore(({ appStatus }) => ({ appStatus }));
  const { checkPermissions, permissionStatus } = useNotificationPermissions();
  const { tokenInitialized } = usePushNotificationToken();

  const onActionPerformed = useCallback(
    ({ notification: ntf }: ActionPerformed | LocalActionPerformed) => {
      const { target } = "data" in ntf ? ntf.data : ntf.extra;
      if (typeof target !== "string") return;

      switch (target.split("_")[0]) {
        case "thr":
          history.push(
            routeToThread({
              threadID: target,
              superTab: "inbox",
              to: "primary",
            })
          );
          break;
        case "usr":
          history.push(routeToUser({ userID: target, to: "secondary" }));
          break;
        case "grp":
        case "wks":
          history.push(routeToGroup({ groupID: target }));
          break;
      }
    },
    [history]
  );

  // Listen to all notification events
  useEffect(() => {
    if (!isNative()) {
      return;
    }

    const listeners = [
      PushNotifications.addListener("pushNotificationActionPerformed", onActionPerformed),
      LocalNotifications.addListener("localNotificationActionPerformed", onActionPerformed),
    ];

    return () => {
      listeners.forEach(async l => (await l).remove());
    };
  }, [history, onActionPerformed]);

  // Set badge count and remove notifications after reading
  const badgeCount = unreadInboxCount;
  useEffect(() => {
    if (!isNative()) {
      return;
    }

    const setBadge = async () => {
      const permissions = await checkPermissions();
      if (permissions !== "granted") return;

      Badge.set({ count: badgeCount }).catch(_ => {
        if (badgeCount === 0) {
          // If for some reason setting badge count fails, we can only
          // safely clear the badge by removing all notifications.
          LocalNotifications.removeAllDeliveredNotifications();
          PushNotifications.removeAllDeliveredNotifications();
        }
      });
    };

    setBadge();

    // There's a check on the Capacitor plugin side to make sure that a token
    // has been generated before calling the functions below. If not it will
    // generate a "capacitorDidRegisterForRemoteNotifications not called" error.
    if (!tokenInitialized) return;

    const shouldRemoveNotification = (ntf: PushNotificationSchema | LocalNotificationSchema) => {
      const target = "data" in ntf ? ntf.data?.target : ntf.extra?.target;
      if (typeof target !== "string" || !target.match(/^thr_/)) return false;
      const threadEdgeID = `${target}-${authData?.me.id}`;
      return readThreadEdge(threadEdgeID, apolloClient.cache)?.isRead;
    };

    LocalNotifications.getDeliveredNotifications().then(({ notifications }) => {
      LocalNotifications.removeDeliveredNotifications({
        notifications: notifications.filter(shouldRemoveNotification),
      });
    });

    PushNotifications.getDeliveredNotifications().then(({ notifications }) => {
      PushNotifications.removeDeliveredNotifications({
        notifications: notifications.filter(shouldRemoveNotification),
      });
    });
  }, [
    appStatus,
    authData,
    badgeCount,
    apolloClient.cache,
    permissionStatus,
    tokenInitialized,
    checkPermissions,
  ]);
};
