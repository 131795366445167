import { ComponentProps } from "react";
import { Flipper, spring } from "react-flip-toolkit";

/**
 * @summary Default spring for the config prop of @react-spring/web.
 */
export const defaultSpring: {
  friction: number;
  mass: number;
  tension: number;
} = {
  friction: 200,
  mass: 5,
  tension: 2000,
};

/**
 * @summary Default spring for the spring prop of Flipped components.
 */
export const flipSpring: {
  damping: number;
  stiffness: number;
} = {
  damping: 60,
  stiffness: 800,
};

/**
 * @summary Default animation for the onAppear prop of Flipped components.
 */
export const flipAnimate = (el: HTMLElement, removeItem?: () => void) =>
  spring({
    config: flipSpring,
    onComplete: removeItem,
    onUpdate: value => {
      if (typeof value === "number") return;
      const { opacity } = value;
      el.style.opacity = `${opacity}`;
    },
    values: {
      opacity: removeItem ? [1, 0] : [0, 1],
    },
  });

/**
 * @summary Transition for the handleEnterUpdateDelete prop of Flipper.
 * @see {@link https://github.com/aholachek/react-flip-toolkit#advanced-props}
 */
export const flipSimultaneousTransition: ComponentProps<typeof Flipper>["handleEnterUpdateDelete"] =
  ({
    animateEnteringElements,
    animateExitingElements,
    animateFlippedElements,
    hideEnteringElements,
  }) => {
    animateExitingElements();
    animateFlippedElements();
    animateEnteringElements();
    hideEnteringElements();
  };
