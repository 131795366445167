import { Button } from "components/design-system/Button";
import tw from "utils/tw";

type BannerActionButtonProps = {
  label: string;
  onClick: () => void;
  inline?: boolean;
};

export const BannerActionButton = ({ label, onClick, inline = false }: BannerActionButtonProps) => (
  <Button
    className={tw(
      "text-footnote-bold text-text-action hover:text-text-action-hover hover:underline hover:cursor-pointer shrink-0",
      inline && "!inline-block"
    )}
    buttonStyle="none"
    buttonType="none"
    onClick={onClick}
  >
    {label}
  </Button>
);
