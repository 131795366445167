import {
  ExternalFeatureFlag,
  ExternalFeatureFlagConfig,
  externalFeatureFlagConfigs,
} from "./configs.external";
import { LocalFeatureFlag, LocalFeatureFlagConfig, localFeatureFlagConfigs } from "./configs.local";

export * from "./useFeatureFlagEnabled";
export {
  isLocalFeatureFlag,
  environmentFlagNames,
  LocalFeatureFlag,
  localFeatureFlagConfigs,
} from "./configs.local";

export type FeatureFlagConfig<Key> = Key extends LocalFeatureFlag
  ? LocalFeatureFlagConfig
  : ExternalFeatureFlagConfig;

export const FeatureFlag = {
  ...LocalFeatureFlag,
  ...ExternalFeatureFlag,
};

export type FeatureFlagKey = (typeof FeatureFlag)[keyof typeof FeatureFlag];

export const featureFlagConfigs: Record<FeatureFlagKey, FeatureFlagConfig<FeatureFlagKey>> = {
  ...localFeatureFlagConfigs,
  ...externalFeatureFlagConfigs,
};
