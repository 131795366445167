import { ComponentProps, useMemo } from "react";

import Avatar from "components/design-system/Avatar/Avatar";
import generateRandomId from "utils/generateRandomId";

import { AvatarUploader } from "./AvatarUploader";

type Props = Pick<ComponentProps<typeof Avatar>, "avatarURL" | "name" | "size" | "rounded"> &
  Omit<ComponentProps<typeof AvatarUploader>, "inputId">;

const EditableAvatar = ({ onAvatarUpdate, size = "x-large", ...props }: Props): JSX.Element => {
  const inputId = useMemo(() => generateRandomId(), []);

  return (
    <div className="group/editable-avatar relative">
      <div>
        <label className="cursor-pointer" htmlFor={inputId}>
          <Avatar {...props} size={size} />
          <AvatarUploader inputId={inputId} onAvatarUpdate={onAvatarUpdate} />
        </label>
      </div>
    </div>
  );
};

export default EditableAvatar;
