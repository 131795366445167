import type { Emoji, EmojiData } from "@emoji-mart/data";
import { SearchIndex, init as initEmoji } from "emoji-mart";
import { useEffect } from "react";
import type { FlatEmoji } from "svgmoji"; // From: https://github.com/iamcal/emoji-data/tree/master/sheets-indexed-256

import emojiApple from "assets/emoji/emoji-v15-apple-sheet-256-64.png";
import emojiGoogle from "assets/emoji/emoji-v15-google-sheet-256-64.png";
import { validCustomEmojiID } from "components/emoji/utils";
import { isNativeAndroid } from "utils/platform";

enum Set {
  apple = "apple",
  facebook = "facebook",
  google = "google",
  native = "native",
  twitter = "twitter",
}

const emojiToFlatEmoji: (emoji: Emoji) => FlatEmoji = emoji => {
  const { id, keywords, name, skins, version } = emoji;
  return {
    annotation: name,
    emoji: skins[0]?.native ?? "",
    hexcode: skins[0]?.unified ?? "",
    shortcodes: [id, ...keywords],
    tags: keywords,
    text: "",
    type: 1,
    version: version,
  };
};

const set = isNativeAndroid() ? Set.google : Set.apple;
let data: EmojiData;
let flat: FlatEmoji[];
let i18n: typeof import("@emoji-mart/data/i18n/en.json");

// skip emoji initialization in test environment
if (process.env.NODE_ENV !== "test") {
  const importData =
    set === Set.google
      ? import("@emoji-mart/data/sets/15/google.json")
      : import("@emoji-mart/data/sets/15/apple.json");
  const importI18n = import("@emoji-mart/data/i18n/en.json");

  Promise.all([
    importData.then(({ default: emojiData }) => (data = emojiData)),
    importI18n.then(({ default: i18nData }) => (i18n = i18nData)),
  ]).then(() => {
    flat = Object.values(data.emojis).map(emojiToFlatEmoji);
    initEmoji({ data, i18n, set }); // ensure that the emoji-mart data is initialized
  });
}

export const emojiMap: Record<string, string> = {
  haha: "joy",
  like: "+1",
  love: "heart",
  sad: "pensive",
  wow: "astonished",
};

const parseName = (name: string) => emojiMap[name] ?? name;

export const getEmoji = (name: string) => data.emojis[parseName(name)];

// Todo: Remove custom emojis filter, this is needed because we don't support custom emojis in the editor.
export const getEmojisByName = async (query: string, limit?: number) =>
  await SearchIndex.search(query).then(results =>
    (results ?? [])
      .filter(emoji => !emoji.id.match(validCustomEmojiID))
      .map((emoji: Emoji) => emojiToFlatEmoji(emoji))
      .slice(0, limit)
  );

export const getEmojiStyles = (name: string) => {
  const skin = getEmoji(name)?.skins[0];

  if (skin?.x === undefined || skin?.y === undefined) return;

  const emojiSheetPath = set === Set.apple ? emojiApple : emojiGoogle;
  return {
    backgroundImage: `url(${emojiSheetPath})`,
    backgroundPosition: `${(100 / (data.sheet.cols - 1)) * skin.x}% ${
      (100 / (data.sheet.rows - 1)) * skin.y
    }%`,
    backgroundSize: `${100 * data.sheet.cols}% ${100 * data.sheet.rows}%`,
    height: 64,
    width: 64,
  };
};

export const getNativeEmoji = (name: string) => {
  const skin = data?.emojis?.[parseName(name)]?.skins[0];

  if (!skin?.native) return;

  return skin.native;
};

export const hasEmoji = (name: string, native?: boolean) =>
  native ? !!getNativeEmoji(name) : !!data.emojis[parseName(name)];

export const emojiSet: Set = set;

export const spritesheetURL = set === Set.google ? emojiGoogle : emojiApple;

export const useEmoji = (suggestedEmoji?: string) => {
  useEffect(() => {
    if (!suggestedEmoji || !data.categories[0]) return;

    const emoji =
      Object.entries(data.emojis).find(e => e[1].skins[0]?.native === suggestedEmoji)?.[0] ?? "";

    data.categories[0].emojis = [emoji];
  }, [suggestedEmoji]);

  return {
    data,
    flat,
    i18n,
  };
};
