import { Button } from "components/design-system/Button";
import { ThreadSelection } from "hooks/thread/useInboxThreadActions";
import useShortcut from "hooks/useShortcut";
import useAppStateStore from "store/useAppStateStore";
import tw from "utils/tw";

import InboxThreadActions from "./InboxThreadActions";

type Props = {
  canArchive: boolean;
  clearExcludedIDs: () => void;
  selection: ThreadSelection;
  setSelectMode: (mode?: ThreadSelection["selectMode"]) => void;
  totalCount?: number;
};

export default function BulkEditActionBar({
  canArchive,
  clearExcludedIDs,
  selection,
  setSelectMode,
  totalCount,
}: Props) {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  useShortcut(["Escape"], () => setSelectMode(undefined));

  const totalSelected = totalCount ?? 0;

  const selectedCount = () => {
    if (selection.selectMode === "all")
      return selection.excludedEdgeIDs?.size === 0 ? (
        <>
          All&nbsp;<span className="font-bold">{totalSelected}</span>
        </>
      ) : (
        <>
          <span className="font-bold">{totalSelected - selection.excludedEdgeIDs.size}</span>
        </>
      );
    return selection.threadEdges.length;
  };

  const exitBulkEditMode = () => setSelectMode(undefined);

  return (
    <div
      className={tw(
        "flex items-center justify-between select-none shrink-0 w-full",
        "bg-background-action-strong text-text-inverse",
        "px-8 rounded-[12px] md:rounded-lg shadow-level1"
      )}
      data-testid="leading"
    >
      <div className="flex items-center justify-start">
        <Button
          buttonStyle="icon-inverse"
          buttonType="none"
          className="px-8 py-16 md:pr-8 md:pl-0 md:py-6 hover:!text-icon-inverse"
          icon="Close"
          iconSize={breakpointMD ? 20 : 24}
          onClick={exitBulkEditMode}
          tooltip="Cancel"
        />

        <div
          className={tw(
            "flex items-center justify-center",
            "box-content h-12 min-w-12 p-4",
            "mr-16 rounded-md text-caption-bold",
            "bg-background-alert-subtle text-text-action-inverse"
          )}
        >
          {selectedCount()}
        </div>

        {(selection.selectMode !== "all" ||
          (selection.selectMode === "all" && selection.excludedEdgeIDs.size !== 0)) && (
          <Button
            buttonStyle="icon-inverse"
            buttonType="none"
            className="px-8 py-22 md:py-10 text-caption-bold hover:!text-icon-inverse"
            onClick={e => {
              e.stopPropagation();

              if (selection.selectMode !== "all") {
                setSelectMode("all");
                return;
              }

              clearExcludedIDs();
            }}
          >
            Select all
          </Button>
        )}
      </div>

      <div className="flex items-center justify-end" data-testid="trailing">
        <InboxThreadActions
          canArchive={canArchive}
          bulkEditMode={true}
          buttonClassName="hover:!text-icon-inverse"
          buttonStyle="icon-inverse"
          exitBulkEditMode={exitBulkEditMode}
          selection={selection}
        />
      </div>
    </div>
  );
}
