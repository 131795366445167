import { uniqBy } from "lodash-es";
import { ComponentProps, forwardRef, useCallback, useImperativeHandle, useMemo } from "react";
import { SubmitHandler, useFormContext } from "react-hook-form";

import type { Group, Recipient } from "@utility-types";
import { ButtonGroup } from "components/design-system/ButtonGroup";
import { CollapsibleSection } from "components/design-system/CollapsibleSection";
import { Form, TextArea, useDisableSubmit } from "components/design-system/Forms";
import { ConfirmationAlert, ConfirmationModal } from "components/Modals";
import ConfirmationRequiredModal from "components/Modals/ConfirmationRequiredModal";
import type { RecipientValue } from "components/RecipientsSelect/types";
import GroupChatPermissions from "components/workspace-group/GroupChatPermissions";
import { JoinableBy, MemberRole as MemberRoleValue, MessageableBy } from "generated/graphql-types";
import useAuthData from "hooks/useAuthData";
import useModalStore from "store/useModalStore";
import { formatNameEmoji } from "utils/formatNameEmoji";

import { GroupVisibilityRadioGroup } from "../GroupVisibilityRadioGroup";
import NameAndEmojiInputs from "../NameAndEmojiInputs";

type MemberRole = { member: string; role: MemberRoleValue };

type GroupInput = {
  description: string;
  joinableBy: JoinableBy;
  chatMessageableBy: MessageableBy;
  messageableBy: MessageableBy;
  members: MemberRole[];
  name: string;
  workspaceID: string | null;
};

type Props = {
  disabled?: boolean;
  group: Group;
  onDelete: () => Promise<void>;
  onLeave: () => Promise<void>;
  onArchive: () => Promise<unknown>;
  onUnarchive: () => Promise<unknown>;
  onSave: (input: GroupInput) => Promise<void>;
  isAdmin: boolean;
  setFocusOnField?: "description" | "name";
};

type FormData = {
  admins: RecipientValue[];
  description: string;
  emoji: string;
  joinableBy: JoinableBy;
  chatMessageableBy: MessageableBy;
  members: RecipientValue[];
  name: string;
  workspaceID: string;
};

type FormContentProps = {
  handleDeleteGroup?: () => void;
  handleLeaveGroup?: () => void;
  handleArchiveGroup?: () => void;
  handleUnarchiveGroup?: () => void;
  onSubmit: SubmitHandler<FormData>;
  isAdmin: boolean;
};

export type FormRef = {
  submitDisabled: boolean;
  submitForm: () => void;
};

const memberRoles = (recipients: Recipient[], role = MemberRoleValue.Default): MemberRole[] =>
  uniqBy(
    recipients.map(r => ({ member: r.id, role })),
    "member"
  );

const FormContent = forwardRef<FormRef, FormContentProps>(
  (
    {
      handleDeleteGroup,
      handleLeaveGroup,
      handleArchiveGroup,
      handleUnarchiveGroup,
      onSubmit,
      isAdmin,
    }: FormContentProps,
    ref
  ) => {
    const {
      handleSubmit,
      formState: { isSubmitting },
    } = useFormContext<FormData>();

    const submitForm = useCallback(() => {
      handleSubmit(onSubmit)();
    }, [handleSubmit, onSubmit]);

    const disabled = useDisableSubmit();

    useImperativeHandle(ref, () => ({ submitDisabled: disabled, submitForm }), [
      disabled,
      submitForm,
    ]);

    const actionButtons = useMemo<ComponentProps<typeof ButtonGroup>["buttons"]>(
      () =>
        [
          handleLeaveGroup && {
            icon: "Leave" as const,
            text: "Leave group",
            disabled: isSubmitting,
            onClick: handleLeaveGroup,
          },
          handleArchiveGroup && {
            icon: "Archive" as const,
            text: "Archive for everyone",
            disabled: isSubmitting,
            onClick: handleArchiveGroup,
          },
          handleUnarchiveGroup && {
            icon: "Archive" as const,
            text: "Unarchive group",
            disabled: isSubmitting,
            onClick: handleUnarchiveGroup,
          },
        ].filter(v => !!v),
      [handleArchiveGroup, handleLeaveGroup, handleUnarchiveGroup, isSubmitting]
    );

    return (
      <div className="flex flex-col gap-24 h-full">
        <div className="mt-20">
          <NameAndEmojiInputs formSubmitting={isSubmitting} />
          <TextArea<FormData>
            className="mt-2"
            name="description"
            placeholder="Description (optional)"
            wrapperClassName="mt-12 mb-0"
          />
        </div>
        {isAdmin && <GroupChatPermissions />}
        {isAdmin && (
          <div>
            <span className="text-subhead-bold">Visibility</span>
            <GroupVisibilityRadioGroup<FormData> name="joinableBy" />
          </div>
        )}
        {(handleLeaveGroup || handleArchiveGroup || handleUnarchiveGroup) && (
          <ButtonGroup buttons={actionButtons} />
        )}
        {handleDeleteGroup && (
          <CollapsibleSection label="Advanced Settings" collapsed={true}>
            <div className="mt-16">
              <ButtonGroup
                buttons={[
                  {
                    icon: "Trash",
                    text: "Delete group",
                    variant: "destructive",
                    onClick: handleDeleteGroup,
                  },
                ]}
              />
            </div>
          </CollapsibleSection>
        )}
      </div>
    );
  }
);

const EditGroupForm = forwardRef<FormRef, Props>(
  (
    { group, onDelete, onLeave, onSave, onArchive, onUnarchive, isAdmin, setFocusOnField }: Props,
    ref
  ) => {
    const { authData } = useAuthData();
    const { openModal } = useModalStore(({ openModal }) => ({
      openModal,
    }));

    const handleDeleteGroup = () => {
      openModal(
        <ConfirmationRequiredModal
          headerTitle="Delete this group?"
          title={
            <span className="text-body text-text-primary">
              Are you sure you want to delete the{" "}
              <span className="text-body-bold">{group.name}</span> group?
            </span>
          }
          subtitle="All members will immediately lose access to the threads in this group, and the group cannot be restored."
          confirmButtonText="Delete group"
          informationBubbleText="This action can't be undone. Once confirmed, the group will be permanently deleted."
          onConfirm={onDelete}
        />
      );
    };

    const handleLeaveGroup = () => {
      openModal(
        <ConfirmationAlert
          confirmLabel="Leave group"
          header="Leave this group?"
          message={`If you leave the ${group.name} group, you will lose access to its threads and chat. You can rejoin anytime if you change your mind.`}
          onConfirm={onLeave}
          isDestructive
        />
      );
    };

    const handleArchiveGroup = () => {
      openModal(
        <ConfirmationModal
          confirmLabel="Archive group"
          header="Archive this group for everyone?"
          content={
            <div className="flex flex-col gap-[1lh] text-body text-text-primary">
              <div>
                Are you sure you want to archive the{" "}
                <span className="text-body-bold">{group.name}</span> group for everyone?
              </div>
              <div>
                Archiving will:
                <ul className="list-indented">
                  <li>Prevent new threads from being addressed to this group.</li>
                  <li>Remove the group from the Groups tab for all members.</li>
                </ul>
              </div>
              <div>
                The group will still be accessible through search and can be unarchived at any time
                if needed.
              </div>
            </div>
          }
          onConfirm={onArchive}
        />
      );
    };

    const handleUnarchiveGroup = () => {
      openModal(
        <ConfirmationModal
          confirmLabel="Unarchive group"
          header="Unarchive this group?"
          content={
            <div className="flex flex-col gap-[1lh] text-body text-text-primary">
              <div>
                Unarchive the <span className="text-body-bold">{group.name}</span> group?
              </div>
              <div>
                Unarchiving will:
                <ul className="list-indented">
                  <li>Allow new threads to be started in this group.</li>
                  <li>Add the group back to the Groups tab for all members.</li>
                </ul>
              </div>
              <div>The group will become active again and visible to all members.</div>
            </div>
          }
          onConfirm={onUnarchive}
        />
      );
    };

    const onSubmit = (data: FormData) => {
      if (!data.workspaceID) return;

      const { description, emoji, joinableBy, chatMessageableBy, name, workspaceID } = data;

      const members = [
        ...memberRoles(data.admins, MemberRoleValue.Admin),
        ...memberRoles(data.members, MemberRoleValue.Default),
      ];

      return onSave({
        description,
        joinableBy,
        chatMessageableBy,
        members,
        name: formatNameEmoji({ name: `${emoji}${name}` }).nameWithEmoji,
        workspaceID,
        messageableBy:
          group.messageableBy === MessageableBy.Anyone
            ? MessageableBy.Anyone
            : chatMessageableBy === MessageableBy.Recipient
              ? MessageableBy.Workspace
              : chatMessageableBy,
      });
    };

    const hasMultipleAdmins =
      (group.members.edges.filter(e => e.memberRole === "admin").length || 0) > 1;

    const canDelete = isAdmin && !!group.id;
    const canArchive = group.archivedAt === null && isAdmin && !!group.id;
    const canUnarchive = group.archivedAt !== null && isAdmin && !!group.id;
    const canLeave = (!isAdmin || hasMultipleAdmins) && !!group.id;

    const { emoji, name } = formatNameEmoji({ name: group?.name });
    const members = group.members.edges;

    return (
      <Form
        className="h-full"
        onSubmit={onSubmit}
        useFormProps={{
          defaultValues: {
            name: name ?? "",
            description: group.description ?? "",
            emoji: emoji ?? "",
            joinableBy: group.joinableBy ?? JoinableBy.Workspace,
            chatMessageableBy: group.chatMessageableBy ?? MessageableBy.Recipient,
            members:
              members
                .filter(({ memberRole }) => memberRole !== MemberRoleValue.Admin)
                .map(e => e.node) ?? [],
            admins:
              members
                .filter(({ memberRole }) => memberRole === MemberRoleValue.Admin)
                .map(e => e.node) ?? (authData ? [authData.me] : []),
            workspaceID: group.workspaceID ?? undefined,
          },
          mode: "onTouched",
        }}
        initialFocusOnField={setFocusOnField}
      >
        <FormContent
          ref={ref}
          handleDeleteGroup={canDelete ? handleDeleteGroup : undefined}
          handleLeaveGroup={canLeave ? handleLeaveGroup : undefined}
          handleArchiveGroup={canArchive ? handleArchiveGroup : undefined}
          handleUnarchiveGroup={canUnarchive ? handleUnarchiveGroup : undefined}
          onSubmit={onSubmit}
          isAdmin={isAdmin}
        />
      </Form>
    );
  }
);

export default EditGroupForm;
