import { useContext, useEffect } from "react";
import { useChannelActionContext, useChatContext } from "stream-chat-react";

import { GlueDefaultStreamChatGenerics } from "@utility-types";
import { StreamClientContext } from "providers/StreamClientProvider";
import useRefreshChannelStore from "store/useRefreshChannelStore";
import env from "utils/processEnv";

const useRefreshChannelState = () => {
  const { streamClient } = useContext(StreamClientContext);
  const { channel: activeChannel } = useChatContext();
  const { dispatch } = useChannelActionContext<GlueDefaultStreamChatGenerics>();
  const channelID = useRefreshChannelStore(({ channelID }) => channelID);

  useEffect(() => {
    if (!channelID) return;
    const targetChannel = streamClient?.activeChannels[`${env.streamChannelType}:${channelID}`];
    if (targetChannel && activeChannel?.id === targetChannel.id) {
      dispatch({ channel: targetChannel, type: "copyStateFromChannelOnEvent" });
    }
  }, [activeChannel?.id, channelID, dispatch, streamClient?.activeChannels]);
};

export default useRefreshChannelState;
