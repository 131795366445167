import { ChatType, ThreadsOrder, usePersistentChatsQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";

import DirectMessagesList from "./DirectMessagesList";

const Recent = () => {
  const { authReady } = useAuthData();
  const { data, fetchMore, loading } = usePersistentChatsQuery({
    fetchPolicy: authReady ? "cache-first" : "cache-only",
    variables: {
      chatType: ChatType.User,
      last: Math.ceil(window.innerHeight / 64),
      order: ThreadsOrder.LastMessage,
    },
  });

  const pageInfo = data?.persistentChats.pageInfo;

  const loadMore = () => {
    if (!pageInfo?.startCursor) return;
    fetchMore({
      variables: { before: pageInfo.startCursor },
    });
  };

  return (
    <DirectMessagesList
      data={data?.persistentChats.edges.slice().reverse()}
      hasNextPage={!!pageInfo?.hasPreviousPage}
      loadMore={loadMore}
      loading={loading}
    />
  );
};

export default Recent;
