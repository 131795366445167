import { lazy, Suspense } from "react";

import { ModalProps } from "components/ModalKit/Modal";
import useShortcut from "hooks/useShortcut";
import useModalStore from "store/useModalStore";
import env from "utils/processEnv";

const OnboardingControlPanelModal = lazy(() => import("../OnboardingControlPanelModal"));

/**
 * This is a development only feature.
 * It allows exiting the onboarding flow and selecting a view to navigate to.
 * To open the modal, press `Ctrl+Meta+p`
 */
const useOnboardingControlPanel = () => {
  const { openModal } = useModalStore();

  const ControlPanelModal = ({ ...props }: ModalProps) => (
    <Suspense fallback={null}>
      <OnboardingControlPanelModal {...props} />
    </Suspense>
  );

  useShortcut(["Control", "Meta", "p"], () => {
    if (env.glueEnv === "production") return;
    openModal(<ControlPanelModal />);
  });
};

export default useOnboardingControlPanel;
