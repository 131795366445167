import tw from "utils/tw";

import AvatarPrimitive from "./Avatar/AvatarPrimitive";

const NotAMemberIcons = ({ background = "transparent" }: { background?: string }) => (
  <div className="icons relative">
    <AvatarPrimitive iconProps={{ icon: "Users", strokeWidth: 5 }} size={60} />
    <div className={tw("-ml-16 rounded-sm", background)}>
      <AvatarPrimitive iconProps={{ icon: "Lock", strokeWidth: 2 }} size={28} />
    </div>
  </div>
);

export default NotAMemberIcons;
