import { ComponentProps } from "react";

import { Icon } from "components/design-system/icons";
import { sidebarHeaderHeight } from "components/SideBar/SidebarHeader/SidebarHeader";
import useAppStateStore from "store/useAppStateStore";
import tw from "utils/tw";

import { TWPadding } from "./types/padding";

type Props = WithChildren<{
  padding?: TWPadding | `${TWPadding} ${TWPadding}`;
  primaryText?: string;
  secondaryText?: string;
  icon: ComponentProps<typeof Icon>["icon"];
  justifyContent?: "center" | "start";
}>;

export const EmptyListPrimitive = ({
  children,
  padding,
  primaryText,
  secondaryText,
  icon,
  justifyContent = "center",
}: Props) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  return (
    <div
      className={tw("flex flex-col items-center gap-16 h-full", padding, {
        "justify-start": justifyContent === "start",
        "justify-center": justifyContent === "center",
      })}
      style={{
        paddingBottom: breakpointMD ? `${sidebarHeaderHeight + 48}px` : undefined, // sidebar header height + find thread input height (48px)
      }}
    >
      <Icon size={40} className="text-icon-primary-selected" icon={icon} strokeWidth={2} />
      <div className="flex flex-col justify-center items-center text-center">
        {primaryText && <span className="text-body-bold text-text-primary">{primaryText}</span>}
        {secondaryText && <span className="text-body text-text-secondary">{secondaryText}</span>}
      </div>
      {children}
    </div>
  );
};
