import { addDays, addHours, endOfDay, setMinutes, setSeconds } from "date-fns";
import { useCallback, useRef, useState } from "react";

import { ThreadEdgeSimple } from "@utility-types";
import { Button } from "components/design-system/Button";
import { DateTimePickerWheel } from "components/design-system/Forms";
import Hr from "components/design-system/Hr";
import { Footer, Header, Main } from "components/ModalKit";
import { ModalProps } from "components/ModalKit/Modal";
import { StandardModal } from "components/Modals";
import useInboxThreadActions from "hooks/thread/useInboxThreadActions";
import useModalStore from "store/useModalStore";
import tw from "utils/tw";

import { ReminderOption, getReminderDate, reminderOptionLabels, reminderOptions } from "./common";

type RemindMeOptionProps = {
  label: string;
  className?: string;
  onClick: () => void;
};

const getInitialCustomDate = (now: Date) => {
  const result = setSeconds(setMinutes(now, 0), 0);

  return now.getMinutes() < 50 ? addHours(result, 1) : addHours(result, 2);
};

const RemindMeOption = ({ label, className, onClick }: RemindMeOptionProps) => {
  return (
    <div
      className={tw("flex items-center self-stretch w-fill py-6 px-16 h-44", className)}
      onClick={onClick}
    >
      {label}
    </div>
  );
};

const ThreadReminderModalSection = ({ children }: WithChildren) => {
  return <div className="flex flex-col">{children}</div>;
};

type ThreadReminderMobileModalProps = ModalProps & {
  threadEdge: ThreadEdgeSimple;
};

const ThreadReminderModalMobile = ({ threadEdge, ...props }: ThreadReminderMobileModalProps) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [customDate, setCustomDate] = useState<Date | null>(null);
  const { closeModal } = useModalStore(({ closeModal }) => ({ closeModal }));
  const { markThreadEdges } = useInboxThreadActions();

  // Keep reference dates static to prevent rerenders as "current date" changes
  const now = useRef(new Date());
  const minReminderDate = useRef(now.current);
  const maxReminderDate = useRef(endOfDay(addDays(now.current, 90)));
  const initialCustomDate = useRef(getInitialCustomDate(now.current));

  const saveReminder = useCallback(
    (time: Date) => {
      markThreadEdges({
        selection: { threadEdges: [threadEdge] },
        remindAt: time.toISOString(),
      });
    },
    [markThreadEdges, threadEdge]
  );

  const handleOptionClick = useCallback(
    (option: ReminderOption) => {
      switch (option) {
        case ReminderOption.CUSTOM:
          setShowDatePicker(v => !v);
          break;
        default:
          saveReminder(getReminderDate(option));
          closeModal();
          break;
      }
    },
    [closeModal, saveReminder]
  );

  const handleRemoveReminder = useCallback(() => {
    markThreadEdges({
      selection: { threadEdges: [threadEdge] },
      remindAt: null,
    });
    closeModal();
  }, [closeModal, markThreadEdges, threadEdge]);

  const handleCustomDateChange = useCallback((date: Date) => setCustomDate(date), []);

  const handleSubmitCustomDate = useCallback(
    (customDate: Date) => {
      saveReminder(customDate);
      closeModal();
    },
    [saveReminder, closeModal]
  );

  return (
    <StandardModal
      {...props}
      contentClassName="select-none pb-32"
      closeButtonPosition="right"
      heightAuto
    >
      <Header variant="bordered">Remind me</Header>
      <Main className="py-8">
        <ThreadReminderModalSection>
          {reminderOptions
            .filter(({ value }) => value !== ReminderOption.CUSTOM)
            .map(({ label, value }) => (
              <RemindMeOption key={value} label={label} onClick={() => handleOptionClick(value)} />
            ))}
        </ThreadReminderModalSection>
        <Hr className="w-full my-8" />
        <ThreadReminderModalSection>
          {!!threadEdge.remindAt && (
            <RemindMeOption
              className="text-text-alert"
              label="Remove reminder"
              onClick={handleRemoveReminder}
            />
          )}
          <RemindMeOption
            label={reminderOptionLabels[ReminderOption.CUSTOM]}
            onClick={() => handleOptionClick(ReminderOption.CUSTOM)}
          />
        </ThreadReminderModalSection>
        {showDatePicker && (
          <div className="py-8">
            <DateTimePickerWheel
              min={minReminderDate}
              max={maxReminderDate}
              initialValue={initialCustomDate.current}
              minuteInterval={15}
              onValueChange={handleCustomDateChange}
              height={160}
            />
          </div>
        )}
      </Main>
      {showDatePicker && (
        <Footer>
          <Button
            buttonStyle="primary"
            type="submit"
            className="flex items-center justify-center"
            onClick={() => customDate && handleSubmitCustomDate(customDate)}
          >
            Set reminder
          </Button>
        </Footer>
      )}
    </StandardModal>
  );
};

export default ThreadReminderModalMobile;
