import { useCombobox } from "downshift";
import { useEffect, useState } from "react";
import { FieldValues, Path, PathValue, useFormContext } from "react-hook-form";

import usePrevious from "hooks/usePrevious";
import tw from "utils/tw";

import { Icon } from "../icons";
import { TWMaxHeight } from "../types/height";

import { Label } from "./Label";
import { ComboBoxProps, Option } from "./types";

const ComboBox = <TFieldValues extends FieldValues>({
  className,
  config,
  formatInput,
  label,
  labelClassName,
  maxHeight = "max-h-[256px]",
  name,
  options,
  placeholder,
  testId,
  wrapperClassName,
  ...props
}: ComboBoxProps<TFieldValues> & {
  formatInput?: (inputValue: string) => string;
  maxHeight?: TWMaxHeight;
  testId?: string;
}) => {
  const {
    formState: { errors, isSubmitting },
    register,
    setValue,
    watch,
  } = useFormContext<TFieldValues>();

  const disabled = props.disabled || isSubmitting;
  const hasError = !!errors[name];
  const { ref, ...registerProps } = register(name, {
    ...config,
  });
  const value = watch(name);
  const prevValue = usePrevious(value);

  const getOptionsFilter = (inputValue: string) => {
    const lowerCasedInputValue = inputValue.toLowerCase();
    const formattedInput = formatInput ? formatInput(lowerCasedInputValue) : lowerCasedInputValue;

    return function optionsFilter(option: Option) {
      return !inputValue || option.label?.toLowerCase().includes(formattedInput);
    };
  };

  const clearValue = () => {
    setValue(name, "" as PathValue<TFieldValues, Path<TFieldValues>>, {
      shouldDirty: true,
    });
  };

  const [filteredOptions, setFilteredOptions] = useState(options);

  const {
    getInputProps,
    getItemProps,
    getLabelProps,
    getMenuProps,
    getToggleButtonProps,
    highlightedIndex,
    isOpen,
    selectedItem,
    selectItem,
  } = useCombobox({
    defaultHighlightedIndex: 0,
    initialHighlightedIndex: 0,
    initialInputValue: options.find(option => option.value === value)?.label ?? "",
    initialSelectedItem: options.find(option => option.value === value),
    items: filteredOptions,
    itemToString: option => {
      return option ? (option.label ?? option.value) : "";
    },
    onInputValueChange: ({ inputValue }) => {
      setFilteredOptions(options.filter(getOptionsFilter(inputValue)));
      if (inputValue === "" && value) {
        clearValue();
      }
    },
    onSelectedItemChange: ({ selectedItem }) => {
      setValue(name, selectedItem?.value as PathValue<TFieldValues, Path<TFieldValues>>, {
        shouldDirty: true,
      });
    },
  });

  useEffect(() => {
    // updates ComboBox when form value changes from outside, via `setValue`
    if (prevValue === value || selectedItem?.value === value) return;
    selectItem(options.find(option => option.value === value) ?? null);
  }, [options, prevValue, selectItem, selectedItem?.value, value]);

  return (
    <div className={tw("flex flex-col my-20 relative", wrapperClassName)}>
      <input id={name} type="hidden" {...registerProps} />

      {label && (
        <Label className={labelClassName} htmlFor={getLabelProps().htmlFor} {...getLabelProps()}>
          {label}
        </Label>
      )}

      <div
        className={tw(
          "flex items-center border border-border-container rounded select-none cursor-pointer h-36",
          "bg-background", // TODO: remove this once we've updated the base.css stylesheet to use the correct background color (bg-background-body) GLU-927
          className,
          {
            "border-border-alert": hasError,
            "border-border-active": isOpen,
            "!bg-background-ghost": disabled,
            "!cursor-default": disabled,
          }
        )}
      >
        <div className="flex gap-10 items-center grow min-w-0 h-full px-10">
          <input
            autoComplete="off"
            className="bg-transparent grow min-w-0 h-full text-body disabled:bg-background-ghost"
            disabled={disabled}
            placeholder={placeholder}
            type="text"
            {...getInputProps()}
          />

          <div className="flex gap-8 items-center">
            {!disabled && value && (
              <button
                aria-label="toggle menu"
                data-testid="clear-button"
                disabled={disabled}
                onClick={() => {
                  selectItem(null);
                  clearValue();
                }}
                tabIndex={-1}
                type="button"
              >
                <Icon
                  className="text-icon-secondary hover:text-icon-secondary-hover"
                  icon="Close"
                  size="20"
                />
              </button>
            )}
            <button
              aria-label="toggle menu"
              data-testid="combobox-toggle-button"
              disabled={disabled}
              type="button"
              {...getToggleButtonProps()}
            >
              <Icon
                className="text-icon-secondary"
                icon={isOpen ? "DropUp" : "DropDown"}
                size="20"
              />
            </button>
          </div>
        </div>

        <ul
          className={tw(
            "bg-background-body border-1 border-border-container overflow-y-auto rounded-md shadow-level2 absolute top-full right-0 left-0 z-1",
            maxHeight,
            {
              hidden: !(isOpen && filteredOptions.length),
            }
          )}
          {...getMenuProps()}
        >
          {isOpen &&
            filteredOptions.map((option, index) => (
              <li
                key={`${JSON.stringify(option)}`}
                className={tw("p-8 hover:bg-background-list-hover cursor-pointer flex gap-8", {
                  "bg-background-list-hover": highlightedIndex === index,
                })}
                {...getItemProps({
                  item: option,
                  index,
                })}
              >
                {option.label ?? option.value}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default ComboBox;
