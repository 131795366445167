import { devtools, persist } from "zustand/middleware";

import { EditorMessage } from "components/MessageEditor/types";
import { glueCreateZustand } from "store/helper/glueCreate";

const STORE_NAME = "DraftMessagesStore";

type Draft = {
  draftID?: string;
  threadID: string;
  message?: EditorMessage;
};

type DraftMessagesStore = {
  addDraft: (draft: Draft) => void;
  drafts: Record<string, Draft>;
  getDraft: (threadID: string) => Draft | undefined;
  removeDraft: ({ threadID, draftID }: { threadID?: string; draftID?: string }) => void;
};

const useDraftMessagesStore = glueCreateZustand<DraftMessagesStore>({
  name: STORE_NAME,
})(
  devtools(
    persist(
      (set, get) => ({
        addDraft: draft =>
          set(state => {
            state.drafts[draft.threadID] = {
              ...state.drafts[draft.threadID],
              ...draft,
            };

            return { drafts: state.drafts };
          }),
        drafts: {},
        getDraft: threadID => get().drafts[threadID],
        removeDraft: ({ threadID, draftID }) =>
          set(state => {
            if (threadID) {
              delete state.drafts[threadID];
              return { drafts: state.drafts };
            }

            const foundThreadID = Object.values(state.drafts).find(
              d => d.draftID === draftID
            )?.threadID;
            foundThreadID && delete state.drafts[foundThreadID];

            return { drafts: state.drafts };
          }),
      }),
      {
        name: STORE_NAME,
        version: 0,
      }
    ),
    {
      name: STORE_NAME,
      serialize: true,
    }
  )
);

export default useDraftMessagesStore;
