import {
  CSSProperties,
  ComponentProps,
  MouseEventHandler,
  MutableRefObject,
  RefCallback,
  useMemo,
  useRef,
} from "react";
import { FieldValues, useFormContext } from "react-hook-form";

import { variantProps } from "components/helper/classNameVariants";
import useElementTextWidth from "hooks/useElementTextWidth";
import tw from "utils/tw";

import { Button } from "../Button";
import { Tooltip, TooltipProps } from "../FloatingUi";

import Error from "./Error";
import useRequiredErrors from "./hooks/useRequiredErrors";
import { Label } from "./Label";
import { TextInputProps } from "./types";

type InputIconProps = Pick<
  ComponentProps<typeof Button>,
  "icon" | "iconClassName" | "iconSize" | "iconStroke"
> & {
  iconPosition?: "start" | "end" | "trailing";
  iconTooltip?: string;
  iconOnClick?: MouseEventHandler;
  iconStyle?: CSSProperties;
  iconTooltipPlacement?: TooltipProps["placement"];
  iconTooltipStyle?: TooltipProps["tooltipStyle"];
};

const TextInputIcon = ({
  iconClassName,
  iconPosition,
  iconTooltip,
  iconOnClick,
  iconStyle,
  iconTooltipPlacement = "top",
  iconTooltipStyle,
  ...props
}: InputIconProps) => (
  <Tooltip
    disabled={!iconTooltip}
    content={iconTooltip}
    placement={iconTooltipPlacement}
    tooltipStyle={iconTooltipStyle}
  >
    <Button
      className={tw(
        "absolute text-icon-secondary",
        { "left-0 ml-8": iconPosition === "start" },
        { "right-0 mr-8": iconPosition === "end" },
        { "cursor-default": !iconOnClick },
        iconClassName
      )}
      buttonType="icon"
      buttonStyle="none"
      onClick={iconOnClick}
      style={iconStyle}
      type="button"
      {...props}
    />
  </Tooltip>
);

type Props<TFieldValues extends FieldValues> = TextInputProps<TFieldValues> &
  Partial<InputIconProps> & {
    disableArrowShortcuts?: boolean;
    error?: JSX.Element | string;
    inlineError?: boolean;
    inputRef?: MutableRefObject<HTMLInputElement | null> | RefCallback<HTMLInputElement | null>;
    variant?: "default" | "line";
    testId?: string;
  };

export const TextInput = <TFieldValues extends FieldValues>({
  className,
  config,
  disableArrowShortcuts = false,
  error,
  hideErrorMessage,
  inlineError,
  icon,
  iconClassName,
  iconOnClick,
  iconPosition = "start",
  iconSize = 16,
  iconStroke,
  iconTooltip,
  inputRef: inputRefProp,
  label,
  labelClassName,
  name,
  onBlur,
  onChange,
  placeholder,
  testId,
  type = "text",
  variant = "default",
  wrapperClassName,
  iconTooltipStyle,
  iconTooltipPlacement,
  ...props
}: Props<TFieldValues>) => {
  const {
    formState: { errors, isSubmitting },
    register,
    watch,
  } = useFormContext<TFieldValues>();

  const disabled = props.disabled || isSubmitting;

  const { ref, ...registerProps } = register(name, {
    ...config,
    onBlur,
    onChange,
  });

  useRequiredErrors({ name });

  const inputRef = useRef<HTMLInputElement | null>(null);
  const { measureText } = useElementTextWidth(inputRef);
  const value = watch(name);

  const { inputText, inputTextWidth, inputWidth } = useMemo(() => {
    const inputText = value || placeholder || "";
    return {
      inputText,
      inputTextWidth: measureText(inputText),
      inputWidth: inputRef.current?.clientWidth || 0,
    };
  }, [value, placeholder, measureText, inputRef]);

  const iconStyle: CSSProperties = {};
  const inputStyle: CSSProperties = {};

  if (icon) {
    switch (iconPosition) {
      case "start":
        inputStyle.paddingLeft = 12 + iconSize;
        break;
      case "end":
        inputStyle.paddingRight = 12 + iconSize;
        break;
      case "trailing":
        if (inputText.length > 0 && inputTextWidth === 0) {
          // hide until text is measured
          iconStyle.display = "none";
          break;
        }
        const paddingRight = 12 + iconSize;
        iconStyle.left = Math.min(inputTextWidth + 16, inputWidth - iconSize - 8);
        inputStyle.paddingRight = paddingRight;
        inputStyle.textOverflow = "ellipsis";
        break;
    }
  }

  const variantClassNames = {
    base: tw("box-border w-full focus:outline-none", {
      "cursor-default": disabled,
    }),
    variants: {
      variant: {
        default: tw("p-10 disabled:bg-background-ghost rounded border border-border-container", {
          "!border-accent-alert": !!errors[name] || !!error,
        }),
        line: tw(
          "border-t-none border-x-none border-b border-border-container",
          "active:border-border-active focus-visible:border-border-active",
          "bg-transparent disabled:border-border-container",
          "rounded-none py-10 px-0",
          { "!border-border-alert": !!errors[name] || !!error }
        ),
      },
    },
  };
  const inputProps = variantProps(variantClassNames);

  return (
    <div
      className={tw(
        "flex flex-col my-20",
        { "justify-center relative": !!icon },
        { "!my-0": variant === "line" },
        wrapperClassName
      )}
    >
      {label && (
        <Label className={labelClassName} htmlFor={name}>
          {label}
        </Label>
      )}
      <input
        {...inputProps({ variant, className })}
        id={name}
        data-testid={testId}
        disabled={disabled}
        placeholder={placeholder}
        style={{
          ...inputStyle,
          ...props.style,
        }}
        type={type}
        {...props}
        {...registerProps}
        ref={e => {
          ref(e);
          inputRef.current = e;
          if (inputRefProp && "current" in inputRefProp) {
            inputRefProp.current = e;
          } else {
            inputRefProp?.(e);
          }
        }}
        onKeyDown={e => {
          props.onKeyDown?.(e);
          disableArrowShortcuts &&
            (e.key === "ArrowUp" || e.key === "ArrowDown") &&
            e.preventDefault();
        }}
      />

      {icon ? (
        <TextInputIcon
          icon={icon}
          iconClassName={iconClassName}
          iconOnClick={iconOnClick ?? props.onClick}
          iconPosition={iconPosition}
          iconSize={iconSize}
          iconStroke={iconStroke}
          iconTooltip={iconTooltip}
          iconStyle={iconStyle}
          iconTooltipStyle={iconTooltipStyle}
          iconTooltipPlacement={iconTooltipPlacement}
        />
      ) : null}
      {(errors[name] || error) && !hideErrorMessage && (
        <div className={tw({ "absolute bottom-0 -mb-20": !inlineError })}>
          <Error customError={error} name={name} />
        </div>
      )}
    </div>
  );
};
