import { useState } from "react";

import type { WorkspaceAppEdge } from "@utility-types";
import WebhookAvatar from "assets/apps/webhook-avatar.png";
import LambdaManager from "components/apps/lambdas/LambdaManager";
import Avatar from "components/design-system/Avatar/Avatar";
import {
  CopyBox,
  Form,
  InputButton,
  SubmitButton,
  TextInput,
} from "components/design-system/Forms";
import { Icon } from "components/design-system/icons";
import { useModalState } from "components/ModalKit/ModalProvider";
import { Footer, Header, Main } from "components/ModalKit/Parts";
import { ConfirmationAlert, StandardModal } from "components/Modals";
import { GLUE_DOCS } from "constants/glue-docs";
import { FeatureFlag, useFeatureFlagEnabled } from "featureFlags";
import {
  FetchWorkspaceOrPreviewEdgeDocument,
  MemberRole,
  useCreateWorkspaceAppMutation,
  useDeleteAppMutation,
  useUpdateAppMutation,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useModalStore from "store/useModalStore";

type FormData = {
  description: string;
  name: string;
};

type Props = {
  workspaceApp?: WorkspaceAppEdge;
  workspaceID?: string;
};

const EditWebhookModal = ({ workspaceApp, workspaceID }: Props) => {
  const { modalId } = useModalState(({ modalId }) => ({
    modalId,
  }));
  const { closeModal, openModal } = useModalStore(({ closeModal, openModal }) => ({
    closeModal,
    openModal,
  }));

  const [disabled, setDisabled] = useState(false);

  const [app, setApp] = useState(workspaceApp);

  const [createWorkspaceApp] = useCreateWorkspaceAppMutation();

  const [deleteApp] = useDeleteAppMutation();
  const [updateApp] = useUpdateAppMutation();

  const isLambdasEnabled = useFeatureFlagEnabled(FeatureFlag.LAMBDAS);

  // need authData to determine if a user is the creator of an app
  // or is an admin of the app workspace, which lets a user edit lambdas
  // for the app.
  const { authData } = useAuthData();
  const workspaceEdge = authData?.workspaces.edges.find(edge => edge.node.id === workspaceID);
  const isAdmin = workspaceEdge?.memberRole === MemberRole.Admin;

  const handleDelete = async () => {
    if (!app) return;

    setDisabled(true);

    openModal(
      <ConfirmationAlert
        afterClose={() => modalId && setDisabled(false)} // should only fire on Cancel
        confirmLabel="Delete"
        header={`Delete the "${app.node.name}" webhook?`}
        onConfirm={() =>
          deleteApp({
            refetchQueries: [FetchWorkspaceOrPreviewEdgeDocument],
            variables: {
              id: app.node.id,
            },
          })
            .then(() => closeModal(`${modalId}`))
            .catch(() => setDisabled(false))
        }
        isDestructive
      />
    );
  };

  const handleFormSubmit = (data: FormData) => {
    if (!workspaceID) return;

    if (app) {
      return updateApp({
        refetchQueries: [FetchWorkspaceOrPreviewEdgeDocument],
        variables: {
          id: app.node.id,
          input: {
            ...data,
            workspaceID,
          },
        },
      }).then(() => closeModal(`${modalId}`));
    }

    return createWorkspaceApp({
      awaitRefetchQueries: true,
      refetchQueries: [FetchWorkspaceOrPreviewEdgeDocument],
      variables: {
        input: {
          ...data,
          workspaceID,
        },
      },
    }).then(({ data }) => {
      setApp(data?.createWorkspaceApp);
    });
  };

  return (
    <StandardModal>
      <Header variant="bordered">
        <h3 className="m-0">{app ? "Edit" : "Setup a"} Webhook</h3>
      </Header>
      <Form
        onSubmit={handleFormSubmit}
        useFormProps={{
          defaultValues: {
            description: app?.node.description,
            name: app?.node.name,
          },
        }}
      >
        <Main className="p-16 md:px-32">
          <div className="flex">
            <div className="w-95">
              <Avatar avatarURL={WebhookAvatar} size="x-large" />
            </div>
            <div className="flex-col grow">
              <TextInput<FormData>
                className="w-1/2"
                config={{ required: true }}
                disabled={disabled}
                label="Name"
                labelClassName="text-interactive-strong"
                name="name"
                wrapperClassName="mt-0 mb-20"
              />
              <TextInput<FormData>
                disabled={disabled}
                label="Description"
                labelClassName="text-interactive-strong"
                name="description"
                wrapperClassName="mt-0 mb-20"
              />
            </div>
          </div>

          {app && (
            <>
              <CopyBox
                content={`${app.webhooks?.[0]?.url}`}
                label="Webhook URL"
                wrapperClassName="mt-0 mb-0"
              />
              <p className="mt-8 mb-20 italic text-text-subtle">
                Important: webhook URLs contain a secure token; do not expose publicly.
              </p>
            </>
          )}

          <div className="flex items-center px-15 mb-16 font-message text-sm leading-normal bg-background-ghost rounded-md">
            <Icon className="shrink-0 mr-15" icon="Info" />
            <p>
              Webhooks allow you to create Glue threads and/or send messages by making a simple HTTP
              POST request to a secure webhook endpoint. Explore our{" "}
              <a
                className="hover:text-interactive-primary-hover text-interactive-primary"
                href={GLUE_DOCS.WEBHOOKS_URL}
                rel="noreferrer"
                target="_blank"
              >
                webhook documentation
              </a>{" "}
              to learn more.
            </p>
          </div>
          {isLambdasEnabled && app?.node.id && (app.creator.id === authData?.me.id || isAdmin) && (
            <LambdaManager appId={app?.node.id} />
          )}
        </Main>
        <Footer flexboxClassName={app ? "justify-between" : "justify-end"}>
          {app && (
            <InputButton
              buttonStyle="simpleDestructive"
              buttonType="text"
              disabled={disabled}
              icon="Trash"
              onClick={handleDelete}
            >
              Delete Webhook
            </InputButton>
          )}

          <SubmitButton>{app ? "Save Webhook" : "Create Webhook"}</SubmitButton>
        </Footer>
      </Form>
    </StandardModal>
  );
};

export default EditWebhookModal;
