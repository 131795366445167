import throttle from "lodash-es/throttle";
import { useEffect, useRef, useState } from "react";
import Observer from "resize-observer-polyfill";

import useNativeHaptics from "hooks/native/useNativeHaptics";
import useNativeKeyboardStore from "store/useNativeKeyboardStore";
import { isNativeIOS } from "utils/platform";
import tw from "utils/tw";

import { FormattingBar } from "../FormattingBar";
import { FormattingBarToggleButton } from "../FormattingBarToggleButton";

type Props = {
  buttonBarSections: JSX.Element[];
  sendButton?: JSX.Element;
};

const PrimaryButtonBar = ({ buttonBarSections, sendButton }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [showAllButtons, setShowAllButtons] = useState(true);
  const [showFormatBar, setShowFormatBar] = useState(false);
  const { lightImpactHaptic } = useNativeHaptics();

  useEffect(() => {
    const { current: el } = ref;

    const handler = throttle(
      () => {
        const showAll = (el && el.offsetWidth > 458) || false;
        setShowAllButtons(showAll);

        showAll && setShowFormatBar(false);
      },
      500,
      {
        leading: true,
        trailing: true,
      }
    );

    const observer = new Observer(handler);

    el && observer.observe(el);

    handler();

    return () => {
      handler.cancel();

      el && observer.unobserve(el);
    };
  });

  const [nativeKeyboardOpen, setNativeKeyboardOpen] = useState(false);
  useEffect(
    () =>
      useNativeKeyboardStore.subscribe(({ state, keyboardHeight }) => {
        if (isNativeIOS()) {
          setNativeKeyboardOpen(keyboardHeight > 100);
          return;
        }

        setNativeKeyboardOpen(state === "opening" || state === "open");
      }),
    []
  );

  const SectionDivider = () => <div className="my-4 mx-10 w-1 h-24 bg-border-container" />;

  return (
    <div
      ref={ref}
      className={tw(
        "pb-10 z-0",
        "[&_svg]:backface-visibility-hidden [&_svg]:transform-box-fill [&_svg]:transform-gpu",
        "flex native:hidden native:modal:!flex",
        {
          "native:!flex": nativeKeyboardOpen,
        }
      )}
    >
      {!showAllButtons && (
        <FormattingBarToggleButton
          setToggleFormatBar={show => {
            lightImpactHaptic();
            setShowFormatBar(show);
          }}
          show={showFormatBar}
        />
      )}
      <FormattingBar show={showFormatBar} showAllButtons={showAllButtons} />

      <div
        className={tw(
          "flex min-w-0",
          {
            hidden: !showAllButtons && showFormatBar,
          },
          {
            "absolute ml-40": !showAllButtons,
          }
        )}
      >
        <SectionDivider />
        {buttonBarSections.map((buttonBarSection, i, arr) => {
          const key = `${buttonBarSection}-${i}`;
          return (
            <div className="flex h-28 min-w-0" key={key}>
              {buttonBarSection}
              {arr.length > 1 && i + 1 < arr.length && <SectionDivider />}
            </div>
          );
        })}
      </div>
      <div className="pr-auto z-0 ml-auto">{sendButton}</div>
    </div>
  );
};

export default PrimaryButtonBar;
