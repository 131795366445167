import { isReference, relayStylePagination } from "@apollo/client/utilities";
import { Kind } from "graphql";

import { GroupEdge, GroupPreview, UnreadCounts } from "@utility-types";
import { StrictTypedTypePolicies } from "generated/graphql-apollo-helpers";
import {
  AuthConfigDocument,
  AuthConfigQuery,
  GroupEdgeSimpleFieldsFragmentDoc,
  GroupPreviewSimpleFieldsFragmentDoc,
} from "generated/graphql-operations";
import { MemberRole, Node, ThreadsOrder } from "generated/graphql-types";
import useLocalSettingsStore from "store/useLocalSettingsStore";
import isEnumValue from "utils/isEnumValue";
import formatThreadEdgeCursor from "utils/thread/formatThreadEdgeCursor";

import { searchGroups, searchThreads, searchUsers, searchWorkspaces } from "./search";

const idPrefixTypeMapping: Record<string, string[]> = {
  adr: ["Address"],
  app: ["App"],
  dft: ["Draft"],
  emj: ["CustomEmoji"],
  grp: ["Group", "GroupMember"],
  msg: ["Message"],
  ntf: ["Notification"],
  thr: ["Thread", "ThreadRecipient"],
  usr: ["User"],
  wap: ["WorkspaceApp"],
  wks: ["Workspace", "WorkspaceMember", "GroupsByWorkspace"],
};

export const typePolicies: StrictTypedTypePolicies = {
  Draft: {
    fields: {
      recipients: {
        merge: false,
      },
    },
  },
  Group: {
    fields: {
      // Temporary fix for issue with @connection and variables https://github.com/apollographql/apollo-client/issues/11099
      members: relayStylePagination((args, { fieldName }) =>
        args?.filter?.match ? `${fieldName}:match` : false
      ),
      pendingApprovals: relayStylePagination((args, { fieldName }) =>
        args?.filter?.match ? `${fieldName}:match` : false
      ),
    },
  },
  GroupPreview: {
    fields: {
      members: relayStylePagination(),
    },
  },
  GroupOrPreviewEdge: {
    fields: {
      memberRole: (value, { cache, readField }) => {
        if (value) return value; // If there is already a value, return it

        // See if we already have a group edge in the cache and return its member role
        const id = readField<string>("id");

        const groupEdge = cache.readFragment<GroupEdge>({
          fragment: GroupEdgeSimpleFieldsFragmentDoc,
          fragmentName: "GroupEdgeSimpleFields",
          id: `GroupEdge:${id}`,
          optimistic: true,
        });

        if (groupEdge?.memberRole) return groupEdge.memberRole;

        // Check for a group preview and return the admin role if it's in a workspace the user is an admin of
        const groupId = id?.split("-")[0];

        const groupPreview = cache.readFragment<GroupPreview>({
          fragment: GroupPreviewSimpleFieldsFragmentDoc,
          fragmentName: "GroupPreviewSimpleFields",
          id: `GroupPreview:${groupId}`,
          optimistic: true,
        });

        const authData = cache.readQuery<AuthConfigQuery>({
          query: AuthConfigDocument,
          optimistic: true,
        });

        const workspace = authData?.workspaces.edges.find(
          edge => edge.node.id === groupPreview?.workspaceID
        );

        if (workspace?.memberRole === MemberRole.Admin) return MemberRole.Admin;

        return MemberRole.Default; // otherwise return the default role
      },
    },
  },
  GroupsByWorkspace: {
    fields: {
      groups: relayStylePagination(["filter", ["unreadThreads", "unseenThreads"], "order"]),
    },
  },
  Message: {
    fields: {
      ownReactions: {
        merge: false,
      },
    },
  },
  MessageMetadata: {
    fields: {
      aiResponseInfo: {
        merge: true,
      },
    },
  },
  Query: {
    fields: {
      customEmojis: relayStylePagination(),
      drafts: relayStylePagination(),
      groups: relayStylePagination([
        "filter",
        ["match", "unreadThreads", "workspaceID"],
        "order",
        "@connection",
        ["key"],
      ]),
      groupsByWorkspace: relayStylePagination(),
      joinApprovals: relayStylePagination(["filter", "joinableID"]),
      joinRequests: relayStylePagination(["filter", "joinableID"]),
      links: relayStylePagination(["filter", ["recipientIDs", "linkedCategories"]]),
      localGroups: {
        read(_, fieldOptions) {
          const edges = searchGroups(fieldOptions);
          return {
            __typename: "GroupConnection",
            edges,
            totalCount: edges.length,
          };
        },
      },
      localThreads: {
        read(_, fieldOptions) {
          const edges = searchThreads(fieldOptions);
          return {
            __typename: "ThreadConnection",
            edges,
            // TODO: local message search
            matchedMessages: {
              __typename: "MessageConnection",
              edges: [],
              totalCount: 0,
            },
            totalCount: edges.length,
          };
        },
      },
      localUsers: {
        read(_, fieldOptions) {
          const edges = searchUsers(fieldOptions);
          return {
            __typename: "UserConnection",
            edges,
            totalCount: edges.length,
          };
        },
      },
      localWorkspaces: {
        read(_, fieldOptions) {
          const edges = searchWorkspaces(fieldOptions);
          return {
            __typename: "WorkspaceConnection",
            edges,
            totalCount: edges.length,
          };
        },
      },
      messagePins: relayStylePagination(["filter", ["threadID"]]),
      node(existing, { args, canRead, field, toReference }) {
        const id = args?.id;
        const prefix = id?.match(/^(\S{3})_\S+/);
        if (!prefix) {
          console.warn(`no ID prefix: '${id}'`);
          return null; // skip network fetch
        }

        // check if type condition is in selection set
        const queryingType = (typename: string) =>
          !!field?.selectionSet?.selections.find(
            s => s.kind === Kind.INLINE_FRAGMENT && s.typeCondition?.name.value === typename
          );

        // Ensure we return full types when available and
        // don't return preview type if not querying for it.
        if (isReference(existing)) {
          const type = existing.__ref.split(":")[0] || "";
          if (queryingType(type) && !type.includes("Preview")) {
            return existing;
          }

          const fullType = type.replace("Preview", "");
          const fullRef = toReference({ __typename: fullType, id });
          if (queryingType(fullType) && canRead(fullRef)) {
            return fullRef;
          }
          if (queryingType(type)) {
            return existing;
          }
        }

        const typenames = idPrefixTypeMapping[prefix[1] as string];
        if (!typenames) {
          console.warn(`no type policy for ID with prefix: '${prefix[1]}'`);
          return;
        }

        const edge = id?.match(/-/) ? "Edge" : "";

        return typenames
          .flatMap(typename => [typename + edge, `${typename}Preview${edge}`])
          .filter(queryingType)
          .map(__typename => toReference({ __typename, id }))
          .find(canRead);
      },
      notifications: relayStylePagination(["filter", ["feedType"]]),
      persistentChats: relayStylePagination(["filter", ["chatType"], "order"]),
      settings: {
        merge: true,
      },
      threads: relayStylePagination([
        "filter",
        ["excludePersistentChats", "excludeStarred", "mailbox", "recipientID"],
        "@connection",
        ["key"],
      ]),
      users: relayStylePagination([
        "filter",
        [
          "match",
          "addressIDs",
          "edgeStatus",
          "excludeIDs",
          "mutualGroups",
          "mutualThreads",
          "mutualWorkspaces",
          "workspaceIDs",
        ],
        "order",
        "@connection",
        ["key"],
      ]),
      workspaces: relayStylePagination(["@connection", ["key"]]),
    },
  },
  Thread: {
    fields: {
      messages: relayStylePagination((_args, context) => {
        return context.field?.alias?.value || context.fieldName;
      }),
    },
  },
  ThreadEdge: {
    fields: {
      // Because cursors are dynamic based on sort setting,
      // we need to override to add the correct prefix
      cursor: (cursor, { readField, variables: { order } = {} }) => {
        if (!cursor) return;
        const threadOrder = isEnumValue(order, ThreadsOrder)
          ? order
          : useLocalSettingsStore.getState().currentThreadSort;

        const isRead = readField<boolean>("isRead") ?? true;
        const isSeen = readField<boolean>("isSeen") ?? true;

        const node = readField<Node>("node");
        const threadID = readField<string>("id", node) ?? "";
        const lastMessage = readField<Node>("lastMessage", node);
        const lastMessageID = readField<string>("id", lastMessage);

        return formatThreadEdgeCursor(
          threadOrder,
          { cursor, isRead, isSeen },
          threadID,
          lastMessageID
        );
      },
      isMentioned: (_, { readField }) => {
        const counts = readField<UnreadCounts>("unreadMessageCounts");
        return (counts?.mentioned || 0) > 0;
      },
    },
  },
  ThreadMetadata: {
    fields: {
      aiSettings: {
        merge: true,
      },
    },
  },
  ThreadPreview: {
    fields: {
      recipients: relayStylePagination(),
    },
  },
  UnreadCounts: {
    merge: true,
  },
  Workspace: {
    fields: {
      groups: relayStylePagination([
        "filter",
        ["archived", "match", "member", "unreadThreads", "workspaceID"],
        "order",
      ]),
      // Temporary fix for issue with @connection and variables https://github.com/apollographql/apollo-client/issues/11099
      members: relayStylePagination((args, { fieldName }) =>
        args?.filter?.match
          ? `${fieldName}:match`
          : args?.filter?.memberRole
            ? `${fieldName}:memberRole`
            : false
      ),
      pendingApprovals: relayStylePagination((args, { fieldName }) =>
        args?.filter?.match ? `${fieldName}:match` : false
      ),
    },
  },
  WorkspacePreview: {
    fields: {},
  },
};

export default typePolicies;
