import { useState } from "react";

import { Button } from "components/design-system/Button";
import { Icon } from "components/design-system/icons";
import {
  FetchLlmModelsDocument,
  FetchWorkspaceSettingsDocument,
  LlmProvider,
  useCreateLlmapiKeyMutation,
  useDeleteLlmapiKeyMutation,
} from "generated/graphql";

type Props = {
  id?: string;
  keyPreview?: string;
  provider: LlmProvider;
  iconSrc: string;
  name: string;
  workspaceID: string;
};

export const ProviderApiKeyInput = ({
  id,
  provider,
  iconSrc,
  name,
  workspaceID,
  keyPreview,
}: Props) => {
  const [isFocused, setIsFocused] = useState(false);
  const [key, setKey] = useState("");

  const [saveApiKey, { loading: isSaving, data: savedResponse, reset: resetSaved, error }] =
    useCreateLlmapiKeyMutation({
      refetchQueries: [FetchLlmModelsDocument, FetchWorkspaceSettingsDocument],
      errorPolicy: "all",
      onCompleted(data) {
        if (data) {
          setKey("");
        }
      },
    });

  const [deleteApiKey, { loading: isDeleting }] = useDeleteLlmapiKeyMutation({
    refetchQueries: [FetchLlmModelsDocument, FetchWorkspaceSettingsDocument],
    onCompleted: () => resetSaved(),
  });

  const showSaveButton = !!key || isFocused;

  return (
    <div className="grid gap-4 md:grid-cols-[210px_1fr]">
      <label htmlFor={provider} className="flex gap-8 items-center">
        <img src={iconSrc} width={20} height={20} />
        {name}
      </label>
      <div className="flex items-center gap-8">
        <div className="grow flex rounded border border-background-subtle has-[:disabled]:bg-background-ghost bg-background items-center pr-10">
          <input
            id={provider}
            value={keyPreview ? `••••••••••••••••••••••••••${keyPreview}` : key}
            placeholder="API key"
            className="grow py-8 px-10 bg-transparent disabled:text-text-subtle"
            disabled={!!keyPreview}
            onChange={event => setKey(event.target.value)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
          {isSaving && <Icon icon="LoaderCircle" size={20} className="animate-spin" />}
          {!!savedResponse && <Icon icon="Check" size={20} className="text-icon-success" />}
        </div>
        {!id && showSaveButton && (
          <Button
            type="button"
            buttonStyle="simplePrimary"
            disabled={isSaving}
            onClick={() => {
              saveApiKey({
                variables: {
                  input: {
                    llmProvider: provider,
                    key,
                    workspaceID,
                  },
                },
              });
            }}
          >
            Save
          </Button>
        )}
        {id && (
          <Button
            icon="Trash"
            type="button"
            disabled={isDeleting}
            buttonStyle="simplePrimary"
            iconClassName="text-icon-secondary"
            onClick={() => {
              deleteApiKey({
                variables: {
                  id,
                  workspaceID,
                },
              });
            }}
          />
        )}
      </div>

      {error && (
        <p className="m-0 mt-4 text-footnote text-text-alert md:col-start-2">{error.message}</p>
      )}
    </div>
  );
};
