import { createZustandStoreContext } from "create-zustand-store-context";

import { FiltersKeys, SharedFilters } from "../types";

type SharedFilterStore = {
  filter: FiltersKeys;
};

const defaultState: SharedFilterStore = {
  filter: SharedFilters.Recent,
};

const {
  storeContext,
  useState: useSharedFilterState,
  useStore,
} = createZustandStoreContext<SharedFilterStore>({
  defaultState,
});

const SharedStoreContext = storeContext;

const SharedFilterProvider = ({ children }: WithChildren) => {
  return <SharedStoreContext.Provider value={useStore()}>{children}</SharedStoreContext.Provider>;
};

export { SharedFilterProvider, useSharedFilterState };
