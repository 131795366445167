import { useMemo } from "react";
import { Link } from "react-router-dom";

import { Recipient } from "@utility-types";
import Avatar from "components/design-system/Avatar/Avatar";
import { parseItemData } from "components/ProfileItem/parseItemData";
import VerifiedDomains from "components/ProfileItem/VerifiedDomains";
import { routeToUser } from "components/routing/utils";
import { Skeleton } from "components/Skeleton";
import { cloneElementForSkeletons } from "components/Skeleton/Skeleton";
import getRandomInt from "utils/getRandomInt";

import { Results } from "../types";

const DeepSearchPerson = ({ recipient }: { recipient: Recipient }) => {
  const data = parseItemData(recipient);
  const domains = recipient.addressDomains;

  return (
    <Link key={recipient.id} to={routeToUser({ userID: recipient.id, to: "secondary" })}>
      <div
        className="bg-background-body hover:bg-background-list-hover mb-16 py-8 pr-16 pl-24 overflow-hidden md:rounded-lg shadow-level1"
        data-testid="deep-search-user"
      >
        <div className="flex items-center">
          <Avatar
            avatarURL={data.avatarURL}
            background={data.avatarURL ? "transparent" : "subtle"}
            margin="mr-8"
            name={data.name}
            rounded="rounded-md"
            size="medium"
          />
          <div className="">
            <div className="text-subhead-bold">{data.name}</div>
            <div className="flex items-baseline text-footnote text-text-subtle">
              {recipient.bio && <div className="">{recipient.bio}</div>}
              {recipient.bio && (domains?.length ?? 0) > 0 && (
                <div className="mx-4 shrink-0 text-footnote text-text-subtle">{"\u00B7"}</div>
              )}
              {(domains?.length ?? 0) > 0 && (
                <div className="mr-4 grow-0 shrink-0 text-footnote text-text-subtle">
                  <VerifiedDomains domains={domains} type={data.type} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

const DeepSearchPeopleSkeleton = () => (
  <div
    className="bg-background-body mb-16 py-8 pr-16 pl-24 overflow-hidden md:rounded-lg select-none shadow-level1"
    data-testid="deep-search-user"
  >
    <div className="flex gap-8 items-center">
      <Avatar loading size="medium" />
      <div className="">
        <div className="text-subhead-bold">
          <Skeleton height="22px" width={`${getRandomInt(200, 300)}px`} />
        </div>
      </div>
    </div>
  </div>
);

const DeepSearchPeople = ({
  searching,
  people,
}: { searching: boolean; people: Results<"users"> }) => {
  const skeletons = useMemo(() => cloneElementForSkeletons(<DeepSearchPeopleSkeleton />, 5), []);

  if (!searching && !people.edges.length)
    return (
      <div className="md:mt-[128px] px-24 py-16 text-center">
        <div className="text-body-bold text-text-primary">No people found</div>
        <div className="text-body text-text-secondary">
          Try using different keywords or check for typos.
        </div>
      </div>
    );

  return (
    <>
      {people.edges?.map(edge => (
        <DeepSearchPerson key={edge.id} recipient={edge.node} />
      ))}
      {searching && skeletons}
    </>
  );
};

export default DeepSearchPeople;
