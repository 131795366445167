import { Recipient } from "@utility-types";
import tw from "utils/tw";

import { Tooltip } from "../../FloatingUi";
import { Icon } from "../../icons";

import useIdentityBadgeData from "./hooks/useIdentityBadgeData";

type Props = {
  recipient?: Recipient;
  showLabel?: boolean;
};

const IdentityBadge = ({ recipient, showLabel = true }: Props) => {
  const { type, label, tooltip } = useIdentityBadgeData(recipient);

  if (!recipient?.id) return null;

  return (
    <div className="flex items-center select-none">
      {(type === "external" || type === "internal-with-guests") && (
        <Tooltip
          content={tooltip}
          placement="bottom"
          tooltipStyle="inverted"
          disabled={type === "internal-with-guests"}
        >
          <div className="flex items-center p-2 w-14 h-14 text-icon-warning bg-background-warning rounded-sm select-none">
            <Icon icon="ExternalAccount" size={10} strokeWidth={1} />
          </div>
        </Tooltip>
      )}
      {(type === "internal" || type === "internal-with-guests") && (
        <Tooltip content={tooltip} placement="bottom" tooltipStyle="inverted">
          <div
            className={tw(
              "flex items-center p-2 w-14 h-14 bg-background-subtle rounded-half text-icon-primary",
              { "ml-6": type === "internal-with-guests" }
            )}
          >
            <Icon icon="Check" size={10} strokeWidth={1} />
          </div>
        </Tooltip>
      )}
      {showLabel && <span className="ml-4 text-caption-bold text-text-secondary">{label}</span>}
    </div>
  );
};

export default IdentityBadge;
