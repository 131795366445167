import { ThreadsOrder } from "generated/graphql";

/**
 * The API provides dynamic cursors based on the requested data's sort order. Ideally, the client wouldn't need to handle them,
 * but since different views sort threads differently, cached cursors often don't match the API's updated cursors.
 *
 * Because we read from cache first, our cached cursors might be outdated, causing the displayed list to reshuffle when updated
 * cursors arrive from the API.
 *
 * To address this, formatThreadEdgeCursor predicts and uses correct cursors for the current view, replacing cached ones until
 * the API updates them, aiming to match the incoming API cursors.
 */
const formatThreadEdgeCursor = (
  threadOrder: ThreadsOrder,
  threadEdge: {
    cursor: string;
    isRead: boolean;
    isSeen: boolean;
  },
  threadID: string,
  lastMessageID: string | undefined
): string => {
  // The API cursors for sorting by unread and unseen are prefixed with 0- or 1- depending on read or unread.
  // Because the cached edge info might be newer, we strip and re-add the prefix later for local sorting.
  let lastMessageCursor = threadEdge.cursor.split("-").pop() ?? threadEdge.cursor;

  // We can only use Glue message IDs for sorting, Stream's are not k-ordered
  // Also, the API does not use the prefix in cursors, so we strip it here
  if (lastMessageID?.startsWith("msg_")) {
    lastMessageCursor = lastMessageID.split("_")[1] ?? lastMessageCursor;
  }

  switch (threadOrder) {
    case ThreadsOrder.Created:
      return threadID;
    case ThreadsOrder.LastMessage:
      return lastMessageCursor;
    case ThreadsOrder.Unread:
      return (threadEdge.isRead ? "0-" : "1-") + lastMessageCursor;
    case ThreadsOrder.Unseen:
      return (threadEdge.isSeen ? "0-" : "1-") + lastMessageCursor;
    default:
      return threadEdge.cursor;
  }
};

export default formatThreadEdgeCursor;
