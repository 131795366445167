import { ComponentProps, forwardRef, memo, useMemo } from "react";
import { Flipped } from "react-flip-toolkit";

import { nodeAs } from "@utility-types";
import { flipAnimate, flipSpring } from "components/Animated/utils";
import SwipeAction from "components/design-system/ui/SwipeAction/SwipeAction";
import { SwipeStyleProps, ThreadSwipeProps } from "components/design-system/ui/SwipeAction/types";
import { isMockChatEdge } from "components/thread/ThreadList/data/mockChatEdge";
import { ThreadSubscription } from "generated/graphql";
import { ThreadSelection } from "hooks/thread/useInboxThreadActions";
import useAuthData from "hooks/useAuthData";
import useComponentMounted from "hooks/useComponentMounted";
import { useLongPress } from "hooks/useLongPress";
import useUpdatingState from "hooks/useUpdatingState";
import { deviceHasMouse } from "utils/deviceHasMouse";
import tw from "utils/tw";

import { ThreadItem } from "../ThreadItem";
import { parseData } from "../ThreadItem/utils";

type ItemPressEvent = Parameters<Parameters<typeof useLongPress>["0"]>[0];

type Props = Omit<ComponentProps<typeof ThreadItem>, "itemData"> & {
  isSelected?: boolean;
  onClick?: (
    event: ItemPressEvent,
    item: Exclude<ComponentProps<typeof ThreadItem>["item"], undefined>,
    selecting: boolean
  ) => void;
  recipientID?: string;
  selection?: ThreadSelection;
} & SwipeStyleProps &
  ({ type?: undefined } | Omit<ThreadSwipeProps, "itemData" | "type">);

type PropsMemo = Props & ComponentProps<typeof ThreadItem>["itemData"];

const ThreadListItem = forwardRef<HTMLDivElement, Props>(
  (
    {
      canArchive = false,
      avatarComponent,
      avatarSize,
      className,
      isSelected = false,
      item,
      itemBulkMode,
      onClick,
      recipientID,
      ...props
    }: Props,
    ref
  ): JSX.Element => {
    const { authData } = useAuthData();

    const parsedData = useMemo(
      () => parseData({ authData, item, recipientID }),
      [authData, item, recipientID]
    );

    const threadEdge = nodeAs(item, ["ThreadEdge"]);

    return (
      <MemoizedThreadListItem
        ref={ref}
        avatarComponent={avatarComponent}
        avatarSize={avatarSize}
        canArchive={canArchive}
        className={className}
        isRead={threadEdge?.isRead ?? true}
        isSelected={isSelected}
        isStarred={threadEdge?.isStarred ?? false}
        isSubscribed={!threadEdge || threadEdge.subscription === ThreadSubscription.Inbox}
        item={item}
        itemBulkMode={itemBulkMode}
        onClick={onClick}
        recipientID={recipientID}
        {...parsedData}
        {...props}
      />
    );
  }
);

const MemoizedThreadListItem = memo(
  forwardRef<HTMLDivElement, PropsMemo>(
    (
      {
        canArchive,
        canRemind,
        avatarComponent,
        avatarSize,
        className = "",
        compact,
        isRead,
        isSelected: isSelectedProp = false,
        isStarred,
        isSubscribed,
        item,
        itemBulkMode,
        onClick,
        recipientID,
        selection,
        ...props
      }: PropsMemo,
      ref
    ): JSX.Element | null => {
      const hasMouse = deviceHasMouse();

      const isMounted = useComponentMounted();

      const [isSelected, setIsSelected] = useUpdatingState(isSelectedProp);

      const hasBulkEditMode = !!itemBulkMode;
      const bulkEditMode = hasBulkEditMode && itemBulkMode !== "default";

      const isThreadEdge = item?.__typename === "ThreadEdge";

      const isMockChat = isThreadEdge ? isMockChatEdge(item) : undefined;

      const onShortPress: (event: ItemPressEvent) => void = event => {
        if (!onClick || !item) return;

        if (!(event.target instanceof Element)) return;

        const clickAvatar = !!event.target.closest(".bulk-edit-target");

        onClick(event, item, clickAvatar || bulkEditMode);
      };

      const bindLongPress = useLongPress<HTMLDivElement>(
        event => {
          if (!onClick || !item) return;

          if (event.pointerType === "mouse") return;

          onClick(event, item, true);
        },
        {
          cancelOnMovement: 10,
          disableHaptic: true,
          onCancel: () => {
            if (!isMounted.current) return;

            setIsSelected(isSelectedProp);
          },
          onFinish: () => {
            if (!isMounted.current) return;

            setIsSelected(isSelectedProp);
          },
          onShortPress,
          onStart: () => setIsSelected(true),
        }
      ).handlers;
      const threadEdge = nodeAs(item, ["ThreadEdge"]);

      const setSwipedOpenItemId =
        "setSwipedOpenItemId" in props ? props.setSwipedOpenItemId : undefined;
      const swipedOpenItemId = "setSwipedOpenItemId" in props ? props.swipedOpenItemId : undefined;

      return (
        <Flipped
          flipId={props.id}
          onAppear={el => flipAnimate(el)}
          onExit={(_el, _i, remove) => remove()}
          spring={flipSpring}
        >
          <li className="list-none">
            <SwipeAction
              canRemind={canRemind}
              borderRadius="rounded-none"
              height={threadEdge?.node.isPersistentChat ? "min-h-[67px]" : "min-h-[84px]"}
              leftActionPadding="!pl-20"
              {...(!deviceHasMouse() &&
              !isMockChat &&
              !selection &&
              threadEdge &&
              setSwipedOpenItemId
                ? {
                    ...{
                      canArchive: !!canArchive,
                      dismissOnRightSwipe: !!canArchive,
                      itemData: threadEdge,
                      setSwipedOpenItemId: setSwipedOpenItemId,
                      swipedOpenItemId: swipedOpenItemId,
                      type: "thread",
                    },
                  }
                : { setSwipedOpenItemId: undefined })}
            >
              <ThreadItem
                ref={isSelected ? ref : undefined}
                canArchive={canArchive}
                canRemind={canRemind}
                avatarComponent={avatarComponent}
                avatarSize={avatarSize}
                className={tw(
                  "hover:bg-background-list-hover",
                  {
                    "!bg-background-list-selected/80 !text-interactive-strong": isSelected,
                  },
                  className,
                  threadEdge?.node.isPersistentChat
                    ? "[&>div]:max-h-[67px]"
                    : "[&>div]:max-h-[84px]"
                )}
                compact={compact}
                elementDOMAttributes={hasMouse ? { onPointerDown: onShortPress } : bindLongPress}
                isBulkEditMode={bulkEditMode}
                isRead={isRead}
                isStarred={isStarred}
                isSubscribed={isSubscribed}
                item={threadEdge}
                itemBulkMode={itemBulkMode}
                itemData={props}
                recipientID={recipientID}
                showSkeleton={props.showSkeleton}
              />
            </SwipeAction>
          </li>
        </Flipped>
      );
    }
  ),
  (prev, next) =>
    (() => {
      if (prev.item?.__typename !== "ThreadEdge") return true;
      if (next.item?.__typename !== "ThreadEdge") return true;

      return (
        prev.item?.isArchived === next.item?.isArchived &&
        prev.item?.remindAt === next.item?.remindAt
      );
    })() &&
    prev.isSelected === next.isSelected &&
    prev.isRead === next.isRead &&
    prev.isStarred === next.isStarred &&
    prev.isSubscribed === next.isSubscribed &&
    prev.itemBulkMode === next.itemBulkMode &&
    prev.id === next.id &&
    "swipedOpenItemId" in prev &&
    "swipedOpenItemId" in next &&
    prev.swipedOpenItemId === next.swipedOpenItemId &&
    prev.avatarComponent?.type === next.avatarComponent?.type &&
    (!(prev.avatarComponent && "avatarURL" in prev.avatarComponent.props) ||
      !(next.avatarComponent && "avatarURL" in next.avatarComponent.props) ||
      (prev.avatarComponent?.props.avatarURL === next.avatarComponent?.props.avatarURL &&
        prev.avatarComponent?.props.name === next.avatarComponent?.props.name)) &&
    prev.message === next.message &&
    prev.attachmentTypes.join() === next.attachmentTypes.join() &&
    prev.messageSender === next.messageSender &&
    prev.recipientID === next.recipientID &&
    prev.recipients === next.recipients &&
    prev.subject === next.subject &&
    prev.unreadCount === next.unreadCount &&
    prev.className === next.className
);

export default ThreadListItem;
