import { useMemo } from "react";

import { nodeIs, WorkspaceEdgeSimple } from "@utility-types";
import { UserFieldsFragment } from "generated/graphql-operations";
import useAuthData from "hooks/useAuthData";
import { isGroupEdgeMessageable } from "utils/group/isGroupEdgeMessageable";

import { GroupedResults, SearchResults } from "../types";

const addDisabledToEdgeNode =
  (workspaces: WorkspaceEdgeSimple[] | undefined, disableResults?: boolean) =>
  (edge: GroupedResults[number]["edges"][number]) => {
    if (
      disableResults &&
      nodeIs(edge, ["GroupOrPreviewEdge", "GroupEdge", "WorkspaceEdge"]) &&
      !isGroupEdgeMessageable(edge, workspaces)
    ) {
      // add disabled property to restricted groups for the user
      // this is used to disallow user selection of restricted groups via keyboard selection
      return {
        ...edge.node,
        disabled: true,
      };
    }

    return edge.node;
  };

const useFlattenedResults = ({
  searchResults,
  reverse = false,
  contacts,
  disableResults,
}: {
  searchResults?: SearchResults;
  reverse?: boolean;
  contacts?: UserFieldsFragment[];
  disableResults?: boolean;
}) => {
  const { authData } = useAuthData();

  return useMemo(() => {
    if (!searchResults) return [];
    const { instantResults, moreResults } = searchResults;

    const instantNodes = instantResults.flatMap(r =>
      r.edges.map(addDisabledToEdgeNode(authData?.workspaces?.edges, disableResults))
    );
    const moreNodes = moreResults?.flatMap(r =>
      r.edges.map(addDisabledToEdgeNode(authData?.workspaces?.edges, disableResults))
    );

    return reverse
      ? [...(moreNodes ?? []), ...(contacts ?? []), ...instantNodes]
      : [...instantNodes, ...(contacts ?? []), ...(moreNodes ?? [])];
  }, [searchResults, reverse, contacts, authData?.workspaces?.edges, disableResults]);
};

export default useFlattenedResults;
