import { createZustandStoreContext } from "create-zustand-store-context";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router";

import useAppDrawerStore from "components/AppDrawer/useAppDrawerStore";
import { routeParams as getRouteParams } from "components/routing/utils";
import { useLayerState } from "providers/LayerProvider";
import useAppStateStore from "store/useAppStateStore";
import useModalStore from "store/useModalStore";
import generateRandomId from "utils/generateRandomId";
import tw from "utils/tw";

export type PartitionStore = {
  activeThreadId: string;
  d?: string;
  focusedThreadId: string;
  isActivePartition: boolean;
  isActiveThread: (id: string) => boolean;
  partitionId?: string;
  route: string;
  setFocusedThreadId: React.Dispatch<React.SetStateAction<string>>;
  threadID?: string;
};

const defaultState: PartitionStore = {
  activeThreadId: "",
  d: undefined,
  focusedThreadId: "",
  isActivePartition: false,
  isActiveThread: () => false,
  route: "",
  setFocusedThreadId: () => "",
  threadID: undefined,
};

const {
  storeContext,
  useState: usePartitionState,
  useStore: usePartitionStore,
} = createZustandStoreContext<PartitionStore>({
  defaultState,
  name: "PartitionProvider",
});

const PartitionStoreContext = storeContext;

const RoutingPartition = ({
  children,
  className = "flex h-full grow min-w-0",
  classNames = {
    off: "absolute invisible inset-0 -z-1", // position: absolute requires inset instead of w/h
    on: "",
  },
  defaultRoute = "",
  isActive,
  unmountInactive = true,
}: {
  className?: string;
  classNames?: {
    off?: string;
    on?: string;
  };
  defaultRoute?: string;
  isActive: boolean;
  // Unmount inactive partitions to avoid unexpected interactions.
  // When we're able to resolve issues with components looking at location
  // rather than partition state, we can try removing this.
  unmountInactive?: boolean;
} & WithChildren) => {
  const partitionId = useMemo(() => generateRandomId(), []);

  const { layerIsActive } = useLayerState(({ layerIsActive }) => ({
    layerIsActive,
  }));
  const { topModalId } = useModalStore(({ topModalId }) => ({ topModalId }));

  const location = useLocation();
  const { d, threadID } = getRouteParams(location);

  const routeRef = useRef("");

  const [focusedThreadId, setFocusedThreadId] = useState("");

  const partitionIsActive = isActive && layerIsActive && !topModalId;

  const activeThreadId = partitionIsActive ? focusedThreadId : "";

  const isActiveThread = useCallback((id: string) => id === activeThreadId, [activeThreadId]);

  const currentStore = usePartitionStore(undefined, {
    activeThreadId,
    d,
    focusedThreadId,
    isActivePartition: isActive,
    isActiveThread,
    partitionId,
    route: defaultRoute,
    setFocusedThreadId,
    threadID,
  });

  useEffect(
    () =>
      currentStore.setState({
        activeThreadId,
        d,
        isActivePartition: isActive,
        threadID,
      }),
    [activeThreadId, currentStore, d, isActive, threadID]
  );

  useEffect(() => {
    if (!isActive) return;
    useAppStateStore.setState({ activeThreadId });
  }, [activeThreadId, isActive]);

  useEffect(() => {
    const nextRoute = `${location.pathname}${location.search}${location.hash}`;

    if (!isActive || nextRoute === routeRef.current) return;

    routeRef.current = nextRoute;
    currentStore.setState({ route: nextRoute });
  }, [currentStore, isActive, location]);

  const { payload } = useAppDrawerStore(({ payload }) => ({
    payload,
  }));

  const isSplit = !!d || !!payload;

  if (!isActive && unmountInactive) return null;

  return (
    <div
      className={tw(
        className,
        isActive ? classNames.on : classNames.off,
        isSplit ? "group/layout-columns is-split" : ""
      )}
    >
      <PartitionStoreContext.Provider value={currentStore}>
        {children}
      </PartitionStoreContext.Provider>
    </div>
  );
};

export { RoutingPartition, usePartitionState };
