import { useState } from "react";
import { useFormContext } from "react-hook-form";

import { Workspace } from "@utility-types";
import { Button } from "components/design-system/Button";
import { Form } from "components/design-system/Forms";
import { Main } from "components/ModalKit";
import { ModalProps } from "components/ModalKit/Modal";
import { StandardModal } from "components/Modals";
import ChoosePlan from "components/Payment/ChoosePlan";
import Payment from "components/Payment/Payment";
import tw from "utils/tw";

type View = "choosePlan" | "payment";

const FormContent = ({
  view,
  workspace,
  onComplete,
}: {
  view: View;
  workspace: Pick<Workspace, "id" | "name" | "avatarURL">;
  onComplete: () => void;
}) => {
  const { watch } = useFormContext();
  const planID = watch("plan");

  return view === "choosePlan" ? (
    <ChoosePlan workspace={workspace} />
  ) : (
    <Payment planID={planID} workspaceID={workspace.id} onComplete={onComplete} />
  );
};

/** Only rendered in case user is running the app in mobile web */
const WorkspaceSubscriptionModal = ({
  workspace,
  onComplete,
  ...props
}: {
  workspace: Pick<Workspace, "id" | "name" | "avatarURL">;
  onComplete: () => void;
} & ModalProps) => {
  const [view, setView] = useState<View>("choosePlan");

  return (
    <StandardModal {...props} closeButton={false}>
      {view === "payment" && (
        <div className="absolute top-0 left-0 w-full h-full bg-background-white z-0" />
      )}
      <Main className="z-1">
        <Form className="flex flex-col grow w-full gap-16 text-start overflow-y-auto p-16 pb-[72px]">
          <FormContent view={view} workspace={workspace} onComplete={onComplete} />
        </Form>
        <div
          className={tw(
            "flex fixed bottom-0 px-12 py-16 w-full border-t-1",
            view === "payment"
              ? "justify-start bg-background-white"
              : "justify-end bg-background-body border-border-container"
          )}
        >
          {view === "payment" && (
            <Button onClick={() => setView("choosePlan")} buttonStyle="secondary" icon="ArrowLeft">
              Back
            </Button>
          )}
          {view === "choosePlan" && (
            <Button onClick={() => setView("payment")} icon="ArrowRight" iconLast>
              Next
            </Button>
          )}
        </div>
      </Main>
    </StandardModal>
  );
};

export default WorkspaceSubscriptionModal;
