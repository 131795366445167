import tw from "utils/tw";

const Hr = ({
  colorClassName = "border-t-border-container",
  ...props
}: { colorClassName?: string } & React.HTMLAttributes<HTMLHRElement>) => (
  <hr {...props} className={tw("h-0 border-t-1", colorClassName, props.className)} />
);

export default Hr;
