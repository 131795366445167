import { useChatContext, useTypingContext } from "stream-chat-react";

import Avatar from "components/design-system/Avatar/Avatar";
import tw from "utils/tw";

const TypingIndicator = (): JSX.Element | null => {
  const { typing } = useTypingContext();
  const { client } = useChatContext();

  if (!typing || !client) {
    return null;
  }

  const typingInChannel = Object.values(typing).filter(({ user }) => user?.id !== client.user?.id);

  return (
    <div
      className={tw(
        "typing-indicator flex items-center h-24 mb-10 pl-28",
        typingInChannel.length ? "typing-indicator--typing" : ""
      )}
    >
      <div className="flex mr-8">
        {typingInChannel.map(({ user }) => (
          <Avatar
            key={user?.id}
            avatarURL={user?.image}
            border="background-body-1"
            name={user?.name || ""}
            margin="-mr-8"
            size="small"
          />
        ))}
      </div>
      <div className="pl-12">
        <span className="typing-indicator__dot" />
        <span className="typing-indicator__dot" />
        <span className="typing-indicator__dot" />
      </div>
    </div>
  );
};

export default TypingIndicator;
