import { useCallback, useEffect, useState } from "react";

import { ModalProps } from "components/ModalKit/Modal";
import { useModalState } from "components/ModalKit/ModalProvider";
import SuperModal from "components/Modals/SuperModal";
import { AccountSettingsView, WorkspaceSettingsView } from "components/SettingsModal/types";
import useAppStateStore from "store/useAppStateStore";

import { AccountSettings } from "./views/AccountSettings";
import { WorkspaceSettings } from "./views/WorkspaceSettings";

const SettingsModal = ({
  subView,
  view: viewProp,
}: {
  subView?: WorkspaceSettingsView;
  view?: AccountSettingsView;
} & ModalProps) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  const { modalId } = useModalState(({ modalId }) => ({
    modalId,
  }));

  const [view, setView] = useState<AccountSettingsView | undefined>(viewProp);

  // we only need the subView when first opening the modal; after that, the prop value should be ignored
  const [initialSubView, setInitialSubView] = useState<WorkspaceSettingsView | undefined>(subView);
  const resetInitialSubView = useCallback(() => {
    if (initialSubView) {
      setInitialSubView(undefined);
    }
  }, [initialSubView]);

  // if the view is not set, set it to the default view when resizing the modal to desktop size
  useEffect(() => {
    if (!breakpointMD || view) return;
    setView(viewProp ?? "profile");
  }, [breakpointMD, view, viewProp]);

  const isWorkspace = view?.startsWith("wks_");

  return (
    <SuperModal>
      <div className="flex relative h-full w-full">
        {isWorkspace ? (
          <WorkspaceSettings
            modalId={modalId}
            parentView={view}
            resetInitialSubView={resetInitialSubView}
            setParentView={setView}
            subView={initialSubView}
          />
        ) : (
          <AccountSettings modalId={modalId} setView={setView} view={view} />
        )}
      </div>
    </SuperModal>
  );
};

export default SettingsModal;
