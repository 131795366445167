import { useMemo } from "react";
import { Link } from "react-router-dom";

import { Recipient } from "@utility-types";
import Avatar from "components/design-system/Avatar/Avatar";
import Hr from "components/design-system/Hr";
import { Icon } from "components/design-system/icons";
import IdentityBadge from "components/design-system/ui/IdentityBadge/IdentityBadge";
import { parseItemData } from "components/ProfileItem/parseItemData";
import { routeToGroup } from "components/routing/utils";
import { Skeleton } from "components/Skeleton";
import { cloneElementForSkeletons } from "components/Skeleton/Skeleton";
import { JoinableBy } from "generated/graphql-types";
import useRecipientDomains from "hooks/workspace/useRecipientDomains";
import getRandomInt from "utils/getRandomInt";
import { formatGroupName } from "utils/group/formatGroupName";

import { Results } from "../types";

const DeepSearchGroup = ({ recipient }: { recipient: Recipient }) => {
  const data = parseItemData(recipient);
  const { emoji, name: formattedName } = formatGroupName({
    id: data.id,
    name: data.name,
  });
  const { workspace } = useRecipientDomains(recipient);
  const isArchived = recipient.archivedAt;
  const isClosed = recipient.joinableBy !== JoinableBy.Workspace;
  const isPreview = recipient.__typename === "GroupPreview";
  const groupRoute =
    (isClosed || isArchived) && isPreview
      ? routeToGroup({ groupID: recipient.id, to: "secondary" })
      : routeToGroup({ groupID: recipient.id });

  return (
    <Link key={recipient.id} to={groupRoute}>
      <div
        className="bg-background-body hover:bg-background-list-hover py-8 px-20 overflow-hidden md:rounded-lg select-none shadow-level1"
        data-testid="deep-search-group"
      >
        <div className="flex items-center">
          <Avatar
            avatarURL={data.avatarURL}
            background="transparent"
            emojiProps={{ emoji }}
            margin="mr-8"
            name={data.name}
            rounded="rounded-md"
            size="medium"
          />
          <div className="my-1">
            <div className="flex flex-row gap-4 items-center">
              <div className="text-subhead-bold text-text-primary">{formattedName}</div>
              {isClosed && <Icon icon="LockFilled" size={16} className="text-icon-secondary" />}
              {isArchived && (
                <Icon icon="ArchiveFilled" size={16} className="text-icon-secondary" />
              )}
            </div>
            <div className="flex gap-6 items-center self-stretch">
              {workspace && <div className="text-footnote text-text-subtle">{workspace.name}</div>}
              <IdentityBadge recipient={recipient} />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

const DeepSearchGroupsSkeleton = () => (
  <div
    className="bg-background-body mb-16 py-8 pr-16 pl-24 overflow-hidden md:rounded-lg shadow-level1"
    data-testid="deep-search-group"
  >
    <div className="flex gap-8 items-center">
      <Avatar loading size="medium" />
      <div className="">
        <div className="text-subhead-bold">
          <Skeleton height="22px" width={`${getRandomInt(200, 300)}px`} />
        </div>
      </div>
    </div>
  </div>
);

const LabeledDivider = ({ label }: { label: string }) => (
  <div className="flex flex-row gap-12 justify-center items-center py-4 select-none">
    <Hr className="grow" />
    <div className="text-footnote-bold text-text-subtle">{label}</div>
    <Hr className="grow" />
  </div>
);

const DeepSearchGroups = ({
  searching,
  groups,
  archivedGroups,
}: {
  searching: boolean;
  groups: Results<"groups">;
  archivedGroups: Results<"archivedGroups">;
}) => {
  const skeletons = useMemo(() => cloneElementForSkeletons(<DeepSearchGroupsSkeleton />, 5), []);

  if (!searching && !groups.edges.length && !archivedGroups.edges.length)
    return (
      <div className="md:mt-[128px] px-24 py-16 text-center">
        <div className="text-body-bold text-text-primary">No groups found</div>
        <div className="text-body text-text-secondary">
          Try using different keywords or check for typos.
        </div>
      </div>
    );

  return (
    <div className="flex flex-col gap-16 pb-16">
      {groups.edges?.map(edge => (
        <DeepSearchGroup key={edge.id} recipient={edge.node} />
      ))}

      {searching && skeletons}

      {!searching && !!archivedGroups.edges?.length && (
        <>
          <LabeledDivider label="Archived" />
          {archivedGroups.edges?.map(edge => (
            <DeepSearchGroup key={edge.id} recipient={edge.node} />
          ))}
        </>
      )}
    </div>
  );
};

export default DeepSearchGroups;
