import { versionInfo } from "components/App/version";

const AppVersion = () => {
  return (
    <div className="px-20 md:px-32 pb-32 text-text-disabled" data-testid="app-version">
      Version: <span className="select-text">{versionInfo.fullVersionString}</span>
    </div>
  );
};

export default AppVersion;
