import { CSSProperties } from "react";

import tw from "utils/tw";

type Variant = "default" | "strong";

export const TabCountBadge = ({
  className,
  count,
  variant = "default",
}: { className: string; count: number; variant?: Variant }) => {
  return (
    <div
      className={tw(
        className,
        "before:flex before:items-center before:justify-center before:min-w-[16px] before:h-16 before:px-4 before:pb-1 before:rounded-half",
        "text-xs font-semibold text-text-action-inverse before:content-[var(--count)]",
        variant === "strong" ? "before:bg-background-alert-strong" : "before:bg-background-alert"
      )}
      style={
        {
          "--count": `'${count <= 99 ? count : `${99}+`}'`,
        } as CSSProperties
      }
    />
  );
};

export const TabDotBadge = ({
  className,
  variant = "default",
}: { className: string; variant?: Variant }) => {
  return (
    <div
      className={tw(
        className,
        "before:block before:w-6 before:h-6 before:content-[''] before:rounded-full",
        variant === "strong" ? "before:bg-background-alert-strong" : "before:bg-background-alert"
      )}
    />
  );
};
